import React, { useState, useEffect } from 'react'
import { Card, List, Row, Col, message, Table, Empty, Typography } from 'antd'
import {
  EyeFilled,
  EditFilled,
} from '@ant-design/icons';
import moment from 'moment';
import "../style.less"

const CallActivity = (props) => {
  const { item } = props

  return (
    <div className="activity-card">
      <table>
        {/* First Row */}
        <tr>
          <td>
            <strong>Call Activity </strong>
          </td>
          <td rowSpan={4} className="vat bb-none">
            {
              item.remarks && item.remarks.length ?
                <>
                  <strong>Remarks:</strong><br />
                  {item.remarks}
                </>
                : null}
            {
              item.createdBy &&
              <div>
                <strong>Employee:</strong> {item.createdBy.profile.employeeName}
              </div>
            }
          </td>
          <td align="center">
            <strong>{item.createdAt ? moment(item.createdAt).format('DD-MM-YYYY HH:mm') : ' -'}</strong>
          </td>
        </tr>
        {/* Second Row */}
        <tr>
          <td rowSpan={2}>
            {
              item.callHistory ?
                <><strong>Phone No: </strong> {item.callHistory.phone2}<br /></> :
                <><strong>Phone No: </strong> {item.phone}<br /></>
            }
            {
              item.callHistory ?
                <><strong>Duration: </strong> {item.callHistory.duration}<br /></> : null
            }
          </td>
          <td align="center" rowSpan={2}>
            {
              item.enquiryType ?
                <strong className={`center pa ${item.enquiryType}`}>{item.enquiryType}</strong> : null
            }
          </td>
        </tr>
        {/* Third Row */}
        <tr>
        </tr>
        <tr>
          <td>
            {
              item.scheduleDateAndTime ?
                <>
                  <strong>FollowUp Date: </strong>{moment.utc(item.scheduleDateAndTime).format("DD-MM-YYYY")}<br />
                  <strong>FollowUp Time: </strong>{moment.utc(item.scheduleDateAndTime).format("HH:mm")}
                </>
                : null
            }
          </td>
          <td>
            <Row>
              <Col className="center pointer icon" onClick={() => openEditor(item.id)} span={12}>
                <EyeFilled />
              </Col>
              <Col className="bl center pointer icon" onClick={() => openEditor(item.id, true)} span={12}>
                <EditFilled />
              </Col>
            </Row>
            {item?.bucketUrl && (
              <Row justify="center" style={{ marginTop: '20px' }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <audio controls style={{ width: '100%', height: '30px' }}>
                    <source 
                      src={item?.bucketUrl} 
                      type="audio/mp3" 
                    />
                    Your browser does not support the audio element.
                  </audio>
                </Col>
              </Row>
            )}
          </td>
        </tr>
      </table>
    </div>
  )
}

export default CallActivity;
