import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const FinanceForm = ({ editable, onDataChange, initialData, emiRows, setEmiRows }) => {
  const initialState = [{ id: 1, tenure: null, emi: "" }];

  useEffect(() => {
    if (initialData) {
      try {
        const parsedData = JSON.parse(initialData);
        if (Array.isArray(parsedData) && parsedData.length > 0) {
          const populatedRows = parsedData.map((item, index) => ({
            id: index + 1,
            tenure: item.tenure || "",
            emi: item.emi || "",
          }));
          setEmiRows(populatedRows);
        }
      } catch (error) {
        console.error("Error parsing initialData:", error);
      }
    } else {
      setEmiRows(initialState);
    }
  }, [initialData]);

  const updateParent = (rows) => {
    const payload = {
      data: rows
        .filter((row) => row.tenure && row.emi)
        .map(({ tenure, emi }) => ({ tenure, emi: Number(emi) })),
    };
    onDataChange(payload);
  };

  const addRow = () => {
    const updatedRows = [...emiRows, { id: Date.now(), tenure: null, emi: "" }];
    setEmiRows(updatedRows);
    updateParent(updatedRows);
  };

  const handleInputChange = (id, field, value) => {
    setEmiRows((prevRows) => {
      const updatedRows = prevRows.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
  
      const currentRowIndex = updatedRows.findIndex((row) => row.id === id);
      const isLastRow = currentRowIndex === updatedRows.length - 1;
      const currentRow = updatedRows[currentRowIndex];
  
      // Add row only if editing the last row and something has been entered
      if (editable && isLastRow && (currentRow.tenure || currentRow.emi)) {
        updatedRows.push({ id: Date.now(), tenure: null, emi: "" });
      }
  
      // Push updated data to parent
      updateParent(updatedRows);
      return updatedRows;
    });
  };
  

  const removeRow = (id) => {
    let updatedRows = emiRows.filter((row) => row.id !== id);
    if (updatedRows.length === 0) {
      updatedRows = initialState;
    }
    setEmiRows(updatedRows);
    updateParent(updatedRows);
  };

  return (
    <>
      <Row gutter={8} style={{ fontWeight: "bold", marginBottom: "5px" }}>
        <Col span={10}>Tenure</Col>
        <Col span={10}>EMI</Col>
        <Col span={4}></Col>
      </Row>

      {emiRows.map((row) => (
        <Row key={row.id} gutter={8} align="middle" style={{ marginBottom: "5px" }}>
          <Col span={10}>
            <Input
              type="number"
              value={row.tenure}
              onChange={(e) => {
                const value = e.target.value ? Number(e.target.value) : "";
                if (value >= 0 && value <= 60) {
                  handleInputChange(row.id, "tenure", value);
                }
              }}
              placeholder="Enter Tenure (Max 60)"
              max={60}
              style={{ width: "100%" }}
              disabled={!editable}
            />
          </Col>

          <Col span={10}>
            <Input
              type="number"
              value={row.emi}
              onChange={(e) => handleInputChange(row.id, "emi", e.target.value)}
              placeholder="Enter EMI"
              disabled={!editable}
            />
          </Col>

          <Col span={4}>
            {editable && (
              <Button
                type="text"
                icon="delete"
                onClick={() => removeRow(row.id)}
              />
            )}
          </Col>
        </Row>
      ))}
    </>
  );
};

export default FinanceForm;
