import React, { useEffect, useState, useContext } from "react";
import {
  Form,
  Select,
  Typography,
  Row,
  Col,
  Button,
  Input,
  DatePicker,
  message,
  Tag,
} from "antd";
import { platformApi } from "../../../../../api";
import { ContextAPI } from "../../../../../ContextAPI";
import LinkQuotationModal from "./LinkQuotationModal";

import moment from "moment";
const { Text } = Typography;
import { customerDataJoi, advancedCustomerDataJoi } from "../../BookingRegisterJoi";
import { generateErrorObj, defaultToNull, formatValue } from "../../../../../utils";
import { number, required } from "@hapi/joi";
import { useBranch } from "../../../../../ContextAPI/branch";
const CustomerData = ({
  form,
  open,
  validityRef,
  clearRef,
  data,
  editable,
  customerId,
  setCustomerId,
  customerAvailable,
  setCustomerAvailable,
  mode,
}) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const [linkQuotationVisibility, setLinkQuotationVisibility] = useState(false);
  const [linkedQuotations, setLinkedQuotations] = useState([]);
  const [customerQuotations, setCustomerQuotations] = useState([]);
  const [bookingAuthStatus, updateBookingAuthStatus] = useState(false);

  //login cred
  const { loginCredintials } = useContext(ContextAPI);
  const { selectBranch } = useBranch()
  //current values of customer
  const [presentCustomerValues, SetPresentCustomerValues] = useState(null);
  const [users, setUser] = useState([]);
  //customer updated details
  const [customerDetails, setCustomerDetails] = useState({});
  //phno valid check
  const [validNum, setValidNum] = useState(false);
  //error msg obj
  const [errorObj, setErrorObj] = useState({});
  // select field values Data
  const [employeeBranch, setEmployeeBranch] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [refferedByData, setRefferedByData] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [country, setCountry] = useState([]);
  const [quotations, setQuotations] = useState([]);

  const relation = [
    { key: "Father", title: "Father" },
    { key: "Mother", title: "Mother" },
    { key: "Sister", title: "Sister" },
    { key: "Brother", title: "Brother" },
    { key: "Daughter", title: "Daughter" },
    { key: "Son", title: "Son" },
    { key: "Wife", title: "Wife" },
    { key: "Husband", title: "Husband" },
    { key: "Father-in-law", title: "Father-in-law" },
    { key: "Mother-in-law", title: "Mother-in-law" },
    { key: "Daughter-in-law", title: "Daughter-in-law" },
    { key: "Son-in-law", title: "Son-in-law" },
    { key: "Sister-in-law", title: "Sister-in-law" },
    { key: "Brother-in-law", title: "Brother-in-law" },
  ];
  // select filter
  const filterMethod = (input, option) =>
    option.props.children.toString().toLowerCase().indexOf(input) >= 0;

  const linkQuotation = (linkQuotations, save = false) => {
    try {
      if (save) {
        message.success("Linked the Quotation!")
        setLinkedQuotations(linkQuotation)
        // to send data to be

        let quotationArr = getFieldValue("quotation") || []
        let tocheck = quotationArr.concat(linkQuotations)
        let updatedQuotations = []
        quotations.map((data) => {
          if (tocheck.includes(data.id))
            updatedQuotations.push(data.quotationId)
        })
        let arrayUniqueByKey = [...new Set(tocheck)];
        setCustomerQuotations(arrayUniqueByKey)
        form.setFieldsValue({
          quotation: arrayUniqueByKey,
          quotationsAssociated: updatedQuotations,
        })
      }
      setLinkQuotationVisibility(!linkQuotationVisibility);
    }
    catch (err) {
      message.error(err.message)
    }
  }

  const getCustomerDetails = (id) => {
    platformApi.get(`/api/customer/${id}`).then((res) => {
      setCustomerDetails(res.data.response.data)
    });
  }
  // customer search
  const dropdownSearch = (search) => {
    if (!data.customer)
      platformApi
        .post("/api/options/get", {
          module: search.module,
          column: search.column,
          searchString: search.searchString,
          fields: search.fields,
          size: 20,
          page: 1,
        })
        .then((res) => {
          search.setValue(res.data.response);
        });
    else
      platformApi
        .post("/api/customer/get", {
          page: 1,
          size: 20,
          searchString: data.customer.contacts[0].phone,
        })
        .then((res) => {
          search.setValue(res.data.response.data.customer);
        });
  };
  //gets reffered by list
  const getCustomers = (search) => {
    platformApi
      .post("/api/options/get/", {
        module: search.module,
        column: search.column,
        searchString: search.searchString,
        fields: search.fields,
        size: 20,
        page: 1,
        except: form.getFieldValue('customer') || data.customer && data.customer.id || null,
        include: form.getFieldValue('refferedBy') || data.refferedBy && data.refferedBy.id || null
      })
      .then((res) => {
        // search.setValue(res.data.response);
        setRefferedByData([...res.data.response]);
      });

  };

  //control state data
  const handleCountryChange = (id) => {
    // form.setFieldsValue({ state: undefined })
    platformApi
      .post("api/csc/states", { id })
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          setState(data.data);

        }
      })
      .catch((error) => {
        console.error("Error on Customer form : ", error);
        message.error("Unable to fetch state", 2);
      });
  };
  //gets employee branch list
  const getEmployeeBranch = (id) => {
    try {
      if (id) {
        form.setFieldsValue({
          salesExecutive: id
        })
        platformApi.get(`/api/user/getUser/${id}`).then((res) => {
          if (res.data.code === 200) {
            let resData = res.data;
            // console.log("res.data", res.data)
            setEmployeeBranch(resData.data.profile.branch);
            let defaultBranch = selectBranch
            // if (resData.data.profile.branch.length === 1) {
            form.setFieldsValue({
              branch: defaultBranch[0].id,
              salesExecutive: resData.data.id
            });
            getQuotations(defaultBranch[0].id);
            // }
            data.branchManufacturer = defaultBranch[0]
              .manufacturer
              ? defaultBranch[0].manufacturer
              : [];
          }
        });
      }
    } catch (err) {
      message.err(err.message)
    }
  };
  //setting up branch based manufacturer for next tab
  const settingBranchBasedManufacturer = (id) => {
    employeeBranch.map((eachBranch) => {
      if (eachBranch.id === id) {
        data.branchManufacturer = eachBranch.manufacturer;
      }
    });
  };
  const getQuotations = (branch = null) => {
    platformApi
      .get(`/api/quotation?branch=${branch}`)
      .then((result) => {
        const {
          data: {
            code,
            response: { data },
          },
        } = result;
        if (code === 200) {
          setQuotations(data);
        }
      })
      .catch((error) => {
        console.error("Error on Customer form : ", error);
        message.error("Unable to fetch country", 2);
      });
  }

  //control city data
  const handleStateChange = (id) => {
    // form.setFieldsValue({ city: undefined })
    platformApi
      .post("api/csc/cities", { id })
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          setCity(data.data);
        }
      })
      .catch((error) => {
        console.error("Error on Customer form : ", error);
        message.error("Unable to fetch cities", 2);
      });
  };

  useEffect(() => {
    if (open) {
      platformApi.get("/api/csc/country")
        .then((result) => {
          const { data } = result;
          if (data.code === 200) {
            setCountry(data.data);
            if (data.data.length === 1) {
              console.log(data.data)
              form.setFieldsValue({ country: data?.data[0]?.id });
              handleCountryChange(data?.data[0]?.id);
            }
          }
        })
        .catch((error) => {
          console.error("Error on Customer form:", error);
          message.error("Unable to fetch country", 2);
        });
  
      getQuotations();
    }
    // console.log("Received data", data, data.executive)
    if (!data.executive) {
      platformApi.get("/api/user/currentUser").then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setFieldsValue({
              salesExecutive: response.data.id,
            });
            getEmployeeBranch(response.data.id);
          }
        }
      });
    }
    else {
      form.setFieldsValue({
        salesExecutive: data.executive.id
      })
      getEmployeeBranch(data.executive.id);
    }
    //sales ececutive setup
    //salesExecutive data for select
    platformApi
      .get("/api/user/all")
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            // console.log("All user", response.data)
            setUser(response.data);
          }
        }
      })
      .catch((error) => {
        message.error("Unable to fetch Users", 1);
        console.error("Error on Quotations Form : ", error);
      });
    // search format for customer search using ph no
    const search = {
      module: "customers",
      column: "name",
      fields: ["contacts{phone}"],
      setValue: setCustomerData,
    };
    const searchReffered = {
      module: "customers",
      column: "name",
      fields: ["contacts{phone}"],
      setValue: setRefferedByData,
    };

    dropdownSearch(search);
    getCustomers(searchReffered);
    console.log("received data", data)
    if (data.customer) {
      setCustomerAvailable(true);
      updateBookingAuthStatus((data.authentication && data.authentication.verifiedAt) || false)
      let branch = data.branch && data.branch.id
      //set up cutomer data in all fields
      setCustomerFields(data.customer.id, true, branch, false);
      //set up cutomer data for this module
      setCustomerDetails(data.customer);
      setCustomerQuotations(data.quotation)
      settingBranchBasedManufacturer((data.branch && data.branch.id) || null);
      let isPhnumValid = data.customer.contacts[0].phone.length === 10;
      setValidNum(isPhnumValid);
      form.setFieldsValue({
        customer: data.customer.id,
        salesExecutive: data.executive && data.executive.id,
        nominee: data.nomineeName,
        nomineeAge: data.nomineeAge,
        relationship: data.relationship,
        branch: data.branch.id,
        refferedBy: data.refferedBy && data.refferedBy.id,
      });
      getEmployeeBranch(data.executive && data.executive.id);
    }
    else if (data.customerName) {
      setCustomerAvailable(false);
      updateBookingAuthStatus((data.authentication && data.authentication.verifiedAt) || false)
      let branch = data.branch && data.branch.id
      setCustomerId(data.customerId);//make it null
      let quotation = [];
      let quotationIdArr = [];
      data.quotation.map((e) => {
        quotation.push(e.quotationId);
        quotationIdArr.push(e.id);
      });

      form.setFieldsValue({
        customer: data.customerName,
        customerId: data.customerId,
        fatherName: data.customerFatherName,
        phone: data.customerPhone,
        DOB: moment(data.customerDob),
        gender: data.customerGender,
        locality: data.customerLocality,
        state: data.customerState,
        country: data.customerCountry,
        city: data.customerCity,
        street: data.customerAddress,
        line1: data.customerLine1 ? data.customerLine1 : data.customerAddress,
        line2: data.customerLine2,
        line3: data.customerLine3,
        pincode: data.customerPhone,
        email: data.customerEmail,
        quotationsAssociated: quotation,
        quotation: data.quotation,
      });

      const searchReffered = {
        module: "customers",
        column: "name",
        fields: ["contacts{phone}"],
        setValue: setRefferedByData,
      };
      getCustomers(searchReffered);
      getFieldValue("country") &&
        handleCountryChange(getFieldValue("country"));
      getFieldValue("state") && handleStateChange(getFieldValue("state"));
      SetPresentCustomerValues(form.getFieldsValue());
      setErrorObj({})

      setCustomerDetails({
        customerId: data.customerId,
        name: data.customerName,
        gender: data.customerGender,
        father: data.customerFatherName,
        // address: data.customerAddress,
        line1: data.customerLine1,
        line2: data.customerLine2,
        line3: data.customerLine3,
        phone: data.customerPhone,
        locality: data.customerLocality,
        country: data.customerCountry,
        state: data.customerState,
        city: data.customerCity,
        pincode: data.customerPincode,
        email: data.customerEmail,
        dob: data.customerDob
      });
      setCustomerQuotations(data.quotation)
      settingBranchBasedManufacturer((data.branch && data.branch.id) || null);
      let isPhnumValid = data.customerPhone && data.customerPhone.length === 10;
      setValidNum(isPhnumValid);
      form.setFieldsValue({
        // customer: data.customer,
        salesExecutive: data.executive && data.executive.id,
        nominee: data.nomineeName,
        nomineeAge: data.nomineeAge,
        relationship: data.relationship,
        branch: data.branch.id,
        refferedBy: data.refferedBy && data.refferedBy.id,
      });
      getEmployeeBranch(data.executive && data.executive.id);
    }
  }, [open, data]);
  //reset form
  useEffect(() => {
    clearRef.current = () => {
      form.resetFields();
      setValidNum(false);
      setCustomerDetails([]);
      setErrorObj({});
      setState([]);
      setCity([]);
      SetPresentCustomerValues(null)
      updateBookingAuthStatus(null)
    };
    validityRef.current = () => {
      return validatePage();
    };
    return () => {
      validityRef.current = null;
      clearRef.current = null;
    };
  });

  const validateOnBlur = () => {
    let customerValidation;
    if (mode == "ConfirmBooking") {
      customerValidation = customerDataJoi(
        defaultToNull(form.getFieldsValue())
      );
    }
    else {
      console.log('else');
      customerValidation = advancedCustomerDataJoi(
        defaultToNull(form.getFieldsValue())
      );
    }
    if (customerValidation.error) {
      let generatedError = generateErrorObj(customerValidation, {})
      setErrorObj(generatedError)
    }
    else {
      setErrorObj({})
    }
  }

  const validatePage = () => {
    let isValid = false;


    if (editable) {
      form.validateFields((validationError) => {
        isValid = validationError === null;
        //check for error and empty values
        let customerValidation;
        if (mode == "ConfirmBooking") {
          customerValidation = customerDataJoi(
            defaultToNull(form.getFieldsValue())
          );
        }
        else {
          console.log('else');
          customerValidation = advancedCustomerDataJoi(
            defaultToNull(form.getFieldsValue())
          );
        }
        // console.log("customerValidation.error", customerValidation.error)
        if (customerValidation.error) {
          // message.warning("Please enter valid data in the fields!");
          console.log("Error", generateErrorObj(customerValidation, errorObj))
          setErrorObj(generateErrorObj(customerValidation, errorObj));
          isValid = false;
        }
      });
    }
    else {
      isValid = true;
    }
    if (isValid) {
      // destructuring the accesory data from BE for the struc needed by FE
      let accessoryArray = [];

      if (data.accessories.length > 0)
        accessoryArray = data.accessories;
      // console.log("CustomerDetails", customerDetails)
      if (!customerDetails?.fatherName
        || !customerDetails?.address?.locality
        || !customerDetails?.address?.pincode
        // || !customerDetails.address
        || !customerDetails?.address?.line1 ||
        !customerDetails?.address?.line2
        || !customerDetails?.address?.line3
      ) {
        var stateName = state.find(d => d.id == form.getFieldValue("state"));
        var countryName = country.find(d => d.id == form.getFieldValue("country"));
        var districtName = city.find(d => d.id == form.getFieldValue("city"))

        var updatedCustomerDetails = {
          ...customerDetails,
          address: {
            state: stateName,
            country: countryName,
            district: districtName,
            locality: form.getFieldValue("locality"),
            pincode: form.getFieldValue("pincode"),
            line1: form.getFieldValue("line1"),
            line2: form.getFieldValue("line2"),
            line3: form.getFieldValue("line3"),
          },
          dateOfBirth: form.getFieldValue("DOB"),
          email: form.getFieldValue("email"),
          fatherName: form.getFieldValue("fatherName"),
        }
        console.log("update", updatedCustomerDetails)
        setCustomerDetails(updatedCustomerDetails);

        data.branch = { id: getFieldValue("branch") || null };
        data.customer = customerDetails?.id ? updatedCustomerDetails : null;
        data.customerId = customerDetails?.customerId;
        data.customerName = customerDetails?.name;
        data.customerFatherName = data.customer ? updatedCustomerDetails?.fatherName : customerDetails?.father;
        data.customerLine1 = data.customer ?
          updatedCustomerDetails?.address?.line1
            ? updatedCustomerDetails.address.line1
            : updatedCustomerDetails?.address?.street
          : customerDetails?.address?.line1;
        data.customerLine2 = data.customer ? updatedCustomerDetails?.address?.line2 : customerDetails?.address?.line2;
        data.customerLine3 = data.customer ? updatedCustomerDetails?.address?.line3 : customerDetails?.address?.line3;
        data.customerPhone = data.customer ? updatedCustomerDetails?.contacts?.[0]?.phone : customerDetails?.phone;
        data.customerLocality = data.customer ? updatedCustomerDetails?.address?.locality : customerDetails?.locality;
        data.customerCountry = data.customer ? updatedCustomerDetails?.address?.country?.name : customerDetails?.country;
        data.customerState = data.customer ? updatedCustomerDetails?.address?.state?.name : customerDetails?.state;
        data.customerCity = data.customer ? updatedCustomerDetails?.address?.district?.name : customerDetails?.city;
        data.customerPincode = data.customer ? updatedCustomerDetails?.address?.pincode : customerDetails?.pincode;
        data.customerEmail = data.customer ? updatedCustomerDetails?.email : customerDetails?.email;
        data.customerDob = data.customer ? updatedCustomerDetails?.dateOfBirth : customerDetails?.dob;
        data.customerGender = data.customer ? updatedCustomerDetails?.gender : customerDetails?.gender;
        data.quotation = customerQuotations || [];
        data.IDbranch = loginCredintials?.branch?.id;
        data.nomineeName = getFieldValue("nominee");
        data.nomineeAge = getFieldValue("nomineeAge");
        data.relationship = getFieldValue("relationship");
        data.refferedBy = { id: getFieldValue("refferedBy") || null };
        data.accessories = accessoryArray;
        data.executive = { id: getFieldValue("salesExecutive") || null };
        if (data.customer) {
          platformApi
            .put(`api/customer/${customerDetails?.id}/`, {
              fatherName: getFieldValue("fatherName") || null,
              dateOfBirth: moment(getFieldValue("DOB")).format('DD-MM-YYYY') || null,
              address: {
                line1: getFieldValue("line1") || null,
                line2: getFieldValue("line2") || null,
                line3: getFieldValue("line3") || null,
                locality: getFieldValue("locality") || null,
                state: getFieldValue("state") || null,
                country: getFieldValue("country") || null,
                district: getFieldValue("city") || null,
                pincode: getFieldValue("pincode") || null,
              },
              email: getFieldValue("email") || null,
              quotation: customerQuotations || [],
              update: "quotation",
            })
            .then((result) => {
              if (result.code === 200) {
                message.success("Customer Details Updated!");
              }
            });
        }
      }
      else {
        data.branch = { id: getFieldValue("branch") || null };
        data.customer = customerDetails?.id ? customerDetails : null;
        data.customerId = customerDetails?.customerId;
        data.customerName = customerDetails?.name;
        data.customerFatherName = data.customer ? customerDetails?.fatherName : customerDetails?.father;
        data.customerLine1 = data.customer ?
          customerDetails?.address?.line1
            ? customerDetails.address.line1
            : customerDetails?.address?.street
          : customerDetails?.address?.line1;
        data.customerLine2 = data.customer ? customerDetails?.address?.line2 : customerDetails?.address?.line2;
        data.customerLine3 = data.customer ? customerDetails?.address?.line3 : customerDetails?.address?.line3;
        data.customerPhone = data.customer ? customerDetails?.contacts?.[0]?.phone : customerDetails?.phone;
        data.customerLocality = data.customer ? customerDetails?.address?.locality : customerDetails?.locality;
        data.customerCountry = data.customer ? customerDetails?.address?.country?.name : customerDetails?.country;
        data.customerState = data.customer ? customerDetails?.address?.state?.name : customerDetails?.state;
        data.customerCity = data.customer ? customerDetails?.address?.district?.name : customerDetails?.city;
        data.customerPincode = data.customer ? customerDetails?.address?.pincode : customerDetails?.pincode;
        data.customerEmail = data.customer ? customerDetails?.email : customerDetails?.email;
        data.customerDob = data.customer ? customerDetails?.dateOfBirth : customerDetails?.dob;
        data.customerGender = data.customer ? customerDetails?.gender : customerDetails?.gender;
        data.quotation = customerQuotations || [];
        data.IDbranch = loginCredintials?.branch?.id;
        data.nomineeName = getFieldValue("nominee");
        data.nomineeAge = getFieldValue("nomineeAge");
        data.relationship = getFieldValue("relationship");
        data.refferedBy = { id: getFieldValue("refferedBy") || null };
        data.accessories = accessoryArray;
        data.executive = { id: getFieldValue("salesExecutive") || null };
        if (data.customer) {
          //update customer details here
          platformApi
            .put(`api/customer/${customerDetails.id}/`, {
              fatherName: getFieldValue("fatherName") || null,
              dateOfBirth: moment(getFieldValue("DOB")).format('DD-MM-YYYY') || null,
              address: {
                line1: getFieldValue("line1") || null,
                line2: getFieldValue("line2") || null,
                line3: getFieldValue("line3") || null,
                locality: getFieldValue("locality") || null,
                state: getFieldValue("state") || null,
                country: getFieldValue("country") || null,
                district: getFieldValue("city") || null,
                pincode: getFieldValue("pincode") || null,
              },
              email: getFieldValue("email") || null,
              quotation: customerQuotations || [],
              update: "quotation",
            })
            .then((result) => {
              if (result.code === 200) {
                message.success("Customer Details Updated!");
              }
            });
        }
      }
    }
    return isValid;
  };


  const setCustomerIdnew = () => {
    platformApi.post("/api/idGenerate/customer").then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        console.log(response)
        if (response?.data)
          // return response.data
          addData(response.data);
      } else {
        message.error("Couldn't fetch id");
      }
    });
  };


  const addData = (customerId) => {
    const name = getFieldValue("customer");
    const phone = getFieldValue("phone");
    if (phone?.length != 10) return
    const contacts = [{
      id: "",
      phone,
      type: "Primary"
    }];

    const customer = {
      customerId,
      name: name,
      fatherName: getFieldValue("fatherName"),
      contacts: contacts,
      dateOfBirth: getFieldValue("DOB")
        ? moment(getFieldValue("DOB")).format("DD-MM-YYYY")
        : null,
      address: {
        district: getFieldValue("city"),
        state: getFieldValue("state"),
        country: getFieldValue("country"),
        line1: getFieldValue("line1"),
        line2: getFieldValue("line2"),
        line3: getFieldValue("line3"),
        locality: getFieldValue("locality"),
        pincode: getFieldValue("pincode")
      },
      refferedBy: getFieldValue("refferedBy"),
      IDbranch: getFieldValue("branch"),
      email: getFieldValue("email")
    };

    platformApi.post("/api/customer", customer)
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setCustomerId(customerId);
            message.success("Customer added successfully", 2);
            searchCustomer(phone);
          } else {
            message.error("Unable to add customer", 2);
          }
        } else if (data.code === 500 && data.err.code === 500) {
          message.error("Customer name already exists", 2);
        } else {
          message.error("Unable to add customer", 2);
        }
      })
      .catch((error) => {
        message.error("Unable to add customer", 2);
        console.error("Error on customer : ", error);
      });
  };


  const setCustomerFields = (id, setPhno = true, branchId = null, validateFields = false) => {
    platformApi.get(`/api/customer/${id}?branch=${branchId}`).then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          let responseData = response.data;
          setCustomerId(responseData.customerId);
          let quotation = [];
          let quotationIdArr = [];
          responseData.quotation.map((e) => {
            quotation.push(e.quotationId);
            quotationIdArr.push(e.id);
          });
          let vehicles = [];
          responseData.purchasedVehicle.map((e) => {
            vehicles.push(e.vehicle.modelName);
          });
          form.setFieldsValue({
            customerId: responseData.customerId,
            customer: responseData.id,
            fatherName: responseData.fatherName,
            DOB: responseData.dateOfBirth ? moment(responseData.dateOfBirth) : null,
            locality: responseData.address ? responseData.address.locality : null,
            state: responseData.address ? (responseData.address.state ? responseData.address.state.id : undefined) : undefined,
            country: responseData.address ? (responseData.address.country ? responseData.address.country.id : undefined) : undefined,
            city: responseData.address ? (responseData.address.district ? responseData.address.district.id : undefined) : undefined,
            // street: responseData.address ? responseData.address.street : null,
            line1: responseData.address
              ? responseData.address.line1
                ? responseData.address.line1
                : responseData.address.street
              : null,
            line2: responseData.address ? responseData.address.line2 : null,
            line3: responseData.address ? responseData.address.line3 : null,

            pincode: responseData.address ? responseData.address.pincode : null,
            email: responseData.email,
            quotationsAssociated: quotation,
            quotation: quotationIdArr,
          });
          setCustomerQuotations(quotationIdArr)

          setPhno && form.setFieldsValue({
            phone: responseData.contacts[0] && responseData.contacts[0].phone || null,
          });
          const searchReffered = {
            module: "customers",
            column: "name",
            fields: ["contacts{phone}"],
            setValue: setRefferedByData,
          };
          getCustomers(searchReffered);
          getFieldValue("country") &&
            handleCountryChange(getFieldValue("country"));
          getFieldValue("state") && handleStateChange(getFieldValue("state"));
          SetPresentCustomerValues(form.getFieldsValue());
          validateFields && validateOnBlur();
          setErrorObj({})
        }
      } else {
        message.error("Unable to fetch Customer");
      }
    }).catch((err) => { })
  };
  // search customer using ph
  const searchCustomer = (number) => {
    // const number = form.getFieldValue("phone");
    platformApi
      .get("api/customer/phone/" + number)
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            if (response.data[0]) {
              // message.success("User fetched", 2);
              setCustomerData(response.data);
              if (response.data[0].address == null) {
                response.data[0].address = undefined
              }
              setCustomerDetails(response.data[0]);
              //sets up customer value
              setCustomerAvailable(true);
              setCustomerFields(response.data[0].id, false, form.getFieldValue('branch'), true);
              form.setFieldsValue({
                customer: response.data[0].id,
              });
            } else {
              message.error("Customer not found");
              setCustomerAvailable(false)
            }
          }
        }
      })
      .catch((error) => {
        console.error("Error on Booking Form : ", error);
      });
  };

  return (
    <Form style={{ marginTop: "4%", marginBottom: "4%" }}>
      <Row type="flex" justify="space-between">
        <Col span={7}>
          <Form.Item colon={false} label={<Text>Branch</Text>}>
            {getFieldDecorator("branch", {
              rules: [{ required: true, message: "Select Branch" }],
            })(
              <Select
                showSearch
                showAction={["click", "focus"]}
                filterOption={filterMethod}
                onChange={(id) => {
                  settingBranchBasedManufacturer(id);
                }}
                onSelect={(id) => {
                  getQuotations(id);
                }}
                // onBlur={validateOnBlur}
                disabled={!editable || bookingAuthStatus}
                placeholder="Branch"
              >
                {employeeBranch.map((branch) => (
                  <Select.Option key={branch.id}>{branch.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={7}>

          <Form.Item
            validateStatus={errorObj && errorObj.phone && errorObj.phone.type}
            help={errorObj && errorObj.phone && errorObj.phone.message}
            colon={false}
            label={<Text>Phone</Text>}
          >

            <Row>
              <Col span={(editable && !bookingAuthStatus) ? 18 : 24}>
                {getFieldDecorator("phone", {
                  rules: [{ required: true, message: "Select Phone" }],
                })(
                  <Input
                    min="0"
                    addonBefore="+91"
                    onKeyUp={e =>
                      form.setFieldsValue({
                        phone: formatValue(e, "onlyNo")
                      })}
                    maxLength={10}
                    disabled={!editable || bookingAuthStatus}
                    placeholder="Phone"
                    required
                    // onBlur={validateOnBlur}
                    onInput={(event) => {
                      if (event.target.value.length === 10) {
                        searchCustomer(event.target.value)
                      }
                      if (event.target.checkValidity()) {
                        setValidNum(true);
                      } else {
                        setValidNum(false);
                        let salesExecutive = getFieldValue("salesExecutive");
                        let branch = getFieldValue("branch");
                        form.resetFields();
                        setCustomerId(null)
                        setCustomerAvailable(false)
                        form.setFieldsValue({
                          salesExecutive,
                          branch
                        });
                      }
                    }}
                    onChange={() => customerDetails && setCustomerDetails(null)}
                  // pattern="^\d{10}$"
                  />
                )}
              </Col>
            </Row>

          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item required={true} colon={false} label={<Text>Customer Name</Text>}>

            {customerAvailable ? getFieldDecorator("customer", {
              rules: [
                {
                  required: true,
                  message: "Customer Required!",
                },
              ],
            })(

              <Select
                // showSearch
                onChange={(id) => {
                  setCustomerFields(id, true, form.getFieldValue('branch'), true),
                    getCustomerDetails(id)
                }}
                filterOption={null}
                placeholder="Customer Name"
                // disabled={!(editable && validNum) || bookingAuthStatus || !customerDetails}
                // onBlur={validateOnBlur}
                onSearch={(e) => {
                  const search = {
                    module: "customers",
                    column: "name",
                    searchString: e,
                    fields: ["contacts{phone}"],
                    searchColumns: ["contacts"],
                    setValue: setCustomerData,
                  };
                  dropdownSearch(search);
                }}
              >
                {customerData.map((data) => (
                  <Select.Option value={data.id} key={data.id}>
                    {data.contacts && data.contacts[0]
                      ? data.contacts[0].phone
                      : "No Phone Updated"}
                    - {data.name}
                  </Select.Option>
                ))}
              </Select>
            )
              :
              getFieldDecorator("customer")(
                <Input
                  disabled={
                    !editable ||
                    (presentCustomerValues && presentCustomerValues["customer"])
                    || bookingAuthStatus
                  }
                  placeholder="Customer Name"
                  // onBlur={validateOnBlur}
                  onKeyUp={e =>
                    form.setFieldsValue({
                      customer: formatValue(e, "allCaps")
                    })
                  }
                  onPressEnter={() => setCustomerIdnew()}
                />
              )
            }
          </Form.Item>
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col span={7}>
          <Form.Item
            validateStatus={errorObj && errorObj.fatherName && errorObj.fatherName.type}
            help={errorObj && errorObj.fatherName && errorObj.fatherName.message}
            colon={false}
            label={<Text>Father's Name</Text>}
            required={mode == "ConfirmBooking" ? true : false}
          >
            {getFieldDecorator("fatherName", {
              rules: [{ required: mode == "ConfirmBooking" ? true : false, message: "Enter Father Name" }],
            })(
              <Input
                disabled={!editable || bookingAuthStatus}
                placeholder="Father's Name"
                // onBlur={validateOnBlur}
                onKeyUp={e =>
                  form.setFieldsValue({
                    fatherName: formatValue(e, "allCaps")
                  })
                }
                pattern="^[A-Z][a-zA-Z.\s]*[a-zA-Z.]+$"
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      fatherName: {
                        type: "error",
                        message: "Enter Valid Name",
                      },
                    });
                  } else {
                    delete errorObj.fatherName;
                    setErrorObj(errorObj);
                  }
                }}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            validateStatus={errorObj && errorObj.line1 && errorObj.line1.type}
            help={errorObj.line1 && errorObj.line1.message}
            colon={false}
            required={mode == "ConfirmBooking" ? true : false}
            label={<Text>Address Line 1</Text>}
          >
            {getFieldDecorator("line1", {
              rules: [
                {
                  required: mode == "ConfirmBooking" ? true : false,
                  message: "Enter valid Address Line 1",
                },
              ],
            })(
              <Input
                disabled={!editable || bookingAuthStatus}
                onKeyUp={e =>
                  form.setFieldsValue({
                    line1: formatValue(e, "allCaps")
                  })
                }
                placeholder="Address Line 1"
                // onBlur={validateOnBlur}
                pattern="^([a-zA-Z0-9-/,]+[-/,])*\w+.?$"
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      line1: {
                        type: "error",
                        message: "Enter valid Address Line 1"
                      }
                    })
                  } else {
                    delete errorObj.line1
                    setErrorObj(errorObj)
                  }
                }}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            validateStatus={errorObj && errorObj.line2 && errorObj.line2.type}
            help={errorObj.line2 && errorObj.line2.message}
            colon={false}
            required={false}
            label={<Text>Address Line 2</Text>}
          >
            {getFieldDecorator("line2", {
              rules: [
                {
                  required: false,
                  message: "Enter valid Address Line 2",
                },
              ],
            })(
              <Input
                disabled={!editable || bookingAuthStatus}
                onKeyUp={e =>
                  form.setFieldsValue({
                    line2: formatValue(e, "allCaps")
                  })
                }
                placeholder="Address Line 2"
                // onBlur={validateOnBlur}
                pattern="^([a-zA-Z0-9-/,]+[-/,])*\w+.?$"
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      line2: {
                        type: "error",
                        message: "Enter Valid Address Line 2"
                      }
                    })
                  } else {
                    delete errorObj.line2
                    setErrorObj(errorObj)
                  }
                }}
              />
            )}
          </Form.Item>
        </Col>

        <Col span={11}>
          <Form.Item
            validateStatus={errorObj && errorObj.line3 && errorObj.line3.type}
            help={errorObj.line3 && errorObj.line3.message}
            colon={false}
            required={false}
            label={<Text>Address Line 3</Text>}
          >
            {getFieldDecorator("line3", {
              rules: [
                {
                  required: false,
                  message: "Enter valid Address Line 3",
                },
              ],
            })(
              <Input
                disabled={!editable || bookingAuthStatus}
                onKeyUp={e =>
                  form.setFieldsValue({
                    line3: formatValue(e, "allCaps")
                  })
                }
                placeholder="Address Line 3"
                // onBlur={validateOnBlur}
                pattern="^([a-zA-Z0-9-/,]+[-/,])*\w+.?$"
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      line3: {
                        type: "error",
                        message: "Enter valid Address Line 3"
                      }
                    })
                  } else {
                    delete errorObj.line3
                    setErrorObj(errorObj)
                  }
                }}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            colon={false}
            label={<Text>Locality</Text>}
            validateStatus={errorObj && errorObj.locality && errorObj.locality.type}
            help={errorObj && errorObj.locality && errorObj.locality.message}
            required={mode == "ConfirmBooking" ? true : false}
          >
            {getFieldDecorator("locality", {
              rules: [
                {
                  required: mode == "ConfirmBooking" ? true : false,
                  message: "Enter Locality",
                },
              ],
            })(
              <Input
                onKeyUp={e =>
                  form.setFieldsValue({
                    locality: formatValue(e, "allCaps")
                  })}
                disabled={!editable || bookingAuthStatus}
                maxLength={30}
                // onBlur={validateOnBlur}
                pattern="^([a-zA-Z0-9-/,]+[ -/,])*\w+.?$"
                // pattern="^^[A-Z][a-zA-Z\s]*[a-zA-Z]+$"
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      locality: {
                        type: "error",
                        message: "Enter Valid Locality"
                      }
                    })
                  } else {
                    delete errorObj.locality
                    setErrorObj(errorObj)
                  }
                }}
                placeholder="Locality"
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col span={7}>
          <Form.Item colon={false} label={<Text>Country</Text>}>
            {getFieldDecorator("country", {
              rules: [{ required: mode == "ConfirmBooking" ? true : false, message: "Select Country" }],
            })(
              <Select
                showAction={["click", "focus"]}
                showSearch
                disabled={!editable || bookingAuthStatus}
                placeholder="Country"
                onChange={handleCountryChange}
                filterOption={filterMethod}
              // onBlur={validateOnBlur}
              >
                {country.map((object) => (
                  <Select.Option value={object.id} key={object.id}>
                    {object.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item colon={false} label={<Text>State</Text>}>
            {getFieldDecorator("state", {
              rules: [{ required: mode == "ConfirmBooking" ? true : false, message: "Select State" }],
            })(
              <Select
                showAction={["click", "focus"]}
                showSearch
                disabled={!editable || bookingAuthStatus}
                placeholder="State"
                filterOption={filterMethod}
                onChange={handleStateChange}
              // onBlur={validateOnBlur}
              >
                {state.map((object) => (
                  <Select.Option value={object.id} key={object.id}>
                    {object.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item colon={false} label={<Text>City</Text>}>
            {getFieldDecorator("city", {
              rules: [{ required: mode == "ConfirmBooking" ? true : false, message: "Select City" }],
            })(
              <Select
                showAction={["click", "focus"]}
                showSearch
                disabled={!editable || bookingAuthStatus}
                placeholder="City"
                filterOption={filterMethod}
              // onBlur={validateOnBlur}
              >
                {city.map((object) => (
                  <Select.Option key={object.id} value={object.id}>
                    {object.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            validateStatus={errorObj && errorObj.pincode && errorObj.pincode.type}
            help={errorObj && errorObj.pincode && errorObj.pincode.message}
            colon={false}
            label={<Text>Pincode</Text>}
          // required={true}
          >
            {getFieldDecorator("pincode", {
              rules: [{ required: mode == "ConfirmBooking" ? true : false, message: "Enter pincode" }],
            })(
              <Input
                disabled={!editable || bookingAuthStatus}
                maxLength={7}
                pattern="([0-9]{6}|[0-9]{3}\s[0-9]{3})"
                onKeyUp={e => {
                  form.setFieldsValue({
                    pincode: formatValue(e, "onlyNo")
                  })
                  if (form.getFieldValue("pincode").length === 0) {
                    delete errorObj.pincode
                  }
                }}
                // onWheel={(event) => event.currentTarget.blur()}
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      pincode: {
                        type: "error",
                        message: "Enter Valid PIN Code",
                      },
                    });
                  } else {
                    delete errorObj.pincode;
                    setErrorObj(errorObj);
                  }
                }}
                // onBlur={validateOnBlur}
                placeholder="Pincode"
              />
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            colon={false}
            label={<Text>Email</Text>}
            validateStatus={errorObj && errorObj.email && errorObj.email.type}
            help={errorObj && errorObj.email && errorObj.email.message}
          >
            {getFieldDecorator("email", {
              rules: [{ required: false, message: "Enter email" }],
            })(
              <Input
                disabled={!editable || bookingAuthStatus}
                onWheel={(event) => event.currentTarget.blur()}
                maxLength={50}
                pattern="^[a-zA-Z0-9.!#$%'*+/=?^_`{|}~-]+@[a-zA-Z_-]+?\.[a-zA-Z.]{2,6}$"
                // onBlur={validateOnBlur}
                placeholder="Email"
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      email: {
                        type: "error",
                        message: "Enter Valid Email Address"
                      }
                    });
                  } else {
                    delete errorObj.email;
                    setErrorObj(errorObj);
                  }
                }}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item colon={false} label={<Text>DOB</Text>}>
            {getFieldDecorator("DOB", {
              rules: [{ required: mode == "ConfirmBooking" ? true : false, message: "Enter DOB" }],
            })(
              <DatePicker
                style={{ width: "100%" }}
                format="DD/MM/YYYY"
                disabled={!editable || bookingAuthStatus}
                placeholder={new Date().toLocaleDateString("en-GB")}                        
                disabledDate={current => {
                  return current && current >= moment().startOf('day');
                }}
              // onBlur={validateOnBlur}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="Referred By" colon={false}>
            {getFieldDecorator("refferedBy", {
              rules: [{ required: false, message: "Select Refered By" }],
            })(
              <Select
                showAction={["click", "focus"]}
                showSearch
                placeholder="Referred By"
                disabled={!editable || !customerAvailable}
                filterOption={null}
                // onBlur={validateOnBlur}
                onSearch={(e) => {
                  const search = {
                    module: "customers",
                    column: isNaN(e) ? "name" : "phone",
                    searchString: e,
                    fields: ["contacts{phone}"],
                    searchColumns: ["contacts"],
                    setValue: setRefferedByData,
                  };
                  getCustomers(search);
                }}
              >
                {refferedByData.map((data) => (
                  <Select.Option value={data.id} key={data.id}>
                    {data.contacts && data.contacts[0]
                      ? data.contacts[0].phone
                      : "No Phone Updated"}
                    - {data.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            validateStatus={errorObj && errorObj.nominee && errorObj.nominee.type}
            help={errorObj && errorObj.nominee && errorObj.nominee.message}
            colon={false}
            // onBlur={validateOnBlur}
            label={<Text>Nominee Details</Text>}
            required={mode == "ConfirmBooking" ? true : false}
          >
            {getFieldDecorator("nominee", {
              rules: [{
                required: mode == "ConfirmBooking" ? true : false,
                message: "Enter Nominee Name"
              }]
            })(
              <Input
                disabled={
                  !editable
                  //  ||
                  // (presentCustomerValues && presentCustomerValues["nominee"])
                  || bookingAuthStatus
                }
                placeholder="Nominee Name"
                onKeyUp={e =>
                  form.setFieldsValue({
                    nominee: formatValue(e, "allCaps")
                  })
                }
                pattern="^[A-Z][a-zA-Z.\s]*[a-zA-Z.]+$"
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      nominee: {
                        type: "error",
                        message: "Enter Valid Nominee Name",
                      },
                    });
                  } else {
                    delete errorObj.nominee;
                    setErrorObj(errorObj);
                  }
                }}
              />
            )}
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item
            validateStatus={errorObj && errorObj.nomineeAge && errorObj.nomineeAge.type}
            help={errorObj && errorObj.nomineeAge && errorObj.nomineeAge}
            colon={false}
            label={<Text>Age</Text>}
          // onBlur={validateOnBlur}
          // required={true}
          >
            {getFieldDecorator("nomineeAge", {
              rules: [{
                required: mode == "ConfirmBooking" ? true : false,
                message: "Enter Nominee Age"
              }]
            })(
              <Input
                disabled={
                  !editable
                  // ||
                  // (presentCustomerValues && presentCustomerValues["nomineeAge"])
                  || bookingAuthStatus
                }
                type="number"
                maxLength={2}
                onKeyUp={e =>
                  form.setFieldsValue({
                    nomineeAge: formatValue(e, "onlyNo")
                  })}
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      nomineeAge: {
                        type: "error",
                        message: "Enter Valid Nominee Age",
                      },
                    });
                  } else {
                    delete errorObj.nomineeAge;
                    setErrorObj(errorObj);
                  }
                }}
                placeholder="Age"
              />
            )}
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item colon={false} label={<Text>Relationship</Text>}>
            {getFieldDecorator("relationship", {
              rules: [{ required: mode == "ConfirmBooking" ? true : false, message: "Select Relationship" }],
            })(
              <Select
                showAction={["click", "focus"]}
                showSearch
                placeholder="Relationship"
                filterOption={filterMethod}
                // onBlur={validateOnBlur}
                disabled={
                  !editable
                  //  ||
                  // (presentCustomerValues &&
                  //   presentCustomerValues["relationship"])
                  || bookingAuthStatus
                }
              >
                {relation.map((data) => (
                  <Select.Option key={data.key}>{data.title}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item colon={false} label={<Text>Quotations Associated</Text>}>
            <Row>
              <Col span={(editable && customerAvailable) ? 18 : 24}>
                {getFieldDecorator("quotationsAssociated")(
                  <Input disabled={true} placeholder="Quotations Associated" />
                )}
              </Col>
              {(editable && customerAvailable) && (<Col span={6}>
                <Button type="link" onClick={() => linkQuotation()}>
                  Link Quotation
                </Button>
              </Col>)}
            </Row>
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("quotation")(<Input type="hidden" />)}
          </Form.Item>
        </Col>
        {
          <LinkQuotationModal
            dataSource={quotations}
            existQuotations={data.quotation}
            form={form}
            handleSave={linkQuotation}
            visibility={linkQuotationVisibility}
          />
        }

        <Col span={7}>
          <Form.Item colon={false} label={<Text>Sales Officer</Text>}>
            {getFieldDecorator("salesExecutive", {
              rules: [{ required: true, message: "Select Sales Executive" }],
            })(
              <Select
                showAction={["click", "focus"]}
                showSearch
                filterOption={filterMethod}
                disabled={!editable || bookingAuthStatus}
                placeholder="Sales Executive"
                // onBlur={validateOnBlur}
                onChange={(id) => {
                  getEmployeeBranch(id);
                }}
              >
                {users.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.profile.employeeName}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>

      {!customerId &&
        <Row type="flex" justify="space-between">
          <Button onClick={() => setCustomerIdnew()}>
            create customer {customerId}
          </Button>
        </Row>
      }
    </Form>
  );
};

const WrappedCustomerData = Form.create({ destroyOnUnmount: false })(CustomerData);

export default WrappedCustomerData;
