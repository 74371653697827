import React, { useState } from "react";
import WrappedFilters from "./Filters";

import { platformApi } from "../../../../api";
import {
  Tabs,
  Typography,
  Input,
  Button,
  Menu,
  Dropdown,
  Icon,
  Collapse,
  Form,
  Row,
  Col,
  message,
} from "antd";
const { Panel } = Collapse;
import FilterTable from "./FilterTable";
import { useEffect } from "react";

const GroupForm = ({
  form,
  filter,
  setFilter,
  setDisplayGroup,
  displayGroup,
  data,
  addData,
  setModify,
  editable,
  setDisabled,
  setFlag
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [FilterData, setFilterData] = useState([]);
  const [sendData, setSendData] = useState([]);
  const [removeData, setRemoveData] = useState([])
  const [loading, setLoading] = useState(false)
  const handlegrp = () => {
    const groupName = form.getFieldValue("grpname");
    const customer = dataSource;
    const filters = FilterData;
    const grp = {
      filters,
      groupName,
      // customer,
      removeData
    };
    if (data) {
      grp.id = data.id;
      addData(grp);
      setModify(-1);
    } else {
      addData(grp);
      setModify(-1);
    }
    // if(data){
    //   platformApi.put(`/api/promotions/group/${data.id}`, grp).then((res) => {
    //     // setSpinning(false);
    //     const { data } = res;
    //     if (data.code === 200) {
    //       const { response } = data;
    //       if (response.code === 200) {
    //       } else {
    //         message.error("Unable to modify ");
    //       }
    //     } else {
    //       message.error("Unable to modify ");
    //     }
    //   });
    // }
    // else{
    //   platformApi.post(`/api/promotions/group`, grp).then((res) => {
    //     // setSpinning(false);
    //     const { data } = res;
    //     if (data.code === 200) {
    //       const { response } = data;
    //       if (response.code === 200) {
    //       } else {
    //         message.error("Unable to fetch ");
    //       }
    //     } else {
    //       message.error("Unable to fetch ");
    //     }
    //   });
    // }

    setDisplayGroup(false);
  };
  useEffect(() => {
    if (data) {
      setSendData(data);
      form.setFieldsValue({ grpname: data.groupName });
      setDataSource(data.customer);
    }
    setFilter([]);
  }, [displayGroup]);

  useEffect(() => {
    if (data) {
      if (data.filters) {
        setFilterData({
          model: data?.filters?.model || [],
          allModel: data?.filters?.allModel || false,
          from: data?.filters?.from || null,
          to: data?.filters?.to || null,
          customerType: data?.filters?.customerType || [],
          allCustomerType: data?.filters?.allCustomerType || false,
          customerGrouping: data?.filters?.customerGrouping || [],
          allCustomerGrouping: data?.filters?.allCustomerGrouping || false,
          gender: data?.filters?.gender || [],
          allGender: data?.filters?.allGender || false,
          fromAge: data?.filters?.fromAge || null,
          toAge: data?.filters?.toAge || null,
          vehicleType: data?.filters?.vehicleType || [],
        });
      }
    } else {
      // Reset to default if no data
      setFilterData({
        model: [],
        allModel: false,
        from: null,
        to: null,
        customerType: [],
        allCustomerType: false,
        customerGrouping: [],
        allCustomerGrouping: false,
        gender: [],
        allGender: false,
        fromAge: null,
        toAge: null,
        vehicleType: [],
      });
    }
  }, [data]);  
  
  return (
    <div>
      <Form>
        {/* <Collapse
          accordion
          destroyInactivePanel
          defaultActiveKey={['1']}
          // onChange={}
          // activeKey={}
        >
          <Panel key="1" header="Select Filters"> */}
        <Row type="flex" justify="space-between">
          {/* <Form.Item label="Enter Group Name"> */}
          <Col span={18}>
            <Form.Item>
              {form.getFieldDecorator("grpname", {
                rules: [
                  {
                    required: true,
                    message: "Please Enter Group Name",
                  },
                ],
              })(<Input placeholder="Group Name" disabled={!editable} />)}
            </Form.Item>
          </Col>

        </Row>
        <Row type="flex" justify="space-between">
          <Col span={24}>
            <WrappedFilters
              data={sendData}
              id={filter}
              setId={setFilter}
              setDataSource={setDataSource}
              dataSource={dataSource}
              filteropen={displayGroup}
              filterData={FilterData}
              setFilterData={setFilterData}
              editable={editable}
              setLoading = {setLoading}
            />
          </Col>
        </Row>
        {/* </Panel>
          <Panel key="2" header="Create Group"> */}
        {/* </Panel>
        </Collapse> */}
      </Form>
      <br />
      <FilterTable
        pagination={{
          defaultCurrent: 1,
          total: dataSource ? dataSource.length : 1,
          showQuickJumper: true,
        }}
        dataSource={dataSource}
        setDataSource={setDataSource}
        removeData={removeData}
        setRemoveData={setRemoveData}
        editable={editable}
        loader ={loading}
      />
      {/* <Button
        type="ghost"
        style={{ float: "right" }}
        onClick={() => {
          setModify(-1), setDisplayGroup(false);
        }}
      >
        Cancel
      </Button> */}
      {/* <Col> */}
      <div className="right-prom-group" style={{ float: "right", marginTop: "15px" }}>
        <Button type="primary" disabled={!editable || dataSource?.length === 0} onClick={handlegrp}>
          Save Group
              </Button>
              &nbsp;
              <Button
          onClick={() => {
            setModify(-1),
              setDisplayGroup(false);
            setDisabled(false);
            setFlag(false);
          }}
        >
          Cancel
              </Button>
      </div>
      {/* </Col> */}
    </div>
  );
};
const WrappedGroupForm = Form.create()(GroupForm);

export default WrappedGroupForm;
