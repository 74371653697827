import React, { useState, useEffect } from 'react';
import {
    Table,
    Input,
    Select,
    Row,
    Col,
    Form,
    Typography,
    message,
    Button,
    Icon
} from "antd";
import { platformApi } from '../../../../api';
const { Title } = Typography;
const { Option } = Select;
const { Item } = Form;
import debounce from 'lodash/debounce';

const TransferSpares = ({ form }) => {
    const [branches, setBranch] = useState([]);
    const [vehicles, setVehicle] = useState([]);
    const [data, setData] = useState([]);
    // const [invalidRows, setInvalidRows] = useState(new Set());

    const { getFieldDecorator, getFieldValue } = form;
    const debouncedSearch = debounce((searchValue, branchId) => {
        platformApi.post(`/api/inventoryTransfers/spares`, {
            branch: branchId,
            inputValue: searchValue
        }).then((result) => {
            let { data } = result;
            if (data.code === 200) {
                setVehicle(data.data);
            }
        });
    }, 300);
    const columns = [
        {
            title: "Part Number",
            key: "partNumber",
            width: 200,
            render: (text, record) => (
                <Select
                    className="ChallanTable-Manufacturers"
                    showSearch
                    value={record.partNumber}
                    // defaultActiveFirstOption={false}
                    filterOption={false}
                    notFoundContent={null}
                    disabled={!getFieldValue("fromBranch")}
                    dropdownStyle={{ overflowY: "auto", overflowX: "hidden" }}
                    onChange={(value) => handlePartChange(value, record.key)}
                    onSearch={(value) => {
                        debouncedSearch(value, getFieldValue("fromBranch"));
                    }}
                    placeholder="Part Number"
                >
                    {vehicles?.map((item) => (
                        <Option key={item.partNumber} value={item.partNumber}>
                            {item.partNumber}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Quantity",
            width: 100,
            render: (text, record) => (
                <Form.Item
                    validateStatus={
                        record.quantity !== null && record.quantity > record.phyQuantity
                            ? "error"
                            : ""
                    }
                    help={
                        record.quantity !== null && record.quantity > record.phyQuantity
                            ? "Exceeds available stock"
                            : ""
                    }
                    style={{ marginBottom: 0 }} // Fixes alignment issue
                >
                    {form.getFieldDecorator(`quantity_${record.key}`, {
                        initialValue: record.quantity,
                        rules: [
                            { required: true, message: "Quantity is required" },
                            {
                                validator: (_, value, callback) => {
                                    if (value && value > record.phyQuantity) {
                                        callback("Exceeds available stock");
                                    } else {
                                        callback();
                                    }
                                },
                            },
                        ],
                    })(
                        <Input
                            type="number"
                            min={1}
                            onChange={(e) =>
                                handleQuantityChange(e.target.value, record.key)
                            }
                            disabled={!record.partNumber}
                            placeholder="Enter Quantity"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    addVehicle()
                                }
                            }}
                        />
                    )}
                </Form.Item>
            ),
        },
        {
            title: "Action",
            key: "action",
            width: 100,
            render: (text, record) => (
                <Button
                    type="danger"
                    icon="delete"
                    onClick={() => handleDeleteRow(record.key)}
                >
                    Delete
                </Button>
            ),
        },
    ];

    useEffect(() => {
        addVehicle();
        platformApi.get("/api/user/branch").then((result) => {
            let { data } = result;
            if (data.code === 200) {
                setBranch(data.data);
            }
        });
    }, []);

    const addVehicle = () => {
        const newRow = {
            key: Date.now(),
            partNumber: null,
            quantity: null,
            phyQuantity: 0,
        };
        setData(prev => [...prev, newRow]);
    };

    const handleFromBranchChange = (branchId) => {
        platformApi.post(`/api/inventoryTransfers/spares`, { branch: branchId }).then((result) => {
            let { data } = result;
            if (data.code === 200) {
                setVehicle(data.data);
            }
        });
        form.setFieldsValue({ toBranch: null });
        setData([]);
        addVehicle();
    };

    const handlePartChange = (partNumber, key) => {
        const selectedVehicle = vehicles.find(v => v.partNumber === partNumber);
        if (!selectedVehicle) return;

        const totalAllocatedQuantity = data
            .filter(row => row.partNumber === partNumber && row.key !== key)
            .reduce((sum, row) => sum + (row.quantity || 0), 0);

        const remainingQuantity = selectedVehicle.phyQuantity - totalAllocatedQuantity;

        if (remainingQuantity <= 0) {
            message.warning(`Part ${partNumber} has no remaining stock. Row removed.`);
            setData(prev => prev.filter(row => row.key !== key));
            return;
        }

        setData(prev => prev.map(row => {
            if (row.key === key) {
                return {
                    ...row,
                    partNo: selectedVehicle.partNo,
                    partNumber: partNumber,
                    phyQuantity: remainingQuantity,
                    quantity: null,
                };
            }
            return row;
        }));
    };

    const handleQuantityChange = (value, key) => {
        let newQuantity = parseInt(value, 10);
        if (isNaN(newQuantity)) newQuantity = null;

        setData(prev =>
            prev.map(row => (row.key === key ? { ...row, quantity: newQuantity } : row))
        );

        form.setFieldsValue({ [`quantity_${key}`]: newQuantity });
        form.validateFields([`quantity_${key}`], { force: true });
    };




    const handleDeleteRow = (key) => {
        if (!key) return;

        setData(prev => {
            // Filter out the row to be deleted
            const filtered = prev.filter(row => row.key !== key);

            // If filtered array is empty or has only empty/invalid rows
            if (filtered.length === 0) {
                // Return a new array with one fresh row
                return [{
                    key: Date.now(),
                    partNumber: null,
                    quantity: null,
                    phyQuantity: 0,
                }];
            }

            return filtered;
        });
    };

    const handleSubmit = () => {
        const fromBranch = getFieldValue("fromBranch");
        const toBranch = getFieldValue("toBranch");

        if (!fromBranch || !toBranch) {
            message.error("Please select both From and To branches!");
            return;
        }

        const validData = data.filter(row => row.partNumber && row.quantity !== null && row.quantity > 0);
        if (validData.length === 0) {
            message.error("Please add at least one valid part with quantity!");
            return;
        }

        const partQuantities = {};
        for (const row of validData) {
            if (!partQuantities[row.partNumber]) {
                partQuantities[row.partNumber] = 0;
            }
            partQuantities[row.partNumber] += row.quantity;
        }

        for (const partNumber in partQuantities) {
            const selectedVehicle = vehicles.find(v => v.partNumber === partNumber);
            if (partQuantities[partNumber] > selectedVehicle.phyQuantity) {
                message.error(`Total quantity for part ${partNumber} exceeds available stock (${selectedVehicle.phyQuantity})`);
                return;
            }
        }

        const payload = {
            spares: validData.map(row => ({
                partNo: row.partNo,
                quantity: row.quantity,
            })),
            from_branch: fromBranch,
            to_branch: toBranch,
        };

        platformApi.put(`/api/inventoryTransfers/spares`, payload)
            .then((result) => {
                if (result.status === 200) {
                    const { data } = result
                    // console.log("result", result)
                    message.success(data.data.message);
                    setData([]);
                    addVehicle();
                    form.resetFields(["fromBranch", "toBranch"]);
                } else {
                    message.error(`Transfer failed: ${result.data.message}`);
                }
            })
            .catch((error) => {
                message.error("An error occurred during transfer.");
                console.error(error);
            });
    };

    return (
        <div>
            <Title className="heading" style={{ width: "60%" }} level={4}>
                Spares Transfer
            </Title>
            <Form>
                <Row type='flex' style={{ gap: "30px" }}>
                    <Col span={4}>
                        <span>From Branch</span>
                        <Item>
                            {getFieldDecorator("fromBranch")(
                                <Select
                                    placeholder="From Branch"
                                    onChange={handleFromBranchChange}
                                >
                                    {branches.map((branch) => (
                                        <Option key={branch.id} value={branch.id}>
                                            {branch.name}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Item>
                    </Col>
                    <Col span={4}>
                        <span>To Branch</span>
                        <Item>
                            {getFieldDecorator("toBranch", {
                                rules: [{ required: true }]
                            })(
                                <Select
                                    placeholder="To Branch"
                                    disabled={!getFieldValue("fromBranch")}
                                >
                                    {branches
                                        .filter(branch => branch.id !== getFieldValue("fromBranch"))
                                        .map((branch) => (
                                            <Option key={branch.id} value={branch.id}>
                                                {branch.name}
                                            </Option>
                                        ))}
                                </Select>
                            )}
                        </Item>
                    </Col>
                </Row>

                <span>Spare Parts Details</span>
                <Table
                    size="small"
                    pagination={false}
                    scroll={{ y: 300 }}
                    className="Challantable"
                    columns={columns}
                    rowKey="key"
                    dataSource={data}
                />
                {/* <Row>
                    <Button type="dashed" onClick={addVehicle} style={{ margin: '10px 0' }}>
                        <Icon type="plus" /> Add Spares
                    </Button>
                </Row> */}
                <Row>
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                        style={{ marginTop: 16 }}
                        disabled={
                            !getFieldValue("fromBranch") ||
                            !getFieldValue("toBranch") ||
                            data.some(row => !row.partNumber || form.getFieldError(`quantity_${row.key}`))
                        }

                    >
                        Transfer Spares
                    </Button>


                </Row>
            </Form>
        </div>
    );
};

const WrappedTransferSpares = Form.create()(TransferSpares);

export default WrappedTransferSpares;