import React, { useState, useEffect, useContext, } from "react";
import {
  Typography,
  Input,
  Button,
  Dropdown,
  Menu,
  Icon,
  message,
  Tabs,
} from "antd";

import { platformApi } from "../../../api";
import JobOrderTable from "./JobOrderTable";
import JobOrderModal from "./JobOrderModal";
import { ContextAPI } from "../../../ContextAPI";
import { useHistory, withRouter } from "react-router-dom";
import WrappedAdvancedFilter from "./AdvancedFilters";
import WrappedMechanicAssign from './MechanicAssign';
import WrappedJobOrderLogs from './JobOrderLog';
import WrappedVehicleForm from '../Vehicles/VehicleForm';
import WrappedVehicleInspection from "./VehicleInspection";
import WrappedOtpSend from "./OTP";
const { TabPane } = Tabs;
const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;

const JobOrder = () => {
  const history = useHistory();
  const [limit, setLimit] = useState(10);
  const [addFlag, setAddFlag] = useState(false);
  const [change, setChange] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [dataSource, setDataSource] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [printAccess, setPrintAccess] = useState(false);
  const [tab, setTab] = useState("PENDING")
  const [active, setActive] = useState("1")
  const [filterData, setFilterData] = useState({});
  const [filteropen, setFilteropen] = useState(false);
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const { loginCredintials } = useContext(ContextAPI)
  const [branch, setBranch] = useState([]);
  const [mechanic, setMechanic] = useState([]);
  const [assign, setAssign] = useState(false);
  const [assignData, setAssignData] = useState(-1);
  const [log, setLog] = useState(false);
  const [logData, setLogData] = useState(-1);
  const [vOpen, setVOpen] = useState(false);
  const [vData, setVData] = useState(-1);
  const [insp, setInsp] = useState(false);
  const [statusData, setStatusData] = useState(-1);
  const [otp, setOtp] = useState(false);
  const [otpData, setOtpData] = useState(-1);
  const [referenceid, setReferenceId] = useState("")
  const [gst, setGst] = useState();

  const getMenu = () => {
    const size = count / 10 + (count % 10) ? 1 : 0;
    const menus = [];
    menus.push(10);
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => setLimit(menu)} key={menu}>
        {menu}
      </Item>
    ));
  };

  useEffect(() => {
    let temp = [];
    setGst(loginCredintials.branch[0].gst)
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === 'job_order') {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
          if (each.access.print) {
            setPrintAccess(true);
          }
        }
      })
    for (let i = 0; i < loginCredintials.branch.length; i++) {
      const element = loginCredintials.branch[i];
      temp.push(element.id)
    }
    setBranch(temp)
    if(branch.length>1)
    setPrimaryData(search);
    getMechanic();
  }, []);

  const getMechanic = () => {
    platformApi.get("/api/user")
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let mechanic = data.data.users.filter(item => {
            return item.profile &&
              item.profile.department.role === "Mechanic"
          })
          setMechanic(mechanic)
        }
      })
  }

  useEffect(
    () => {
      if(branch.length>1)
      setPrimaryData(search);
    }, [page, limit, search, branch, filterData]);

  const setPrimaryData = (search, status, val) => {
    if (!status) {
      status = tab
    }
    setSpinning(true);

    let obj = {
      page: val ? val : page,
      size: limit,
      filter: filterData,
      searchString: search,
      status,
      branch: branch && branch
    };
    // console.log(filteropen, filterData)

    // if (filteropen) {
    //   if (filterData.status) {
    //     obj.filter.status = filterData.status;
    //     obj.filter.fromDate = filterData.fromDate && filterData.fromDate._d.toISOString();
    //     obj.filter.toDate = filterData.toDate && filterData.toDate._d.toISOString();
    //   }
    //   else {
    //     obj.filter.fromDate = filterData.fromDate && filterData.fromDate._d.toISOString();
    //     obj.filter.toDate = filterData.toDate && filterData.toDate._d.toISOString();
    //   }
    // }
    // else {
    //   obj.searchString = "";
    //   // obj.filter = {}
    // }
    platformApi
      .post("/api/jobOrder/get", obj)
      .then((res) => {
        setSpinning(false);
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {

            console.log(" -----   ---  "+JSON.stringify(response.data.jobOrder));

            setDataSource(response.data.jobOrder);
            setCount(response.data.count);
          } else {
            message.error("Failed to fetch Job Order");
          }
        } else {
          message.error("Failed to fetch Job Order");
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on Job Order : ", error);
        message.error("Failed to fetch Job Order");
      });
  }

  const deleteData = (id) => {
    setSpinning(true);
    let temp = page != 1 && dataSource.length === 1
    // if (page != 1 && dataSource.length === 1) {
    //   setPage(page - 1)
    // }
    platformApi
      .delete("/api/jobOrder/" + id)
      .then((result) => {
        setSpinning(false);
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            if (temp) {
              setPage(1)
              setPrimaryData(null, null, 1)
            }
            else {
              setPrimaryData();
            }
            message.success("Deleted Sucessfully", 2);
          } else {
            message.error("Unable to delete Job Order", 2);
          }
        } else {
          message.error("Unable to delete Job Order", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on Job Order : ", error);
        message.error("Unable to delete Job Order", 2);
      });
  };

  const addData = (jobOrder) => {
    console.log("submit data", jobOrder)
    setSpinning(true);
    if (jobOrder.id) {
      platformApi
        .put(`/api/jobOrder/${jobOrder.id}`, jobOrder)
        .then((res) => {
          setSpinning(false);
          let { data } = res;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              dataSource.some((obj, index) => {
                if (obj.id === jobOrder.id)
                  dataSource[index] = response.data;
              });
              setDataSource([]);
              setDataSource(dataSource);
              message.success("Job Order Updated successfully", 2);
            } else {
              message.error("Unable to update Job Order", 2);
            }
          } else {
            message.error("Unable to update Job Order", 2);
          }
        })
        .catch((err) => {
          setSpinning(false);
          message.error("Unable to update Job Order", 2);
          console.error("Error on Job Order : ", err);
        });
    }
    else
      platformApi
        .post("/api/jobOrder/", jobOrder)
        .then((res) => {
          setSpinning(false);
          let { data } = res;
          // console.log("PdfData", res)
          if (data.code === 200) {
            let { response } = res.data;
            if (response.code === 200) {
              setDataSource([...dataSource, response.data]);
              setCount(count + 1);
              message.success("Job Order added successfully");
              setPrimaryData(null, tab);
            } else {
              message.error("Error in Job Order creation");
            }
          } else {
            message.error("Error in Job Order creation");
          }
        })
        .catch((err) => {
          console.error("Error on Job Order : ", err);
        });
  };


  const handleTabChange = (activeKey) => {
    console.log(search,"search",activeKey);
    switch (activeKey) {
     
      case "1": setPrimaryData(search, "PENDING")
        setTab("PENDING")
        setPage(1)
        setActive("1")
        break;
      case "2": setPrimaryData(search, "IN PROGRESS")
        setTab("IN PROGRESS")
        setPage(1)
        setActive("2")
        break;
      case "3": setPrimaryData(search, "COMPLETED")
        setTab("COMPLETED")
        setPage(1)
        setActive("3")
        break;
      case "4": setPrimaryData(search, "ALL")
        setTab("ALL")
        setPage(1)
        setActive("4")
        break;
      default: setPrimaryData(search, null)
        setTab("ALL")
        setPage(1)
        setActive("4")
    }
  }

  const collapseFilter = () => {
    if (filteropen) {
      setFilteropen(false)
      setFilterData({})
    }
    else {
      setFilteropen(true)
    }
    setSearch('')
    // setPrimaryData();
    // filteropen ? setFilteropen(false) : setFilteropen(true);
    // let obj = {
    //   name: "",
    // };
    // setSearch(obj);
  };

  const menu = <Menu>{getMenu()}</Menu>;
  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          Job Card [{count}]
          <span style={{ margin: ".5rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit} &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search style={{ width: "30%" }} placeholder="Search Job Order"
          onSearch={(event) => {
            setPage(1);
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === '') {
              setSearch(null);
              setPage(1);
            }
          }} />
        <div style={{ width: "12%", textAlign: "center", marginLeft: "1%" }}>
          <Button
            type="link"
            onClick={() => {
              collapseFilter();
            }}
          >
            {filteropen ? "Back" : "Advanced  Filters"}
          </Button>
        </div>
        <Button
          onClick={() => {
            setAddFlag(true);
            setEditFlag(true);
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add Job Card
        </Button>
      </div>

      <WrappedAdvancedFilter
        filteropen={filteropen}
        searchName={search}
        setId={setFilter}
        setPageData={setPrimaryData}
        setSearch={setSearch}
        setFilterData={setFilterData}
        setPage={setPage}
      />


      <Tabs activeKey={active} onChange={handleTabChange}>
        <TabPane
          tab={
            <span>
              Pending
            </span>
          }
          key="1"
        >
          {/* {setPrimaryData(null, "ALL")} */}
          <JobOrderTable
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              defaultCurrent: 1,
              total: count,
              showQuickJumper: true,
            }}
            // tab={0}
            openModal={(id, editable, change) => {
              setSelected(id);
              setEditFlag(editable);
              setChange(change)
              setAddFlag(true);
            }}
            delete={(id) => deleteData(id)}
            dataSource={dataSource}
            spinner={spinning}
            modify={modifyAccess}
            setAssign={setAssign}
            setAssignData={setAssignData}
            setLogData={setLogData}
            setLog={setLog}
            setInsp={setInsp}
            setStatusData={setStatusData}
            setOtp={setOtp}
            setOtpData={setOtpData}
            setReferenceId={setReferenceId}
            gst={gst}
            modify={modifyAccess}
            deleteAccess={deleteAccess}
            printAccess={printAccess}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              In Progress
            </span>
          }
          key="2"
        >
          {/* {setPrimaryData(null, "ACTIVE")} */}
          <JobOrderTable
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              defaultCurrent: 1,
              total: count,
              showQuickJumper: true,
            }}
            // tab={1}
            openModal={(id, editable, change) => {
              setSelected(id);
              setEditFlag(editable);
              setChange(change)
              setAddFlag(true);
            }}
            delete={(id) => deleteData(id)}
            dataSource={dataSource}
            spinner={spinning}
            modify={modifyAccess}
            setAssign={setAssign}
            setAssignData={setAssignData}
            setLogData={setLogData}
            setLog={setLog}
            setInsp={setInsp}
            setStatusData={setStatusData}
            modify={modifyAccess}
            deleteAccess={deleteAccess}
            printAccess={printAccess}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              Completed
            </span>
          }
          key="3"
        >
          {/* {setPrimaryData(null, "ACTIVE")} */}
          <JobOrderTable
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              defaultCurrent: 1,
              total: count,
              showQuickJumper: true,
            }}
            // tab={2}
            openModal={(id, editable, change) => {
              setSelected(id);
              setEditFlag(editable);
              setChange(change)
              setAddFlag(true);
            }}
            delete={(id) => deleteData(id)}
            dataSource={dataSource}
            spinner={spinning}
            modify={modifyAccess}
            setLogData={setLogData}
            setLog={setLog}
            setInsp={setInsp}
            setStatusData={setStatusData}
            modify={modifyAccess}
            deleteAccess={deleteAccess}
            printAccess={printAccess}
           
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              All
            </span>
          }
          key="4"
        >
          {/* {setPrimaryData(null, "ACTIVE")} */}
          <JobOrderTable
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              defaultCurrent: 1,
              total: count,
              showQuickJumper: true,
            }}
            // tab={3}
            openModal={(id, editable, change) => {
              setSelected(id);
              setEditFlag(editable);
              setChange(change)
              setAddFlag(true);
            }}
            delete={(id) => deleteData(id)}
            dataSource={dataSource}
            spinner={spinning}
            modify={modifyAccess}
            setAssign={setAssign}
            setAssignData={setAssignData}
            setLogData={setLogData}
            setLog={setLog}
            setInsp={setInsp}
            setStatusData={setStatusData}
            gst={gst}
            modify={modifyAccess}
            deleteAccess={deleteAccess}
            printAccess={printAccess}
          />
        </TabPane>
      </Tabs>


      <JobOrderModal
        data={dataSource[dataSource.findIndex((obj) => selected === obj.id)]}
        emitData={(data) => addData(data)}
        editable={editFlag}
        changed={change}
        setChange={setChange}
        setEditFlag={setEditFlag}
        modify={modifyAccess}
        loading={loading}
        setLoading={setLoading}
        open={addFlag}
        close={() => {
          setSelected(null);
          setAddFlag(false);
          setLoading(false);
          setChange(false)
        }}
      />{" "}

      <WrappedMechanicAssign
        data={dataSource[dataSource.findIndex((obj) => assignData === obj.id)]}
        modify={modifyAccess}
        open={assign}
        close={() => {
          setAssign(false)
          setAssignData(null);
          setPrimaryData();
        }}
        editable={true}
        mechanic={mechanic}
        setMechanic={setMechanic}
      />

      <WrappedJobOrderLogs
        data={dataSource[dataSource.findIndex((obj) => logData === obj.id)]}
        modify={modifyAccess}
        open={log}
        close={() => {
          setLog(false)
          setLogData(null);
          setPrimaryData();
        }}
        editable={true}
        setVData={setVData}
        setVOpen={setVOpen}
      />

      <WrappedVehicleInspection
        data={dataSource[dataSource.findIndex((obj) => statusData === obj.id)]}
        modify={modifyAccess}
        open={insp}
        close={() => {
          setInsp(false)
          setStatusData(null);
          setPrimaryData();
        }}
        editable={true}
      />

      <WrappedVehicleForm
        data={vData}
        // emitData={addData}
        vData={true}
        open={vOpen}
        editable={false}
        close={() => {
          setVOpen(false);
          setPrimaryData();
        }}
        modify={modifyAccess}
        edit={vOpen}
        setEdit={setVOpen}
      />

      <WrappedOtpSend
        data={dataSource[dataSource.findIndex((obj) => otpData === obj.id)]}
        modify={modifyAccess}
        open={otp}
        close={() => {
          setOtp(false)
          setOtpData(null);
          setPrimaryData();
        }}
        editable={true}
        referenceid={referenceid}
      />

    </div>
  );
}
export default withRouter(JobOrder);