import React from "react";
import { Table, Divider, Popconfirm, Typography } from "antd";
import moment from 'moment';
import { platformApi } from "../../../../../api";

const { Text } = Typography;

function SparesTable(props) {
  const { pagination, dataSource, openModal, spinner, getPSI } = props;
  const column = [
    {
      title: "Part No",
      dataIndex: "partNo",
      render: (record) => <span>{record.partNumber}</span>,
    },
    {
      title: "Part Name",
      dataIndex: "partNo",
      render: (record, data) => <span>{record.partName}</span>,
    },
    {
      title: "Part Status",
      dataIndex: "partNo",
      render: (record, data) => <span>{record.partStatus}</span>,
    },
    {
      title: "Manufacturer",
      dataIndex: "partNo",
      render: (record, data) => <span>{record.manufacturer.name ? record.manufacturer.name : ""}</span>,
    },
    {
      title: "Quantity",
      dataIndex: "",
      key: "",
      render: (record, data) => {
        console.log("record", record)
        console.log("data", data)
        const totalPhyQuantity = record.branch.reduce((sum, branch) => sum + (branch.phyQuantity || 0), 0);
        return totalPhyQuantity > 0 ? <span>{totalPhyQuantity}</span> : <span>0</span>;
      },
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record, data) => <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
    },
    // {
    //   title: "Action",
    //   //   render: (data) => (
    //   //     <div>
    //   //       {/* {
    //   //         modify ? */}
    //   //       <span
    //   //         onClick={(event) => {
    //   //           event.stopPropagation();
    //   //           openModal(data.id, true);
    //   //         }}
    //   //         className="linkylink"
    //   //       >
    //   //         Modify
    //   //       </span>
    //   //       {/* :
    //   //           <span></span>
    //   //       } */}
    //   //       <Divider type="vertical" />
    //   //       {/* {
    //   //         deleteAccess ? */}
    //   //       <Popconfirm
    //   //         title="Do you want to delete this Group?"
    //   //         okText="Yes"
    //   //         cancelText="No"
    //   //         onCancel={(event) => {
    //   //           event.stopPropagation();
    //   //         }}
    //   //         onConfirm={(event) => {
    //   //           deleteData(data.id);
    //   //           event.stopPropagation();
    //   //         }}
    //   //       >
    //   //         <span
    //   //           onClick={(event) => event.stopPropagation()}
    //   //           className="linkylink"
    //   //         >
    //   //           Delete
    //   //         </span>
    //   //       </Popconfirm>
    //   //       <Divider type="vertical" />
    //   //       <span
    //   //         onClick={(event) => {
    //   //           event.stopPropagation();
    //   //           refreshData(data.id)
    //   //         }}
    //   //         className="linkylink"
    //   //       >
    //   //         Refresh
    //   //       </span>

    //   //       {/* :
    //   //           <span></span>
    //   //       } */}
    //   //     </div>
    //   //   ),
    // },
  ];

  return (
    <Table
      pagination={pagination}
      rowKey={(record) => record.id}
      columns={column}
      tableLayout="fixed"
      loading={spinner}
      style={{ cursor: "pointer" }}
      dataSource={dataSource}
      onRow={(props) => ({
        onClick: () => openModal(props, false),
      })}
    />
  );
}


export default SparesTable;