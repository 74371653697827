import PIOPIY from "piopiyjs";

var piopiy = new PIOPIY({
  name: "Display Name",
  debug: false,
  autoplay: true,
  ringTime: 60,
});

const piopiyLogin = async (obj) => {
  try {
    obj.login("1002_33335196", "12345678", "sbcind.telecmi.com");
    console.log("piopy login success");
  } catch (error) {
    console.log(error);
  }

}

const piopiyDialUser = (obj,selectedNumber, UniqueId) => {
  try {
    console.log(selectedNumber,obj)
    const data =JSON.stringify({
      module:"Follow-Ups",
      uniqueId:UniqueId
    })
    obj.call(selectedNumber,{ extra_param:data });
  } catch (error) {
    console.log(error);
  }
}

const answerincommingCall = (obj) => {
  obj.answer();
}

const declineIncommingCall = (obj) => {
  obj.reject();
}

const declineOutgoingCall =(obj)=>{
  try {
    obj.terminate();
  } catch (error) {
    console.log(error);
  }
}

export { piopiy, piopiyLogin, piopiyDialUser, answerincommingCall, declineIncommingCall,declineOutgoingCall }