import React, { useState } from "react";
import { Table, Divider, Popconfirm, Typography } from "antd"
const { Text } = Typography
import { withRouter } from 'react-router-dom'
import moment from "moment";

const FollowUpTable = (props) => {
    const {
        pagination,
        dataSource,
        spinner,
        history,
        firstDate,
        lastDate
    } = props;

    const column = [
        {
            title: "Vehicle",
            dataIndex: "registerNo",
            key: "registerNo",
            render: (record) => <span>{record? record:"-" }</span>,
        },
        {
            title: "Customer Name",
            dataIndex: "customer",
            key: "name",
            render: (record) => {
                return <span>{record[0] && record[0].customer && record[0].customer.name ?record[0].customer.name :"-" }</span>;
            },
        },
        {
            title: <span>Service No</span>,
            dataIndex: "service",
            key: "serviceNo",
            render: (record, data) => {
                for (const item of record) {
                //   if (item.serviceDate >= firstDate && item.serviceDate <= lastDate) {
				// 						return <span>{item.serviceNo}</span>;
				// 					}
                if(item.serviceNo)
                 return <span>{item.serviceNo}</span>
                return <span>-</span>

                }
            }
        },
        {
            title: <span>Service Type</span>,
            dataIndex: "service",
            key: "serviceType",
            render: (record, data) => {
              for (const item of record) {
                if(item.serviceType !== "null")
                    return (
                        <span>
                            {item.serviceType}
                        </span>
                    )
                    return(<span>-</span>)
              }
              if (record[record.length - 1]) return record[record.length - 1].serviceType;
              return <span>-</span>
            },
        },
        {
            title: <span>Service Kms</span>,
            dataIndex: "service",
            key: "serviceKms",
            render: (record, data) => {
                return (
                    record.map(item => {
                        if (item.serviceKms) {
                            return (
                                <span>
                                    {item.serviceKms}
                                </span>
                            )
                        }
                        return <span>-</span>
                    })
                )
            },
        },
        {
            title: "Mobile No",
            dataIndex: "customer",
            key: "phone",
            render: (record) => <span>{record[0] && record[0].customer && record[0].customer.contacts[0]?.phone ? record[0].customer.contacts[0]?.phone : "-" }</span>,

        },
        {
            title: "Next Service Date",
            dataIndex: "service",
            key: "serviceDate",
            render: (record, data) => {
              for (const item of record) {
                if (item.serviceDate) {
									return <span>{moment(new Date(item.serviceDate)).format("DD/MM/YYYY")}</span>;
								}
              }

              if (record[record.length - 1]) return record[record.length - 1].serviceDate;
							return <span>-</span>;
            },
            // dataIndex: "scheduleDate",
            // key: "scheduleDate",
            // render: (record) => <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
        },
    ];
    return (
        <Table
            // rowKey={(record) => record.id}
            onRow={(record, index) => {
                return {
                    onClick: () => {
                        record.service.map(item => {
                            if (item.serviceDate ) {
                                history.push(`/autoadmin/service-followups/${record?.customer[0].customer?.contacts[0]?.phone}/fupdate/${item.serviceDate}`)
                            }
                        })
                    }
                }
            }
            }
            style={{ cursor: "pointer" }}
            pagination={pagination}
            columns={column}
            dataSource={dataSource}
            loading={spinner}
        />
    )
}
export default withRouter(FollowUpTable)
