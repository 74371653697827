import React, { useState, useEffect } from "react";
import { Form, Typography, Row, Col, Checkbox, Radio } from "antd";
import placeholder from "../../../../../../../assets/vehicle-placeholder.jpg";
import PriceTable from "./PriceTable";
import TaxTable from "./TaxTable";
import { isArray } from "lodash";

const { Item } = Form;
const { Text } = Typography;

const PriceModal = ({ form, open, editable, data, validityRef }) => {
  const [priceList, setPriceList] = useState({ total: undefined });
  const [vehicle, setVehicle] = useState(null);
  const [price, setPrice] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [otherChecked, setOthersChecked] = useState([]);
  const [colorSelect, setColorSelect] = useState({});
  // Hold the unmodified base total so recalculations are consistent
  const [baseTotal, setBaseTotal] = useState(0);
  // Store the selected insurance option. Initially, nothing is selected.
  const [selectedInsurance, setSelectedInsurance] = useState(null);

  const { getFieldDecorator, resetFields } = form;

  const insTypes = [
    { key: "insurance1plus5", title: "1+5" },
    { key: "insurance5plus5", title: "5+5" },
    { key: "insurance1plus5ZD", title: "1+5 Zero Dep" },
    { key: "insurance5plus5ZD", title: "5+5 Zero Dep" },
  ];

  // Only these two options are used for display.
  const insuranceOptions = insTypes.filter(
    (type) =>
      type.key === "insurance1plus5" || type.key === "insurance1plus5ZD"
  );

  const others = [
    { key: "warrantyPrice", title: "Extended Warranty" },
    { key: "amc", title: "Amc" },
    { key: "rsa", title: "Rsa" },
    { key: "otherCharges", title: "Other Charges" },
    { key: "discount", title: "Discount" },
  ];

  const priceType = [
    { key: "showroomPrice", title: "Ex-Showroom Price" },
    { key: "roadTax", title: "Road Tax" },
    { key: "registrationFee", title: "Registration Fee" },
    { key: "handlingCharges", title: "Handling Charges" },
    { key: "tcs", title: "Tcs" },
  ];

  useEffect(() => {
    validityRef.current = () => {
      return handleSubmit();
    };
    return () => {
      validityRef.current = null;
    };
  });

  useEffect(() => {
    if (open) {
      if (data) {
        const { vehicleDetail, insuranceType, price, color } = data;
        if (vehicleDetail) {
          setVehicle(price?.colors[0]);
          if (color) setColorSelect(color.imageDetails[0]);
          if (price) {
            setPrice(price);
            const total = fetchPrice(price);
            if (insuranceType) {
              // If initial insurance is provided, set it
              const insurance = insuranceType.map((insure) => insure.type);
              getFieldDecorator("insurance", { initialValue: insurance });
              calculateTotal(insurance, price, { total });
              setSelectedInsurance(insurance[0]);
            }
          }
        }
      }
    } else {
      form.resetFields();
      setPriceList({});
      setVehicle(null);
      setDataSource([]);
      setSelectedInsurance(null);
      resetFields();
    }
  }, [open, data]);

  const handleSubmit = () => {
    let error;
    form.validateFields((validationError) => {
      error = validationError === null;
      if (error) {
        const insurance = [];
        const optional = [];
        if (!data.color) {
          data.color = vehicle.imageDetails[0].id;
        }
        insTypes.forEach((types) => {
          if (priceList[types.key]) {
            insurance.push({
              type: types.key,
              amount: priceList[types.key] - priceList.total,
              onRoad: priceList[types.key],
            });
          }
        });
        others.forEach((val) => {
          if (priceList[val.key]) {
            optional.push({
              type: val.key,
              amount: priceList[val.key],
            });
          }
        });
        data.optionalType = optional;
        data.insuranceType = insurance;
      }
    });
    return error;
  };

  // Insurance calculation triggered by radio change.
  // It updates only the selected insurance option.
  const calculateTotal = (checked, mockPrice = {}) => {
    const selected = Array.isArray(checked) ? checked[0] : checked.target.value;
    const loadedPrice = mockPrice.id ? mockPrice : price;
    // Calculate the total from the already selected "others"
    const othersTotal = otherChecked.reduce((sum, key) => sum + (price[key] || 0), 0);
    // Compute a new base that includes "others" charges
    const newBase = baseTotal + othersTotal;
  
    let newPrices = { ...priceList };
    // Clear previous insurance entries
    insuranceOptions.forEach((type) => {
      delete newPrices[type.key];
    });
    // Set the new insurance value, merging with others total
    newPrices[selected] = newBase + loadedPrice[selected];
    setPriceList(newPrices);
  };
  

  // Optional charges calculation. It recalculates the total based on the selected others.
  // It also updates the insurance value only if an insurance option is selected.
  const calculateAMCRSATotal = (checked) => {
    setOthersChecked(checked);
    const othersTotal = checked.reduce(
      (sum, key) => sum + (price[key] || 0),
      0
    );
    let newPriceList = { ...priceList };
    newPriceList.total = baseTotal + othersTotal;
    // Set each selected other's price
    checked.forEach((key) => {
      newPriceList[key] = price[key];
    });
    // Update insurance field only if an option is selected.
    const insuranceFields = ["insurance1plus5", "insurance1plus5ZD"];
    insuranceFields.forEach((field) => {
      if (selectedInsurance === field && price[field]) {
        newPriceList[field] = baseTotal + price[field] + othersTotal;
      } else {
        delete newPriceList[field];
      }
    });
    setPriceList(newPriceList);
  };

  // Fetch the base price from the provided data and store it as baseTotal.
  const fetchPrice = (data) => {
    let total = 0,
      source = [];

    priceType.forEach((type) => {
      if (data[type.key]) {
        source.push({ type: type.title, price: data[type.key] });
        if (type.key === "discount") {
          total -= data[type.key];
        } else {
          total += data[type.key];
        }
      }
    });
    setPriceList({ total });
    setBaseTotal(total);
    setDataSource(source);
    return total;
  };

  return (
    <Form>
      <Row type="flex" justify="space-around">
        <Col span={11}>
          <Item>
            {vehicle &&
              (vehicle.imageDetails ? (
                (vehicle.imageDetails[0].url && (
                  <img
                    src={colorSelect.url}
                    alt="Vehicle"
                    width="100%"
                    onError={(event) =>
                      (event.target.src = placeholder)
                    }
                  />
                )) ||
                (vehicle.imageDetails[0] && (
                  <img
                    src={colorSelect.url}
                    alt="Vehicle"
                    width="100%"
                    onError={(event) =>
                      (event.target.src = placeholder)
                    }
                  />
                ))
              ) : (
                <img width="100%" src={placeholder} alt="Vehicle" />
              ))}
          </Item>
          <Item>
            {vehicle && (
              <div>
                <Text strong>Selected Model</Text>
                <div
                  style={{
                    border: "1px solid gray",
                    borderRadius: "1%",
                    padding: "1%",
                  }}
                >
                  <Row>
                    <Text strong>Model Name : </Text>
                    <Text>{data.vehicleDetail.modelName}</Text>
                  </Row>
                  <Row>
                    <Text strong>Model Code : </Text>
                    <Text>{data.vehicleDetail.modelCode}</Text>
                  </Row>
                  <Row>
                    <Text strong>Selected Color : </Text>
                    <Text>{colorSelect.color}</Text>
                  </Row>
                </div>
              </div>
            )}
          </Item>
        </Col>
        <Col span={11}>
          <Col span={12}>
            <Row>
              {vehicle && price && (
                <Item label={<Text>Insurance Type</Text>} colon={false}>
                  {getFieldDecorator("insurance", {
                    rules: [
                      { required: true, message: "Select Insurance Type" },
                    ],
                  })(
                    <Radio.Group
                      disabled={!editable}
                      onChange={(e) => {
                        setSelectedInsurance(e.target.value);
                        calculateTotal(e);
                      }}
                      value={selectedInsurance}
                    >
                      <Row type="flex" justify="space-between">
                        {insuranceOptions.map((types) => (
                          <Radio key={types.key} value={types.key}>
                                  {types.title} - &#8377;
                                  {price[types.key]}
                          </Radio>
                        ))}
                      </Row>
                    </Radio.Group>
                  )}
                </Item>
              )}
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              {vehicle && price && (
                <Item label={<Text>Others</Text>} colon={false}>
                  <Checkbox.Group
                    disabled={!editable}
                    onChange={(checked) => {
                      calculateAMCRSATotal(checked);
                    }}
                    value={otherChecked}
                  >
                    <Row type="flex" justify="space-between">
                      {others.map((types) => {
                        if (price[types.key]) {
                          return (
                            <Col span={22} key={types.key}>
                              <Checkbox value={types.key}>
                                {types.title} - &#8377;{price[types.key]}
                              </Checkbox>
                            </Col>
                          );
                        }
                        return null;
                      })}
                    </Row>
                  </Checkbox.Group>
                </Item>
              )}
            </Row>
          </Col>
          {!editable ? (
            <Row>
              <Item label={<Text>Color Select</Text>} colon={false}>
                <span>
                  <b>
                    {data.color
                      ? data.color.color
                      : "No color Selected for this Model"}
                  </b>
                </span>
              </Item>
            </Row>
          ) : (
            <span></span>
          )}
          <Row>
            <Item>
              <PriceTable dataSource={dataSource} />
            </Item>
            <Item>
              <TaxTable dataSource={[priceList]} />
            </Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

const PriceForm = Form.create({ name: "image" })(PriceModal);

export default PriceForm;
