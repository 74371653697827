/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import {
  Layout,
  Typography,
  Menu,
  Icon,
  Select,
  Tag,
  Button,
  Col,
  Row,
  message,
} from "antd";
import { MessageOutlined } from "@ant-design/icons";
import "./navigation.less";
import GlobalHeaderRight from "./GlobalHeader/RightContent";
import { useEffect } from "react";
import { platformApi } from "../../api";
import { ContextAPI } from "../../ContextAPI";
import { useContext } from "react";
import LOGO1 from "../../assets/logo-04.png";
import LOGOSmall from "../../assets/Smallicon.png";

import Customer from "../../assets/customer.svg";
import Quotation from "../../assets/quotation.svg";
import BookingRegister from "../../assets/bookingregister.svg";
import Vehicle from "../../assets/Vehicle.svg";
import VehiclePurchaseChallan from "../../assets/Vehiclepurchacechallan.svg";
import VehiclePurchaseInvoice from "../../assets/Vehiclepurchaceinvoice.svg";
import PurchaseReturn from "../../assets/Purchasereturn.svg";
import SaleChallan from "../../assets/Salechallan.svg";
import Joborder from "../../assets/Joborder.svg";
import Materialissue from "../../assets/Materialissue.svg";
import Jobinvoice from "../../assets/jobinvoice.svg";
import Customerpartsorder from "../../assets/Customerpartsorder.svg";
import Customersalesspares from "../../assets/Customersalesspares.svg";
import Suppliermaster from "../../assets/suppliermaster.svg";
import Vehiclesaleinvoice from "../../assets/Vehiclesaleinvoice.svg";
import Promotions from "../../assets/Promotions.svg";
import Tasks from "../../assets/task.svg";
import Groups from "../../assets/groups.svg";
import { useBranch } from "../../ContextAPI/branch";

// import Dashboard from "../Dashboard";

const { Header, Sider, Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

const Navigation = (props) => {
  const { loginCredintials, setLoginCredintials } = useContext(ContextAPI);
  const { type, category } = props.match.params;
  const { content: ContentComponent } = props;

  const [openedSubMenu, setOpenedSubMenu] = useState("company_masters");
  const [collapsed, setCollapsed] = useState(false);
  const [collapseMargin, setCollapseMargin] = useState(240);
  const [branchName, setBranchName] = useState([{ name: "Select Branch" }]);
  const [currentBranch, setCurrentBranch] = useState([]);
  const { selectBranch, setSelectBranch } = useBranch()
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [branchOpen, setBranchOpen] = useState(false);
  const [theme] = useState("light");

  const handleToggle = () => {
    setCollapsed(!collapsed);
    setCollapseMargin(collapsed ? 240 : 80);
    !collapsed ? setOpenedSubMenu() : setOpenedSubMenu("company_masters");
  };
  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  const { SubMenu, Item } = Menu;

  // const logoText =

  useEffect(() => {
    if (loginCredintials.branch && loginCredintials.branch[0]) {
      setBranchName([loginCredintials.branch[0]]);
    } else {
      let array = [];
      selectBranch.map((e) => {
        array.push(e);
      });
      setBranchName(array);
    }
  }, []);

  useEffect(() => {
    setCurrentBranch(loginCredintials && loginCredintials.branch);
  }, [loginCredintials]);

  const changeSubMenu = (prop) => {
    if (openedSubMenu !== prop.key) setOpenedSubMenu(prop.key);
    else setOpenedSubMenu();
  };
  function checkDropdownList(type) {
    let bool = false;
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (
          (each.subModule === type && each.access.read) ||
          (type === "service-followups" &&
            each.subModule === "service_reminder" &&
            each.access.create)
        ) {
          bool = true;
        }
      });
    return bool;
  }

  function checkDashboard() {
    let bool = true;
    loginCredintials.departmentType &&
      loginCredintials.departmentType.map((type) => {
        if (type === "General") {
          bool = false;
        }
      });

    if (
      loginCredintials.departmentType &&
      loginCredintials.departmentType.length === 0
    ) {
      bool = false;
    }
    return bool;
  }

  function checkPromotion() {
    return !checkDropdownList("groups") && !checkDropdownList("tasks");
  }

  function checkReports(){
    return !checkDropdownList("spares_inventory") && !checkDropdownList("stock_check") && !checkDropdownList("vehicle_inventory") && !checkDropdownList("Service Report")
  }

  function checkServiceDashboard() {
    let bool = false;
    loginCredintials.departmentType &&
      loginCredintials.departmentType.map((type) => {
        if (type === "General") {
          bool = false;
        } else if (type === "Service") {
          bool = true;
        }
      });
    if (
      loginCredintials.departmentType &&
      loginCredintials.departmentType.length === 0
    ) {
      bool = false;
    }
    return bool;
  }
  const onOk = () => {
    let flag = selectBranch;
    flag[0]
      ? setBranchOpen(!branchOpen)
      : message.error("Select atleast one branch");
    let branch = [];
    flag.map((each) => {
      branch.push(each.id);
    });
    platformApi.post("/api/user/token", { branch }).then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          localStorage.setItem("token", response.data);
          props.history.push("/autoadmin");
        }
      }
    });
  };

  const changeLoginCredintials = (name, event) => {
    let obj = loginCredintials;
    obj.branch.name = name;
    obj.branch.id = event.key;
    setLoginCredintials(obj);
  };

  return (
    <Layout
      hasSider
      style={{
        height: "100vh",
      }}
    >
      <Sider
        className="sider"
        trigger={null}
        collapsible
        collapsed={collapsed}
        width="240px"
        height="100vh"
        style={{
          overflowY: "scroll",
          height: "100%",
          left: 0,
          boxShadow: "3px 0 17px 0 rgba(0, 0, 0, 0.15)",
          zIndex: 101,
          fontFamily: "Arial",
        }}
        theme={theme}
      >
        <Title level={2} style={{ margin: "1rem 1.2rem" }}>
          {!collapsed ? (
            <img style={{ width: "100%" }} src={LOGO1}></img>
          ) : (
            <img style={{ width: "100%" }} src={LOGOSmall}></img>
          )}
        </Title>

        <h4
          onClick={() => setBranchOpen(!branchOpen)}
          style={{
            cursor: "pointer",
            marginLeft: "11%",
            fontWeight: "700",
            color: "blue",
            fontFamily: "Sans-serif",
          }}
        >
          {!collapsed ? (
            branchName[0] === "Select Branch" ? (
              <span>Select Branch Here</span>
            ) : (
              branchName.map((e) => {
                return (
                  <Tag key={true} style={{ cursor: "pointer" }} color="#006a71">
                    {e.name}
                  </Tag>
                );
              })
            )
          ) : (
            ""
          )}
        </h4>
        {branchOpen && !collapsed ? (
          <Row gutter={0}>
            <Col span={18}>
              <Select
                placeholder="Select Branch"
                filterOption={filterMethod}
                showSearch
                mode="multiple"
                style={{ width: "80%", marginLeft: "1.5rem" }}
                value={selectedBranch}
                onChange={(id, event) => {
                  changeLoginCredintials(id, event);
                  let array = [];
                  let array1 = [];
                  event.map((each) => {
                    let obj = {
                      id: each.key,
                      name: each.props.value,
                    };
                    array.push(obj);
                    array1.push(each.props.value);
                  });
                  if (id.length) {
                    setBranchName(array);
                    setSelectedBranch(array1);
                    setSelectBranch(array)
                  }
                }}
              >
                {currentBranch &&
                  currentBranch.map((d) => (
                    <Option key={d.id} value={d.name}>
                      {d.name}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={4}>
              <Button
                type="primary"
                onClick={() => {
                  onOk();
                }}
              >
                OK
              </Button>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <Menu
          openKeys={[openedSubMenu]}
          selectedKeys={type === "promotion" ? category : type}
          theme={theme}
          mode="inline"
          inlineCollapsed={!collapsed}
          // onMenuCloseDelay={5}
          defaultOpenKeys={["transaction_masters"]}
          
        >
          {checkDashboard() ? (
            <Menu.Item key="dashboard">
              <NavLink to="/autoadmin/dashboard">
                <Icon
                  style={{ fontSize: "18px", marginLeft: "0px" }}
                  type="transaction"
                />
                <span
                  style={{
                    fontSize: "14px",
                    marginLeft: "3px",
                    color: "rgb(108, 123, 138)",
                  }}
                >
                  Sales Dashboard
                </span>
              </NavLink>
            </Menu.Item>
          ) : (
            <span></span>
          )}



          {checkServiceDashboard() ? (
            <Menu.Item key="services">
              <NavLink to="/autoadmin/services">
                <Icon
                  style={{ fontSize: "18px", marginLeft: "0px" }}
                  type="transaction"
                />
                <span
                  style={{
                    fontSize: "14px",
                    marginLeft: "3px",
                    color: "rgb(108, 123, 138)",
                  }}
                >
                  Service Dashboard
                </span>
              </NavLink>
            </Menu.Item>
          ) : (
            <span></span>
          )}
         
        
          <SubMenu
            onTitleClick={changeSubMenu}
            key="transaction_masters"
            title={
              <span>
                <Icon
                  type="transaction"
                  style={{
                    color: "rgba(0,0,0,0.65)",
                    height: "100vh",
                    fontSize: "16px",
                  }}
                />
                <Text style={{ fontSize: "15.5px" }}>Transaction Master</Text>
              </span>
            }
          >
            {checkDropdownList("customer_details") ? (
              <Menu.Item key="customer_details">
                <NavLink to="/autoadmin/customer_details">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Customer}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Customer Details
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}

              {checkDropdownList("customer_spare_order") ? (
              <Menu.Item key="customerspare">
                <NavLink to="/autoadmin/customerspare">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Quotation}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Customer Spare Order
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}      

            {checkDropdownList("whatsapp_web")  ? (
              <Menu.Item key="whatsapp">
                <NavLink to="/autoadmin/whatsapp">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Vehiclesaleinvoice}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Whatsapp Web
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}

            {checkDropdownList("quotations") ? (
              <Menu.Item key="quotations">
                <NavLink to="/autoadmin/quotations">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Quotation}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Quotations
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}
           
            {checkDropdownList("follow-ups") ? (
              <Menu.Item key="follow-ups">
                <NavLink to="/autoadmin/follow-ups">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Quotation}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Follow Ups
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}

            {checkDropdownList("booking_register") ? (
              <Menu.Item key="booking_register">
                <NavLink to="/autoadmin/booking_register">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={BookingRegister}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Booking Register
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}

            {checkDropdownList("vehicles") ? (
              <Menu.Item key="vehicles">
                <NavLink to="/autoadmin/vehicles">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Vehicle}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Vehicles
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}

            {/* {checkDropdownList("vehicle_purchase_challan") ? (
              <Menu.Item key="vehicle_purchase_challan">
                <NavLink to="/autoadmin/vehicle_purchase_challan">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={VehiclePurchaseChallan}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Vehicle Purchase Challan
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )} */}

            {checkDropdownList("vehicle_purchase_invoice") ? (
              <Menu.Item key="vehicle_purchase_invoice">
                <NavLink to="/autoadmin/vehicle_purchase_invoice">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={VehiclePurchaseInvoice}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Vehicle Purchase Invoice
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}

            {checkDropdownList("purchase_return") ? (
              <Menu.Item key="purchase_return">
                <NavLink to="/autoadmin/purchase_return">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={PurchaseReturn}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Purchase Return
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}

            {checkDropdownList("sale_challan") ? (
              <Menu.Item key="sale_challan">
                <NavLink to="/autoadmin/sale_challan">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={SaleChallan}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Sale Challan
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}
            {checkDropdownList("vehicle_sale_invoice") ? (
              <Menu.Item key="vehicle_sale_invoice">
                <NavLink to="/autoadmin/vehicle_sale_invoice">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Vehiclesaleinvoice}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Vehicle Sale Invoice
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}



            {/* {checkDropdownList("service_reminder") ? (
              <Menu.Item key="service_reminder">
                <NavLink to="/autoadmin/service_reminder">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Vehicle}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Service Reminder
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )} */}
            {checkDropdownList("service-followups") ? (
              <Menu.Item key="service-followups">
                <NavLink to="/autoadmin/service-followups">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Vehicle}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Service Reminder
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}
            {checkDropdownList("job_order") ? (
              <Menu.Item key="job_order">
                <NavLink to="/autoadmin/job_order">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Joborder}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Job Card
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}

            {checkDropdownList("estimate") ? (
              <Menu.Item key="estimate">
                <NavLink to="/autoadmin/estimate">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Customersalesspares}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Estimate
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}

            {checkDropdownList("transfer_vehicles") ? (
              <Menu.Item key="transferVehicles">
                <NavLink to="/autoadmin/transfer/vehicles">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Customersalesspares}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Transfer Vehicles
                  </span>
                </NavLink>
              </Menu.Item>) : (<span></span>)}

              {checkDropdownList("transfer_spares")?(
              <Menu.Item key="transferSpares">
                <NavLink to="/autoadmin/transfer/spares">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Customersalesspares}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Transfer Spares
                  </span>
                </NavLink>
              </Menu.Item>):(<span></span>)
              }

            {checkDropdownList("number_plate_ordering") ? (
              <Menu.Item key="numberPlating">
                <NavLink to="/autoadmin/number_plating">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Customersalesspares}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Number Plate Ordering
                  </span>
                </NavLink>
              </Menu.Item>) : (<span></span>)}

            {checkDropdownList("insurance_renewal_reminder") ? (
              <Menu.Item key="insuranceRenewal">
                <NavLink to="/autoadmin/insurance_renewal_reminder">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Joborder}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Insurance Renewal
                  </span>
                </NavLink>
              </Menu.Item>) : (<span></span>)}

              {checkDropdownList("insurance_renewal_reminder") ? (
              <Menu.Item key="insuranceRenewal">
                <NavLink to="/autoadmin/other_insurance_renewal_reminder">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Joborder}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Other Insurance Renewal
                  </span>
                </NavLink>
              </Menu.Item>) : (<span></span>)}

            {checkDropdownList("material_issue") ? (
              <Menu.Item key="material_issue">
                <NavLink to="/autoadmin/material_issue">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Materialissue}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Parts Issue
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}
            {checkDropdownList("final_inspection") ? (
              <Menu.Item key="final_inspection">
                <NavLink to="/autoadmin/final_inspection">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Materialissue}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Final Inspection
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}

            {checkDropdownList("ledger") ? (
              <Menu.Item key="ledger">
                <NavLink to="/autoadmin/ledger">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Jobinvoice}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Ledger Details
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}
            {checkDropdownList("customer_parts_order") ? (
              <Menu.Item key="customer_parts_order">
                <NavLink to="/autoadmin/customer_parts_order">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Customerpartsorder}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Customer Parts Order
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}

            {checkDropdownList("customer_sales_spares") ? (
              <Menu.Item key="customer_sales_spares">
                <NavLink to="/autoadmin/counter_sale">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Customersalesspares}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Sale Spares
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}

            {checkDropdownList("counter_sale") ? (
              <Menu.Item key="counter_sale">
                <NavLink to="/autoadmin/counter_sale">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Customersalesspares}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Counter Sales
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}

            {checkDropdownList("job_invoice") ? (
              <Menu.Item key="job_invoice">
                <NavLink to="/autoadmin/job_invoice">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Jobinvoice}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Job Invoice
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}
            
            {checkDropdownList("purchase_spares_invoice") ? (
              <Menu.Item key="purchase_spares_invoice">
                <NavLink to="/autoadmin/purchase_spares_invoice">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Customersalesspares}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Purchase Spares Invoice
                  </span>
                </NavLink>
              </Menu.Item>
            ) : (
              <span></span>
            )}


            

            {/* {
              checkDropdownList('vehicles')
                ? */}

            {/* {checkDropdownList("supplier_master") ? (
              <Menu.Item key="supplier_master">
                <NavLink to="/autoadmin/supplier_master">
                  <Icon style={{fontSize:'18px',marginLeft:'-8px'}} component={Suppliermaster} />
                  <span style={{fontSize:'14px',marginLeft:'3px',color:'rgb(108, 123, 138)'}}>Supplier Master</span>
                </NavLink>
              </Menu.Item>
            ) : (
                <span></span>
              )} */}
            {/* <Menu.Item key="promotion">
              <NavLink to="/autoadmin/promotion">
                <Icon style={{ fontSize: '18px', marginLeft: '-8px' }} component={Promotions} />
                <span style={{ fontSize: '14px', marginLeft: '3px', color: 'rgb(108, 123, 138)' }}>Promotions</span>
              </NavLink>
            </Menu.Item> */}
            {/* :
                <span></span>
            } */}
          </SubMenu>

         {!checkPromotion()? (<SubMenu
            onTitleClick={changeSubMenu}
            key="promotions"
            title={
              <span>
                <Icon
                  style={{
                    color: "rgba(0,0,0,1)",
                    height: "100vh",
                    fontSize: "19px",
                  }}
                  component={Promotions}
                />

                <Text style={{ fontSize: "15.5px" }}>Promotions</Text>
              </span>
            }
          >
            {/* <Menu.Item key="promowhatsapp">
              <NavLink to="/autoadmin/promotion/promowhatsapp">
                <Icon
                  style={{
                    fontSize: "18px",
                    marginLeft: "-8px",
                    color: "rgba(0,0,0,1)",
                  }}
                  component={MessageOutlined}
                />
                <span
                  style={{
                    fontSize: "14px",
                    marginLeft: "3px",
                    color: "rgb(108, 123, 138)",
                  }}
                >
                  Whatsapp
                </span>
              </NavLink>
            </Menu.Item> */}

            {checkDropdownList("groups")? (<Menu.Item key="groups">
              <NavLink to="/autoadmin/promotion/groups">
                <Icon
                  style={{
                    fontSize: "18px",
                    marginLeft: "-8px",
                    color: "rgba(0,0,0,1)",
                  }}
                  component={Groups}
                />
                <span
                  style={{
                    fontSize: "14px",
                    marginLeft: "3px",
                    color: "rgb(108, 123, 138)",
                  }}
                >
                  Groups
                </span>
              </NavLink>
            </Menu.Item>):(<span></span>)}
          {checkDropdownList("tasks")? (<Menu.Item key="tasks">
              <NavLink to="/autoadmin/promotion/tasks">
                <Icon
                  style={{ fontSize: "18px", marginLeft: "-8px" }}
                  component={Tasks}
                />
                <span
                  style={{
                    fontSize: "14px",
                    marginLeft: "3px",
                    color: "rgb(108, 123, 138)",
                  }}
                >
                  Tasks
                </span>
              </NavLink>
            </Menu.Item>):(<span></span>)}
          </SubMenu>):(<span></span>)}

          {!checkReports()?(<SubMenu
            onTitleClick={changeSubMenu}
            key="reports"
            title={
              <span>
                <Icon
                  style={{
                    color: "rgba(0,0,0,1)",
                    height: "100vh",
                    fontSize: "19px",
                  }}
                  component={Promotions}
                />
                <Text style={{ fontSize: "15.5px" }}>Reports</Text>
              </span>
            }
          >
           {checkDropdownList("spares_inventory")? (<Menu.Item key="spares">
              <NavLink to="/autoadmin/report/spares">
                <Icon
                  style={{
                    fontSize: "18px",
                    marginLeft: "-8px",
                    color: "rgba(0,0,0,1)",
                  }}
                  component={Groups}
                />
                <span
                  style={{
                    fontSize: "14px",
                    marginLeft: "3px",
                    color: "rgb(108, 123, 138)",
                  }}
                >
                  Spares Inventory
                </span>
              </NavLink>
            </Menu.Item>):<span></span>}

           {(checkDropdownList("stock_check"))? (<Menu.Item key="stockcheck">
              <NavLink to="/autoadmin/stockcheck">
                <Icon
                  style={{ fontSize: "18px", marginLeft: "-8px" }}
                  component={Tasks}
                />
                <span
                  style={{
                    fontSize: "14px",
                    marginLeft: "3px",
                    color: "rgb(108, 123, 138)",
                  }}
                >
                  Stock Check
                </span>
              </NavLink>
            </Menu.Item>):(<span></span>)}

           {(checkDropdownList("vehicle_inventory"))?(<Menu.Item key="vehicle">
              <NavLink to="/autoadmin/report/vehicle">
                <Icon
                  style={{ fontSize: "18px", marginLeft: "-8px" }}
                  component={Tasks}
                />
                <span
                  style={{
                    fontSize: "14px",
                    marginLeft: "3px",
                    color: "rgb(108, 123, 138)",
                  }}
                >
                  Vehicle Inventory
                </span>
              </NavLink>
            </Menu.Item>):(<span></span>)}

            {checkDropdownList("Service Report") ? (
              <Menu.Item key="vehicle">
                <NavLink to="/autoadmin/report/ReportDashboard">
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "-8px" }}
                    component={Tasks}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "3px",
                      color: "rgb(108, 123, 138)",
                    }}
                  >
                    Service Report
                  </span>
                </NavLink>
              </Menu.Item>) : null}
             

          </SubMenu>):(<span></span>)}

          {/* <SubMenu
            onTitleClick={changeSubMenu}
            key="services"
            title={
              <span>
                <Icon
                  type="customer-service"
                  style={{ color: "rgba(0,0,0,0.65)",fontSize:'16px' }}
                />
                <Text style={{fontSize:'15.5px'}}>Services</Text>
              </span>
            }
          >
            <Menu.Item key="sales">
              <NavLink to="/autoadmin/sales">
                <Icon style={{fontSize:'18px',marginLeft:'-8px'}} type="appstore" />
                <span style={{fontSize:'14px',marginLeft:'3px',color:'rgb(108, 123, 138)'}}>Sales</span>
              </NavLink>
            </Menu.Item>
          </SubMenu> */}
        </Menu>
      </Sider>
      <Layout style={{ height: "100vh" }}>
        <Header
          style={{
            padding: 0,
            width: "100%",
            zIndex: 100,
            boxShadow: "0 3px 2px 0 rgba(0, 0, 0, 0.05)",
          }}
        >
          <div className="global-header">
            <span className="global-header-trigger">
              <Icon
                type={collapsed ? "menu-unfold" : "menu-fold"}
                onClick={handleToggle}
              />
            </span>
            <GlobalHeaderRight theme="dark" layout="topmenu" />
          </div>
        </Header>
        <Content
          style={{
            // margin: "30px 16px",
            padding: 24,
            background: "#f4f7fb",
            minHeight: "max-content",
          }}
        >
          <ContentComponent />
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRouter(Navigation);
