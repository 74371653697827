import React, { useEffect, useState, useContext } from "react";
import { useHistory, withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Progress,
  Select,
  message,
  Button,
  Divider,
  Tooltip,
  Icon,
  Popconfirm,
  DatePicker
} from "antd";
const { RangePicker } = DatePicker
import Text from "antd/lib/typography/Text";
import WrappedQuotationModal from "../../components/TransactionMasters/Quotations/QuotationsForm";
import "./index.less";
import { platformApi } from "../../api";
import { ContextAPI } from "../../ContextAPI";
import Chart from "react-apexcharts";
import moment from "moment";
import WrappedAuthenticationData from "../TransactionMasters/BookingRegister/BookingRegisterModal/AuthenticationData"

const Dashboard = (props) => {
  const { history } = props
  const { loginCredintials } = useContext(ContextAPI);
  const [quotationView, setQuotationView] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [timeline, setTimeline] = useState("month");
  const [editable, setEditable] = useState(false)
  const [taskData, setTaskData] = useState([]);
  const [pendingSalesData, setPendingSalesData] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [quotationData, setQuatationData] = useState([]);
  const [quotationDetail, setQuotationDetail] = useState(null)
  const [quotationEditable, setQuotationEditable] = useState(false)
  const [authorisationData, setAuthorisationData] = useState([]);
  const [bookingData, setBookingData] = useState({});
  const [open, setOpen] = useState(false)
  const [initialData, setInitialData] = useState(false)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [authorization, setAuthorization] = useState(false)
  const [barGraphSeries, setBarGraphSeries] = useState([
    {
      name: "Enquiries",
      data: [0, 0, 0, 0, 0, 0, 0, 0],
    },
  ]);
  const [employee, setEmployee] = useState([]);
  const [count, setCount] = useState({
    bookingCount: 0,
    callEnquiryCount: 0,
    coldCount: 0,
    hotCount: 0,
    leafletCount: 0,
    newspaperCount: 0,
    quotationCount: 0,
    referralCount: 0,
    smsCount: 0,
    socialMediaCount: 0,
    soldVehicleCount: 0,
    televisionAdCount: 0,
    walkInCount: 0,
    warmCount: 0,
    todaysTaskCount: 0,
    salesTargetCount: 0
  });

  const [showDate, setShowDate] = useState([])

  const barGraphOptions = {
    chart: {
      id: "apexchart-example",
      type: "bar",
      stacked: false,
      stackType: '100%',
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: [
        "Walk-In",
        "Call Enquiry",
        "Referral",
        "Social Media",
        "SMS  ",
        "Newspaper",
        "TV-Ad",
        "Leaflet",
      ],
      labels: {
        style: {
          colors: ['#33b2df', '#f48024', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e'],
        }
      }
    },
    colors: ['#33b2df', '#f48024', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e'],
    plotOptions: {
      bar: {
        distributed: true
      }
    }
  };



  const getQuotation = (quotationId) => {
    return new Promise(async (resolve, reject) => {
      // let tmpQuotation = {}
      platformApi.get(`/api/quotation/${quotationId}`).then(res => {
        const { data: { response: { data } } } = res;
        setQuotationDetail(data)
        resolve(data)
      })
    })
  }

  const openQuotation = (edit = false, record = null) => {
    setQuotationEditable(edit);
    if (record) {
      let quotationId = record.id
      getQuotation(quotationId).then(() => setQuotationView(true))
    }
    else {
      setQuotationView(true);
    }
  }

  const getData = (timeline, employee) => {


    console.log(timeline, fromDate, toDate);

    platformApi
      .post("/api/dashboard", {
        timeline,
        from: fromDate,
        to: toDate,
        employee,
        current: loginCredintials.employeeId,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setCount(res.data.response.data);
          setTaskData(res.data.response.data.todaysTask)
          setQuatationData(res.data.response.data.quotations)
          setAuthorisationData(res.data.response.data.bookings)
          setPendingSalesData(res.data.response.data.bookings)
          const data = res.data.response.data;
          setBarGraphSeries([
            {
              name: "Enquiries",
              data: [
                data.walkInCount,
                data.callEnquiryCount,
                data.referralCount,
                data.socialMediaCount,
                data.smsCount,
                data.newspaperCount,
                data.televisionAdCount,
                data.leafletCount,
              ],
            },
          ]);
        } else if (res.data.code === 500) {
          message.error("Unable to fetch data", 2)
        }
      });
  };

  const handleDateChange = async (date, dateString) => {
    await setFromDate(dateString[0])
    await setToDate(dateString[1])
    // getData(timeline, selectedEmployee)
  }

  useEffect(() => {
    platformApi.get("/api/dashboard/users").then((res) => {
      // console.log(res.data.response.data)
      setEmployee(res.data.response.data);
      let tempUser = res.data.response.data.map((user) => user.id)
      setSelectedEmployee(tempUser)
    });
    if(selectedEmployee && selectedEmployee.length>0){
      getData(timeline);

    }
    // loginCredintials && loginCredintials.othersAccess ? () => {
    //   let tempUser = employee.map((user) => user.id)
    //   setSelectedEmployee(tempUser)
    // } : setSelectedEmployee(loginCredintials.employeeId)
  }, []);

  useEffect(() => {
    if(selectedEmployee && selectedEmployee.length>0)
     getData(timeline, selectedEmployee);
  }, [timeline, selectedEmployee]);

  useEffect(() => {
    if(selectedEmployee && selectedEmployee.length>0)
     getData(timeline, selectedEmployee);
  }, [fromDate, toDate]);

  useEffect(() => {
    // loginCredintials && setSelectedEmployee([loginCredintials.employeeId]);

    if (Object.keys(loginCredintials).length > 0) {
      let branchPic = loginCredintials.branch && loginCredintials.branch[0] && loginCredintials.branch[0].personInCharge.map((pic) => pic.id)
      if (branchPic && branchPic.length > 0 && branchPic.includes(loginCredintials.employeeId)) {
        setAuthorization(true)
      } else {
        setAuthorization(false)
      }
    }
  }, [loginCredintials]);

  useEffect(() => {
    if (initialData) {
      if(selectedEmployee && selectedEmployee.length>0){
      getData(timeline, selectedEmployee)
      setInitialData(false)
      }
    }
  }, [initialData])





  const openModal = async (id, edit) => {
    await platformApi.get(`/api/booking/${id}`)
      .then((result) => {
        const { data } = result
        if (data.code === 200) {
          const { response } = data
          if (response.code === 200) {
            setBookingData(response.data)
            setOpen(true)
            if (edit === "edit") {
              setEditable(true)
            }
            else if (edit === "view") {
              setEditable(false)
            }
          } else {
            message.error("Unable to fetch booking data", 2)
          }
        }
        else {
          message.error("Unable to fetch booking data", 2)
        }
      })
  }

  const RemoveCustomer = (id) => {
    platformApi.put(`/api/user/updateStatus/${id}`)
      .then(res => {
        let { data } = res;
        if (data.code === 200) {
          message.success('Employee Removed Successfully');
        }
        else {
          message.error('Unable to remove Employee');
        }
      })
  }

  // const addQuotationData = (quotation) => {
  //   platformApi
  //     .post("/api/quotation", quotation)
  //     .then((result) => {
  //       setQuotationView(false);
  //       const { data } = result;
  //       if (data.code === 200) {
  //         const { response } = data;
  //         if (response.code === 200) {
  //           message.success("Quotation added successfully", 2);
  //           setQuotationView(false);
  //         } else {
  //           message.error("Unable to add new quotation", 2);
  //         }
  //       } else {
  //         message.error("Unable to add quotation", 2);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error on quotation : ", error);
  //       message.error("Unable to add new quotation");
  //     });
  // };

  const addQuotationData = (quotation, callBack) => {
    const formData = new FormData()
    formData.append("finalData", JSON.stringify(quotation))
    platformApi
      .post("/api/quotation", formData, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            message.success("Quotation added successfully", 2);
            callBack(true, response.data.id);
          } else {
            message.error("Unable to add new quotation", 2);
            callBack(false);
          }
        } else {
          message.error("Unable to add quotation", 2);
          callBack(false);
        }
      })
      .catch((error) => {
        callBack(false);
        console.error("Error on quotation : ", error);
        message.error("Unable to add new quotation");
      });
  }

  const rejectBooking = (id) => {
    platformApi.put(`/api/dashboard/rejectBooking/${id}`)
      .then((res) => {
        const { data } = res
        if (data.code === 200) {
          const { response } = data
          if (response.code === 200) {
            getData(timeline, selectedEmployee)
            message.success("Booking rejected successfully", 2)
          }
          else {
            message.error("Unable to reject booking", 2)
          }
        }
        else {
          message.error("Unable to reject booking", 2)
        }
      })
  }

  const gridStyle = {
    width: "33.33%",
    textAlign: "center",
    cursor: "pointer",
  };

  const taskColumn = [
    {
      title: "Customer Details",
      dataIndex: "customer",
      key: "customer",
      render: (record, data) => <span>{record ? record.name : data.customerName} - {record ? record.contacts && record.contacts.length && record.contacts[0].phone : data.customerPhone}</span>,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Followup Type",
      dataIndex: "enquiryType",
      key: "followupType",
      render: (record, data) => <span>{record}<br></br>{data.interactionType}</span>,
    },
    {
      title: "Followup Date and Time",
      dataIndex: "scheduleDateAndTime",
      key: "scheduleDateAndTime",
      render: (record) => <span>{record ? moment(new Date(record)).format("DD/MM/YYYY HH:mm") : null}</span>,
    },
    {
      title: "Employee",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (record) => <span>{record && record.profile && record.profile.employeeName} - {record && record.profile.employeeId}</span>,
    },
    {
      title: "Status",
      dataIndex: "quotationStatus",
      key: "status",
      render: (record) => <span>{record}</span>,
    },
  ];

  const quotationColumn = [
    {
      title: "Quotation Id",
      dataIndex: "quotationId",
      key: "quotationId",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Customer name",
      dataIndex: "customer",
      key: "customer",
      render: (record, data) => {
        const name = data.customerName
          || record && record.name
        return <span>{name}</span>;
      },
    },
    {
      title: "Vehicle",
      dataIndex: "vehicle",
      key: "vehicle",
      render: (record, data) => <span>{record && record.length ? record[0].vehicleDetail.modelName : "-"}
        <span style={{ marginLeft: "10px" }}>
          <Tooltip placement="right" title={data.remarks ? data.remarks : "No remarks given"} >
            <Icon type="message" />
          </Tooltip>
        </span>
      </span>,
    },
    {
      title: "Enquiry Type",
      dataIndex: "enquiryType",
      key: "enquiryType",
      render: (record) => <span>{record}</span>
    },
    {
      title: "FollowUp Date",
      dataIndex: "scheduleDateAndTime",
      key: "scheduleDateAndTime",
      render: (record) => <span>{record ? moment(new Date(record)).format("DD/MM/YYYY HH:mm") : null}</span>,
    },
    {
      title: "Employee",
      dataIndex: "executive",
      key: "executive",
      render: (record) => <span>{record.profile.employeeName} - {record.profile.employeeId}</span>
    },
    {
      title: "Status",
      dataIndex: "quotationStatus",
      key: "quotationSTatus",
      render: (record) => <span>{record}</span>,
    },
  ];

  // const notificationColumn = [
  //   {
  //     title: "Doc Id",
  //     dataIndex: "code",
  //     key: "code",
  //     render: (record) => <span>{record}</span>,
  //   },
  //   {
  //     title: "Document Type",
  //     dataIndex: "description",
  //     key: "description",
  //     render: (record) => <span>{record}</span>,
  //   },
  //   {
  //     title: "Notification : Text Value",
  //     dataIndex: "cgst",
  //     key: "cgst",
  //     render: (record) => <span>{record}</span>,
  //   },
  //   {
  //     title: "Due Date & Time",
  //     dataIndex: "sgst",
  //     key: "sgst",
  //     render: (record) => <span>{record}</span>,
  //   },
  //   {
  //     title: "Action",
  //     render: (record) => (
  //       <span
  //         onClick={(event) => {
  //           event.stopPropagation();
  //         }}
  //         className="linkylink"
  //       >
  //         View
  //       </span>
  //     ),
  //   },
  // ];

  const authorisationColumn = [
    {
      title: "Doc Id",
      dataIndex: "bookingId",
      key: "bookingId",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Customer Details",
      dataIndex: "customer",
      key: "customer",
      render: (record, data) => <span>{record ? record.name : data.customerName} - {record ? record.contacts[0].phone : data.customerPhone}</span>,
    },
    {
      title: "Vehicle",
      dataIndex: "vehicle",
      key: "vehicle",
      render: (record, data) => <span>{record.modelCode} - {record.modelName} ({data.color && data.color.color})
        <span style={{ marginLeft: "10px" }}>
          <Tooltip placement="right" title={data.remarks ? data.remarks : "No remarks given"} >
            <Icon type="message" />
          </Tooltip>
        </span>
      </span>
      ,
    },
    {
      title: "Status",
      dataIndex: "bookingStatus",
      key: "bookingStatus",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Date and Amount",
      dataIndex: "price",
      key: "price",
      render: (record, data) => <span>Booking Date: {data.createdAt ? moment(new Date(data.createdAt)).format("DD/MM/YYYY") : null}
        <br></br>
        Net Recievables: {record.finalAmount}
      </span>,
    },
    {
      title: "Employee",
      dataIndex: "executive",
      key: "executive",
      render: (record) => <span>{record.profile.employeeName} - {record.profile.employeeId}</span>,
    },
    {
      title: "Actions",
      render: (data) => (
        <div>
          {authorization ? (<div>
            {/* <span
              onClick={(event) => {
                event.stopPropagation();
                openModal(data.id, "view")
              }}
              className="linkylink"
            >
              Verify
          </span>
            <Divider type="vertical" /> */}
            <span
              onClick={(event) => {
                event.stopPropagation();
                openModal(data.id, "edit")
              }}
              className="linkylink"
            >
              Authorize
            </span>
            <Divider type="vertical" />
            <Popconfirm
              title="Do you want to reject this Booking?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                rejectBooking(data.id);
              }}
            >
              <span
                onClick={(event) => event.stopPropagation()}
                className="linkylink"
              >
                Reject
              </span>
            </Popconfirm>
          </div>)
            :
            <Text strong disabled>
              No Actions Given
            </Text>
          }

        </div>
      ),
    },
  ];
  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  // --------------------------------------------

  useEffect(() => {
    const { fromDate, toDate } = DateShow(timeline);

    if (timeline === "date") {
      setShowDate([]);
      setFromDate()
      setToDate()
    } else {
      let arr = [];
      arr.push(fromDate);
      arr.push(toDate)
      setShowDate(arr)
    }

  }, [timeline])

  const handleTimeline = (e) => {
    if (e !== "date") {
      const { fromDate, toDate } = DateShow(e)
      let arr = [];
      arr.push(fromDate);
      arr.push(toDate)
      setShowDate(arr)
    }
    else if (e === "date") {
      setShowDate([]);
      setFromDate(null)
      setToDate(null)
    }
    setTimeline(e)
  }


  const DateShow = (timeline, from, to) => {
    let toDate;
    let fromDate;
    let months;

    switch (timeline) {
      case "today":
        fromDate = moment(new Date()).startOf("day");
        toDate = moment(new Date()).endOf("day");
        months = [moment(new Date()).format("MMM")]
        break;
      case "week":
        fromDate = moment(new Date()).startOf("week");
        toDate = moment(new Date()).endOf("week");
        months = [moment(new Date()).format("MMM")]
        break;
      case "month":
        fromDate = moment(new Date()).startOf("month");
        toDate = moment(new Date()).endOf("month");
        months = [moment(new Date()).format("MMM")]
        break;
      case "quarterly":
        fromDate = moment(new Date()).startOf("quarter");
        toDate = moment(new Date()).endOf("quarter");
        months = [fromDate.format("MMM"), moment(fromDate).add(1, "months").format("MMM"), moment(toDate).format("MMM")]
        break;
      case "halfyearly":
        let tempDate;
        let tempEnd;
        if (
          moment(new Date())
            .startOf("month")
            .format("MM") > 3 &&
          moment(new Date())
            .startOf("month")
            .format("MM") < 7
        ) {
          tempDate = moment(new Date())
            .subtract(3, "M")
            .startOf("quarter");
        } else if (
          moment(new Date())
            .startOf("month")
            .format("MM") > 9 &&
          moment(new Date())
            .startOf("month")
            .format("MM") <= 12
        ) {
          tempDate = moment(new Date())
            .subtract(3, "M")
            .startOf("quarter");
        } else {
          tempDate = moment(new Date()).startOf("quarter");
        }
        if (
          moment(new Date())
            .startOf("month")
            .format("MM") >= 1 &&
          moment(new Date())
            .startOf("month")
            .format("MM") < 4
        ) {
          tempEnd = moment(new Date())
            .add(3, "M")
            .endOf("quarter");
        } else if (
          moment(new Date())
            .startOf("month")
            .format("MM") > 6 &&
          moment(new Date())
            .startOf("month")
            .format("MM") <= 9
        ) {
          tempEnd = moment(new Date())
            .add(3, "M")
            .endOf("quarter");
        } else {
          tempEnd = moment(new Date()).endOf("quarter");
        }
        fromDate = tempDate;
        toDate = tempEnd;
        months = [
          fromDate.format("MMM"),
          moment(fromDate).add(1, "months").format("MMM"),
          moment(fromDate).add(2, "months").format("MMM"),
          moment(fromDate).add(3, "months").format("MMM"),
          moment(fromDate).add(4, "months").format("MMM"),
          moment(toDate).format("MMM")
        ]
        break;
      case "year":
        fromDate = moment(new Date()).startOf("year");
        toDate = moment(new Date()).endOf("year");
        months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        break;
      case "date":
        fromDate = moment(from, "DD-MM-YYYY").startOf("day");
        toDate = moment(to, "DD-MM-YYYY").endOf("day");
        let difference = toDate.diff(fromDate, 'months')
        if (difference == 0) {
          months = [moment(fromDate).format("MMM")]
        }
        else if (difference == 1) {
          months = [moment(fromDate).format("MMM"), moment(toDate).format("MMM")]
        }
        else {
          months = [moment(fromDate).format("MMM")]
          for (let i = 0; i < difference; i = i + 1) {
            months.push(moment(fromDate).add(i, "months").format("MMM"))
          }
          months.push(moment(toDate).format("MMM"))
        }
        break;
      default:
        fromDate = moment(new Date()).startOf("day");
        toDate = moment(new Date()).endOf("day");
        months = [moment(new Date()).format("MMM")]
    }

    return { fromDate, toDate, months }

  }



  return (
    <div style={{ background: "#f4f7fb" }}>
      <Row gutter={16} style={{ marginBottom: "10px" }}>
        <Col span={7}>
          <Text style={{ fontWeight: "bold" }}>
            Team Statistics
          </Text>
        </Col>
        {/* <Col span={8}>
          
        </Col> */}
        {/* <Col span={5}>
          <Select
            value={timeline}
            onChange={(e) => {
              setTimeline(e);
            }}
            placeholder="Timeline"
          >
            <Select.Option value="today">Today</Select.Option>
            <Select.Option value="week">This Week</Select.Option>
            <Select.Option value="month">This Month</Select.Option>
            <Select.Option value="quarterly">This Quarter</Select.Option>
            <Select.Option value="halfyearly">Half-Yearly</Select.Option>
            <Select.Option value="year">This Year</Select.Option>
          </Select>
        </Col> */}
        {/* <Col span={3}>
         
        </Col> */}
      </Row>
      <Row type="flex" justify="space-between" style={{ border: "1px solid black" }}>
        <Col span={6}>
          <Chart
            options={barGraphOptions}
            series={barGraphSeries}
            type="bar"
            width={250}
            height={320}
          />
        </Col>
        <Col span={13} style={{ marginTop: "25px" }}>
          <Row gutter={20}>
            {/* <Col span={8}>
              <Row
                style={{
                  background: "white",
                  padding: "10px 20px",
                  textAlign: "center",
                  boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                  // height: "20vh",
                }}
              >
                <Text
                  style={{
                    padding: "5px 4px ",
                    color: "#a8afb3",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Target Achievement
                </Text>
                <br />
                <Progress
                  strokeColor="#6adec2"
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                  }}
                  width={70}
                  type="circle"
                  percent={count.soldVehicleCount && count.salesTargetCount ? ((count.soldVehicleCount / count.salesTargetCount) * 100).toFixed(2) : 0}
                  status="active"
                />
              </Row>
              <Row
                style={{
                  background: "white",
                  padding: "15px 20px",
                  marginTop: "14px",
                  textAlign: "center",
                  boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                  // height: "18vh",
                }}
              >
                <Text
                  style={{
                    padding: "5px 4px ",
                    color: "#a8afb3",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Conversion Ratio
                </Text>
                <br />
                <Progress
                  strokeColor="#f69161"
                  style={{
                    marginTop: "10px",
                    // width: "100%",
                    textAlign: "center",
                  }}
                  width={70}
                  type="circle"
                  percent={
                    count.bookingCount && count.quotationCount ? ((count.bookingCount / count.quotationCount) * 100).toFixed(2) : 0
                  }
                  status="active"
                />
              </Row>
            </Col> */}
            <Col span={24}>
              <Row gutter={20}>
                <Col
                  span={7}
                  style={{
                    background: "white",
                    padding: "15px 15px 0px 15px",
                    textAlign: "center",
                    boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                  }}
                >
                  <Text
                    style={{
                      padding: "5px 4px ",
                      color: "#a8afb3",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Quotations
                  </Text>
                  <p
                    style={{
                      fontSize: "26px",
                      paddingTop: "10px",
                      color: "#6c7b8a",
                    }}
                  >
                    {count.quotationCount}
                  </p>
                </Col>
                <Col
                  span={7}
                  style={{
                    background: "white",
                    marginLeft: "15px",
                    padding: "15px 15px 0px 15px",
                    textAlign: "center",
                    boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                  }}
                >
                  <Text
                    style={{
                      padding: "5px 4px ",
                      color: "#a8afb3",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Bookings
                  </Text>
                  <p
                    style={{
                      fontSize: "26px",
                      paddingTop: "10px",
                      color: "#6c7b8a",
                    }}
                  >
                    {count.bookingCount}
                  </p>
                </Col>
                <Col
                  span={7}
                  style={{
                    background: "white",
                    marginLeft: "15px",
                    marginRight: "5px",
                    padding: "15px 15px 0px 15px",
                    textAlign: "center",
                    boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                  }}
                >
                  <Text
                    style={{
                      // padding: "5px 4px ",
                      color: "#a8afb3",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    Vehicles Sold
                  </Text>
                  <p
                    style={{
                      fontSize: "26px",
                      paddingTop: "10px",
                      color: "#6c7b8a",
                    }}
                  >
                    {count.soldVehicleCount}
                  </p>
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "white",
                  textAlign: "center",
                  marginTop: "30px",
                  marginLeft: "-8px",
                  marginRight: "4%",
                  paddingTop: "10px",
                }}
              >
                <Text style={{
                  color: "#a8afb3",
                  textAlign: "center",
                  width: "100%",
                }}
                >
                  Enquiry Type
                </Text>{" "}
              </Row>
              <Row
                style={{
                  marginTop: "0px",
                  marginLeft: "-8.5px",
                  marginRight: "4%",
                }}
              >
                <Col
                  span={8}
                  style={{
                    background: "white",
                    padding: "5px 15px 0px 15px",
                    textAlign: "center",
                    boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                  }}
                >
                  <Text
                    style={{
                      padding: "5px 4px ",
                      color: "#a8afb3",
                      textAlign: "center",
                      width: "100%",
                      fontSize: "12px",
                    }}
                  >
                    Hot
                  </Text>
                  <p
                    style={{
                      fontSize: "26px",
                      paddingTop: "4px",
                      color: "#6c7b8a",
                    }}
                  >
                    {count.hotCount}
                  </p>
                </Col>
                <Col
                  span={8}
                  style={{
                    background: "white",
                    padding: "5px 15px 0px 15px",
                    textAlign: "center",
                    boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                  }}
                >
                  <Text
                    style={{
                      padding: "5px 4px ",
                      color: "#a8afb3",
                      textAlign: "center",
                      width: "100%",
                      fontSize: "12px",
                    }}
                  >
                    Warm
                  </Text>
                  <p
                    style={{
                      fontSize: "26px",
                      paddingTop: "4px",
                      color: "#6c7b8a",
                    }}
                  >
                    {count.warmCount}
                  </p>
                </Col>
                <Col
                  span={8}
                  style={{
                    background: "white",
                    padding: "5px 15px 0px 15px",
                    textAlign: "center",
                    boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                  }}
                >
                  <Text
                    style={{
                      padding: "5px 4px ",
                      color: "#a8afb3",
                      textAlign: "center",
                      width: "100%",
                      fontSize: "12px",
                    }}
                  >
                    Cold
                  </Text>
                  <p
                    style={{
                      fontSize: "26px",
                      paddingTop: "4px",
                      color: "#6c7b8a",
                    }}
                  >
                    {count.coldCount}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={5} style={{ marginTop: "25px" }}>
          <Row style={{ padding: "10px" }}>
            <Select
              placeholder="Employee"
              mode="multiple"
              showSearch
              value={selectedEmployee}
              filterOption={filterMethod}
              // onDeselect={(event) => {
              //   console.log("unselect", event)
              //   RemoveCustomer(event)
              // }}
              onChange={(e) => {
                setSelectedEmployee(e);
              }}
            >
              {employee[0] &&
                employee.map((data) => (
                  <Select.Option key={data.id}>
                    {data && data.profile && data.profile.employeeName}
                  </Select.Option>
                ))}
            </Select>
          </Row>
          <Row style={{ padding: "10px" }}>
            <Select
              value={timeline}
              onChange={(e) => {
                setTimeline(e);
                handleTimeline(e)
              }}
              placeholder="Timeline"
            >
              <Select.Option value="today">Today</Select.Option>
              <Select.Option value="week">This Week</Select.Option>
              <Select.Option value="month">This Month</Select.Option>
              <Select.Option value="quarterly">This Quarter</Select.Option>
              <Select.Option value="halfyearly">Half-Yearly</Select.Option>
              <Select.Option value="year">This Year</Select.Option>
              <Select.Option value="date">Select a Date Range</Select.Option>
            </Select>
          </Row>

          {(timeline === "date") && (
            <Row style={{ padding: "10px" }}>
              <RangePicker onChange={handleDateChange} format={"DD/MM/YYYY"} />
            </Row>
          )}

          {(timeline !== "date") && (
            <Row style={{ padding: "0px", marginTop: "0rem", position: 'relative', width:200,left:"0.5rem" }}>
              <RangePicker disabled={true} value={showDate} format={"DD/MM/YYYY"} />
            </Row>
          )}
          <Row style={{ padding: "10px" }}>
            <Button
              style={{ padding: "5px 30px" }}
              type="primary"
              onClick={() => {
                openQuotation(true)
              }}
            >
              Create Quotation
            </Button>
          </Row>
        </Col>
      </Row>


      <Row style={{ marginTop: "25px" }}>
        <Col span={24}>
          <Text style={{ padding: "10px 0px 20px 10px", fontWeight: "bold" }}>Todays FollowUps [{count.todaysTaskCount}]</Text>
          <Table
            // size="middle"
            onRow={(record, index) => {
              return {
                onClick: () => {
                  window.open(`/autoadmin/follow-ups/${record.customer.contacts[0].phone}/fupdate/${record.scheduleDateAndTime}`)
                }
              }
            }
            }
            columns={taskColumn}
            dataSource={taskData}
            className="todaysTaskTable"
            pagination={{
              pageSize: 6,
              defaultCurrent: 1,
              showQuickJumper: true,
            }}
            // onRow={(props) => ({
            //   onClick: () => {
            //     history.push("/autoadmin/follow-ups/7904387249/fupdate/2020-10-19T01:45:00.000Z")
            //   }
            // })}
            // title={() => 'Todays Tasks'}
            style={{ margin: "20px 20px 20px 10px" }}
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Text style={{ padding: "10px 0px 20px 10px", fontWeight: "bold" }}>Quotations [{quotationData.length}]</Text>
          <Table
            // size="middle"
            columns={quotationColumn}
            dataSource={quotationData}
            onRow={(record, index) => {
              return {
                onClick: () => {
                  openQuotation(false, record)
                }
              }
            }
            }
            className="todaysTaskTable"
            pagination={{
              pageSize: 8,
              defaultCurrent: 1,
              showQuickJumper: true,
            }}
            // title={() => 'Todays Tasks'}
            style={{ cursor: "pointer", margin: "20px 20px 20px 10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Text style={{ padding: "10px 0px 20px 10px", fontWeight: "bold" }}>Authorisations [{authorisationData.length}]</Text>
          <Table
            // size="small"
            columns={authorisationColumn}
            dataSource={authorisationData}
            className="todaysTaskTable"
            pagination={{
              pageSize: 6,
              defaultCurrent: 1,
              showQuickJumper: true,
            }}
            onRow={(props) => ({
              onClick: () => {
                openModal(props.id, false);
              },
            })}
            // title={() => 'Todays Tasks'}
            style={{ cursor: "pointer", margin: "20px 0px 0px 0px" }}
          />
        </Col>
      </Row>
      {/* <Row>
        <Col span={12}>
          <Text style={{ padding: "10px 0px 20px 10px" }}>Notification</Text>
          <Table
            // size="middle"
            columns={notificationColumn}
            dataSource={notificationData}
            className="todaysTaskTable"
            pagination={{
              pageSize: 6,
              defaultCurrent: 1,
              showQuickJumper: true,
            }}
            // title={() => 'Todays Tasks'}
            style={{ cursor: "pointer", margin: "20px 20px 20px 10px" }}
          />
        </Col>
        <Col span={11}>
          <Text style={{ padding: "10px 0px 20px 10px" }}>Pending Sales</Text>
          <Table
            // size="middle"
            columns={pendingSalesColumn}
            dataSource={pendingSalesData}
            className="todaysTaskTable"
            pagination={{
              pageSize: 4,
              defaultCurrent: 1,
              showQuickJumper: true,
            }}
            // title={() => 'Todays Tasks'}
            style={{ cursor: "pointer", margin: "20px 0px 0px 0px" }}
          />
        </Col>
        <Col span={1} />
      </Row> */}
      <WrappedQuotationModal
        open={quotationView}
        leadData={null}
        close={() => {
          setQuotationView(false)
          setQuotationDetail(null)
        }}
        editable={quotationEditable}
        data={quotationDetail}
        emitData={addQuotationData}
        addData={async (quotation, callBack) => {
          let tmpQuotation = await getQuotation(quotation.id)
          // getQuotation(quotation.id)
          tmpQuotation.pdfWithBrochure = quotation.pdfWithBrochure;
          tmpQuotation.pdfWithOutBrochure =
            quotation.pdfWithOutBrochure;
          callBack(tmpQuotation);
        }}
      />
      <WrappedAuthenticationData
        open={open}
        close={() => setOpen(false)}
        id={bookingData.id}
        data={bookingData}
        editable={editable}
        reload={() => setInitialData(true)}
      />
    </div>
  );
};

export default withRouter(Dashboard);
