import React, { useState, useEffect, useContext } from 'react'
import { Col, Row, Card, Table, Button, message, Select, Empty, Typography, Icon } from 'antd'
import {
    EyeFilled,
    EditFilled,
    WhatsAppOutlined
} from '@ant-design/icons';
import { ContextAPI } from "../../../ContextAPI"
import { platformApi } from '../../../api'
import './style.less'
import moment from 'moment'
import WrappedCallingInterface from "../../FollowUp/QuotationInfo/CallingInterface"
import WrappedMessageInterface from "../../FollowUp/QuotationInfo/MessageInterface"
import WrappedWhatsAppInterface from "../../FollowUp/QuotationInfo/WhatsAppInterface"
import WrappedVehicleForm from "../../TransactionMasters/Vehicles/VehicleForm"
const VehicleInfo = (props) => {
    const { dataSource, getCustomerInfo, getActivityByCustomer, customerDetail, customerId, actionStatus } = props
    const { Text, Title } = Typography
    const [vehicleModal, setVehicleModal] = useState(false)
    const [vehicleDetail, setVehicleDetail] = useState(null)
    const [vehicleEditable, setVehicleEditable] = useState(false)
    const [callModal, setCallModal] = useState(false)
    const [messageModal, setMessageModal] = useState(false)
    const [whatsAppMessageModal, setWhatsAppMessageModal] = useState(false)
    const { loginCredintials } = useContext(ContextAPI);
    const [uploading, setUploading] = useState(false)
    const [modifyAccess, setModifyAccess] = useState(false);
    const column = [
        {
            title: <Text strong>Model Name</Text>,
            dataIndex: "vehicle",
            key: "name",
            render: (record) => (
                <span>{record && `${record.modelName}`}</span>
            )
        },
        {
            title: <Text strong>Registration No</Text>,
            dataIndex: "registerNo",
            key: "regno",
            render: (record) => (record ? <span>{record}</span> : <span>-</span>)
        },
        {
            title: <Text strong>Created On</Text>,
            dataIndex: "createdAt",
            key: "createdAt",
            render: (record) => <span>{record?moment(new Date(record)).format('DD/MM/YYYY') : '-'}</span>,
        },
        ...(actionStatus ? [{
            title: <Text strong>Action</Text>,
            dataIndex: "id",
            align: 'center',
            key: "id",
            render: (record) => <EyeFilled style={{ textAlign: 'center' }} onClick={() => viewVehicle(record)} />,
        }] : [])
    ]
    console.log(actionStatus);
    const viewVehicle = (vehicleId) => {
        getVehicle(vehicleId)
        setVehicleModal(true);
    }
    const getVehicle = (vehicleId) => {
        return new Promise(async (resolve, reject) => {
            await platformApi.get(`/api/vehicle/${vehicleId}`).then(res => {
                const { data: { response: { data } } } = res;
                setVehicleDetail(data)
                resolve(data)
            })
        })
    }
    const addData = (formData, vehicle) => {
        if (vehicle.id) {
            platformApi
                .put('/api/vehicle/' + vehicle.id, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((result) => {
                    setSpinning(false);
                    let { data } = result;
                    if (data.code === 200) {
                        let { response } = data;
                        if (response.code === 200) {
                            message.success('Vehicle saved successfully', 2);
                        } else {
                            message.error('Unable to save vehicle', 2);
                        }
                    } else {
                        message.error('Unable to save vehicle', 2);
                    }
                })
                .catch((error) => {
                    console.error('Error on Vehicles : ', error);
                    message.error('Unable to save vehicle', 2);
                });
        }
        else {
            platformApi
                .post('/api/vehicle', formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((result) => {
                    let { data } = result;
                    if (data.code === 200) {
                        let { response } = data;
                        if (response.code === 200) {
                            message.success('Vehicle added successfully', 2);
                        } else {
                            message.error('Unable to add vehicle', 2);
                        }
                    } else {
                        message.error('Unable to add vehicle', 2);
                    }
                })
                .catch((error) => {
                    console.error('Error on Vehicles : ', error);
                    message.error('Unable to add vehicle', 2);
                });
        }
    };

    return (
        <div className="quotation-info">
            <Card style={{ height: '220px' }}>
                <Row type="flex" justify="space-between" gutter={[16, 16]}>
                    <Col span={18}>
                        <Row type="flex" justify="space-between" gutter={[16, 16]}>
                            <Col span={24}>
                                <Table
                                    locale={{
                                        emptyText: (
                                            <Empty
                                                imageStyle={{
                                                    height: 25,
                                                    fontSize: 30,
                                                }}
                                                description={(
                                                    <Text disabled strong>
                                                        No Vehicles Associated
                                                    </Text>
                                                )}
                                            />
                                        ),
                                    }}
                                    rowKey={(record) => record.id}
                                    pagination={false}
                                    size="small"
                                    columns={column}
                                    dataSource={dataSource}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row type="flex" gutter={[16, 16]} justify='space-between'>
                            <Col>
                                <Button style={{ width: '100px' }} onClick={() => setCallModal(true)}>
                                    <Icon type="phone" />
                                </Button>
                            </Col>
                            {/* <Col>
                                <Button style={{ width: '100px' }} onClick={() => setMessageModal(true)}>
                                    <Icon type="message" />
                                </Button>
                            </Col> */}
                            <Col>
                                <Button style={{ width: '100px' }} onClick={() => setWhatsAppMessageModal(true)}>
                                    <WhatsAppOutlined />
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </Card>
            <WrappedMessageInterface
                visibility={messageModal}
                customerDetail={customerDetail}
                type={"serviceFollowup"}
                getCustomerInfo={getCustomerInfo}
                getActivityByCustomer={getActivityByCustomer}
                setVisibility={setMessageModal}
            />
            <WrappedWhatsAppInterface
                visibility={whatsAppMessageModal}
                customerDetail={customerDetail}
                type={"serviceFollowup"}
                getCustomerInfo={getCustomerInfo}
                getActivityByCustomer={getActivityByCustomer}
                setVisibility={setWhatsAppMessageModal}
            />
            <WrappedCallingInterface
                visibility={callModal}
                type={"serviceFollowup"}
                customerDetail={customerDetail}
                getActivityByCustomer={getActivityByCustomer}
                setVisibility={setCallModal}
            />
            <WrappedVehicleForm
                data={vehicleDetail}
                emitData={addData}
                open={vehicleModal}
                editable={vehicleEditable}
                setUploading={setUploading}
                uploading={uploading}
                close={() => {
                    setVehicleModal(false);
                    setUploading(false);
                }}
                modify={modifyAccess}
                edit={modifyAccess}
                setEdit={setModifyAccess}
                vData={false}
            />
        </div>
    )
}
export default VehicleInfo;