import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Button, Input, Radio, DatePicker, message , Checkbox,Slider,InputNumber } from "antd";
import { platformApi } from "../../../../api";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Item } = Form;
// import "./index.less";
const { Option } = Select;
function AdvancedFilters({
  form,
  filteropen,
  setId,
  searchName,
  setPageData,
  setSearch,
  setFilterData,
  setPage,
  data
}) {
  const {
    getFieldDecorator,
    getFieldValue,
    resetFields,
    setFieldsValue
  } = form;
  const [models, setModels] = useState([]);
  const [dateFilter, setDateFilter] = useState(false);
  const [type, setType] = useState(null);
  const [fromDate, setFromDate] = useState(null)
  const [users, setUser] = useState(null);
  const [mechanic,setMechanic] = useState(null)
  const [selectedModels, setSelectedModels] = useState([]); 
  const [serviceTypeCheck, setServiceTypeCheck] = useState(false);
  const [modelCheck, setModelCheck] = useState(false);

  const [mechanicCheck, setMechanicCheck] = useState(false);

  const serviceType = [
    { key: 1, title: "Free" },
    { key: 2, title: "Extended Warranty" },
    { key: 3, title: "Paid (UW)" },
    { key: 4, title: "Paid (AW)" },
    { key: 5, title: "AMC" },
    { key: 6, title: "Accidental" },
    { key: 7, title: "Minor" },
];

const serviceTypeCheckAll = (e) => {
  const selectedValues = e.target.checked ? serviceType.map((obj) => obj.title) : [];
  setServiceTypeCheck(e.target.checked);
  setFieldsValue({ serviceType: selectedValues });
};

const mechanicCheckAll = (e) => {
  const selectedValues = e.target.checked ? mechanic.map((obj) => obj.id) : [];
  setMechanicCheck(e.target.checked);
  setFieldsValue({ mechanic: selectedValues });
};

   useEffect(() => {
      fetchEmployeeBasedOnBanch()
      getMechanic()
      
      platformApi.get(`/api/vehicleMaster`).then((res) => {
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setModels(response.data);
          }
        }
      });
    }, [data]);


        const getMechanic = () => {
          platformApi
            .get("/api/user")
            .then((result) => {
              let { data } = result;
              if (data.code === 200) {
                let mechanic = data.data.users.filter((item) => {
                  return (
                    item.profile &&
                    item.profile.department.role === "Mechanic" &&
                    item.status === true
                  );
                });
                setMechanic(mechanic);
                // setMechanic(data.data.users);
              }
            })
            .catch((error) => {
              console.error("Error on MaterialIssue form : ", error);
              message.error("Unable to fetch joborder", 2);
            });
        }; 

      const fetchEmployeeBasedOnBanch = () => {
        // console.log(id);
        platformApi
          .post("/api/user/get", {
            size: 1000,
            page: 1,
          })
          .then((result) => {
            let { data } = result;
            if (data.code === 200) {
              let { response } = data;
              if (response.code === 200) {
                let employees = [];
                response.data.users.forEach((element) => {
                  let sale = false;
                  element.profile.department.departmentType.forEach((data) => {
                    if (data === "Sales") {
                      sale = true;
                      return;
                    }
                  });
                  //sale check
                  if (sale) {
                    {
                      if (element.status === true) {
                        employees.push(element);
                      }
                    }
                  }
                });
                setUser(employees);
              }
            }
          })
          .catch((error) => {
            message.error("Unable to fetch Users", 1);
            console.error("Error on Quotations Form : ", error);
          });
      };
      // console.log(mechanic,"mechanic")
      const [range, setRange] = useState([]); // Default range
      const maxLimit = 200000; // Upper limit for manual input
    
      const handleChangeKms = (value, index) => {
        // console.log(value,index,"RANGEEEE")
        const newRange = [...range];
        newRange[index] = value;
        setRange(newRange);
      };
    

      // console.log(range,"RANGE")
      const options = models.map(model => ({
        value: model.id,
        label: `${model.modelName}-${model.modelCode}`,
      }));
    
      const allSelected = selectedModels.length === options.length;

      const handleSelectAll = () => {
        if (allSelected) {
          setSelectedModels([]); // Deselect all
          setFieldsValue({ model: [] });
        } else {
          const allValues = options.map(option => option.value);
          setSelectedModels(allValues); // Select all
          setFieldsValue({ model: allValues });
        }
      };
    
      const handleChange = (values) => {
        setSelectedModels(values);
        setFieldsValue({ model: values });
      };

      const modelCheckAll = (e) => {
        const selectedValues = e.target.checked ? models.map((obj) => obj.id) : [];
        setModelCheck(e.target.checked)
        // setModelCheck(e.target.checked);
        setFieldsValue({ model: selectedValues });
      };
      // const handleSelectAll = (field, options) => {
      //   const allValues = options.map(option => option.value);
      //   setFieldsValue({ [field]: allValues });
      // };
    
      // const handleDeselectAll = (field) => {
      //   setFieldsValue({ [field]: [] });
      // };
      // const [open, setOpen] = useState(false);

      // const renderSelectWithSelectAll = (field, options, placeholder) => {
      //   const allSelected = getFieldValue(field)?.length === options.length;
      
      //   return (
      //     <Select
      //       mode="multiple"
      //       placeholder={placeholder}
      //       open={open}
      //       onDropdownVisibleChange={setOpen}
      //       onSelect={(value) => {
      //         if (value === "selectAll") {
      //           if (!allSelected) {
      //             handleSelectAll(field, options);
      //           } else {
      //             handleDeselectAll(field);
      //           }
      //           setOpen(false); // Close dropdown after selection
      //         }
      //       }}
      //       onDeselect={(value) => {
      //         if (value === "selectAll") {
      //           handleDeselectAll(field);
      //         } else {
      //           const newValues = getFieldValue(field).filter(v => v !== value);
      //           setFieldsValue({ [field]: newValues });
      //         }
      //         setOpen(false); // Close dropdown after deselection
      //       }}
      //     >
      //       <Option key="selectAll" value="selectAll">
      //         <Checkbox checked={allSelected}>Select All</Checkbox>
      //       </Option>
      //       {options.map(option => (
      //         <Option key={option.value} value={option.value}>
      //           {option.label}
      //         </Option>
      //       ))}
      //     </Select>
      //   );
      // };
      
  //price range


  const searchValue = () => {
    setId([]);
    setPageData();
    resetFields();
    setSearch('');
    setFilterData({})
  };

  // const handleSubmit = () => {
  //   if (!dateFilter || getFieldValue("from") && getFieldValue("to")) {
  //     const data = {};
  //     if (dateFilter) { 
  //       data.fromDate = moment(getFieldValue("from").startOf('day').toISOString());
  //       data.toDate = moment(getFieldValue("to").endOf('day').toISOString());
  //       data.status = null
  //     }
  //     else {
  //       data.status = type;
  //       if (getFieldValue("from") && getFieldValue("to")) {
  //         data.fromDate = moment(getFieldValue("from").startOf('day').toISOString());
  //         data.toDate = moment(getFieldValue("to").endOf('day').toISOString());
  //       }
  //     }
  //     console.log("Data", data)
  //     setFilterData(data)
  //     // // setSearch('');
  //     // setPageData(searchName, "PENDING", null);
  //   }
  //   else {
  //     message.error('Enter all fields')
  //   }
  // }

  // const radioOnChange = (e) => {
  //   setPage(1)
  //   form.setFieldsValue({
  //     from: undefined,
  //     to: undefined
  //   })
  //   setType(e.target.value);
  //   e.target.value === "date" ? setDateFilter(true) : setDateFilter(false)
  // }

  const handleSubmit = () => {
    const data = {};
    const fields = [
      "model",
      "serviceType",
      "mechanic",
      "registerNo",
      "kms",
      "jobCardCreationDate",
      
    ];

    fields.forEach((field) => {
      const value = getFieldValue(field);

      if (
        (!Array.isArray(value) && value) ||
        (Array.isArray(value) && value.length > 0)
      ) {
        // console.log(value, "VLA");
        if (field.includes("Date") || field.includes("kms")) {
          // console.log(field)
          if (Array.isArray(value)) {
            if (field === "jobCardCreationDate") {
              data[`from`] = moment(value[0]).toISOString();
              data[`to`] = moment(value[1]).toISOString();
            } else {
              // console.log(field,"else")
              if(value[0]==0 && value[1]==0){
                data[`serviceKmFrom`] = null;
                data[`serviceKmTo`] = null;
              }
              data[`serviceKmFrom`] = value[0];
              data[`serviceKmTo`] = value[1];
            }
          }
        } else {
          data[field] = value;
        }
      } else {
        if (field !== "jobCardCreationDate") data[field] = null;
      }
    });
    // console.log(data,"check")
    setFilterData(data);
  };

  useEffect(()=>{
    setModelCheck(false)
    setMechanicCheck(false)
    setServiceTypeCheck(false)
    setRange([])
  },[filteropen])

  return (
    <div>
      {filteropen && (
        <div className="AdvancedFilters" >
           <Form>
                     <Row>
                       <h3>Advanced Filters</h3>
                     </Row>
         
                     <Row gutter={25}>
     
                       <Col span={8}>
                         {models && ( 
                           <Item label="Vehicle Model">
                             <Row gutter={11}>
                               <Col span={15}>
                                 {getFieldDecorator("model")(
                                  <Select
                                  mode="multiple"
                                  placeholder="Select Vehicle Model"
                                  >
                                   { models.map((model) => (
                                    <Select.Option key={model.id} value={model.id}>
                                      {`${model.modelCode} - ${model.modelName}`}
                                    </Select.Option>
                                    ))}
                                  </Select>
                                  
                                 )}
                               </Col>
                               <Col span={9}>
                                 <Checkbox onChange={modelCheckAll} value={modelCheck}>
                                   Select All
                                 </Checkbox>
                               </Col>
                             </Row>
                           </Item>
                         )}
                       </Col>

                       <Col span={8}>
                       {serviceType && (
                        <Item label="Service Type">
                        <Row gutter={11}>
                          <Col span={15}>
                            {getFieldDecorator("serviceType")(
                             <Select
                             mode="multiple"
                            placeholder="Select Service Type"
                             >
                              {
                                serviceType.map((service) => (
                                  
                                  <Select.Option key={service.key} value={service.title}>
                                      {`${service.title}`}
                                  </Select.Option>

                                  // value: service.key,
                                  // label: service.title,
                                ))
                              }  
                            </Select>
                            )}
                          </Col>
                          <Col span={9}>
                            <Checkbox checked={serviceTypeCheck} onChange={serviceTypeCheckAll}>
                              Select All
                            </Checkbox>
                          </Col>
                        </Row>
                      </Item>
                       )}

                       </Col>

                       <Col span={8}>
                       {mechanic && (
                        <Item label="Mechanic">
                        <Row gutter={11}>
                          <Col span={15}>
                            {getFieldDecorator("mechanic")(
                             <Select
                             mode="multiple"
                              placeholder="Select Mechanic"
                             >
                              {
                                mechanic.map((mechanic) => (
                                  
                                  <Select.Option key={mechanic.id} value={mechanic.id}>
                                      {`${mechanic.profile.employeeName}`}
                                  </Select.Option>

                                  // value: service.key,
                                  // label: service.title,
                                ))
                              }  
                            </Select>
                            )}
                          </Col>
                          <Col span={9}>
                            <Checkbox checked={mechanicCheck} onChange={mechanicCheckAll}>
                              Select All
                            </Checkbox>
                          </Col>
                        </Row>
                      </Item>
                       )}

                       </Col>
                     </Row>
 
                     <Row gutter={25}>
                       <Col span={8}>
                         
                           <Item label="Register Number">
                             {getFieldDecorator("registerNo")(
                              <Input  placeholder="Enter the Register Number"/>
                             )}
                           </Item>
                         
                       </Col>
                       <Col span={8}>
                        <Item label="Service KMs">
                          {getFieldDecorator("kms", { initialValue: range })(
                            <>
                              <Row gutter={16}>
                                <Col span={11}>
                                  <InputNumber
                                    min={0}
                                    max={maxLimit}
                                    value={range[0] || null}
                                    step={1000}
                                    onChange={(value) => handleChangeKms(value, 0)}
                                    style={{ width: "100%" }}
                                    placeholder="From"
                                  />
                                </Col>
                                <Col span={2} style={{ textAlign: "center" }}>to</Col>
                                <Col span={11}>
                                  <InputNumber
                                    min={0}
                                    max={maxLimit}
                                    value={range[1] || null}
                                    step={1000}
                                    onChange={(value) => handleChangeKms(value, 1)}
                                    style={{ width: "100%" }}
                                    placeholder="To"
                                  />
                                </Col>
                              </Row>
                            </>
                          )}
                        </Item>
                       </Col>
         
                       <Col span={8}>
                         <Item label="Job Card Creation Date">
                           {getFieldDecorator("jobCardCreationDate")(
                             <RangePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
                           )}
                         </Item>
                       </Col>
                     </Row>
         
                     <Row gutter={25}>
                       <Col span={3}>
                         <Button type="primary" onClick={handleSubmit}>
                           Search
                         </Button>
                       </Col>
                       <Col span={3}>
                         <Button type="ghost" onClick={searchValue}>
                           Clear
                         </Button>
                       </Col>
                     </Row>
                   </Form>
        </div>
      )}
    </div>
  );
}

const WrappedAdvancedFilter = Form.create()(AdvancedFilters);
export default WrappedAdvancedFilter;
