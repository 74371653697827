import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Button, DatePicker, message, Checkbox } from "antd";
import moment from "moment";
import { platformApi } from "../../../../api";
const { RangePicker } = DatePicker;
const { Item } = Form;
const { Option } = Select;

const enquiryTypes = ["Hot", "Warm", "Cold"];

const leadSources = [
  { key: "WALK IN", title: "Walk In" },
  { key: "CALL ENQUIRY", title: "Call Enquiry" },
  { key: "REFERRAL", title: "Referral" },
  { key: "SOCIAL MEDIA", title: "Social Media" },
  { key: "SMS", title: "SMS" },
  { key: "NEWSPAPER", title: "Newspaper" },
  { key: "TELEVISION AD", title: "Television Ad" },
  { key: "LEAFLET", title: "Leaflet" },
];

function AdvancedFilters({ form, filteropen, setId, setPageData, setSearch, setFilterData, data }) {
  const { getFieldDecorator, getFieldValue, resetFields, setFieldsValue } = form;
  const [models, setModels] = useState(null);
  const [users, setUser] = useState(null);

  const [checks, setChecks] = useState({
    modelCheck: false,
    salesExecCheck: false,
    leadCheck: false,
    categoryCheck: false,
    enquiryCheck: false,
  });

  const handleCheckChange = (key) => {
    setChecks((prevChecks) => ({
      ...prevChecks,
      [key]: !prevChecks[key],
    }));
  };

  useEffect(()=>{clearFilter()},[filteropen])
  useEffect(() => {
    if (data && data.filters) {
      const { quotationIssuedDate } = data.filters;
      if (quotationIssuedDate) {
        const dateRange = [moment(quotationIssuedDate.from), moment(quotationIssuedDate.to)];
        setFieldsValue({ quotationIssuedDate: dateRange });
      }
    }
  }, [data]);

  useEffect(() => {
    platformApi.get(`/api/vehicleMaster`).then((res) => {
      const { data } = res;
      if (data.code === 200) {
        const { response } = data;
        if (response.code === 200) {
          setModels(response.data);
        }
      }
    });

    fetchEmployeeBasedOnBranch();
  }, [data]);

  const fetchEmployeeBasedOnBranch = () => {
    platformApi
      .post("/api/user/get", { size: 1000, page: 1 })
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            let employees = [];
            response.data.users.forEach((element) => {
              let sale = false;
              element.profile.department.departmentType.forEach((data) => {
                if (data === "Sales") {
                  sale = true;
                  return;
                }
              });
              if (sale && element.status === true) {
                employees.push(element);
              }
            });
            setUser(employees);
          }
        }
      })
      .catch((error) => {
        message.error("Unable to fetch Users", 1);
        console.error("Error on Quotations Form : ", error);
      });
  };

  const handleSubmit = () => {
    const data = {};
    const fields = [
      "model",
      "category",
      "enquiryType",
      "testDriven",
      "paymentMode",
      "leadSource",
      "salesExecutive",
      "expectedPurchaseDate",
      "quotationIssuedDate",
    ];

    fields.forEach((field) => {
      const value = getFieldValue(field);
      if ( (!Array.isArray(value) && value) || (Array.isArray(value) && value.length > 0)) {
        if (field.includes("Date")) {
          if (Array.isArray(value)) {
            if (field === "quotationIssuedDate") {
              data[`fromDate`] = moment(value[0]).toISOString();
              data[`toDate`] = moment(value[1]).toISOString();
            } else {
              data[`${field}fromDate`] = moment(value[0]).toISOString();
              data[`${field}toDate`] = moment(value[1]).toISOString();
            }
          }
        } else {
          data[field] = value;
        }
      } else {
        if (field !== "quotationIssuedDate") data[field] = null;
      }
    });

    setFilterData(data);
  };

  const handleSelectAll = (field, options) => {
    const allValues = options.map(option => option.value);
    setFieldsValue({ [field]: allValues });
  };

  const handleDeselectAll = (field) => {
    console.log("Deselecting all");
    setFieldsValue({ [field]: [] });
  };

  const renderSelectWithSelectAll = (field, options, placeholder) => {
    const allSelected = getFieldValue(field)?.length === options.length;

    return (
      <Select
        mode="multiple"
        placeholder={placeholder}
        onDropdownVisibleChange={(open) => {
          if (open && allSelected) {
            handleSelectAll(field, options);
          }
        }}
        onSelect={(value) => {
          if (value === "selectAll") {
            if (!allSelected) {
              handleSelectAll(field, options);
            } else {
              handleDeselectAll(field);
            }
          }
        }}
        onDeselect={(value) => {
          if (value === "selectAll") {
            handleDeselectAll(field);
          } else {
            const newValues = getFieldValue(field).filter(v => v !== value);
            setFieldsValue({ [field]: newValues });
          }
        }}
      >
        {options.map(option => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const modelCheckAll = (e) => {
    const selectedValues = e.target.checked ? models.map((obj) => obj.id) : [];
    handleCheckChange("modelCheck");
    setFieldsValue({ model: selectedValues });
  };

  const cTypeCheckAll = (e) => {
    const selectedValues = e.target.checked ? ["SCOOTER", "MOTORCYCLE"] : [];
    handleCheckChange("categoryCheck");
    setFieldsValue({ category: selectedValues });
  };
  const leadCheckAll = (e) => {
    const selectedValues = e.target.checked ? leadSources.map((obj) => obj.key) : [];
    handleCheckChange("leadCheck");
    setFieldsValue({ leadSource: selectedValues });
  };

  const enquiryCheckAll = (e) => {
    const selectedValues = e.target.checked ? enquiryTypes : [];
    handleCheckChange("enquiryCheck");
    setFieldsValue({ enquiryType: selectedValues });
  };


  const salesExecCheckAll = (e) => {
    const selectedValues = e.target.checked ? users.map((user) => user.id) : [];
    handleCheckChange("salesExecCheck");
    setFieldsValue({ salesExecutive: selectedValues });
  };

  // enquiryCheckAll
  const clearFilter = () => {
    resetFields();
    setChecks({
      modelCheck: false,
      salesExecCheck: false,
      leadCheck: false,
      categoryCheck: false,
      enquiryCheck: false,
    });
  };

  return (
    <div>
      {filteropen && (
        <div className="AdvancedFilters">
          <Form>
            <Row>
              <h3>Advanced Filters</h3>
            </Row>

            <Row gutter={25} >
              <Col span={8}>
                {models && (
                  <Item label="Vehicle Model">
                    <Row gutter={11}>
                      <Col span={15}>
                        {getFieldDecorator("model")(
                          renderSelectWithSelectAll(
                            "model",
                            models.map((model) => ({
                              value: model.id,
                              label: `${model.modelName}-${model.modelCode}`,
                            })),
                            "Select Vehicle Model"
                          )
                        )}
                      </Col>
                      <Col span={9}>
                        <Checkbox
                          checked={checks.modelCheck}
                          onChange={modelCheckAll}
                        >
                          Select All
                        </Checkbox>
                      </Col>
                    </Row>
                  </Item>
                )}
              </Col>

              <Col span={8}>
                <Item label="Vehicle Category">
                  <Row gutter={11}>
                    <Col span={15}>
                      {getFieldDecorator("category")(
                        renderSelectWithSelectAll(
                          "category",
                          [
                            { value: "SCOOTER", label: "Scooter" },
                            { value: "MOTORCYCLE", label: "Motorcycle" },
                          ],
                          "Select Vehicle Category"
                        )
                      )}
                    </Col>
                    <Col span={9}>
                      <Checkbox
                        checked={checks.categoryCheck}
                        onChange={cTypeCheckAll}
                      >
                        Select All
                      </Checkbox>
                    </Col>
                  </Row>
                </Item>
              </Col>

              <Col span={8}>
                <Item label="Enquiry Type">
                  <Row gutter={11}>
                    <Col span={15}>
                      {getFieldDecorator("enquiryType")(
                        renderSelectWithSelectAll(
                          "enquiryType",
                          enquiryTypes.map((type) => ({ value: type, label: type })),
                          "Select Enquiry Type"
                        )
                      )}
                    </Col>
                    <Col span={9}>
                      <Checkbox
                        checked={checks.enquiryCheck}
                        onChange={enquiryCheckAll}
                      >
                        Select All
                      </Checkbox>
                    </Col>
                  </Row>
                </Item>
              </Col>

            </Row>

            <Row gutter={25}>
              <Col span={8}>
                <Item label="Test Drive Taken">
                  {getFieldDecorator("testDriven")(
                    renderSelectWithSelectAll(
                      "testDriven",
                      [
                        { value: "TRUE", label: "Yes" },
                        { value: "FALSE", label: "No" },
                      ],
                      "Select Test Drive Status"
                    )
                  )}
                </Item>
              </Col>
              <Col span={8}>
                <Item label="Payment Mode">
                  {getFieldDecorator("paymentMode")(
                    renderSelectWithSelectAll(
                      "paymentMode",
                      [
                        { value: "CASH", label: "Cash" },
                        { value: "FINANCE", label: "Finance" },
                      ],
                      "Select Payment Mode"
                    )
                  )}
                </Item>
              </Col>

              <Col span={8}>
                <Item label="Lead Source">
                  <Row gutter={11}>
                    <Col span={15}>
                      {getFieldDecorator("leadSource")(
                        renderSelectWithSelectAll(
                          "leadSource",
                          leadSources.map((source) => ({
                            value: source.key,
                            label: source.title,
                          })),
                          "Select Lead Source"
                        )
                      )}
                    </Col>
                    <Col span={9}>
                      <Checkbox
                        checked={checks.leadCheck}
                        onChange={leadCheckAll}
                      >
                        Select All
                      </Checkbox>
                    </Col>
                  </Row>
                </Item>

              </Col>
            </Row>

            <Row gutter={25}>
              <Col span={8}>
                {users && (
                  <Item label="Sales Executive">
                    <Row gutter={11}>
                      <Col span={15}>
                        {getFieldDecorator("salesExecutive")(
                          renderSelectWithSelectAll(
                            "salesExecutive",
                            users.map((user) => ({
                              value: user.id,
                              label: user.profile.employeeName,
                            })),
                            "Select Sales Executive"
                          )
                        )}
                      </Col>
                      <Col span={9}>
                        <Checkbox
                          checked={checks.salesExecCheck}
                          onChange={salesExecCheckAll}
                        >
                          Select All
                        </Checkbox>
                      </Col>
                    </Row>
                  </Item>
                )}
              </Col>

              <Col span={8}>
                <Item label="Expected Purchase Date">
                  {getFieldDecorator("expectedPurchaseDate")(
                    <RangePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
                  )}
                </Item>
              </Col>

              <Col span={8}>
                <Item label="Quotation Issued Date">
                  {getFieldDecorator("quotationIssuedDate")(
                    <RangePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
                  )}
                </Item>
              </Col>
            </Row>

            <Row gutter={25}>
              <Col span={3}>
                <Button type="primary" onClick={handleSubmit}>
                  Search
                </Button>
              </Col>
              <Col span={3}>
                <Button type="ghost" onClick={clearFilter}>
                  Clear
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
}

const WrappedAdvancedFilter = Form.create()(AdvancedFilters);
export default WrappedAdvancedFilter;
