/* eslint-disable quotes */
/* eslint-disable react/destructuring-assignment */
import React, { PureComponent, useContext, useEffect, useState } from "react";
import { withRouter, Route } from "react-router-dom";

// Components
import Manufacturers from "../CompanyMasters/Manufacturers";
import Employee from "../CompanyMasters/Employee";
import SACCode from "../CompanyMasters/SACCode";
import Financier from "../CompanyMasters/Financier";
import Insurance from "../CompanyMasters/Insurance Master";
import HSNCode from "../CompanyMasters/HSNCode";
import Department from "../CompanyMasters/Departments";
import JobCode from "../CompanyMasters/JobCode";
import Accessories from "../CompanyMasters/Accessories";
import VehiclePrice from "../CompanyMasters/VehiclePrice";
import PartsMaster from "../CompanyMasters/PartsMaster";
import VehicleMaster from "../CompanyMasters/VehicleMaster";
import SMS from "../CompanyMasters/SMS";
import SupplierMaster from "../CompanyMasters/SupplierMaster";
import Company from "../CompanyMasters/Company";
import VehiclePurchaseInvoice from "../TransactionMasters/VehiclePurchaseInvoice";
import PurchaseReturn from "../TransactionMasters/PurchaseReturn";
import Vehicles from "../TransactionMasters/Vehicles";
import SalesChallan from "../TransactionMasters/SalesChallan";
import Customers from "../TransactionMasters/CustomerDetails";
import BookingRegister from "../TransactionMasters/BookingRegister";
import Quotation from "../TransactionMasters/Quotations";
import VehicleSaleInvoice from "../TransactionMasters/VehicleSaleInvoice";
import JobOrder from "../TransactionMasters/JobOrder";
import VehiclePurchaseChallan from "../TransactionMasters/VehiclePurchaseChallan";
import IDGenerator from "../CompanyMasters/IDGenerator";
import NotFound from "../NotFound";
import Forbidden from "../Forbidden";
import ReferredCustomerPage from "../TransactionMasters/ReferredCustomerPage";
import { ContextAPI } from "../../ContextAPI";
import Sales from "../Services/Sales";
import { submodules } from "../../JSONFiles/Submodule.json";
import Promotions from "../TransactionMasters/Promotions";
import RTOMaster from "../CompanyMasters/RTOMaster";
import FrameNumber from "../CompanyMasters/FrameNumber";
import Dashboard from "../Dashboard";
import FollowUps from "../FollowUp";
import FollowUpHomePage from "../FollowUp/HomePage";
import SalesTargets from "../CompanyMasters/SalesTargets";
import PMC from "../CompanyMasters/PMC";
import Reports from "../TransactionMasters/Reports";
import PurchaseSparesTable from "../TransactionMasters/PurchaseSparesInvoice";
import PurchaseSparesInvoice from "../TransactionMasters/PurchaseSparesInvoice/Invoice";
import SparseInventory from "../CompanyMasters/SparesInventory";
import SparesInventory from "../CompanyMasters/SparesInventory";
import VehicleInventory from "../CompanyMasters/VehicleInventory";
// import StockCheck from "../TransactionMaster/StockCheck";    //updated
import SaleSparesInvoice from "../TransactionMasters/SaleSparesInvoice/Invoice";
import SaleSparesTable from "../TransactionMasters/SaleSparesInvoice";
import VehicleSaleInvoiceTable from "../TransactionMasters/VehicleSaleInvoice/VehicleSaleInvoiceTable";
import EstimateTable from "../TransactionMasters/Estimate";
import EstimateInvoice from "../TransactionMasters/Estimate/Invoice";
import MaterialIssueTable from "../TransactionMasters/MaterialIssue";
import MaterialIssueForm from "../TransactionMasters/MaterialIssue/MaterialIssueForm";
import WrappedServiceReminder from "../TransactionMasters/ServiceReminder";
import ServiceDashboard from "../ServiceDashboard";
import LedgerDetails from "../TransactionMasters/LedgerDetails";
import ServiceFollowUp from "../ServiceFollowUp";
import WrappedServiceFollowUpHome from "../ServiceFollowUp/ServiceFollowUpHome";
import FinalInspection from "../TransactionMasters/FinalInspection";
import WrappedFinalInspectionForm from "../TransactionMasters/FinalInspection/FinalInspectionForm";
import Whatsup from "../CompanyMasters/Whatsup";
import JobInvoiceTable from "../TransactionMasters/JobInvoice";
import JobInvoice from "../TransactionMasters/JobInvoice/Invoice";
import ReportDashboard from "../TransactionMasters/ReportDashboard";
import WhatsappWeb from "../CompanyMasters/Whatsup/WhatsappWeb";
import CustomerSpareOrder from "../TransactionMasters/CustomerSpareOrder";
import CustomerSpareOrderForm from "../TransactionMasters/CustomerSpareOrder/CustomerSpareForm";
import PurchaseInvoice from "../TransactionMasters/VehiclePurchaseInvoice/VehiclePurchaseInvoiceForm";
import StockCheck from "../TransactionMasters/Reports/StockCheck";
import WhatsappPromo from "../TransactionMasters/Promotions/WhatsappPromotions";
// import VehicleForm from "../Vehicles/VehicleForm";
import Upload from "../CompanyMasters/Upload"
import Transfer from "../TransactionMasters/Transfer";
import NumberPlating from "../TransactionMasters/NumberPlating";
import InsuranceReminder from "../TransactionMasters/InsuranceReminder";
import InsuranceFollowUp from "../TransactionMasters/InsuranceReminder/InsuranceFollowUp";
import OtherInsuranceReminder from "../TransactionMasters/OtherInsuranceReminder";
import OtherInsuranceFollowUp from "../TransactionMasters/OtherInsuranceReminder/OtherInsuranceFollowUp";
// import "";

const HelloReact = (props) => {
  const [persistentPage, setPersistentPage] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);

  const type = props.match.params.type;
  const category = props.match.params.category;
  let dashboardAccess = true;
  let serviceDashboardAccess = false;
  loginCredintials.departmentType &&
    loginCredintials.departmentType.map((type) => {
      if (type === "General") {
        dashboardAccess = false;
        serviceDashboardAccess = false;
      } else if (type === "Service") {
        serviceDashboardAccess = true;
      }
    });

  if (
    loginCredintials.departmentType &&
    loginCredintials.departmentType.length === 0
  ) {
    dashboardAccess = false;
    serviceDashboardAccess = false;
  }

  if (!type) {
    if (dashboardAccess) {
      props.history.push("/autoadmin/dashboard");
    } else if (serviceDashboardAccess) {
      props.history.push("/autoadmin/services"); //updated
    } else {
      props.history.push("/autoadmin/employee");
    }
  }
  let access = false;

  useEffect(() => {
    submodules.map((e) => {
      if (e.key === type) {
        setPersistentPage(true);
      }
    });
  }, []);

  loginCredintials.roleAccess &&
    loginCredintials.roleAccess.map((each) => {
      if ( // actual check with the access control!
        (each.subModule === type && each.access.read) ||
        type === "job-order" ||
        (type === "referred_customer" &&
          each.subModule === "customer_details" &&
          each.access.read) ||
        (type === "follow-ups" &&
          each.subModule === "quotations" &&
          each.access.create) ||
        type === "counter_sale" ||
        type === "sales_targets" ||
        type === "pmc" ||
        type === "upload" ||
        type === "report" ||
        type === "purchase_spares_invoice" ||
        type == "spares_inventory" ||
        type == "vehicle_inventory" ||
        type == "stockcheck" ||
        type === "estimate" ||
        type === "transfer" ||
        type === "service_reminder" ||
        (type === "service-followups" &&
          each.subModule === "service_reminder" &&
          each.access.create) ||
        type === "ledger" ||
        type === "number_plating" ||
        type === "whatsup" || type === "whatsapp" || type === "customerspare" || type === "customerspare" ||
        type === "other_insurance_renewal_reminder"
        // || type === "promowhatsapp"
      ) {
        access = true;
      }
      if (
        type === "company"
        // && each.subModule === 'company_settings'
        // && each.access.read
      ) {
        access = true;
      }
    });

  let component;
  if (type === "dashboard" && dashboardAccess) {
    component = <Dashboard />;
  } else if (type === "dashboard" && !dashboardAccess) {
    component = <Forbidden />;
  } else if (type === "services" && serviceDashboardAccess) {
    component = <ServiceDashboard />;
  } else {
    console.log("access", access);
    if (access) {
      switch (type) {
        // case "customerspare":
        //   component = <CustomerSpareOrder/>
        //   break;
        case "transfer":
          if (category === "vehicles") {
            component = <Transfer type="vehicles" />;
          } else if (category === "spares") {
            component = <Transfer type="spares" />;
          } else {
            component = <NotFound />;
          }
          break;

        case "number_plating":
          component = <NumberPlating/>
          break;
        
        case "insurance_renewal_reminder":
          // component = <InsuranceReminder/>
          component = (
            <>
              <Route exact path="/autoadmin/insurance_renewal_reminder/">
              <InsuranceReminder/>
              </Route>
              <Route
                exact
                path="/autoadmin/insurance_renewal_reminder/:phoneNumber"
              >
                <InsuranceFollowUp />
              </Route>
            </>
          )
          break;

          case "other_insurance_renewal_reminder":
            console.log("inside_de")
            component = (
              <>
                <Route exact path="/autoadmin/other_insurance_renewal_reminder/">
                <OtherInsuranceReminder/>
                </Route>
                <Route
                  exact
                  path="/autoadmin/other_insurance_renewal_reminder/:phoneNumber"
                >
                  <OtherInsuranceFollowUp />
                </Route>
              </>
            )
            break;

        case "company":
          component = <Company />;
          break;
        case "manufacturer":
          component = <Manufacturers />;
          break;
        case "employee":
          component = <Employee />;
          break;
        case "sac":
          component = <SACCode />;
          break;
        case "financier":
          component = <Financier />;
          break;
        case "insurance":
          component = <Insurance />;
          break;
        case "hsn":
          component = <HSNCode />;
          break;
        case "department":
          component = <Department />;
          break;
        case "job_code":
          component = <JobCode />;
          break;
        // case "accessories":
        //   component = <Accessories />;
        //   break;
        case "vehicle_price":
          component = <VehiclePrice />;
          break;
        case "vehicle_master":
          component = <VehicleMaster />;
          break;
        case "parts_master":
          component = <PartsMaster />;
          break;
        case "sms":
          component = <SMS />;
          break;
        case "whatsup":
          component = <Whatsup />;
          break;
        case "supplier_master":
          component = <SupplierMaster />;
          break;
        case "vehicle_purchase_invoice":
          component = <VehiclePurchaseInvoice />;
          component = (
            <>
              <Route exact path="/autoadmin/vehicle_purchase_invoice">
                <VehiclePurchaseInvoice />
              </Route>
              <Route exact path="/autoadmin/vehicle_purchase_invoice/:id">
                <PurchaseInvoice />
              </Route>
            </>
          );

          break;
        case "purchase_return":
          component = <PurchaseReturn />;
          break;
        case "vehicles":
          component = <Vehicles />;
          break;
        case "sale_challan":
          component = <SalesChallan />;
          break;
        case "customer_details":
          component = <Customers />;
          break;
        case "booking_register":
          component = <BookingRegister />;
          break;
        case "quotations":
          component = <Quotation />;
          break;
        case "vehicle_sale_invoice":
          component = <VehicleSaleInvoice />;
          break;
        case "job_order":
          component = <JobOrder />;
          break;
        case "vehicle_purchase_challan":
          component = <VehiclePurchaseChallan />;
          break;
        case "idgenerator":
          component = <IDGenerator />;
          break;
        case "referred_customer":
          component = <ReferredCustomerPage />;
          break;
        case "sales":
          component = <Sales />;
          break;
        case "pmc":
          component = <PMC />;
          break;
        case "upload" :
          component = <Upload />;
          break;
        case "sales_targets":
          component = <SalesTargets />;
          break;
        case "spares_inventory":
          component = <SparesInventory />;
          break;
        case "vehicle_inventory":
          component = <VehicleInventory />;
          break;
        // case "stock_check":
        //   component = <StockCheck />;     //updated
        //   break;
        case "service_reminder":
          component = <WrappedServiceReminder />;
          break;
        case "ledger":
          component = <LedgerDetails />;
          break;
        // case "promowhatsapp":
        //   component = <WhatsappPromo />;
        //   break;
        case "purchase_spares_invoice":
          component = (
            <>
              <Route exact path="/autoadmin/purchase_spares_invoice">
                <PurchaseSparesTable />
              </Route>
              <Route exact path="/autoadmin/purchase_spares_invoice/:id">
                <PurchaseSparesInvoice />
              </Route>
            </>
          );
          break;
        case "customerspare":
          component = (
            <>
              <Route exact path="/autoadmin/customerspare">
                <CustomerSpareOrder />
                {/* <CustomerSpareOrderForm /> */}
              </Route>
              <Route exact path="/autoadmin/customerspare/:id">
                <CustomerSpareOrderForm />
              </Route>
            </>
          );
          break;
        case "counter_sale":
          component = (
            <>
              <Route exact path="/autoadmin/counter_sale">
                <SaleSparesTable />
              </Route>
              <Route exact path="/autoadmin/counter_sale/:id">
                <SaleSparesInvoice />
              </Route>
            </>
          );
          break;
        case "job_invoice":
          component = (
            <>
              <Route exact path="/autoadmin/job_invoice">
                <JobInvoiceTable />
              </Route>
              <Route exact path="/autoadmin/job_invoice/:id">
                <JobInvoice />
              </Route>
            </>
          );
          break;
        case "estimate":
          component = (
            <>
              <Route exact path="/autoadmin/estimate">
                <EstimateTable />
              </Route>
              <Route exact path="/autoadmin/estimate/:id">
                <EstimateInvoice />
              </Route>
            </>
          );
          break;
        case "material_issue":
          component = (
            <>
              <Route exact path="/autoadmin/material_issue">
                <MaterialIssueTable />
              </Route>
              <Route exact path="/autoadmin/material_issue/:id">
                <MaterialIssueForm />
              </Route>
            </>
          );
          break;
        case "final_inspection":
          component = (
            <>
              <Route exact path="/autoadmin/final_inspection">
                <FinalInspection />
              </Route>
              <Route exact path="/autoadmin/final_inspection/:id">
                <WrappedFinalInspectionForm />
              </Route>
            </>
          );
          break;
        case "promotion":
          if (category === "groups") {
            component = <Promotions type="groups" />;
          } else if (category === "tasks") {
            component = <Promotions type="tasks" />;
          } else {
            component = <NotFound />;
          }
          break;
        case "report":
          if (category === "spares") {
            component = <Reports type="spares" />;
          } else if (category === "vehicle") {
            component = <Reports type="vehicle" />;
          } else if (category === "ReportDashboard") {
            component = <ReportDashboard />
          }
          else {
            component = <NotFound />;
          }
          break;
        case "rtomaster":
          component = <RTOMaster />;
          break;
        case "frame_number":
          component = <FrameNumber />;
          break;
        case "follow-ups":
          component = (
            <>
              <Route exact path="/autoadmin/follow-ups/">
                <FollowUpHomePage />
              </Route>
              <Route
                exact
                path="/autoadmin/follow-ups/:phoneNo/fupdate/:fupDate"
              >
                <FollowUps />
              </Route>
            </>
          );
          break;

        case "stockcheck":
          component = (
            <>
              <Route
                exact
                path="/autoadmin/stockcheck/"
              >
                <StockCheck />
              </Route>
            </>
          );
          break;

        case "service-followups":
          component = (
            <>
              <Route exact path="/autoadmin/service-followups/">
                <WrappedServiceFollowUpHome />
              </Route>
              <Route
                exact
                path="/autoadmin/service-followups/:phoneNo/fupdate/:fupDate"
              >
                <ServiceFollowUp />
              </Route>
            </>
          );
          break;

        case "whatsapp":
          component = (
            <>
              <Route exact path="/autoadmin/whatsapp">
                <WhatsappWeb />
              </Route>
            </>
          );
          break;

        default:
          component = <NotFound />;
      }
    } else {
      if (!persistentPage) {
        component = <NotFound />;
      } else {
        component = <Forbidden />;
      }
    }
  }

  return <div>{component}</div>;
};

export default withRouter(HelloReact);
