import React, { useState, useEffect } from "react";
import {
  Typography,
  Input,
  Modal,
  Button,
  Select,
  Form,
  Col,
  Row,
  DatePicker,
  Checkbox,
  Tag,
  Icon,
  message,
  Tabs,
  Table,
} from "antd";

import { Collapse } from 'antd';

const { Panel } = Collapse;
import { Empty } from 'antd';
function callback(key) {
  console.log(key);
}

import { platformApi } from "../../../../api";
import "./index.less";
// import JobOrder from "./JobOrder";
import moment from "moment";
import ColorSelection from "./ColorSelection";
import { formatValue } from "../../../../utils";
import AddCustomerModal from "../../CustomerDetails/CustomerDetailsForm";
import WrappedCustomerAddModal from "../../JobOrder/JobOrderModal/VehicleDetails/AddCustomer";
import Files from "./Files";
import WrappedInsuranceForm from "./InsuranceForm";
import { jsPDF } from "jspdf";
import QRCode from "qrcode";






// var ReactPropTypes = React.PropTypes;
// var Panel = require('react-bootstrap').Panel; 

const { Title, Text } = Typography;
const { Option } = Select;
const { Item } = Form;
const { TabPane } = Tabs;
// const JsBarcode = window.JsBarcode;

const VehicleForm = ({
  form,
  open,
  close,
  emitData,
  data,
  editable: editData,
  modify,
  vehicle,
  customer,
  edit,
  setEdit,
  uploading,
  setUploading,
  vData,
}) => {
  const [manufacturers, setManufacturer] = useState([]);
  const [error, setError] = useState({});
  const [vehicles, setVehicle] = useState([]);
  const [colors, setColor] = useState([]);
  const [editable, setEditable] = useState(editData);
  const [customers, setCustomer] = useState([]);
  const [prevCustomer, setPrevCustomer] = useState([]);
  const [colorModal, setColorModal] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [MFGId, setMFGId] = useState("");
  const [MFGDate, setMFGDate] = useState(null);
  const [DOMFlag, setDOMFlag] = useState(null);
  const [searchCustomer, setSearchCustomer] = useState(null);
  const [removedCustomer, setRemovedCustomer] = useState([]);
  const [deleteCustomer, setDeleteCustomer] = useState([]);
  const [deleteService, setDeleteService] = useState([]);
  //CUSTOMER ADD MODAL STATE
  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [cusId, setCusId] = useState("");
  //Insurance Modal
  const [insModal, setInsModal] = useState(false);
  const [insuranceData, setInsuranceData] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [formData, setFormData] = useState(null);
  const VehicleTypes = ["Sales", "Service"];
  const [dataSource, setDataSource] = useState([]);
  const [removedData, setRemovedData] = useState([]);
  const [services, setServices] = useState([]);
  const [vehicleService, setVehicleService] = useState(null);
  const [qrImageURL, setQrImageURL] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [historyData, setHistoryData] = useState([]);
  const [labourData, setLabourData] = useState([{}]);
  const [partData, setPartData] = useState([{}]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [dateSale, setDateSale] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  useEffect(() => {
    setEditable(editData);
    if (data) {
      console.log("data", data);
      if (data.vehicle) {
        platformApi
          .post("/api/vehicle/getCustomer", {
            customer: data.customer.map((customer) => {
              return customer.customer != null && customer.customer.id;
            }),
          })
          .then((res) => {
            let { data } = res;
            if (data.code === 200) {
              let { response } = data;
              if (response.code === 200) {
                setCustomer(response.data);
                setMobileNumber(
                  response.data && response.data[0]
                    ? response.data[0].contacts[0].phone
                    : ""
                );
              }
            }
          });
        platformApi
          .post(`/api/vehicle/get?color=${data.id}`).then((res) => {
            setSelectedColor(res?.data?.response?.data?.SoldVehicle[0]?.color)
          });
          platformApi
          .post(`/api/vehicle/get?vehicleFiles=${data.id}`).then((res) => {
            setInsuranceData(res.data?.response?.data?.SoldVehicle[0]?.insurance)
            setFileData(res.data?.response?.data?.SoldVehicle[0]?.vehicle ?? [])
            const eReceiptUrl =
              res.data?.response?.data?.SoldVehicle[0]?.eReceipt;
            if (eReceiptUrl) {
              setPdfUrl(eReceiptUrl);
            }
            else setPdfUrl(null);
          });
          platformApi.post(`/api/vehicle/get?vehicleServices=${data.id}`).then((res)=>{
            setVehicleService(res.data?.response?.data?.SoldVehicle[0]?.services)
          })
        initiateModel(data.vehicle.manufacturer.id, data.vehicle.id);
        // setSelectedColor(data.color || {});
        setMFGId(data.vehicle.manufacturer.id);
        setPrevCustomer(data.customer);
        // setVehicleService(data.services);
        setDeleteService(data.services);
        setDateSale(moment(new Date(data.dateOfSale)).format("DD/MM/YYYY"));
        // let customer=data.customer.filter((d)=>{return d.customer!=null})
        setFieldsValue({
          manufacturer: data.vehicle.manufacturer.id,
          vehicle: data.vehicle.id,
          chassisNo: data.chassisNo,
          dateOfSale: data.dateOfSale
            ? moment(new Date(data.dateOfSale))
            : undefined,
          registerNo: data.registerNo,
          vehicleType: data.vehicleType,
          active: data.active === true ? "Yes" : "No",
          customer: data.customer.map((customer) => {
            return customer.customer != null && customer.customer.id;
          }),
          // customer:customer,
          engineNo: data.engineNo,
          serviceCouponNumber: data.serviceCouponNumber,
        });
        // dropdownSearch({searchString:})
        checkChassisNumber(
          data.chassisNo,
          data.vehicle.manufacturer.id,
          data ? data.id : null,
          true
        );
        getJobOrderHistory(data.id);
      }
    }
  }, [open, data, editData]);

  useEffect(() => {
    console.log({ selectedColor, colorModal });
  }, [selectedColor, colorModal]);

  const getJobOrderHistory = (id) => {
    let partList = [];
    let codeList = [];
    platformApi
      .get(`/api/jobOrder/vehicleHistory/${id}`)
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            console.log("HistoryData", response.data);
            setHistoryData(response.data.History);
            setInvoiceData(response.data.Invoice);
            for (let i = 0; i < response.data.Invoice.length; i++) {
              const updateItemList = [
                ...response.data.Invoice[i].saleItemInvoice,
              ];
              // console.log("updateItemList", updateItemList)
              for (let j = 0; j < updateItemList.length; j++) {
                if (updateItemList[j].jobCode) {
                  updateItemList[j].jobNo =
                    response.data.Invoice[i].jobOrder?.jobNo;
                  updateItemList[j].jobCode = { ...updateItemList[j].jobCode };
                  updateItemList[j].rate =
                    updateItemList[j].quantity * updateItemList[j].unitRate;
                } else {
                  updateItemList[j].jobNo =
                    response.data.Invoice[i].jobOrder?.jobNo;
                  updateItemList[j].partNumber = {
                    ...updateItemList[j].partNumber,
                  };
                  updateItemList[j].rate =
                    updateItemList[j].quantity * updateItemList[j].unitRate;
                }
                if (updateItemList[j].jobCode) {
                  codeList.push(updateItemList[j]);
                } else {
                  partList.push(updateItemList[j]);
                }
              }
            }
            // console.log("Lists", codeList, partList)
            setPartData(partList);
            setLabourData(codeList);
          }
        }
      })
      .catch((err) => {
        console.error("Couldn't get Job History: ", err);
        message.error("Couldn't get Job History");
      });
  };

  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
  } = form;

  const dropdownSearch = (search) => {
    setSearchCustomer(search.searchString);
    platformApi
      .post("/api/options/get", {
        module: search.module,
        column: search.column,
        searchString: search.searchString,
        fields: search.fields,
        size: 50,
      })
      .then((res) => {
        search.setValue(res.data.response);
      });
  };
  useEffect(() => {
    if (vehicle) {
      form.setFieldsValue({
        registerNo: vehicle,
      });
    }
    if (customer) {
      platformApi
        .post("/api/vehicle/getCustomer", {
          customer: customer,
        })
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setCustomer(response.data);
              form.setFieldsValue({
                customer: [customer],
              });
              // setMobileNumber(response.data && response.data[0] ? response.data[0].contacts[0].phone : '')
            }
          }
        });
    }
  }, [vehicle, customer]);
  // const addCustomer = () => {
  //   if (searchCustomer.includes('-')) {
  //     let [name, phone] = searchCustomer.split('-')
  //     if (isNaN(name) && !isNaN(phone)) {
  //       let customerId;
  //       platformApi.post("/api/idGenerate/customer").then((res) => {
  //         const { data: { code, response } } = res;
  //         if (code === 200) {
  //           customerId = response.data;
  //           platformApi
  //             .post("/api/customer", {
  //               name:name.trim(),
  //              contacts:
  //               [{
  //               phone:phone.trim(),
  //               type: 'Primary',
  //               valid: false,
  //               DND: false,
  //             }]
  //               ,customerId})
  //             .then((result) => {
  //               const { data: { code, response } } = result;
  //               (code === 200) ? setCustomer([...customers, response.data]) : null
  //             })
  //         } else {
  //           message.error("Couldn't fetch id");
  //         }
  //     }
  //     else if (!isNaN(name)) {
  //       message.error("Name should not contain Numbers");
  //     }
  //     else if(isNaN(phone)){
  //       message.error("Phone number should not contain Characters");
  //     }

  //   }
  //   else {
  //     message.error("Add Customer Format Invalid");
  //   }
  //   }

  useEffect(() => {
    if (dataSource.length !== 0){
    setInsuranceData([...dataSource]);
  }
  }, [dataSource.length]);

  useEffect(() => {
    if (open) {
      let formdata = new FormData();
      setFormData(formdata);
      platformApi.get("/api/manufacturer/branch").then((res) => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setManufacturer(response.data);
            form.setFieldsValue({
              manufacturer: response.data[0].id,
            });

            if (edit) {
              handleManufacturerChange(response.data[0].id);
            }
          }
        }
      });
      // platformApi.get("/api/manufacturer").then((result) => {
      //   let { data } = result;
      //   if (data.code === 200) {
      //     let { response } = data;
      //     if (response.code === 200) {
      //       console.log(response.data)
      //       setManufacturer(response.data);
      //     }
      //   }
      // });
      const search = {
        module: "customers",
        column: "phone",
        fields: ["contacts{phone}"],
        setValue: setCustomer,
      };
      dropdownSearch(search);
    }
  }, [open]);

  const initiateModel = (manId, vehicleId) => {
    platformApi.get(`/api/vehicleMaster/manAll/${manId}`).then((result) => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setVehicle(response.data);
          response.data.some((vehicle) => {
            if (vehicle.id === vehicleId) {
              setColor(vehicle.image);
              setFieldsValue({ category: vehicle.category });
              setServices(vehicle.services);
            }
          });
        }
      }
    });
  };

  const handleManufacturerChange = (id) => {
    setFieldsValue({
      vehicle: undefined,
      category: undefined,
    });
    setMFGId(id);
    platformApi.get(`/api/vehicleMaster/manAll/${id}`).then((result) => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          // console.log("response", response.data)
          setVehicle(response.data);
          setSelectedColor(null);
        }
      }
    });
  };

  const handleModelChange = (id) => {
    vehicles.some((vehicle) => {
      if (vehicle.id === id) {
        setColor(vehicle.image);
        setFieldsValue({ category: vehicle.category });
        setSelectedColor(null);
      }
    });
  };
  const handleSubmit = () => {
    validateFields(async (validationError) => {
      if (
        validationError === null &&
        !error.CHS &&
        !error.RGN &&
        !error.ENO
      ) {
        let customerArray = [];
        let customer = getFieldValue("customer");
        if (data) {
          console.log("HIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII");

          customer &&
            customer.map((each) => {
              let flag = false;
              prevCustomer.map((prev) => {
                if (prev.customer.id === each) {
                  customerArray.push({
                    customer: each,
                    id: prev.id,
                  });
                  flag = true;
                }
              });
              if (!flag) {
                customerArray.push({
                  customer: each,
                  id: "",
                });
              }
            });
        } else {
          customer &&
            customer.map((each) => {
              customerArray.push({
                customer: each,
                id: "",
              });
            });
        }
        // var manufacturerName = manufacturers.find((m) => m.id == getFieldValue("manufacturer"));
        var manufacturerName = getFieldValue("manufacturer");
        var model = vehicles.find((v) => v.id == getFieldValue("vehicle"));
        let serviceDate = [];
        let vehiceleServiceContent = [];

        const DateofSale = getFieldValue("dateOfSale")
          ? moment(new Date(getFieldValue("dateOfSale"))).format("DD/MM/YYYY")
          : null || null;
        setUploading(true);

        await platformApi
          .get(`/api/vehicleMaster/manAll/${getFieldValue("manufacturer")}`)
          .then((result) => {
            let { data } = result;
            if (data.code === 200) {
              let { response } = data;
              if (response.code === 200) {
                setVehicle(response.data);
                response.data.some((vehicle) => {
                  if (vehicle.id === getFieldValue("vehicle")) {
                    for (let i = 0; i < vehicle.services.length; i++) {
                      if (DateofSale) {
                        serviceDate.push(
                          moment(
                            moment(DateofSale, "DD/MM/YYYY").add(
                              vehicle.services[i].serviceDays,
                              "d"
                            )
                          ) || null
                        );
                      }
                    }
                    // console.log("serviceDate", serviceDate)
                    for (let i = 0; i < vehicle.services.length; i++) {
                      vehiceleServiceContent.push({
                        serviceNo: vehicle.services[i].serviceNo,
                        serviceType: vehicle.services[i].serviceType,
                        serviceKms: vehicle.services[i].serviceKm,
                        serviceDate: serviceDate.length > 0 && serviceDate[i],
                      });
                    }
                  }
                });
              }
            }
          });

        const Vehicle = {
          vehicle: getFieldValue("vehicle"),
          customer: customerArray,
          color: (selectedColor && selectedColor.id) || "",
          registerNo: getFieldValue("registerNo"),
          vehicleType: getFieldValue("vehicleType"),
          active: getFieldValue("active"),
          chassisNo: getFieldValue("chassisNo"),
          engineNo: getFieldValue("engineNo"),
          serviceCouponNumber: getFieldValue("serviceCouponNumber"),
          removedCustomer: removedCustomer,
          deleteCustomer: deleteCustomer,
          dateOfSale: getFieldValue("dateOfSale")
            ? moment(new Date(getFieldValue("dateOfSale"))).format("DD/MM/YYYY")
            : null,
          mfg: MFGDate,
          // manufacturer: manufacturerName.name,
          manufacturer: manufacturerName,
          modelName: model.modelName,
          serviceList: vehiceleServiceContent,
          vehicleColor:
            selectedColor == undefined || selectedColor == null
              ? ""
              : selectedColor.color,
          images:
            selectedColor == undefined || selectedColor == null
              ? ""
              : selectedColor.url,
          insuranceData:
            insuranceData && insuranceData.length > 0 ? insuranceData : [],
          removedData,
          deleteService,
        };
        data ? (Vehicle.id = data.id) : {};
        formData.append("id", Vehicle.chassisNo);
        formData.append("vehicleData", JSON.stringify(Vehicle));
        console.log("----------------" + JSON.stringify(Vehicle));
        emitData(formData, Vehicle);

        clearFields();
        close();
      }
      // else if (!selectedColor) {
      //   message.error("Enter All Vehicle Color along with other input fields");
      // }
      else {
        message.error("Enter valid input fields");
      }
    });
  };

  const clearFields = () => {
    form.resetFields();
    setError({});
    setColor([]);
    setVehicle([]);
    setManufacturer([]);
    setInsuranceData(null);
    setFileData(null);
    setVehicleService(null);
    setPrevCustomer([]);
    setRemovedCustomer([]);
    setDeleteCustomer([]);
    setSelectedColor(null);
    setMFGDate(null);
    setMFGId("");
    setDOMFlag(null);
    setDataSource([]);
    setRemovedData([]);
    setDeleteService([]);
    // setEditable(false)
    setEdit(false);
  };


  const addCustomerData = (customer) => {
    platformApi
      .post("/api/customer", customer)
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setCustomer([...customers, response.data]);
            message.success("Customer added successfully", 2);
          } else {
            message.error("Unable to add customer", 2);
          }
        } else if (data.code === 500 && data.err.code === 500) {
          message.error("Customer name already exists", 2);
        } else {
          message.error("Unable to add customer", 2);
        }
      })
      .catch((error) => {
        message.error("Unable to add customer", 2);
        console.error("Error on customer : ", error);
      });
  };

  const onCustomerChange = (id) => {
    if (data) {
      let deleteCust = "";
      setRemovedCustomer([...removedCustomer, id]);
      prevCustomer.map((e) => {
        if (e.customer.id === id) {
          deleteCust = e.id;
        }
      });
      setDeleteCustomer([...deleteCustomer, deleteCust]);
      // platformApi
      //   .delete(`/api/vehicle/customer/${deleteCustomer}`)
      //   .then((res) => {
      //   });
    }
  };
  const checkRegistrationNumber = (registerNo, id) => {
    platformApi
      .post(`/api/vehicle/checkRegisterNo`, { registerNo, id })
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 400) {
            setError({
              ...error,
              RGN: {
                type: "error",
                message: "Registration Number already exists",
              },
            });
          }
        }
      });
  };

  const checkEngineNumber = (engineNo, id) => {
    platformApi
      .post(`/api/vehicle/checkEngineNo`, { engineNo, id })
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 400) {
            setError({
              ...error,
              ENO: {
                type: "error",
                message: "Engine Number already exists",
              },
            });
          }
        }
      });
  };

  const checkChassisNumber = (chassisNo, manId, id, checkType=false) => {
    platformApi
      .post(`/api/purchaseChallan/frameNumber`, {
        chassisNo,
        manufacturer: manId,
        id,
        checkType
      })
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            const date = new Date(response.data).toLocaleDateString("en-us", {
              month: "short",
              year: "numeric",
            });
            if(response?.otherValues)
            {                
                if(response.otherValues?.engineNo)
                  form.setFieldsValue({
                    engineNo:response.otherValues.engineNo 
                  }); 
                if(response.otherValues?.dateOfSale)
                  form.setFieldsValue({
                    dateOfSale: response.otherValues.dateOfSale? moment(new Date(response.otherValues.dateOfSale)):null
                  });
                if(response.otherValues?.modelCode)
                  form.setFieldsValue({
                    vehicle:response.otherValues.modelCode,
                  });
                if(response.otherValues?.colors)
                  setSelectedColor(response.otherValues?.colors)
            }
            setDOMFlag(moment(response.data));
            setMFGDate(date);
            if (!modify) {
              // message.success("Chassis Number is Valid");
            }
            delete error.CHS;
            setError({ ...error });
          } else if (response.code === 401) {
            const date = new Date(response.data).toLocaleDateString("en-us", {
              month: "short",
              year: "numeric",
            });
            setDOMFlag(moment(response.data));
            setMFGDate(date);
            setError({
              ...error,
              CHS: {
                type: "error",
                message: "Chassis Number already exists",
              },
            });
          } else if (response.code === 400) {
            setError({
              ...error,
              CHS: {
                type: "error",
                message: "Enter Valid Chassis Number",
              },
            });
            setMFGDate(null);
            setDOMFlag(null);
            if (!id) {
              message.error("Enter valid Chassis Number");
            }
          }
        }
      });
  };

  //Converting service days to date
  const frameNo = getFieldValue("chassisNo");
  const engineNo = getFieldValue("engineNo");
  const date = getFieldValue("dateOfSale")
    ? moment(new Date(getFieldValue("dateOfSale"))).format("DD/MM/YYYY")
    : null || null;
  const pdfData =
    "Frame No: " +
    frameNo +
    "\n" +
    "Enigne No: " +
    engineNo +
    "\n" +
    "Date of Sale :" +
    date +
    "\n" +
    "Mobile No: " +
    mobileNumber;
  const generatePDF = new jsPDF("p", "pt", "a4");

  const serviceSchedule = () => {
    const serviceDate = [];
    const vehiceleServiceContent = [];
    for (let i = 0; i < services.length; i++) {
      if (date) {
        serviceDate.push(
          moment(
            moment(date, "DD/MM/YYYY")
              .add(services[i].serviceDays, "d")
              .format("DD/MM/YYYY")
          ) || null
        );
      }
    }
    for (let i = 0; i < services.length; i++) {
      vehiceleServiceContent.push({
        serviceNo: services[i].serviceNo,
        serviceType: services[i].serviceType,
        serviceKm: services[i].serviceKm,
        dateOfService: serviceDate.length > 0 && serviceDate[i]._i,
      });
    }

    // setVehicleService(vehiceleServiceContent)
  };

  const handleTabChange = (activeKey) => {
    if (open) {
      if (activeKey === "3") {
        setTimeout(function () {
          setQrImageURL("");
          document.getElementById("itf").src = "";
          document.getElementById("qrcode").src = "";
          document.getElementById("itf").style.display = "none";
          document.getElementById("qrcode").style.display = "none";
        }, 1000);
      }
      // serviceSchedule();
    }
  };

  //generating content for PDF
  const servicePDFContent = () => {
    const arr = [];
    for (let i = 0; i < services.length; i++) {
      arr.push(`Frame Number:${frameNo}`);
      arr.push(`Engine No:${engineNo}`);
      arr.push(`Date of Sale: ${date}`);
      arr.push(`Mobile Number: ${mobileNumber}`);
      arr.push("\n");
      arr.push("\n");
    }
    return arr;
  };

  //Bar code generator, adding barcode and customer details to PDF

  // const barCodeReader = () => {
  //   JsBarcode("#itf", pdfData, {
  //     format: "CODE128", width: 1,
  //     height: 65,
  //     fontSize: 20,
  //     displayValue: false
  //   });

  //   const barCodeBtn = document.getElementById('itf')
  //   document.getElementById('qrcode').style.display = "none"
  //   if (barCodeBtn.style.display === "none") {
  //     barCodeBtn.style.display = "block"
  //   }

  //   const img = document.querySelector('img#itf');
  //   let yCoordinate = 10;
  //   for (let i = 0; i < services.length; i++) {
  //     generatePDF.addImage(img.src, 'JPEG', 300, yCoordinate, 180, 65)
  //     yCoordinate = yCoordinate + 75
  //   }
  //   generatePDF.setFontSize(11);
  //   const pdfContent = servicePDFContent()
  //   generatePDF.text(40, 25, pdfContent);
  //   generatePDF.save('bar.pdf');
  // }

  const qrCodeReader = async () => {
    let url = "";
    url = await QRCode.toDataURL(pdfData);
    // .then((url) => {
    //   setQrImageURL(url);
    // })
    // .catch((err) => {
    //   console.error(err);
    // });
    if (url != "") {
      setQrImageURL(url);
      const qyCodeBtn = document.getElementById("qrcode");
      document.getElementById("itf").style.display = "none";
      if (qyCodeBtn.style.display === "none") {
        qyCodeBtn.style.display = "block";
      }
      qyCodeBtn.setAttribute("src", url);
      let yCoordinate = 10;
      for (let i = 0; i < services.length; i++) {
        generatePDF.addImage(url, "JPEG", 300, yCoordinate, 65, 65);
        yCoordinate = yCoordinate + 75;
      }

      generatePDF.setFontSize(11);
      const pdfContent = servicePDFContent();
      generatePDF.text(40, 25, pdfContent);
      if (url !== "") {
        generatePDF.save("qr.pdf");
      }
    }
  };
  // console.log("vehicleService", vehicleService)
  const types = [
    { id: "FREE", title: "Free" },
    { id: "PAID", title: "Paid" },
    { id: "BONUS", title: "Bonus" },
  ];

  const column = [
    {
      title: "Service No",
      dataIndex: "serviceNo",
      key: "serviceNo",
      render: (text) => <span disabled="true">{text}</span>,
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      key: "serviceType",
      render: (text, record) => (
        <span>{text}</span>
        // <Select
        //   showAction={["click", "focus"]}
        //   // onChange={key => handleChange(key, record.serviceNo, "serviceType")}
        //   // disabled={!editable}
        //   value={text}
        //   disabled="true"
        // >
        //   {/* {types.map(obj => (
        //     <Select.Option key={obj.id} value={obj.id}>
        //       {obj.title}
        //     </Select.Option>
        //   ))} */}
        // </Select>
      ),
    },
    {
      title: "Service KMs",
      dataIndex: "serviceKms",
      key: "kms",
      render: (text, record) => (
        <Input
          key="kms"
          disabled="true"
          // disabled={!editable}
          // onInput={event => {
          //   handleChange(
          //     parseInt(event.target.value) || 0,
          //     record.serviceNo,
          //     "serviceKm"
          //   );
          // }}
          value={text}
        />
      ),
    },
    {
      title: "Service Date",
      dataIndex: "serviceDate",
      key: "serviceDate",
      render: (text, record) => (
        <span>{text && moment(new Date(text)).format("DD-MM-YYYY")}</span>
        // <Input
        //   key="serviceDate"
        //   // placeholder="Date Of Service"
        //   disabled="true"
        //   // disabled={!editable}
        //   maxLength={4}
        //   // onInput={event => {
        //   //   handleChange(
        //   //     parseInt(event.target.value) || 0,
        //   //     record.serviceNo,
        //   //     "serviceDate"
        //   //   );
        //   // }}
        //   value={text ? moment(new Date(text).format("DD/MM/YYYY")) : "-"}
        // />
      ),
    },
  ];
  const renderPartColumns = () => {
    const columns = [
      {
        title: "Details",
        dataIndex: "partNumber",
        key: "partNumber",
        width: 220,
        render: (record, data) => (
          <Form.Item>
            <span>{`${record?.partNumber} - ${record?.partName}`}</span>
          </Form.Item>
        ),
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        width: 70,
        render: (data, record, index) => (
          <Form.Item>
            <Input
              key="quantity"
              disabled={true}
              placeholder="quantity"
              value={record && record.quantity}
            />
          </Form.Item>
        ),
      },
      {
        title: "Unit rate",
        dataIndex: "unitRate",
        key: "unitRate",
        width: 80,
        render: (data, record, index) => (
          <Form.Item>
            <Input
              key="unitRate"
              disabled={true}
              placeholder="Unit Rate"
              value={record && record.unitRate}
            />
          </Form.Item>
        ),
      },
      {
        title: "Amount",
        dataIndex: "rate",
        key: "rate",
        width: 100,
        render: (data, record) => (
          <Form.Item>
            <Input
              key="rate"
              disabled={true}
              placeholder="Amount"
              value={record && record.rate}
            />
          </Form.Item>
        ),
      },
    ];
    return columns;
  };

  const renderLabourColumns = () => {
    const columns = [
      {
        title: "Details",
        dataIndex: "jobCode",
        key: "jobCode",
        width: 220,
        render: (record, data) => (
          <Form.Item>
            <span>{record?.code}</span>
          </Form.Item>
        ),
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        width: 100,
        render: (data, record, index) => (
          <Form.Item>
            <Input
              key="quantity"
              disabled={true}
              placeholder="quantity"
              value={record && record.quantity}
            />
          </Form.Item>
        ),
      },
      {
        title: "Unit rate",
        dataIndex: "unitRate",
        key: "unitRate",
        width: 100,
        render: (data, record, index) => (
          <Form.Item>
            <Input
              key="unitRate"
              disabled={true}
              placeholder="Unit Rate"
              value={record && record.unitRate}
            />
          </Form.Item>
        ),
      },
      {
        title: "Amount",
        dataIndex: "rate",
        key: "rate",
        width: 120,
        render: (data, record) => (
          <Form.Item>
            <Input
              key="rate"
              disabled={true}
              placeholder="Amount"
              value={record && record.rate}
            />
          </Form.Item>
        ),
      },
    ];
    return columns;
  };
  const historyColumn = [
    {
      render: (data, key) => (
        <div className="box" style={{ backgroundColor: "white" }} key={key}>
          <Row key={key}>
            <Col
              span={9}
              style={{
                borderRight: "0.1px solid rgba(0, 0, 0, 0.1)",
                padding: "1rem",
              }}
            >
              <table>
                <thead>
                  <tr>
                    <td style={{ textAlign: "right", fontWeight: "bolder" }}>
                      Job No
                    </td>
                    <td>:</td>
                    <td style={{ textAlign: "left", paddingLeft: ".5rem" }}>
                      {data.jobNo && data.jobNo}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right", fontWeight: "bolder" }}>
                      Reg. No
                    </td>
                    <td>:</td>
                    <td style={{ textAlign: "left", paddingLeft: ".5rem" }}>
                      {data.vehicle && data.vehicle.registerNo}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right", fontWeight: "bolder" }}>
                      Customer
                    </td>
                    <td>:</td>
                    <td style={{ textAlign: "left", paddingLeft: ".5rem" }}>
                      {data.customer && data.customer.name}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right", fontWeight: "bolder" }}>
                      Model
                    </td>
                    <td>:</td>
                    <td style={{ textAlign: "left", paddingLeft: ".5rem" }}>
                      {data.vehicle && data.vehicle.vehicle.modelName}
                    </td>
                  </tr>
                </thead>
              </table>
            </Col>
            {/* <Col span={5} style={{ borderRight: '0.1px solid rgba(0, 0, 0, 0.1)', padding: '1rem' }}>
              <div>
                <img style={{ width: '150px', height: '120px' }} src={data.vehicle?.color && data.vehicle.color ? data.vehicle.color.url : vehicleLogo} />
              </div>
            </Col> */}
            <Col span={15}>
              <div style={{ margin: "1rem" }}>
                <Row>
                  <Col span={12}>
                    <table>
                      <thead>
                        <tr>
                          <td
                            style={{ textAlign: "right", fontWeight: "bolder" }}
                          >
                            Service Type
                          </td>
                          <td>:</td>
                          <td
                            style={{ textAlign: "left", paddingLeft: ".5rem" }}
                          >
                            {data.serviceType && data.serviceType}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ textAlign: "right", fontWeight: "bolder" }}
                          >
                            Mechanic
                          </td>
                          <td>:</td>
                          <td
                            style={{ textAlign: "left", paddingLeft: ".5rem" }}
                          >
                            {data.mechanic
                              ? data.mechanic.profile.employeeName
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ textAlign: "right", fontWeight: "bolder" }}
                          >
                            Supervisor
                          </td>
                          <td>:</td>
                          <td
                            style={{ textAlign: "left", paddingLeft: ".5rem" }}
                          >
                            {data.supervisor ? data.supervisor.name : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ textAlign: "right", fontWeight: "bolder" }}
                          >
                            Service Number
                          </td>
                          <td>:</td>
                          <td
                            style={{ textAlign: "left", paddingLeft: ".5rem" }}
                          >
                            {data.serviceNo ? data.serviceNo : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ textAlign: "right", fontWeight: "bolder" }}
                          >
                            Kms
                          </td>
                          <td>:</td>
                          <td
                            style={{ textAlign: "left", paddingLeft: ".5rem" }}
                          >
                            {data.kms ? data.kms : "-"}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </Col>
                  <Col span={12}>
                    <table>
                      <thead>
                        <tr>
                          <td
                            style={{ textAlign: "right", fontWeight: "bolder" }}
                          >
                            Date
                          </td>
                          <td>:</td>
                          <td
                            style={{ textAlign: "left", paddingLeft: ".5rem" }}
                          >
                            {data.createdAt &&
                              moment(new Date(data.createdAt)).format(
                                "DD-MM-YYYY"
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ textAlign: "right", fontWeight: "bolder" }}
                          >
                            Time
                          </td>
                          <td>:</td>
                          <td
                            style={{ textAlign: "left", paddingLeft: ".5rem" }}
                          >
                            {data.createdAt &&
                              new Date(data.createdAt).toLocaleTimeString()}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ textAlign: "right", fontWeight: "bolder" }}
                          >
                            Status
                          </td>
                          <td>:</td>
                          <td
                            style={{
                              textAlign: "left",
                              fontWeight: "bolder",
                              paddingLeft: ".5rem",
                            }}
                          >
                            {data.jobStatus && data.jobStatus}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ textAlign: "right", fontWeight: "bolder" }}
                          >
                            Pdf
                          </td>
                          <td>:</td>
                          <td
                            style={{
                              textAlign: "left",
                              fontWeight: "bolder",
                              paddingLeft: ".5rem",
                            }}
                          >
                            <a
                              className="linkylink"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`/api/jobOrder/generatePDF/${data.id}`}
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                            >
                              Download PDF
                            </a>
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <h3 style={{ textAlign: "right", fontWeight: "bolder", marginRight: "2rem" }}>
              Total Invoice: &nbsp;
              {invoiceData.map((value, index) => {
                if (value.jobOrder.jobNo === data?.jobNo) {
                  return (
                    <span
                      style={{ textAlign: "right", fontWeight: "bolder" }}
                      key={index}
                      name={index}
                    >
                      {value.totalInvoice} /-
                    </span>
                  );
                }
              })}
            </h3>
          </div>

          <Collapse 
              className="site-collapse-custom-collapse" 
              bordered={false}
               
              onChange={callback} 
          >
          <Panel className="site-collapse-custom-panel" header="Details" key="1" >
          <p>
          <Tabs defaultActiveKey="1" style={{ width: "100%" }}>
            <TabPane tab="Parts Details" key="1">
              <Table
                // scroll={{ y: 200 }}
                pagination={false}
                columns={renderPartColumns()}
                style={{ cursor: "pointer" }}
                // tableLayout="fixed"
                dataSource={partData.filter((val) => {
                  return val.jobNo === data?.jobNo;
                })}
              />
            </TabPane>
            <TabPane tab="Labour Detail" key="2">
              <Table
                // scroll={{ y: 200 }}
                pagination={false}
                columns={renderLabourColumns()}
                style={{ cursor: "pointer" }}
                // tableLayout="fixed"
                dataSource={labourData.filter((code) => {
                  return code?.jobNo === data?.jobNo;
                })}
              />
            </TabPane>
          </Tabs>
          </p>
          </Panel>
          </Collapse>
          {/* <Tabs defaultActiveKey="1" style={{ width: "100%" }}>
            <TabPane tab="Parts Details" key="1">
              <Table
                // scroll={{ y: 200 }}
                pagination={false}
                columns={renderPartColumns()}
                style={{ cursor: "pointer" }}
                // tableLayout="fixed"
                dataSource={partData.filter((val) => {
                  return val.jobNo === data?.jobNo;
                })}
              />
            </TabPane>
            <TabPane tab="Labour Detail" key="2">
              <Table
                // scroll={{ y: 200 }}
                pagination={false}
                columns={renderLabourColumns()}
                style={{ cursor: "pointer" }}
                // tableLayout="fixed"
                dataSource={labourData.filter((code) => {
                  return code?.jobNo === data?.jobNo;
                })}
              />
            </TabPane>
          </Tabs> */}
        </div>
      ),
    },
  ];

                     
  //regenerating service date by 30 days
  const regenerateServiceDates = async () => {
    console.log("regenerating date but clicked!!");

    let serviceDate = [];
    let vehiceleServiceContent = [];

    const DateofSale = getFieldValue("dateOfSale")
      ? moment(new Date(getFieldValue("dateOfSale"))).format("DD/MM/YYYY")
      : null || null;

    await platformApi
      .get(`/api/vehicleMaster/manAll/${getFieldValue("manufacturer")}`)
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setVehicle(response.data);
            response.data.some((vehicle) => {
              if (vehicle.id === getFieldValue("vehicle")) {
                for (let i = 0; i < vehicle.services.length; i++) {
                  if (DateofSale) {
                    serviceDate.push(
                      moment(
                        moment(DateofSale, "DD/MM/YYYY").add(
                          vehicle.services[i].serviceDays,
                          "days"
                        )
                      ) || null
                    );
                  }
                }
                for (let i = 0; i < vehicle.services.length; i++) {
                  vehiceleServiceContent.push({
                    serviceNo: vehicle.services[i].serviceNo,
                    serviceType: vehicle.services[i].serviceType,
                    serviceKms: vehicle.services[i].serviceKm,
                    serviceDate: serviceDate.length > 0 && serviceDate[i],
                  });
                }
              }
            });
          }
        }
      });

    setVehicleService(vehiceleServiceContent);
  };

  return (
    <div>
      <Modal
        wrapClassName="vehicle-form"
        visible={open}
        title={<Title level={4}>Vehicle</Title>}
        onCancel={() => {
          clearFields();
          close();
        }}
        okButtonProps={{ disabled: (!editable && !modify) || uploading }}
        okText={editable ? "Save" : "Modify"}
        onOk={() => (
          editable ? handleSubmit() : setEditable(true), setEdit(true)
        )}
      >
        <Tabs defaultActiveKey="1" onChange={handleTabChange}>
          <TabPane tab={<span>Vehicle Details</span>} key="1">
            <Form>
              <Row type="flex" justify="space-between">
                <Col span={11}>
                  <Item colon={false} label="Manufacturer Name">
                    {getFieldDecorator("manufacturer", {
                      rules: [
                        { required: true, message: "Select Manufacturer" },
                      ],
                    })(
                      <Select
                        showAction={["click", "focus"]}
                        disabled={!editable}
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onSelect={handleManufacturerChange}
                        placeholder="Manufacturer name"
                      >
                        {manufacturers.map((manufacturer) => (
                          <Option key={manufacturer.id} value={manufacturer.id}>
                            {manufacturer.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Item>
                </Col>
                <Col span={11}>
                  <Item colon={false} label="Model">
                    {getFieldDecorator("vehicle", {
                      rules: [{ required: true, message: "Select Model" }],
                    })(
                      <Select
                        disabled={!editable || vehicles.length == 0}
                        onChange={handleModelChange}
                        showSearch
                        showAction={["click", "focus"]}
                        placeholder="Select a Model"
                        filterOption={(input, option) =>
                          option.props.children.some(
                            (child) =>
                              child
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                          )
                        }
                      >
                        {vehicles.map((vehicle) => (
                          <Option key={vehicle.id} value={vehicle.id}>
                            {vehicle.modelCode} - {vehicle.modelName}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Item>
                </Col>
              </Row>
              <Row type="flex" justify="space-between">
                <Col span={11}>
                  <Item name="Type" colon={false} label="Category">
                    {getFieldDecorator("category")(
                      <Select placeholder="Category" disabled>
                        <Option value="MOTORCYCLE" key="MOTORCYCLE">
                          Motorcycle
                        </Option>
                        <Option value="SCOOTER" key="SCOOTER">
                          Scooter
                        </Option>
                      </Select>
                    )}
                  </Item>
                </Col>
                <Col span={4}>
                  <Item
                    colon={false}
                    label={
                      <Text style={{ fontSize: "15px", fontWeight: "bold" }}>
                        Select Color
                      </Text>
                    }
                  >
                    {getFieldDecorator("color", {
                      rules: [{ required: false, message: "Select Color" }],
                    })(
                      <div>
                        {selectedColor ? (
                          <div>
                            <img
                              style={{ height: "80px" }}
                              src={selectedColor.url}
                            />
                            <Tag>
                              {selectedColor.code !== undefined ? (
                                <Text>
                                  {selectedColor.code +
                                    " - " +
                                    selectedColor.color}
                                </Text>
                              ) : (
                                <Text>No Color Chosen</Text>
                              )}
                            </Tag>
                          </div>
                        ) : (
                          <Tag>
                            <Text>No Color Chosen</Text>
                          </Tag>
                        )}
                      </div>
                    )}
                  </Item>
                </Col>
                <Col span={5}>
                  <Button
                    disabled={!editable || colors.length === 0}
                    onClick={() => setColorModal(true)}
                    style={{ marginTop: "6.4vh" }}
                    type="primary"
                  >
                    Select Color
                  </Button>
                </Col>
              </Row>
              <Row type="flex" justify="space-between">
                <Col span={11}>
                  <Item
                    name="Chassis Number"
                    colon={false}
                    label="Chassis Number"
                    validateStatus={error.CHS && error.CHS.type}
                    help={error.CHS && error.CHS.message}
                  >
                    {getFieldDecorator("chassisNo", {
                      rules: [
                        { required: true, message: "Enter Chassis Number" },
                      ],
                    })(
                      <Input
                        // style={{ textTransform: "uppercase" }}
                        disabled={!editable || !MFGId}
                        placeholder="Chassis Number"
                        maxLength={17}
                        pattern="^[A-Z0-9]{17}$"
                        onKeyUp={(e) => {
                          form.setFieldsValue({
                            chassisNo: e.target.value.toUpperCase(),
                          });
                        }}
                        onInput={(event) => {
                          if (event.target.value.length === 17) {
                            checkChassisNumber(
                              event.target.value,
                              MFGId,
                              data ? data.id : null
                            );
                          } else {
                            if (!event.target.checkValidity()) {
                              setError({
                                ...error,
                                CHS: {
                                  type: "error",
                                  message: "Enter Valid Chassis Number",
                                },
                              });
                            } else {
                              delete error.CHS;
                              setError(error);
                            }
                          }
                        }}
                      />
                    )}
                  </Item>
                </Col>
                <Col span={11}>
                  <Row gutter={10}>
                    <Col span={9}>
                      <Item
                        name="MFG Month"
                        colon={false}
                        label="MFG [MM-YYYY]"
                      >
                        <DatePicker.MonthPicker
                          style={{ width: "100%" }}
                          format="MMM-YYYY"
                          disabled
                          placeholder="MM-YYYY"
                          value={MFGDate ? moment(MFGDate, "MMM-YYYY") : null}
                        />
                      </Item>
                    </Col>
                    <Col span={15}>
                      <Item
                        name="Date of Sale"
                        colon={false}
                        label="Date of Sale"
                      >
                        {getFieldDecorator("dateOfSale", {
                          rules: [
                            { required: false, message: "Select Date of Sale" },
                          ],
                        })(
                          <DatePicker
                            disabledDate={(moment) =>
                              moment._d > Date.now() || moment._d < DOMFlag._d
                            }
                            format="DD/MM/YYYY"
                            disabled={!editable || !DOMFlag}
                          />
                        )}
                      </Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" justify="space-between">
                <Col span={11}>
                  <Item
                    name="Engine Number"
                    colon={false}
                    label="Engine Number"
                    validateStatus={error.ENO && error.ENO.type}
                    help={error.ENO && error.ENO.message}
                  >
                    {getFieldDecorator("engineNo", {
                      rules: [
                        { required: false, message: "Enter Engine Number" },
                      ],
                    })(
                      <Input
                        // style={{ textTransform: "uppercase" }}
                        disabled={!editable}
                        placeholder="Engine Number"
                        minLength={5}
                        maxLength={25}
                        onKeyUp={(e) => {
                          form.setFieldsValue({
                            engineNo: e.target.value.toUpperCase(),
                          });
                        }}
                        pattern="^[a-zA-Z0-9]+(([a-zA-Z])?[a-zA-Z]*)*$"
                        onInput={(event) => {
                          if (event.target.value.length > 7) {
                            checkEngineNumber(
                              event.target.value,
                              data ? data.id : null
                            );
                          }
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              ENO: {
                                type: "error",
                                message: "Enter Valid Engine Number",
                              },
                            });
                          } else {
                            delete error.ENO;
                            setError(error);
                          }
                        }}
                      />
                    )}
                  </Item>
                </Col>
                <Col span={11}>
                  <Item
                    name="Registration Number"
                    colon={false}
                    label="Registration Number"
                    validateStatus={error.RGN && error.RGN.type}
                    help={error.RGN && error.RGN.message}
                  >
                    {getFieldDecorator("registerNo", {
                      rules: [
                        {
                          required: false,
                          message: "Enter Registration Number",
                        },
                      ],
                    })(
                      <Input
                        // style={{ textTransform: "uppercase" }}
                        disabled={!editable}
                        placeholder="Registration Number"
                        maxLength={10}
                        minLength={7}
                        onKeyUp={(e) => {
                          form.setFieldsValue({
                            registerNo: e.target.value.toUpperCase(),
                          });
                        }}
                        pattern="^[A-Za-z0-9]+(([A-Za-z ])?[A-Za-z]*)*$"
                        onInput={(event) => {
                          if (event.target.value.length > 6) {
                            checkRegistrationNumber(
                              event.target.value,
                              data ? data.id : null
                            );
                          }
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              RGN: {
                                type: "error",
                                message: "Enter Valid Registration Number",
                              },
                            });
                          } else {
                            delete error.RGN;
                            setError(error);
                          }
                        }}
                      />
                    )}
                  </Item>
                </Col>
              </Row>
              <Row type="flex" justify="space-between">
                <Col span={7}>
                  <Item colon={false} label="Vehicle Type">
                    {getFieldDecorator("vehicleType", {
                      rules: [
                        { required: true, message: "Select Vehicle Type" },
                      ],
                    })(
                      <Select
                        showAction={["click", "focus"]}
                        disabled={!editable}
                        showSearch={true}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        // onChange={handleManufacturerChange}
                        placeholder="Vehicle Type"
                      >
                        {VehicleTypes.map((type) => (
                          <Option key={type} value={type}>
                            {type}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Item>
                </Col>
                <Col span={7}>
                  <Item colon={false} label="Service Coupon Number">
                    {getFieldDecorator("serviceCouponNumber")(
                      <Input
                        disabled={!editable}
                        placeholder="Service Coupon Number"
                        maxLength={20}
                      ></Input>
                    )}
                  </Item>
                </Col>
                <Col span={7}>
                  <Item colon={false} label="Vehicle Activate">
                    {getFieldDecorator("active")(
                      <Input
                        disabled={true}
                        placeholder="Vehicle status"
                        maxLength={20}
                      ></Input>
                    )}
                  </Item>
                </Col>
              </Row>
              <Row type="flex" justify="space-between">
                <Col span={22}>
                  <Item
                    name="Customer Associated"
                    colon={false}
                    label="Customer Associated"
                  >
                    {getFieldDecorator("customer", {
                      rules: [{ required: false, message: "Select Customer" }],
                    })(
                      <Select
                        mode="multiple"
                        showAction={["click", "focus"]}
                        showSearch
                        onDeselect={(e) => {
                          onCustomerChange(e);
                        }}
                        filterOption={null}
                        onSearch={(e) => {
                          const search = {
                            module: "customers",
                            column: "phone",
                            searchString: e,
                            fields: ["contacts{phone}"],
                            searchColumns: ["contacts"],
                            setValue: setCustomer,
                          };
                          dropdownSearch(search);
                        }}
                        notFoundContent={
                          <Button
                            type="primary"
                            style={{ width: "100%" }}
                            onClick={() => setModalOpen(true)}
                          >
                            Add Customer
                          </Button>
                        }
                        disabled={!editable}
                        placeholder="Customer Associated"
                      >
                        {customers.map(
                          (customer) =>
                            customer.contacts && (
                              <Option key={customer.id} value={customer.id}>
                                {customer.name}
                                {customer.name &&
                                  customer.contacts &&
                                  customer.contacts[0]
                                  ? " - "
                                  : ""}{" "}
                                {customer.contacts && customer.contacts[0]
                                  ? customer.contacts[0].phone
                                  : ""}
                              </Option>
                            )
                        )}
                      </Select>
                    )}
                  </Item>
                </Col>
                {/* {editable && !data ? <Col span={11}>
                  <Item
                    name="Adding Insurance"
                    colon={false}
                    label="Adding Insurance"
                  >
                    <Button type='primary' onClick={() => { setInsModal(true) }} ghost>Add Data</Button>
                    <WrappedInsuranceForm
                      setData={setInsuranceData}
                      insuranceData={insuranceData}
                      allData={data}
                      dataSource={dataSource}
                      setDataSource={setDataSource}
                      insModal={insModal}
                      setInsModal={setInsModal}
                      formData={formData}
                      editable={editable}
                      modifyData={data&&data.insurance}
                    />
                  </Item>
                </Col> : <span></span>
                } */}
              </Row>
            </Form>
          </TabPane>
          {
          (data? (fileData && insuranceData ):
            (getFieldValue("manufacturer") && getFieldValue("vehicle")) ) ? ( 
            <TabPane tab={<span>Associated Documents</span>} key="2">
              {data ? (
                <Files
                  data={data && fileData}
                  insurance={data && insuranceData}
                  removedData={removedData}
                  setRemovedData={setRemovedData}
                  editable={editable}
                />
              ) : null}

              <WrappedInsuranceForm
                setData={setInsuranceData}
                insuranceData={insuranceData}
                dataSource={dataSource}
                setDataSource={setDataSource}
                insModal={insModal}
                setInsModal={setInsModal}
                removedData={removedData}
                setRemovedData={setRemovedData}
                setUploading={setUploading}
                uploading={uploading}
                formData={formData}
                editable={editable}
                modifyData={data && insuranceData}
              />

            <div style={{ marginTop: "10px" }}>
              <h3>Vehicle e-Receipt:</h3>
                <div style={{ marginTop: "10px" }}>
                {pdfUrl ? (
                <Button type="primary">
                  <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                    View e-Receipt
                  </a>
                </Button>
                ) : (
                      <div style={{ marginTop: "5px" }}>
                      <Empty />
                      </div>
                )}
              </div>
            </div>

              {/* {editable ? <Col span={24}>
              <Item
                style={{ float: "right" }}
                name="Adding Insurance"
                colon={false}
                label="Adding Insurance"
              >
                <Button style={{ float: "right", marginRight: '1rem' }} type='primary' onClick={() => { setInsModal(true) }} ghost>Add Data</Button>
                <WrappedInsuranceForm
                  setData={setInsuranceData}
                  insuranceData={insuranceData}
                  dataSource={dataSource}
                  setDataSource={setDataSource}
                  insModal={insModal}
                  setInsModal={setInsModal}
                  formData={formData}
                  editable={editable}
                  modifyData={data&&data.insurance}
                />
              </Item>
            </Col> : <span></span>
            } */}
            </TabPane>
          ) : null}

          {data && vehicleService ? (
            <TabPane tab={<span>Service Schedule</span>} key="3">
              <Table
                style={{ marginBottom: "1rem" }}
                rowKey={(record) => record.id}
                columns={column}
                dataSource={vehicleService}
                pagination={false}
              />
              <div>
                <img
                  src=""
                  id="itf"
                  style={{
                    display: "none",
                    width: "100%",
                    marginBottom: "16px",
                  }}
                />
                <img
                  src=""
                  id="qrcode"
                  style={{ display: "none", margin: "auto" }}
                />
              </div>
              <canvas id="canvas" style={{ display: "none" }}></canvas>
              <Button style={{ float: "right" }} onClick={() => qrCodeReader()}>
                OR Code
              </Button>
              <Button
                style={{ float: "right", marginRight: "1rem" }}
                onClick={() => regenerateServiceDates()}
              >
                Re-Generate
              </Button>
            </TabPane>
          ) : null}

          {historyData.length ? (
            <TabPane tab={<span>Job Order History</span>} key="4">
              <Table
                pagination={false}
                columns={historyColumn}
                tableLayout="auto"
                style={{ cursor: "pointer" }}
                dataSource={historyData}
              />
            </TabPane>
          ) : null}
        </Tabs>
        <ColorSelection
          open={colorModal}
          close={() => setColorModal(false)}
          color={colors}
          selectedColor={setSelectedColor}
        />
      </Modal>
      {/* <AddCustomerModal
        data={selected}
        setData={setSelected}
        emitData={(data) => addCustomerData(data)}
        open={modalOpen}
        close={() => {
          setSelected(null);
          setModalOpen(false);
        }}
        editable={true}
        cusId={cusId}
        setCusId={setCusId}
      /> */}
      <WrappedCustomerAddModal
        data={selected}
        setData={setSelected}
        emitData={(data) => addCustomerData(data)}
        open={modalOpen}
        close={() => {
          setSelected(null);
          setModalOpen(false);
          // setpFlag(false);
        }}
        editable={true}
        cusId={cusId}
        setCusId={setCusId}
      />
    </div>
  );
};

const WrappedVehicleForm = Form.create()(VehicleForm);

export default WrappedVehicleForm;
