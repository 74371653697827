import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Table,
  Tabs,
  Radio,
  Upload,
  Button,
  message,
  Typography,
  Input,
  Dropdown,
  Menu,
  Icon,
  Col,
  Form,
  DatePicker,
  Tag,
} from 'antd';
import moment from "moment";
import { InboxOutlined } from '@ant-design/icons';
import { Card } from 'react-bootstrap';
import { platformApi } from '../../../api';
import './insuranceRenewalReminder.less';

const date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
firstDay = firstDay.toISOString();
lastDay = lastDay.toISOString();

const { Title } = Typography;
const { TabPane } = Tabs;
const { Dragger } = Upload;
const { Search } = Input;
const { Item } = Menu;
const { MonthPicker } = DatePicker;

const columns = [
  {
    title: 'S.No',
    dataIndex: 'serialNo',
    key: 'serialNo',
  },
  {
    title: 'Customer Name',
    dataIndex: 'customerName',
    key: 'customerName',
  },
  {
    title: 'Customer Phone Number',
    dataIndex: 'customerPhoneNumber',
    key: 'customerPhoneNumber',
  },
  {
    title: 'Registration Number',
    dataIndex: 'vehicleNumber',
    key: 'vehicleNumber',
  },
  {
    title: 'Renewable Date',
    dataIndex: 'renewingDate',
    key: 'renewingDate',
  },
  {
    title: 'Renewal Type',
    dataIndex: 'typeOfRenewal',
    key: 'typeOfRenewal',
  },
];

const uploadHistoryColumns = [
  {
    title: 'S.No',
    dataIndex: 'sno',
    key: 'sno',
  },
  {
    title: 'Filename',
    dataIndex: 'filename',
    key: 'filename',
  },
  {
    title: 'Upload Time',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: (text) => text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status) => {
      const color = status === 'COMPLETED' ? 'green' : 'orange';
      return <Tag color={color}>{status.toUpperCase()}</Tag>;
    }
  },
];

const InsuranceReminder = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialDateParam = queryParams.get('date');
  const initialDate = initialDateParam ? moment(initialDateParam) : moment(new Date());
  const initialPageParam = queryParams.get('page');
  const initialPage = initialPageParam ? parseInt(initialPageParam, 10) : 1;

  const [fileList, setFileList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(initialPage);
  const [count, setCount] = useState(0);
  const [firstDate, setFirstDate] = useState(firstDay);
  const [lastDate, setLastDate] = useState(lastDay);
  const [insuranceData, setInsuranceData] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [selectDate, setSelectDate] = useState(initialDate);
  const [activeKey, setActiveKey] = useState("1");
  const [activeTab, setActiveTab] = useState(initialDate.format("YYYY-MM"));
  const [customTab, setCustomTab] = useState(null);

  const [uploadHistoryData, setUploadHistoryData] = useState([]);
  const [uploadPage, setUploadPage] = useState(1);
  const [uploadLimit, setUploadLimit] = useState(10);
  const [uploadCount, setUploadCount] = useState(0);

  const generateDefaultTabs = () => {
    const tabs = [];
    const currentMonth = moment().startOf('month');
    for (let i = 0; i < 12; i++) {
      const tabMoment = currentMonth.clone().add(i, 'months');
      tabs.push({
        key: tabMoment.format("YYYY-MM"),
        label: tabMoment.format("MMM-YYYY")
      });
    }
    return tabs;
  };

  const getTabList = () => {
    const defaultTabs = generateDefaultTabs();
    const tabs = [...defaultTabs];
    if (customTab) {
      tabs.unshift(customTab);
    }
    return tabs.slice(0, 13);
  };

  const onTabChange = (key) => {
    setActiveTab(key);
    const newDate = moment(key, "YYYY-MM").startOf('month');
    setSelectDate(newDate);
    setPage(1);
  };

  useEffect(() => {
    if (!selectDate) return setSelectDate(moment(new Date()));

    const month = selectDate.month();
    const year = selectDate.year();
    let firstDay = new Date(year, month, 1);
    let lastDay = new Date(year, month + 1, 0);
    firstDay = firstDay.toISOString();
    lastDay = lastDay.toISOString();
    setFirstDate(firstDay);
    setLastDate(lastDay);

    const tabKey = selectDate.format("YYYY-MM");
    setActiveTab(tabKey);

    const currentMonth = moment().startOf('month');
    const defaultEndMonth = currentMonth.clone().add(11, 'months');
    const isInDefaultRange = selectDate.isBetween(
      currentMonth,
      defaultEndMonth,
      'month',
      '[]'
    );

    if (!isInDefaultRange) {
      setCustomTab({
        key: tabKey,
        label: selectDate.format("MMM-YYYY")
      });
    }

    const params = new URLSearchParams(location.search);
    params.set('date', selectDate.format('YYYY-MM-DD'));
    history.push({ search: params.toString() });
  }, [selectDate, history, location.search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set('page', page);
    history.push({ search: params.toString() });
  }, [page, history, location.search]);

  const disabledDate = (current) => {
    const nextMonth = moment(new Date()).add(1, "month");
    const currentDate = new Date().getDate();
    if (currentDate >= 25) {
      return current && current > nextMonth.endOf("month");
    }
    return current && current > nextMonth.startOf("month");
  };

  useEffect(() => {
    const fetchInsuranceData = async () => {
      setSpinning(true);
      try {
        const response = await platformApi.post('/api/insurance/other/getInsuranceRenewal', {
          page: page,
          size: limit,
          searchString: search ?? "",
          fromDate: firstDate,
          toDate: lastDate
        });
        const res = response.data.response;
        if (res.code === 200) {
          const insuranceArray = res.data?.insurance;
          setCount(res.data?.count);
          const transformedData = insuranceArray.map((item, index) => {
            const customerInfo = item?.customer && item.customer;
            const customerName = customerInfo ? customerInfo.name : '-';
            const customerPhoneNumber =
              customerInfo && customerInfo.contacts && customerInfo.contacts.length > 0
                ? customerInfo.contacts[0].phone
                : '-';
            const vehicleNumber = item?.registerNo || '-';
            const renewingDate = item.expiryDate ? new Date(item.expiryDate).toLocaleDateString() : '-';
            const typeOfRenewal = item.expiryType || '-';
            const vehicleModel = item.vehicleModel;
            const customerDetails = item.customer;
            const createdDate = item.regDate;
            return {
              key: item.id,
              serialNo: (page - 1) * limit + index + 1,
              customerName,
              customerPhoneNumber,
              vehicleNumber,
              renewingDate,
              typeOfRenewal,
              vehicleModel,
              customerDetails,
              createdDate
            };
          });
          setInsuranceData(transformedData);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSpinning(false);
      }
    };
    if (activeKey === "1") {
      fetchInsuranceData();
    }
  }, [page, limit, search, firstDate, lastDate, activeKey]);

  useEffect(() => {
    if (activeKey === "2") {
      fetchUploadHistory();
    }
  }, [activeKey, uploadPage, uploadLimit]);

  useEffect(() => {
    let pollingInterval;
    if (activeKey === "2") {
      pollingInterval = setInterval(() => {
        fetchUploadHistory();
      }, 10000);
    }
    return () => {
      if (pollingInterval) clearInterval(pollingInterval);
    };
  }, [activeKey, uploadPage, uploadLimit]);

  const fetchUploadHistory = async () => {
    try {
      setSpinning(true);
      const offset = (uploadPage - 1) * uploadLimit;
      const response = await platformApi.get(`/api/insurance/other/uploadHistory?limit=${uploadLimit}&offset=${offset}`);
      if (response.data.response.code === 200) {
        const { data: items, total } = response.data.response;
        setUploadCount(total || items.length);
        const transformedUploadHistoryData = items.map((item, index) => ({
          key: item.id,
          sno: offset + index + 1,
          filename: item.filename,
          timestamp: item.createdAt ? item.createdAt : '-',
          status: item.status,
        }));
        setUploadHistoryData(transformedUploadHistoryData);
      } else {
        message.error('Failed to fetch upload history.');
      }
    } catch (error) {
      console.error(error);
      message.error('Failed to fetch upload history.');
    } finally {
      setSpinning(false);
    }
  };

  const handleUpload = ({ file, fileList }) => {
    const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel';
    if (!isExcel) {
      message.error('You can only upload Excel files!');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error('File must be smaller than 5MB!');
    }
    setFileList([file]);
  };

  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    let menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item
        onClick={() => {
          setLimit(menu);
          setPage(1);
        }}
        key={menu}
      >
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;

  const handleSubmit = async () => {
    if (fileList.length > 0) {
      const formData = new FormData();
      formData.append('xlsxFile', fileList[0]);
      try {
        const response = await platformApi.post('api/insurance/other/upsertInsurance', formData);
        if (response.status === 200) {
          message.success('File submitted successfully!');
          setFileList([]);
        } else {
          message.error('File submission failed.');
        }
      } catch (error) {
        message.error('File submission failed.');
      }
    } else {
      message.error('Please upload a file before submitting.');
    }
  };

  const draggerProps = {
    name: 'file',
    multiple: false,
    fileList,
    onChange: handleUpload,
    beforeUpload: () => false,
  };

  return (
    <>
      <div className="page-head">
        <Title style={{ width: '60%', marginTop: '0.9rem' }} level={4}>
          Other Insurance Renewal [{count}]
          <span style={{ margin: '.5rem' }}>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button>
                {limit} &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <div style={{ width: "30%" }}>
          <Col span={10}>
            <MonthPicker
              defaultValue={selectDate}
              value={selectDate}
              onChange={(e) => {
                if (e) {
                  setSelectDate(e);
                  setPage(1);
                }
              }}
              placeholder="Select month"
            />
          </Col>
        </div>
        <Search
          style={{ width: '30%' }}
          onSearch={(value) => {
            setPage(1);
            setSearch(value);
          }}
          onChange={(event) => {
            if (event.target.value === '') {
              setSearch(null);
              setPage(1);
            }
          }}
          placeholder="Search Vehicle"
        />
      </div>

      <Tabs activeKey={activeKey} onChange={setActiveKey}>
        <TabPane tab="View Data" key="1">
          <div style={{
            width: '100%',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            marginBottom: 16,
            paddingBottom: 8
          }}>
            <Radio.Group
              value={activeTab}
              onChange={(e) => onTabChange(e.target.value)}
              optionType="button"
              buttonStyle="solid"
              style={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
              }}
            >
              {getTabList().map((tab, index, arr) => (
                <Radio.Button
                  key={tab.key}
                  value={tab.key}
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '8px 16px',
                    marginRight: 0,
                    borderRadius: 0,
                    borderRight: index !== arr.length - 1 ? 'none' : undefined,
                  }}
                >
                  {tab.label}
                </Radio.Button>
              ))}

            </Radio.Group>
          </div>
          <Table
            dataSource={insuranceData}
            columns={columns}
            loading={spinning}
            pagination={{
              onChange: (page) => setPage(page),
              pageSize: limit,
              defaultCurrent: 1,
              total: count,
              current: page,
              showQuickJumper: true,
            }}
            onRow={(record) => ({
              onClick: () => {
                localStorage.setItem("selectedRecord", JSON.stringify(record));
                history.push({
                  pathname: `/autoadmin/other_insurance_renewal_reminder/${record.customerPhoneNumber}`
                });
              },
            })}
          />
        </TabPane>
        <TabPane tab="Upload Files" key="2">
          <Card className="vehicle-upload-card" bordered={false}>
            <Title level={4} className="upload-title">
              Vehicle Insurance Details Upload
            </Title>
            <Dragger {...draggerProps} className="upload-dragger">
              <>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag Excel file to this area
                </p>
                <p className="ant-upload-hint">
                  Upload a vehicle detail Excel file (Max 5MB)
                </p>
              </>
            </Dragger>
            <Button type="primary" onClick={handleSubmit} style={{ marginTop: 16 }}>
              Submit
            </Button>
            <div style={{ marginTop: 32 }}>
              <Title level={4}>Upload History</Title>
              <Table
                dataSource={uploadHistoryData}
                columns={uploadHistoryColumns}
                loading={spinning}
                pagination={{
                  current: uploadPage,
                  pageSize: uploadLimit,
                  total: uploadCount,
                  showQuickJumper: true,
                  onChange: (page, pageSize) => {
                    setUploadPage(page);
                    setUploadLimit(pageSize);
                  },
                }}
                size="middle"
              />
            </div>
          </Card>
        </TabPane>
      </Tabs>
    </>
  );
};

const WrappedInsuranceRenewalReminder = Form.create("insurance_renewal_reminder")(InsuranceReminder);
export default WrappedInsuranceRenewalReminder;