import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { ContextAPI } from "../../../ContextAPI";
import {
  Typography,
  Input,
  Button,
  Dropdown,
  Menu,
  Icon,
  message,
  Result,
} from 'antd';

import { platformApi } from '../../../api';

import MaterialIssueTable from './MaterialIssueTable';
// import WrappedAccessoriesModal from './MaterialIssueForm';
import WrappedMaterialForm from './MaterialsForm';
import { setConfig } from 'react-hot-loader';

const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;

export default function MaterialIssues() {
  const history = useHistory();

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [editable, setEditable] = useState(false);
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [dataSource, setData] = useState([]);

  const [redirect, setRedirect] = useState(false);
  const [selected, setSelected] = useState(null);
  const [spinning, setSpinning] = useState(false);

  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const { loginCredintials } = useContext(ContextAPI)
  const menu = (
    <Menu>
      <Item onClick={() => setLimit(5)} key="1">
        5
      </Item>
      <Item onClick={() => setLimit(10)} key="2">
        10
      </Item>
      <Item onClick={() => setLimit(15)} key="3">
        15
      </Item>
      <Item onClick={() => setLimit(20)} key="4">
        20
      </Item>
    </Menu>
  );

  useEffect(() => {
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === 'material_issue') {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      })
  }, []);
  useEffect(() => {
    setPrimaryData(search);
  }, [page, limit, search]);

  const setPrimaryData = (search, val) => {
    setSpinning(true);
    platformApi
      .post('/api/materialIssue/get', {
        page: val ? val : page,
        size: limit,
        searchString: search,
      })
      .then((res) => {
        setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setData(response.data.materialIssue);
            setCount(response.data.count)
          } else {
            message.error('Unable to fetch MaterialIssues', 2);
          }
        } else {
          message.error('Unable to fetch MaterialIssues', 2);
        }
      })
      .catch((err) => {
        setSpinning(false);
        console.error('Error on MaterilIssue : ', err);
        message.error('Unable to fetch MaterialaIssue', 2);
      });

  }

  const addData = (materialIssue) => {
    setSpinning(true);
    if (materialIssue.id) {
      platformApi
        .put(`/api/materialIssue/${materialIssue.id}`, materialIssue)
        .then((result) => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              dataSource.some((obj, index) => {
                if (materialIssue.id === obj.id) dataSource[index] = response.data;
              });
              setData([]);
              setData(dataSource);
              message.success('materialIssue saved successfully', 2);
            } else {
              message.error('Unable to save materialIssue.', 2);
            }
          } else {
            message.error('Unable to save materialIssue', 2);
          }
        })
        .catch((error) => {
          setSpinning(false);
          message.error('Unable to save materialIssue');
          console.error('Error on MaterialIssue : ', error);
        });
    } else {
      platformApi
        .post('/api/materialIssue', materialIssue)
        .then((result) => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              setData([...dataSource, response.data]);
              setCount(count + 1);
              message.success('materialIssue added successfully', 2);
            } else {
              message.error('Unable to add materialIssue', 2);
            }
          } else if (data.code === 500 && data.err.code === 500) {
            message.error('MaterialIssue already exists', 2);
          } else {
            message.error('Unable to add MaterialIssue', 2);
          }
        })
        .catch((error) => {
          setSpinning(false);
          message.error('Unable to add MaterialIssue', 2);
          console.error('Error on MaterialIssue : ', error);
        });
    }
  };

  const deleteData = (id) => {
    setSpinning(true);
    let temp = page != 1 && dataSource.length === 1;
    platformApi
      .delete(`/api/materialIssue/${id}`)
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        console.log(data)
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setData(dataSource.filter(obj => id !== obj.id));
            message.success('materialIssue deleted successfully', 2);
            if (temp) {
              setPage(1)
              setPrimaryData(null, 1)
            }
            else {
              setPrimaryData()
            }
          }
          else {
            message.error('Unable to delete materialIssue', 2);
          }
        }
        else if (data.code === 201) {
          message.error("Can't to delete materialIssue ,Sale Invoice Associated", 2);
        }
        else {
          message.error('Unable to delete materialIssue', 2);
        }
      })
      .catch((error) => {
        message.error('Unable to materialIssue', 2);
        console.error('Error on materialIssue : ', error);
      });
  };

  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: '60%' }} level={4}>
          Parts Issue  [{count}]
        </Title>
        <Search style={{ width: '30%' }}
          placeholder="Search Parts Issue"
          onSearch={(event) => {
            setPage(1)
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null)
              setPage(1)
            }
          }}
        />
        {/* <Button
          onClick={() => {
            setEditFlag(true);
            setAddFlag(true);

          }}
          style={{ margin: '0 3%', width: '20%' }}
          type="primary"
        >
          Add Material Issue
        </Button> */}
        <Button
          onClick={() => {
            history.push('/autoadmin/material_issue/:id');
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add Parts Issue
        </Button>
      </div>
      <div style={{ margin: '.5rem' }}>
        <Dropdown overlay={menu} trigger={['click']}>
          <Button>
            {limit}
            {' '}
            &nbsp;
            <Icon type="down" />
          </Button>
        </Dropdown>
      </div>
      <MaterialIssueTable
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: limit,
          defaultCurrent: 1,
          current: page,
          total: count,
          showQuickJumper: true,
        }}
        // openModal={(id, editable) => {
        //   setSelected(id);
        //   setEditFlag(editable);
        //   setAddFlag(true);
        // }}
        redirectPage={(id, editable) => {
          setSelected(id);
          setRedirect(true);
          setEditable(editable);
        }}
        deleteData={deleteData}
        dataSource={dataSource}
        modify={modifyAccess}
        spinner={{
          spinning,
        }}
        deleteAccess={deleteAccess}

      />
      <WrappedMaterialForm
        data={dataSource[dataSource.findIndex((obj) => obj.id === selected)]}
        emitData={addData}
        open={addFlag}
        close={() => {
          setSelected(null);
          setAddFlag(false);
        }}
        editable={editFlag}
      />
    </div>
  );
}