import React, { useState, useEffect, useContext } from 'react';
import { Form, Modal, Row, Typography, message, Col, Input, Select, Button, Tabs } from 'antd';
const { TabPane } = Tabs;
import QuotationModal from '../../../TransactionMasters/Quotations/QuotationsForm';
import { platformApi } from '../../../../api';
import { ContextAPI } from '../../../../ContextAPI';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import './style.less';
import DiscardModal from '../../DiscardModal';
import { piopiy, piopiyDialUser } from '../../../../utils/piopiy';
import { callContext } from '../../../../App';
const { Item } = Form;
const { Text, Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const CallingInterface = (props) => {
  const { visibility, setVisibility, createQuotation, customerDetail, getActivityByCustomer, form, type } = props;
  const [quotationModal, setQuotationModal] = useState(false);
  const [defaultPhoneNo, setDefaultPhoneNo] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [enquiryType, setEnquiryType] = useState(null);
  const [dateValue, setDateValue] = useState(null);
  const [date, setDate] = useState(null);
  const [timeValue, setTimeValue] = useState(null);
  const [time, setTime] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const { loginCredintials } = useContext(ContextAPI);
  const {mobileNumber,setMobileNumber} = useContext(callContext);
  const [discardModal, setDiscardModal] = useState(false);
  const [qFlag, setQFlag] = useState(false);
  const enquiryTypes = ["Hot", "Warm", "Cold"];
  const { getFieldDecorator } = form;

  const getDefaultTimePeriod = () => {
    const hour = moment().hour();
    if (hour < 10) return 'morning';
    if (hour < 14) return 'afternoon';
    if (hour < 17) return 'evening';
    return 'evening';
  };

  const isMorningDisabled = () => {
    return timeSelection.dateOption === 'today' && moment().hour() >= 10;
  };

  const isAfternoonDisabled = () => {
    
    return timeSelection.dateOption === 'today' && moment().hour() >= 14;
  };

  const isEveningDisabled = () => {
    return timeSelection.dateOption === 'today' && moment().hour() >= 17;
  };

  const getCurrentTimePeriod = () => {
    const hour = moment().hour();
    if (hour < 12) return 'morning';
    if (hour < 17) return 'afternoon';
    return 'evening';
  };

  // Using only presets – remove custom date picker option
  const [timeSelection, setTimeSelection] = useState({
    period: getCurrentTimePeriod(),
    dateOption: 'today'
  });

  const getDefaultPhone = () => {
    const primaryContact = customerDetail.contacts.filter(contact => contact.type === 'Primary');
    if (primaryContact.length) {
      setDefaultPhoneNo(primaryContact[0].phone);
      setSelectedNumber(primaryContact[0].phone);
    }
  };

  const handleNumberChange = (value) => {
    setSelectedNumber(value);
  };

  const handleEnquiryChange = (value) => {
    setEnquiryType(value);
  };

  const handleRemarkChange = (event) => {
    setRemarks(event.target.value);
  };

  const handleCall = () => {
    let mobileNumber = "91" + selectedNumber;
    console.log(setMobileNumber,'set mobile number');
    setMobileNumber(mobileNumber);
    piopiyDialUser(piopiy, mobileNumber);
  };

  const handleSubmit = () => {
    if (enquiryType || (date && time)) {
      let payload = {
        enquiryType,
        followUpDate: date,
        followUpTime: time,
        remarks,
        customerId: customerDetail.id,
        phone: selectedNumber
      };
      if (type === 'serviceFollowup') {
        payload.type = "serviceFollowup";
      }
      platformApi.post("/api/activity/call", payload)
        .then(res => {
          const { data } = res;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              message.success("Activity created successfully", 3);
              getActivityByCustomer();
              clearFields();
              close();
            } else {
              message.error("Unable to save activity", 3);
            }
          } else {
            message.error("Unable to save activity", 3);
          }
        })
        .catch(err => {
          message.error("Unable to save activity");
        });
    } else {
      message.error("Enter all the required fields", 4);
    }
  };

  const clearFields = () => {
    const now = moment();
    const defaultPeriod = getDefaultTimePeriod();

    setTimeSelection({
      period: defaultPeriod,
      dateOption: 'today'
    });
    let defaultTime = now.clone();
    switch (defaultPeriod) {
      case 'morning':
        defaultTime.set({ hour: 10, minute: 0 });
        break;
      case 'afternoon':
        defaultTime.set({ hour: 14, minute: 0 });
        break;
      case 'evening':
        defaultTime.set({ hour: 17, minute: 0 });
        break;
    }

    setEnquiryType(null);
    setRemarks(null);
    setDateValue(now);
    setTimeValue(defaultTime);
    setDate(now.format('DD-MM-YYYY'));
    setTime(defaultTime.format('HH:mm'));
  };

  useEffect(() => {
    if (visibility) {
      getDefaultPhone();
    }
  }, [visibility]);

  const close = () => {
    setVisibility(false);
  };

  // Helper to determine if a given period is disabled for the provided date option
  const isPeriodDisabled = (period, dateOption) => {
    if (dateOption !== 'today') return false;
    const currentHour = moment().hour();
    if (period === 'morning') return currentHour >= 10;
    if (period === 'afternoon') return currentHour >= 14;
    if (period === 'evening') return currentHour >= 17;
    return false;
  };

  const handleTimeSelection = (type, value) => {
    let newSelection = { ...timeSelection, [type]: value };
    const now = moment();
    let newDate = now.clone();
    let newTime = now.clone();

    // Update date based on selected preset
    if (newSelection.dateOption === 'tomorrow') {
      newDate.add(1, 'days');
    } else if (newSelection.dateOption === 'after2days') {
      newDate.add(2, 'days');
    } else if (newSelection.dateOption === 'after7days') {
      newDate.add(7, 'days');
    }
    // For 'today', no change

    // If the new date option is 'today' and the selected period is disabled, auto-select the next available period.
    if (newSelection.dateOption === 'today' && isPeriodDisabled(newSelection.period, 'today')) {
      const periodsOrder = ['morning', 'afternoon', 'evening'];
      const currentIndex = periodsOrder.indexOf(newSelection.period);
      for (let i = currentIndex + 1; i < periodsOrder.length; i++) {
        if (!isPeriodDisabled(periodsOrder[i], 'today')) {
          newSelection.period = periodsOrder[i];
          break;
        }
      }
    }

    // Set the time based on period
    switch(newSelection.period) {
      case 'morning':
        newTime.set({ hour: 10, minute: 0 });
        break;
      case 'afternoon':
        newTime.set({ hour: 14, minute: 0 });
        break;
      case 'evening':
        newTime.set({ hour: 17, minute: 0 });
        break;
      default:
        newTime = now;
    }

    setTimeSelection(newSelection);
    setDateValue(newDate);
    setTimeValue(newTime);
    setDate(newDate.format('DD-MM-YYYY'));
    setTime(newTime.format('HH:mm'));
  };

  return (
    <Modal
      title={<Title level={4}>Session Calling Interface</Title>}
      visible={visibility}
      cancelText="Cancel"
      okText="Save"
      onCancel={() => {
        close();
        clearFields();
      }}
      onOk={() => handleSubmit()}
      wrapClassName="calling-modal"
    >
      <Row type="flex" justify="space-between" gutter={[16, 16]}>
        <Col span={10}>
          <strong>Customer Name: </strong>
          {customerDetail.name}
        </Col>
        <Col span={14}>
          <Row type="flex" justify="space-between">
            <Col span={8}>
              <p>
                <strong>
                  Followup Date & Time <span style={{ color: "rgb(220,0,0)" }}>*</span>
                </strong>
              </p>
            </Col>
            <Col span={16}>
              {/* Using only presets */}
              <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
                {['today', 'tomorrow', 'after2days', 'after7days'].map(option => (
                  <Col span={6} key={option}>
                    <Button
                      size="small"
                      type={timeSelection.dateOption === option ? "primary" : "default"}
                      onClick={() => handleTimeSelection('dateOption', option)}
                      block
                    >
                      {option === 'today'
                        ? 'Today'
                        : option === 'tomorrow'
                        ? 'Tomorrow'
                        : option === 'after2days'
                        ? 'In 2 Days'
                        : 'In 7 Days'}
                    </Button>
                  </Col>
                ))}
              </Row>

              <Row gutter={[8, 8]}>
                {[
                  { key: 'morning', label: 'Morning', disabled: isMorningDisabled() },
                  { key: 'afternoon', label: 'Afternoon', disabled: isAfternoonDisabled() },
                  { key: 'evening', label: 'Evening', disabled: isEveningDisabled() }
                ].map(timeOpt => (
                  <Col span={8} key={timeOpt.key}>
                    <Button
                      size="small"
                      type={timeSelection.period === timeOpt.key ? "primary" : "default"}
                      onClick={() => !timeOpt.disabled && handleTimeSelection('period', timeOpt.key)}
                      block
                      disabled={timeOpt.disabled}
                      style={timeOpt.disabled ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
                    >
                      {timeOpt.label}
                    </Button>
                  </Col>
                ))}
              </Row>
              {/* {dateValue && timeValue && (
                <div style={{ marginTop: 8, fontSize: 12 }}>
                  Selected: {moment(dateValue).format('DD-MM-YYYY')} at {time}
                </div>
              )} */}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row type="flex" justify="space-between" gutter={[16, 16]}>
        <Col span={10}>
          <Row type="flex" gutter={[16, 16]}>
            <Col span={6}>
              <strong>Number: </strong>
            </Col>
            <Col span={12}>
              <Select onChange={handleNumberChange} defaultValue={defaultPhoneNo || ""}>
                {customerDetail.contacts.length &&
                  customerDetail.contacts.map(contact => {
                    if (!contact.DND)
                      return (
                        <Select.Option key={contact.phone} value={contact.phone}>
                          {contact.phone}
                        </Select.Option>
                      );
                  })}
              </Select>
            </Col>
          </Row>
        </Col>
        {type === 'serviceFollowup' ? null : (
          <Col span={14}>
            <Row type="flex" gutter={[16, 16]}>
              <Col span={8}>
                <strong>Enquiry Type: </strong>
              </Col>
              <Col span={15}>
                <Item>
                  {getFieldDecorator("enquiryType", {})(
                    <Select showAction={["click", "focus"]} placeholder="Enquiry Type" onChange={handleEnquiryChange}>
                      {enquiryTypes.map((type) => (
                        <Select.Option value={type} key={type}>
                          {type}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row type="flex" justify="space-between" style={{ marginTop: "10px" }} gutter={[16, 16]}>
        {type === 'serviceFollowup' ? null : (
          <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={() => setQuotationModal(true)}>Create Quotation</Button>
          </Col>
        )}
        <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleCall}>Call Customer</Button>
        </Col>
        <Col span={24}>
          <strong>Remarks: </strong>
          <TextArea rows={4} value={remarks} onChange={handleRemarkChange} />
        </Col>
        {type === 'serviceFollowup' ? null : (
          <Col span={24}>
            <span className="linkylink" onClick={() => setDiscardModal(true)}>
              Discard Followup
            </span>
          </Col>
        )}
      </Row>
      <QuotationModal
        leadData={null}
        editable={true}
        cusData={customerDetail}
        open={quotationModal}
        emitData={createQuotation}
        close={() => {
          setQuotationModal(false);
        }}
      />
      <DiscardModal visibility={discardModal} setVisibility={setDiscardModal} customerDetail={customerDetail} />
    </Modal>
  );
};

const WrappedCallingInterface = Form.create({ name: "calling_interface" })(CallingInterface);

export default withRouter(WrappedCallingInterface);
