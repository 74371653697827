import React, { useEffect, useState } from "react";
import {
  Form,
  Modal,
  Input,
  Typography,
  Table,
  Button,
  Select,
  message,
} from "antd";
import { platformApi } from "../../../../../../api";

const { Text } = Typography;
const { Option } = Select;

const ImagesModal = ({ form, open, close, emitData }) => {
  // Main table data
  const [tableData, setTableData] = useState([]);
  // State for dropdown suggestions
  const [dropDownList, setDropDownList] = useState([]);
  // Counter for unique IDs of newly added items
  const [counter, setCounter] = useState(0);
  // Row selection
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  let debounceTimer;
  const handleSearch = async (value) => {
    if (value) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(async () => {
        try {
          // Using the same (first) API call for "Accessories":
          // /api/partsMaster/accessories?category=Accessories&searchString=${value}
          const response = await platformApi.get(
            `/api/partsMaster/accessories?searchString=${value}`
          );
          const { data } = response;
          if (data && data.response && data.response.data) {
            setDropDownList(data.response.data);
          } else {
            setDropDownList([]);
          }
        } catch (error) {
          console.log(error);
          setDropDownList([]);
          message.error("Failed to search accessories.");
        }
      }, 500);
    }
  };

  // Update specific field in tableData
  const updateRowField = (id, field, value) => {
    const updatedData = tableData.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setTableData(updatedData);
  };

  const deleteRow = (id) => {
    setTableData(tableData.filter((row) => row.id !== id));
  };

  const handleSelect = (value, row) => {
    updateRowField(row.id, "isPercent", value === "percent");
  };

  const handleModelSelect = (selectedValues) => {
    // We only need to add the last selected item
    const lastSelectedId = selectedValues[selectedValues.length - 1];

    const selectedModel = dropDownList.find((model) => model.id === lastSelectedId);
    if (selectedModel) {
      setCounter(counter + 1);

      const newRow = {
        ...selectedModel,
        id: `${selectedModel.id}`, 
        isPercent: false,
        discount: selectedModel.discount || 0,
        mrp: selectedModel.mrp || 0,
      };

      setTableData([...tableData, newRow]);
    }

    handleSearch("");
  };

  const handleSubmit = () => {
    const selectedRows = tableData.filter((row) => selectedRowKeys.includes(row.id));
    emitData(selectedRows);

    close();
    form.resetFields();
    setTableData([]);
    setSelectedRowKeys([]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys) => setSelectedRowKeys(keys),
  };

  // Table columns
  const columns = [
    {
      title: "Accessory Name",
      dataIndex: "partName",
      key: "partName",
      render: (text, row) => (
        <Input
          value={text}
          onChange={(e) => updateRowField(row.id, "partName", e.target.value)}
        />
      ),
    },
    {
      title: "Accessory Code",
      dataIndex: "partNumber",
      key: "partNumber",
      render: (text, row) => (
        <Input
          value={text}
          onChange={(e) => updateRowField(row.id, "partNumber", e.target.value)}
        />
      ),
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (text, row) => {
        const fieldKey = `discount_${row.id}`;
        return (
          <div style={{ display: "flex" }}>
            <Form.Item>
              {form.getFieldDecorator(fieldKey, {
                initialValue: row.discount,
              })(
                <Input
                  onChange={(e) => {
                    const value = parseFloat(e.target.value) || 0;
                    updateRowField(row.id, "discount", value);
                  }}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              )}
            </Form.Item>
            <Select
              defaultValue={row.isPercent ? "percent" : "amount"}
              style={{ width: 60, marginTop: "4px" }}
              onChange={(value) => handleSelect(value, row)}
            >
              <Option value="amount">Rs.</Option>
              <Option value="percent">%</Option>
            </Select>
          </div>
        );
      },
    },
    {
      title: "Before Discount",
      dataIndex: "mrp",
      key: "mrp",
      render: (text, row) => (
        <Input
          value={row.mrp}
          onChange={(e) => {
            const newValue = parseFloat(e.target.value) || 0;
            updateRowField(row.id, "mrp", newValue);
          }}
        />
      ),
    },
    {
      title: "After Discount",
      dataIndex: "afterDiscount",
      key: "afterDiscount",
      render: (_, row) => {
        const discount = row.discount || 0;
        const calculated = row.isPercent
          ? row.mrp - (row.mrp * discount) / 100
          : row.mrp - discount;
        return <span>{row.discount ? calculated : row.mrp}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, row) => (
        <Button type="danger" onClick={() => deleteRow(row.id)}>
          Delete
        </Button>
      ),
    },
  ];

  return (
    <Modal
      title={<Text strong>Accessories Table</Text>}
      visible={open}
      onCancel={() => {
        close();
        setTableData([]);
        setSelectedRowKeys([]);
        form.resetFields();
      }}
      onOk={handleSubmit}
      width="60vw"
      okText="Save"
    >
      <div style={{ marginBottom: 16 }}>
        <Select
          mode="multiple"
          showSearch
          placeholder="Select Accessories"
          style={{ width: "100%" }}
          allowClear
          onSearch={handleSearch}
          filterOption={false}
          onChange={handleModelSelect}
        >
          {dropDownList.map((model) => (
            <Option key={model.id} value={model.id}>
              {model.partName} - {model.partNumber}
            </Option>
          ))}
        </Select>
      </div>

      <Form>
        <Table
          rowSelection={rowSelection}
          rowKey="id"
          columns={columns}
          dataSource={tableData}
          pagination={{ pageSize: 5 }}
          tableLayout="fixed"
        />
      </Form>
    </Modal>
  );
};

const AccessoryModal = Form.create({ name: "imagesModal" })(ImagesModal);
export default AccessoryModal;
