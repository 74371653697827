import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Typography,
  Row,
  Col,
  Button,
  Icon,
  Upload,
  Input,
  message,
} from "antd";
import moment from "moment";
import { platformApi } from "../../../../../api";
import { formatValue } from '../../../../../utils'
import { ENDPOINT } from "../../../../../constants";
const { Text } = Typography;

const DownloadData = ({
  form,
  validityRef,
  clearRef,
  data,
  open,
  saveHere,
  editable,
  id,
  setBFlag
}) => {
  generatedPDF
  const [generatedPDF, setGeneratedPDF] = useState(false);
  const [verifiedStatus, setVerifiedStatus] = useState(false);
  const [finance, setFinance] = useState({});
  const [digital, setDigital] = useState(false);
  const [verifiedTime, setVerifiedTime] = useState(null);
  const [manual, setManual] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authStatus, setAuthStatus] = useState("Pending");
  const [refId, setRefId] = useState(0);
  useEffect(() => {
    downloadPdf(false)
  }, [data])

  useEffect(() => {
    console.log("Receive Data", data)
    if (data.loan) {
      if (data.loan.financer && data.loan.financer.id)
        platformApi
          .get(`api/financer/${data.loan.financer.id}`)
          .then((result) => {
            const { data } = result;
            if (data.code === 200) {
              const { response } = data;
              if (response.code === 200) {
                setFinance(response.data);
              }
            }
          });

      form.setFieldsValue({
        registeredNumber: data.customer ? data.customer.contacts[0].phone : data.customerPhone,
      });
      setGeneratedPDF(data.authentication && data.authentication.beforeVerification)
      setVerifiedStatus(data.authentication && data.authentication.verifiedAt || false)
      setVerifiedTime(data.authentication && data.authentication.verifiedAt ? !data.authentication.verifiedAt.includes("/") ? moment(data.authentication.verifiedAt).format("DD/MM/YYYY, h:mm:ss a") : data.authentication.verifiedAt : null);
      setDigital(data.authentication && data.authentication.digital);
      if (data.authentication && data.authentication.verifiedAt) setAuthStatus("Verified");
      else setAuthStatus("Pending");
      setManual(data.authentication && data.authentication.manual);
    }
  }, [open]);

  useEffect(() => {
    clearRef.current = () => {
      form.resetFields();
      setRefId(0);
    };
    validityRef.current = () => validatePage();
    return () => {
      validityRef.current = null;
      clearRef.current = null;
    };
  });

  const validatePage = () => {
    let isValid;
    form.validateFields((validationError) => {
      isValid = validationError === null;
    });
    if (isValid) {
      data.authentication = {
        ...data.authentication,
        digital: digital,
        verifiedAt: verifiedTime
      }
    }
    return isValid;
  };

  const handleChanger = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      setBFlag(true);
    } else if (info.file.status === "done") {
      message.success("File Uploading ...");

      const formData = new FormData();

      formData.append("profile", info.file.originFileObj);
      formData.append("master", "Transaction Master");
      formData.append("module", "Booking");
      formData.append("id", id);

      platformApi
        .post("/api/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setLoading(false);
          const { data } = res;
          if (data.code === 200) {
            const { Location } = data.response.data;
            console.log("Location", data.authentication, Location)
            message.success("File Uploaded");
            setBFlag(false);
            data.authentication = {
              ...data.authentication,
              afterVerification: Location,
              verifiedAt: moment(new Date()).format(
                "DD/MM/YYYY, h:mm:ss a")
            }
            setVerifiedStatus(true)
            setVerifiedTime(moment(new Date()).format("DD/MM/YYYY, h:mm:ss a"));
          } else {
            message.error("Upload Not Done");
          }
        })
        .catch((error) => {
          console.error("Error on Files Modal : ", error);
          setLoading(false);
        });
    }
  };

  const generateOtp = () => {
    platformApi
      .post("/api/sendSms/sendOtp", {
        phone: data.customer ? data.customer.contacts[0].phone : data.customerPhone,
        type: "WhatsApp",
        smsData: {
          link: data.authentication.beforeVerification,
          cname: data.customer ? data.customer.name : data.customerName,
          slex: data.executive,
          bkid: data.bookingId,
          vname: data.vehicle.modelName,
          dlr: data.branch
        }
      })
      .then((res) => {
        if (res.data.code === 200) {
          const { data } = res.data.response;
          setRefId(data.referenceId);
          message.success("OTP will be sent to your mobile number");
        }
        else if (data.code === 400) {
          message.error(data.message)
        }
        else if (res.data.code === 401) {
          message.error(res.data.message)
        }
      });
  };

  const verifyOtp = () => {
    platformApi
      .post("/api/sendSms/verifyOtp", {
        referenceId: refId,
        passcode: form.getFieldValue("otp"),
      })
      .then((res) => {
        if (res.data.code === 200) {
          if (res.data.response.data.isValid) {
            message.success("OTP Verified");
            setDigital(true);
            setAuthStatus("Verified");
            //updates OTP verfied time
            data.authentication = {
              ...data.authentication,
              verifiedAt: moment(new Date()).format(
                "DD/MM/YYYY, h:mm:ss a"
              )
            }
            setVerifiedStatus(true)
            setVerifiedTime(moment(new Date()).format("DD/MM/YYYY, h:mm:ss a"));
            saveHere()
          }
          else if (res.data.code === 401) {
            message.error(res.data.message)
          }
          else {
            message.error("Wrong OTP Code");
            setDigital(false);
            setAuthStatus("Pending");
          }
        }
      });
  };
  const getLoanDate = (no) => {
    switch (no) {
      case 1: return '1st'
      case 2: return '2nd'
      case 3: return '3rd'
      default: return no + 'th'
    }
  }

  const downloadPdf = (newOpenModal = true) => {
    if (data.loan) {
      if (editable || !manual) {
        const { loan, customer, price, accessories, exchange } = data
        const vehicle = data.selectedVehicle[0];
        const items = [];

        accessories.map((obj) => {
          const newObj = {
            name: obj.partName,
            price: obj.mrp,
          };

          items.push(newObj);
        });
        let accessoryString = ''
        accessories.map((accessory, key) => {
          accessoryString = accessoryString + accessory?.partName + (accessories[key + 1] ? ", " : ".")
        })
        platformApi.get(`api/rto/${data.rto.id}`).then((result) => {

          if (result.data.code === 200) {
            const response = result.data.response;
            if (response.code === 200) {
              const rtoName = `${response.data.code} - ${response.data.area},${response.data.city.name}`;
              const pdfData = {
                fileNo: id,
                status: authStatus,
                verifiedTime: verifiedTime,
                bookingId: data.bookingId || '-',
                bookingDate: moment(new Date()).format("DD/MM/YYYY"),
                printDate: moment(new Date()).format("DD/MM/YYYY"),
                printTime: moment(new Date()).format("h:mm:ss a"),
                customer: {
                  customerId: data.customer ? customer.customerId : data.customerId,
                  name: data.customer ? customer.name || '-' : data.customerName || '-',
                  gender: data.customer ? customer.gender || '-' : data.customerGender || '-',
                  dob: data.customer ? customer.dateOfBirth ? moment(new Date(customer.dateOfBirth)).format("DD/MM/YYYY") : '-' : data.customerDob ? moment(new Date(data.customerDob)).format("DD/MM/YYYY") : '-',
                  fatherName: data.customer ? customer.fatherName : data.customerFatherName || '-',
                  phone: data.customer ? customer.contacts[0].phone || '-' : data.customerPhone || '-',
                  email: data.customer ? customer.email || '-' : data.customerEmail || '-',
                  address: {
                    line1: data.customer ? customer.address.street || '-' : data.customerAddress || '-',
                    line2: data.customer ? customer.address.locality || '-' : data.customerLocality || '-',
                    city: data.customer ? customer.address?.district?.name || '-' : data.customerCity || '-',
                    pinCode: data.customer ? customer.address?.pincode || '-' : data.customerPincode || '-',
                  },
                },
                nominee: {
                  name: data.nomineeName || '-',
                  age: data.nomineeAge || '-',
                  relationship: data.relationship || '-',
                },
                executive: data.executive,
                vehicle: {
                  modelName: data.vehicle.modelName || '-',
                  manufacturerId: data.vehicle && data.vehicle.manufacturer.id,
                  modelColor: vehicle.color.color || '-',
                  rto: rtoName,
                  onRoad: parseFloat(price.onRoadPrice),
                  onRoadDiscount: (price.onRoadDiscount),
                  netRecieveables: (price.netRecieveables) < 0 ? null : price.netRecieveables,
                  refund: (price.netRecieveables) < 0 ? Math.abs(price.netRecieveables) : null,
                  hp: parseFloat(price.hp),
                  numberPlate: parseFloat(price.numberPlate),
                  tr: parseFloat(price.tempRegister),
                  affidavit: parseFloat(price.affidavit),
                  specialNoCharges: parseFloat(price.specialNoCharges),
                  accessoryString: accessoryString,
                  accessories:
                    accessories.length < 1
                      ? null
                      : {
                        total: price.accessoriesTotal,
                        totalDiscount: price.totalDiscount,
                        totalAfterDiscount: price.accessoriesTotalAfterDiscount,
                        items,
                      },
                },
                exchange: {
                  vehicleModel: data.exchange && data.exchange.vehicleModel,
                  vehicleManufacturer: data.exchange && data.exchange.vehicleManufacturer,
                  vehiclePrice: parseFloat(data.exchange && data.exchange.vehiclePrice),
                },
                branch: data.branch,
                financer: {
                  id: data.loan.financer && data.loan.financer.id,
                  branch: data.loan.financerBranch,
                  loan: {
                    downPayment: parseFloat(data.loan.downPayment),
                    emi: parseFloat(data.loan.emiAmount),
                    tenure: loan.tenure,
                    emiDate: getLoanDate(loan.emiDate),
                    amount: parseFloat(data.loan.netRecieveables),
                    emiStartDate: new Date(
                      data.loan.emiStartDate
                    ).toLocaleDateString(),
                  },
                },
                remarks: price.remarks,
              };

              platformApi
                .post("/api/pdfGenerate/booking", pdfData)
                .then((result) => {
                  if (result.data.code === 200) {
                    const response = result.data.response;
                    if (response.code === 200) {
                      newOpenModal && message.success("PDF Report Downloaded");
                      newOpenModal && window.open(`${response.data.Location}`);
                      data.authentication = {
                        ...data.authentication,
                        beforeVerification: response.data.Location,
                      }
                      setGeneratedPDF(true)
                    } else {
                      newOpenModal && message.error("Download Failed");
                    }
                  } else {
                    newOpenModal && message.error("Download Failed");
                  }
                });
            }
          }
        });
      } else {
        window.open(data.authentication.afterVerification);
      }
    }
  };

  const { getFieldDecorator } = form;
  return (
    <Form style={{ marginTop: "4%", marginBottom: "4%" }}>
      <Text strong>Digital Authentication</Text>
      <Row type="flex" justify="space-between">
        <Col span={22}>
          <Form.Item colon={false} label={<Text>Registered Phone Number</Text>}>
            <Row type="flex" justify="space-between">
              <Col span={7}>
                {getFieldDecorator("registeredNumber")(
                  <Input placeholder="Registered Phone Number"
                    onKeyUp={e =>
                      form.setFieldsValue({
                        registeredNumber: formatValue(e, "onlyNo")
                      })}
                    disabled />
                )}
              </Col>
              <Col span={7}>
                <Button
                  disabled={!editable || !generatedPDF}
                  type="primary"
                  // loading={!generatedPDF}
                  onClick={generateOtp}
                >
                  Generate OTP and Link
                </Button>
              </Col>
              <Col span={7}>
                <Text strong>Authentication Status </Text>:{verifiedTime ? "Verfied" : 'Pending'}
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col span={22}>
          <Form.Item colon={false} label={<Text>Enter OTP</Text>}>
            <Row type="flex" justify="space-between">
              <Col span={7}>
                {getFieldDecorator("otp")(
                  <Input
                    disabled={!editable}
                    onKeyUp={e =>
                      form.setFieldsValue({
                        pincode: formatValue(e, "onlyNo")
                      })}
                    placeholder="Enter OTP"
                  />
                )}
              </Col>
              <Col span={7}>
                <Button
                  disabled={!editable}
                  type="primary"
                  onClick={verifyOtp}
                >
                  Verify
                </Button>
              </Col>
              <Col span={7} />
            </Row>
          </Form.Item>
        </Col>
      </Row>

      <br />
      <Text strong>Manual Authentication</Text>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Form.Item colon={false} label={<Text>Download Booking Form</Text>}>
            <Button
              onClick={downloadPdf}
            // loading={!generatedPDF}
            >
              Click to download &nbsp;&nbsp;
              <Icon type="download" />
            </Button>
          </Form.Item>
        </Col>

        <Col span={11}>
          <Form.Item colon={false} label={<Text>Upload Booking Form</Text>}>
            {getFieldDecorator("bookingFormUpload")(
              <Upload
                showUploadList={false}
                onChange={handleChanger}
                action={`${ENDPOINT}api/upload/mocky`}
                multiple={false}
                listType="text"
                accept=".pdf,.doc,.docx"
                disabled={!editable}
              >
                <Button
                  disabled={(!editable && manual) || authStatus === "Verified" || loading}
                >
                  Upload Booking Form&nbsp;&nbsp; <Icon type="upload" />
                </Button>
              </Upload>
            )}
          </Form.Item>
        </Col>
      </Row>
      {verifiedTime && (
        <Row justify="space-between" align="center" style={{ margin: '10px' }}>
          <Col span={11} marginTop="15px">
            <Text strong>Verified Time </Text>:{verifiedTime}
          </Col>
        </Row>
      )}
    </Form>
  );
};

const WrappedDownloadData = Form.create()(DownloadData);

export default WrappedDownloadData;
