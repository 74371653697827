import React, { useState, useEffect } from 'react';
import { withRouter, useLocation } from "react-router-dom";
import { Typography, message, Carousel, Button, Form, Row, Col, Input, Tooltip, Card } from "antd";
import { platformApi } from '../../api'
import {
  LeftOutlined
} from '@ant-design/icons';
import './style.less'
import Moment from 'moment'
import QuotationInfo from './QuotationInfo';
import CustomerInfo from './CustomerInfo'
import VehicleInfo from './VehicleInfo'
import ActivityLog from './ActivityLog';

const FollowUp = (props) => {
  const location = useLocation();

  const { Title, Text } = Typography;
  const [customerId, setCustomerId] = useState(null);
  const [customerIds, setCustomerIds] = useState(null);
  const [customerPhone, setCustomerPhone] = useState(null);
  const [allCustomers, setCustomers] = useState([])
  const [customerDetails, setCustomerDetails] = useState(null);
  const [mergedQuotations, setMergedQuotations] = useState([]);
  const [mergedPurchasedVehicle, setMergedPurchasedVehicle] = useState([]);
  const [activities, setActivities] = useState([]);
  const [followUpDate, setFollowUpDate] = useState();
  const [status, setStatus] = useState('');

  useEffect(() => {
    settingDetails();
    return () => {
      clearingState();
    }
  }, [location])

  const settingDetails = () => {
    const { phoneNo, fupDate } = props.match.params
    setCustomerPhone(phoneNo)
    setFollowUpDate(fupDate);
    getCustomersByPhone(phoneNo)
  }
  // const queryParams = new URLSearchParams(props.location.search);
  // const filter = JSON.parse(decodeURIComponent(queryParams.get("filter")));
  const filter = JSON.parse(localStorage.getItem("followUpsFilters"));
  console.log("filters", filter);
  
  const clearingState = () => {
    setActivities([])
  }

  const getCustomersByPhone = (phoneNo = customerPhone) => {
    platformApi.get(`/api/customer/phone-no/${phoneNo}`)
      .then(res => {
        const { data: { response: { data: { customers } } } } = res;
        setCustomers(customers)
        if (customers.length) {
          let allCustomerIDS = customers.map(customer => customer.id)
          setCustomerIds(allCustomerIDS)
          setCustomerId(customers[0].id);
          getCustomerInfo(customers[0].id)
          getMergedInfo(allCustomerIDS)
          getActivityByCustomer(allCustomerIDS)
        }
        // console.log("Customer", customers);
      })

  }

  const getMergedInfo = (ids = customerIds) => {
    platformApi.post('/api/customer/merge', {
      ids
    }).then(res => {
      const { data: { response: { data } } } = res
      data.purchasedVehicle = data.purchasedVehicle.filter(vehicle => data.quotation.every(quo => Moment(vehicle.dateOfSale).isBefore(quo.createdAt)))
      setMergedPurchasedVehicle(data.purchasedVehicle);
      setMergedQuotations(data.quotation)
    })
  }

  const getCustomerInfo = (custId = customerId) => {
    let customer = custId
    if (customer) {
      platformApi.get(`/api/customer/${customer}`).then(result => {
        const { data } = result.data.response;
        // console.log("Customer Data", data)
        setCustomerDetails(data);
        platformApi.get(`/api/quotation/getCus/${data.id}`).then(res => {
          const { data } = res.data.response;
          // console.log("Followup", data)
          setStatus(data[data.length-1].quotationStatus)
          setFollowUpDate(data[0].createdAt)

        })
      }).catch(err => { })
    }
  }

  const updateCustomerData = (customer) => {
    platformApi
      .put(`/api/customer/${customer.id}`, customer)
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            getCustomerInfo();
            message.success("Customer updated successfully", 2);
            // window.location.reload();
          } else {
            message.error("Unable to update customer", 2);
          }
        } else if (data.code === 500 && data.err.code === 500) {
          message.error("Customer name already exists", 2);
        } else {
          message.error("Unable to update customer", 2);
        }
      })
      .catch((error) => {
        message.error("Unable to update customer", 2);
        console.error("Error on customer : ", error);
      });
  };

  const updateAttachQuotation = (data) => {
    platformApi
      .post(`/api/quotation/attach/`, data)
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            message.success("Quotation Attach updated successfully", 2);
          } else {
            message.error("Unable to update Quotation Attach", 2);
          }
        } else {
          message.error("Unable to update Quotation Attach", 2);
        }
      })
      .catch((error) => {
        message.error("Unable to update Quotation Attach", 2);
        console.error("Unable to update Quotation Attach : ", error);
      });
  };

  const linkQuotation = (data) => {
    //Call to Attach quotations
    let customer = { ...customerDetails }
    let tmpData = []
    data.map(id => {
      tmpData.push({ id })
    })
    customer.quotation = customer.quotation.concat(tmpData)
    if (customer.dateOfBirth)
      customer.dateOfBirth = Moment(customer.dateOfBirth).format('DD-MM-YYYY')
    customer.update = "quotation"//to update Quotation
    updateAttachQuotation(data) //attach Quotation
    updateCustomerData(customer)
    getCustomersByPhone();
  }

  const createQuotation = (quotation, callBack) => {
    const formData = new FormData()
    formData.append("finalData", JSON.stringify(quotation))
    platformApi
      .post("/api/quotation", formData, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            getCustomerInfo()
            getActivityByCustomer()
            message.success("Quotation added successfully", 2);
            callBack(true, response.data.id);
          } else {
            message.error("Unable to add new quotation", 2);
            callBack(false);
          }
        } else {
          message.error("Unable to add quotation", 2);
          callBack(false);
        }
      })
      .catch((error) => {
        callBack(false);
        console.error("Error on quotation : ", error);
        message.error("Unable to add new quotation");
      });
  }

  const getActivityByCustomer = (ids = customerIds, limit = 15, offset = 0) => {
    platformApi.post('/api/activity/customers', {
      limit,
      offset,
      ids
    }).then(res => {
      const { data: { response: { data } } } = res
      console.log("Data", data)
      setActivities(data)
    }).catch(err => message.error("Unable to get Activities"))
  }

  const changeFollowUp = (next = false) => {
    // console.log("id",mergedQuotations[mergedQuotations.length-1]?.id);
    const last_quotation    = mergedQuotations[mergedQuotations.length-1]?.id
    const { phoneNo } = props.match.params
     const quotations = mergedQuotations.map((data) => data.id )
    platformApi.post("/api/quotation/scheduled", { fupDateTime: followUpDate, next, status, last_quotation, quotations, phone:phoneNo,filter }).then((res) => {
      const { data: { response: { data } } } = res
      if (data.length) {
        const quotationInfo = data[0];
        // console.log("quotationInfo", quotationInfo)
        const phno = (quotationInfo.customer.contacts.filter(phno => phno.type === "Primary"))[0].phone
        props.history.push(`/autoadmin/follow-ups/${phno}/fupdate/${quotationInfo.scheduleDateAndTime}`)
        message.success("FollowUp Fetched !!!")
      }
      else {
        if (next)
          message.error("No Next FollowUp Exist")
        else
          message.error("No Previous FollowUp Exist")
      }
      // props.history.push(`/autoa`)
    })
  }

  return (
    <div className="follow-ups">
      <Title style={{ width: "60%" }} level={4}>
        <Tooltip placement="topLeft" title={"Back"}>
          <Button
            style={{ margin: "0 20px" }}
            onClick={() => props.history.push('/autoadmin/follow-ups')}
          >
            <LeftOutlined />
          </Button>
        </Tooltip>
          Follow-Ups
        </Title>
      <Row type='flex' justify='space-between' gutter={[16, 16]}>
        <Col span={24}>
          <Row type='flex' justify='space-between' >
            <Col>
              <Button
                style={{ margin: "0 20px" }}
                onClick={() => changeFollowUp()}
              >
                Previous
          </Button>
            </Col>
            <Col>
              <Button
                style={{ margin: "0 20px" }}
                onClick={() => changeFollowUp(true)}
              >
                Next
          </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card>
            <Row type='flex' justify='space-between' gutter={[16, 16]}>
              <Col span={8}>
                <Carousel
                  initialSlide={0}
                  beforeChange={(from, to) => {
                    if (allCustomers.length) {
                      setCustomerId(prevId => allCustomers[to].id)
                      getCustomerInfo(allCustomers[to].id)
                    }
                    // console.log("ERROR!!")
                    // console.log("From", from, "To", to);
                  }
                  }
                >
                  {
                    allCustomers.map(customer => {
                      return (customerDetails) ? <CustomerInfo
                        updateCustomerData={updateCustomerData}
                        customer={customerDetails}
                        enquiryType={
                          // currentQuotation.enquiryType ||
                          "-"}
                        setCustomer={setCustomerDetails} /> : null
                    })
                  }
                </Carousel>
              </Col>
              <Col span={16}>
                {(customerDetails) ? <QuotationInfo
                  customerId={customerId}
                  createQuotation={createQuotation}
                  getCustomerInfo={getCustomerInfo}
                  getActivityByCustomer={getActivityByCustomer}
                  dataSource={mergedQuotations}
                  customerDetail={customerDetails}
                  linkQuotation={linkQuotation}
                /> : null}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          {(customerDetails) ?
            mergedPurchasedVehicle.length > 0 &&
            <VehicleInfo dataSource={mergedPurchasedVehicle} />
            : null}
        </Col>
        <Col span={24}>
          <ActivityLog
            customerId={customerId}
            dataSource={activities}
            setDataSource={setActivities}
            getActivityByCustomer={getActivityByCustomer}
          />
        </Col>
      </Row>
    </div>
  )
}

export default withRouter(FollowUp);
