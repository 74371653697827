import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";


import TransferSpares from './spares';
import TransferVehicles from './vehicles';


const Reports = (props) => {

    const { type } = props
    const [transferSpares, setTransferSpares] = useState(false);
    const [transferVehicles, setTransferVehicles] = useState(false);
    

    useEffect(() => {
      if (type === "spares") {
        console.log("SPARES CALED!!!")
        setTransferSpares(true);
        setTransferVehicles(false);
      }
      else {
        console.log("VEHCILES CALED!!!")

        setTransferSpares(false);
        setTransferVehicles(true);
      }
    }, type)

    console.log(transferSpares,' spares ')
    return (
       <div>
          {transferSpares && <TransferSpares />}
          {transferVehicles && <TransferVehicles />}
       </div> 
    )
}

export default withRouter(Reports);