import React, { useEffect, useState } from "react";
import { Table, Divider, Popconfirm, Typography, Input } from "antd";
import moment from "moment";

export default function BooingRegisterTable(props) {
  const { pagination, dataSource, openModal, spinner, onDelete, modify, deleteAccess, advancedBookingModifyAccess, updateDataStatus } = props;
  const { Text } = Typography
  const [reasons, setReasons] = useState({});

  // Helper to track changes to the reason for a specific row
  const handleReasonChange = (id, value) => {
    setReasons((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  const column = [
    {
      title: "Booking ID",
      dataIndex: "bookingId",
      key: "bookingId",
      render: (record) => record && <span>{record}</span>,
    },
    {
      title: "Customer Name",
      dataIndex: "customer",
      key: "customerName",
      render: (record, data) => record ? <span>{record.name}</span> : <span>{data.customerName}</span>,
    },
    {
      title: "Mobile Number",
      dataIndex: "customer",
      key: "contacts",
      render: (record, data) =>
        record ? (
          <span>
            {record.contacts && record.contacts[0] && record.contacts[0].phone}
          </span>
        )
          : (
            <span>
              {data.customerPhone}
            </span>
          ),
    },
    {
      title: "Booked Vehicle",
      dataIndex: "vehicle",
      key: "model",
      render: (record) => record && <span>{record.modelName}</span>,
    },
    {
      title: "Mode of Payment",
      dataIndex: "price",
      key: "paymentMode",
      render: (record) => (
        <span>
          {record &&
            record.paymentMode &&
            record.paymentMode.charAt(0).toUpperCase() +
            record.paymentMode.slice(1)}
        </span>
      ), //record && <span>{record.name}</span>,
    },
    {
      title: "Date of Creation",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record) => record && <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
    },
    {
      title: "Action",
      render: (data) => (
        <div>

          {
            (data.bookingStatus == "ACCEPTED" && (data.customerAuth == false) || (data.bookingStatus == 'PENDING')) || (advancedBookingModifyAccess) ?
              modify ?
                <>
                  <span
                    onClick={(event) => {
                      event.stopPropagation();
                      openModal(data.id, true);
                    }}
                    className="linkylink"
                  >
                    Modify
                    <Divider type="vertical" />
                  </span>
                  {advancedBookingModifyAccess &&
                    <span
                      onClick={(event) => {
                        event.stopPropagation();
                        openModal(data.id, true, true);
                      }}
                      className="linkylink">
                      Confirm
                      <Divider type="vertical" />
                    </span>
                  }
                </>
                :
                null
              :
              <span></span>
          }

          {
            deleteAccess ? (advancedBookingModifyAccess ?
              <div>
                <Popconfirm
                  title={
                    <div style={{ maxWidth: 250 }}>
                      <div>Do you want to cancel this Booking?</div>
                      <div style={{ marginTop: 8 }}>
                        <Input.TextArea
                          placeholder="Reason for cancellation"
                          rows={3}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            handleReasonChange(data.id, e.target.value)
                          }
                          value={reasons[data.id] || ""} 
                        />
                      </div>
                    </div>
                  }
                  okText="Yes"
                  cancelText="No"
                  onCancel={(event) => {
                    event.stopPropagation();
                    // remove the data.id reasons data
                    setReasons({});
                  }}
                  onConfirm={(event) => {
                    event.stopPropagation();
                    // onDelete(data.id);
                    console.log(data.id)
                    updateDataStatus(reasons[data.id],data.id)
                    setReasons({});
                  }}
                >
                  <span onClick={(event) => {
                    event.stopPropagation();
                  }}
                    className="linkylink">
                    Cancel
                  </span>
                </Popconfirm>
                <Divider type="vertical" />

                {/* <Popconfirm
                  title="Do you want to delete this Booking Register?"
                  okText="Yes"
                  cancelText="No"
                  onCancel={(event) => {
                    event.stopPropagation();
                  }}
                  onConfirm={(event) => {
                    event.stopPropagation();
                    onDelete(data.id);
                  }}
                >
                  <span
                    onClick={(event) => event.stopPropagation()}
                    className="linkylink"
                  >
                    Delete
                  </span>
                  
                </Popconfirm> */}
              </div>
              :
              null
            )
              :
              <span></span>
          }

          {
            !modify && !deleteAccess
              ?
              <Text strong disabled>No Actions Given</Text>
              :
              <span></span>
          }
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table
        rowKey={(record) => record.id}
        pagination={pagination}
        columns={column}
        tableLayout="auto"
        style={{ cursor: "pointer" }}
        dataSource={dataSource}
        onRow={(props) => ({
          onClick: () => openModal(props.id, false),
        })}
        loading={spinner}

      />
    </div>
  );
}
