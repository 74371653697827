import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Typography, Steps, Icon, message } from "antd";
import WrappedCustomerData from "./CustomerData";
import WrappedVehicleData from "./VehicleData";
import WrappedPaymentData from "./PaymentData";
import WrappedDownloadData from "./DownloadData";
// import WrappedAuthenticationData from "./AuthenticationData";

const { Step } = Steps;
const { Text } = Typography;

//default data sturucture
const defaultData = {
  bookingId: null,
  customer: null,
  customerId: null,
  customerName: null,
  customerFatherName: null,
  customerAddress: null,
  customerPhone: null,
  customerLocality: null,
  customerCountry: null,
  customerState: null,
  customerCity: null,
  customerPincode: null,
  customerEmail: null,
  customerDob: null,
  customerGender: null,
  IDbranch: null,
  nomineeName: null,
  nomineeAge: null,
  relationship: null,
  vehicle: null,
  rto: null,
  rtoCode: null,
  rtoArea: null,
  exchangeVehicleName: null,
  exchangeVehiclePrice: null,
  price: null,
  accessories: [],
  quotation: [],
  color: null,
  loan: null,
  authentication: null,
  pdf: "example",
  pdfSigned: "example",
  executive: null,
  remarks: null,
  bookingStatus: null,
  authorisedTime: null,
  refferedBy: null,
};
const BookingRegisterModal = (props) => {
  const { open, close, id, currentData, editable, emitData, clearBookingData, setBFlag, bFlag,mode } = props;

  const [currentBookingData, setCurrentBookingData] = useState(defaultData);
  const [customerId, setCustomerId] = useState("");
  const [customerAvailable, setCustomerAvailable] = useState(false);
  useEffect(() => {
    if (currentData) {

      let accessoryArray = []
      let temp = { ...currentData }
      if (temp.accessories.length > 0) {
        temp.accessories.map((data, index) => {
          let obj = { ...data }
          if ("accessory" in temp.accessories[index]) {
            obj = {
              ...data.accessory,
              discountPrice: data.accessory ? data.accessory.price - data.discount : 0,
              discount: data.discount,
              isPercent: data.isPercent && data.isPercent,
              arrayId: data.id || '',
            };
          }
          accessoryArray.push(obj);
        });
      }
      temp.accessories = accessoryArray
      setCurrentBookingData(temp);
    }
  }, [open, currentData]);

  const ChildRef = useRef();
  const ClearRef = useRef();

  const [current, setCurrent] = useState(0);
  const icons = [
    <Icon key={true} style={{ fontSize: "1rem" }} type="user" />,
    <Icon key={true} style={{ fontSize: "1rem" }} type="car" />,
    <Icon key={true} style={{ fontSize: "1rem" }} type="transaction" />,
    <Icon key={true} style={{ fontSize: "1rem" }} type="upload" />,
    <Icon key={true} style={{ fontSize: "1rem" }} type="check" />,
  ];

  const steps = [
    { id: 1, title: "Customer Data" },
    { id: 2, title: "Vehicle Data" },
    { id: 3, title: "Payment Data" },
    // { id: 5, title: "Supervisor Authentication" },
  ];

  if(mode=="ConfirmBooking")
    steps.push({ id: 4, title: "Customer Authentication" })

  const forms = [
    <WrappedCustomerData
    mode={mode}
    key={true}
      data={currentBookingData}
      open={open}
      validityRef={ChildRef}
      clearRef={ClearRef}
      editable={editable}
      setCustomerId={(data) => setCustomerId(data)}
      customerAvailable={customerAvailable}
      setCustomerAvailable={setCustomerAvailable}
      customerId={customerId}
    />,
    <WrappedVehicleData
    mode={mode}
    key={true}
      data={currentBookingData}
      open={open}
      validityRef={ChildRef}
      clearRef={ClearRef}
      editable={editable}
    />,
    <WrappedPaymentData
    mode={mode}
    key={true}
      data={currentBookingData}
      open={open}
      validityRef={ChildRef}
      clearRef={ClearRef}
      editable={editable}
    />,

    // <WrappedAuthenticationData
    //   key
    //   id={id}
    //   data={currentBookingData}
    //   open={open}
    //   validityRef={ChildRef}
    //   clearRef={ClearRef}
    //   editable={editable}
    //   saveHere={()=>handleSubmit()}
    // />,
  ];

  if(mode=="ConfirmBooking")
    forms.push(    <WrappedDownloadData
      key={true}
        id={id}
        data={currentBookingData}
        open={open}
        validityRef={ChildRef}
        clearRef={ClearRef}
        editable={editable}
        setBFlag={setBFlag}
        bFlag={bFlag}
        saveHere={() => handleSubmit()}
      />)


  const clearFields = () => {
    clearBookingData(defaultData);
    setCurrentBookingData(defaultData);
    setCurrent(0);
    setCustomerId("");
    ClearRef.current();
    setCustomerAvailable(false)
    close();
  };

  function reformatSelectedVehicle(data) {
    if(data.selectedVehicle) {

    return {
      selectedVehicle: data.selectedVehicle.map(vehicle => {
        const { color, vehicleDetail } = vehicle;
        if(!color?.imageDetails) return {color}
        const imageDetails = color?.imageDetails[0]; 
        return {
          color: {
            id: color?.colorId,
            color: color?.colorName,
            code: imageDetails?.code,
            url: imageDetails?.url
          },
          vehicleDetail
        };
      })
    };
  }
  }
  

  const handleSubmit = (changeTab = false) => {
    const data = { ...currentBookingData, selectedVehicle: reformatSelectedVehicle(currentBookingData)?.selectedVehicle  };
    data.bookingId = id;
    let accesoriesArray = [];
    data?.accessories.map((obj, index) => {
      let newObj
      if ("accessory" in currentBookingData?.accessories[index])
        newObj = { ...obj }
      else {
        newObj = {
          id: obj.arrayId || '',
          accessory: {
            code: obj?.code,
            id: obj?.id,
            name: obj?.name,
            price: obj?.price,
          },
          discount: obj?.discount,
          isPercent: obj?.isPercent && obj?.isPercent
        };
      }
      accesoriesArray.push(newObj);
    });

    let quotationArr = []
    currentBookingData?.quotation?.map((data, index) => {
      if (data.id)
        quotationArr.push(data)
      else
        quotationArr.push({ id: data })
    })
    // payload  to CRUD
    let payload = {
      isAdvanceBooking:mode=="ConfirmBooking"?false:true,
      bookingId: data?.bookingId ?? id,
      customer: data?.customer,
      customerId: data?.customerId,
      customerName: data?.customerName,
      customerFatherName: data?.customerFatherName,
      customerAddress: data?.customerAddress,
      customerPhone: data?.customerPhone,
      customerGender: data?.customerGender,
      customerLocality: data?.customerLocality,
      customerCountry: data?.customerCountry,
      customerState: data?.customerState,
      customerCity: data?.customerCity,
      customerPincode: data?.customerPincode,
      customerEmail: data?.customerEmail,
      customerDob: data?.customerDob,
      IDbranch: data?.IDbranch,
      branch: Array.isArray(data?.branch) ? data.branch[0] : data?.branch,
      nomineeName: data?.nomineeName,
      nomineeAge: data?.nomineeAge,
      relationship: data?.relationship,
      vehicle: data?.vehicle,
      rto: data?.rto,
      price: {
        onRoadDiscount: parseFloat(data?.price?.onRoadDiscount) || 0,
        onRoadPrice: parseFloat(data?.price?.onRoadPrice) || 0,
        insuranceType: data?.price?.insuranceType,
        numberPlate: parseFloat(data?.price?.numberPlate) || 0,
        tempRegister: parseFloat(data?.price?.tempRegister) || 0,
        hp: parseFloat(data?.price?.hp) || 0,
        paymentMode: data?.price?.paymentMode,
        affidavit: parseFloat(data?.price?.affidavit) || 0,
        netRecieveables: parseFloat(data?.price?.netRecieveables) || 0,
        specialNoCharges: parseFloat(data?.price?.specialNoCharges) || 0,
        accessoriesTotal: parseFloat(data?.price?.accessoriesTotal) || 0,
        accessoriesTotalAfterDiscount: parseFloat(data?.price?.accessoriesTotalAfterDiscount) || 0,
        totalDiscount: parseFloat(data?.price?.totalDiscount) || 0,
        finalAmount: parseFloat(data?.price?.finalAmount) || 0,
      },
      priceData: {
        onRoadDiscount: parseFloat(data?.price?.onRoadDiscount) || 0,
        onRoadPrice: parseFloat(data?.price?.onRoadPrice) || 0,
        insuranceType: data?.price?.insuranceType,
        numberPlate: parseFloat(data?.price?.numberPlate) || 0,
        tempRegister: parseFloat(data?.price?.tempRegister) || 0,
        hp: parseFloat(data?.price?.hp) || 0,
        paymentMode: data?.price?.paymentMode,
        affidavit: parseFloat(data?.price?.affidavit) || 0,
        netRecieveables: parseFloat(data?.price?.netRecieveables) || 0,
        specialNoCharges: parseFloat(data?.price?.specialNoCharges) || 0,
        accessoriesTotal: parseFloat(data?.price?.accessoriesTotal) || 0,
        accessoriesTotalAfterDiscount: parseFloat(data?.price?.accessoriesTotalAfterDiscount) || 0,
        totalDiscount: parseFloat(data?.price?.totalDiscount) || 0,
        finalAmount: parseFloat(data?.price?.finalAmount) || 0,
      },
      rtoCode: data?.rtoCode,
      rtoArea: data?.rtoArea,
      exchangeVehicleName: data?.exchangeVehicleName,
      exchangeVehiclePrice: data?.exchangeVehiclePrice,
      accessories: accesoriesArray,
      quotation: quotationArr,
      color: { id: data?.selectedVehicle?.[0]?.color?.id },
      loan: {
        financerBranch: data?.loan?.financerBranch,
        financer: data?.loan?.financer ?? { id: null },
        downPayment: parseInt(data?.loan?.downPayment) || 0,
        loanAmount: parseInt(data?.loan?.loanAmount) || 0,
        tenure: parseInt(data?.loan?.tenure) || 0,
        emiDate: parseInt(data?.loan?.emiDate) || 0,
        emiStartDate: data?.loan?.emiStartDate,
        loanType: data?.loan?.loanType,
        emiAmount: parseFloat(data?.loan?.emiAmount) || 0,
        disbursementAmount: parseFloat(data?.loan?.disbursementAmount) || 0,
        showroomFinanceCharges: parseFloat(data?.loan?.showroomFinanceCharges) || 0,
      },
      loanData: {
        finName: data?.finName,
        financerBranch: data?.loan?.financerBranch,
        downPayment: parseInt(data?.loan?.downPayment) || 0,
        loanAmount: parseInt(data?.loan?.loanAmount) || 0,
        tenure: parseInt(data?.loan?.tenure) || 0,
        emiDate: parseInt(data?.loan?.emiDate) || 0,
        emiStartDate: data?.loan?.emiStartDate,
        loanType: data?.loan?.loanType,
        emiAmount: parseFloat(data?.loan?.emiAmount) || 0,
        disbursementAmount: parseFloat(data?.loan?.disbursementAmount) || 0,
        showroomFinanceCharges: parseFloat(data?.loan?.showroomFinanceCharges) || 0,
      },
      selectedVehicle: data?.selectedVehicle,
      authentication: {
        beforeVerification: data?.authentication?.beforeVerification ?? null,
        afterVerification: data?.authentication?.afterVerification ?? null,
        digital: data?.authentication?.digital ?? null,
        verifiedAt: data?.authentication?.verifiedAt ?? null,
      },
      exchange: {
        vehicleModel: data?.exchange?.vehicleModel ?? null,
        vehiclePrice: parseFloat(data?.exchange?.vehiclePrice) || null,
      },
      pdf: "example",
      pdfSigned: "example",
      executive: data?.executive,
      remarks: data?.price?.remarks,
      bookingStatus: mode=="ConfirmBooking"? (data?.bookingStatus || "PENDING"):"ADVANCE",
      authorisedTime: data?.authorisedTime || null,
      refferedBy: data?.refferedBy,
    };
    
    data ? (payload.id = data.id) : {};
    emitData(payload, changeTab);
    if (changeTab)
      setCurrent(current + 1)
  };

  return (
    <Modal
      width="80%"
      title="Booking Register"
      visible={open}
      okText={current < 3 ? "Next" : "Save"}
      onCancel={() => {
        clearFields();
        close();
      }}
      footer={
        editable
          ? [
            <Button
            key={true}
              onClick={() => {
                  current > 0
                    ? setCurrent(current - 1)
                    : (close(), clearFields());
              }}
            >
              {current > 0 ? "Back" : "Cancel"}
            </Button>,
            <Button
            key={true}
              loading={bFlag}
              disabled={bFlag || !customerId}
              onClick={() => {
                if (ChildRef.current())
                {
                  if(mode=="ConfirmBooking")
                  current !== 2 ? current == 3 ?  clearFields()  : setCurrent(current + 1) : handleSubmit(true);
                else
                current == 2 ? (handleSubmit(true),  clearFields()) : setCurrent(current + 1) 
                }
              }}
              type="primary"
            >
              {current !== 2 ? current == 3 ? "Finish" : "Next" : "Save & Continue"}
            </Button>,
            // mode === "AdvancedBooking" && current >=1 ? (
            //   <Button
            //     key="save-confirm"
            //     type="primary"
            //     onClick={() => console.log("new button clicked!")}
            //   >
            //     Save & Confirm
            //   </Button>
            // ) : null,
          ]
          : [
            <Button
            key={true}
              onClick={() => {
                current > 0 ? setCurrent(current - 1) : close();
              }}
            >
              {current > 0 ? "Back" : "Cancel"}
            </Button>,
            <Button
            key={true}
              onClick={() => {
                if (ChildRef.current() && mode=="ConfirmBooking")
                  current < 3
                    ? setCurrent(current + 1)
                    : (clearFields(), close());
              
              else{
                if(ChildRef.current())
                {
                  current < 2
                  ? setCurrent(current + 1)
                  : (clearFields(), close());
                }
              }
              }
            }
              type="primary"
            >
              {mode=="ConfirmBooking"?current < 3 ? "Next" : "Cancel":current < 2 ? "Next" : "Cancel"}
            </Button>,
          ]
      }
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', margin:'0' }}>
        <Text strong>
          Booking ID&nbsp;&nbsp;&nbsp;: {currentData != null ? currentData.bookingId : id}
        </Text>
        <Text strong>
         {mode && mode.replace(/([A-Z])/g, ' $1').trim()}
      </Text>
      </div>
      <Text strong>
        Customer ID&nbsp;: {customerId}
      </Text>
      <br />
      <Steps size="small" type="navigation" current={current}>
        {steps.map((step, index) => (
          <Step
            key={step.id}
            icon={icons[index]}
            title={<Text>{step.title}</Text>}
          />
        ))}
      </Steps>
      <div>{forms[current]}</div>
    </Modal>
  );
};

export default BookingRegisterModal;
