import React, { createContext, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import AppRouter from './routes/AppRouter';
import 'antd/dist/antd.css';
import './index.less';
import CallPopup from './components/CallPopup';
import OutgoingCallPopup from './components/CallPopup/OutGoingCallPopup';
import {piopiy,piopiyLogin} from './utils/piopiy';
export var callContext=createContext({mobileNumber:'',setMobileNumber:()=>{}});
const App = () => {
  const [showOutgoing,setShowOutgoing]=useState(false)
  const [showInComming,setShowInComming]=useState(false)
  const [isAttended,setIsAttended]=useState(false)
  const [mobileNumber,setMobileNumber]=useState('')

  //to reconnect telecmi the socket connection if app is reloaded
  useEffect(()=>{
  if(localStorage.getItem('token'))
  {
  piopiyLogin(piopiy)
  }
  },[])

  //handler to track incomming call
  piopiy.on("inComingCall", function (object) {
    setMobileNumber(object.from)
    console.log("Outgoing call:", object);   
    setShowInComming(true); 
  });
  
  //handler to track sucessful login
  piopiy.on("login", function(object){
    console.log(object);
    console.log('login successful');
  })

  //handler to track the outgoing call is started
  piopiy.on( 'trying', function ( object ) {    
    if(object.code == 100) {
      console.log(object,'obj hello');
      console.log('hello');
     setShowOutgoing(true);    
    }
});

//handler to track the call is attened by the user
  piopiy.on( 'answered', function ( object ) {      
    if(object.code == 200) {
setIsAttended(true)       
    }
});

//handler to track the call is ended
piopiy.on( 'ended', function ( object ) {
console.log('ended',object);
setIsAttended(false);
setShowOutgoing(false)  
setShowInComming(false);     

});

//handler to track the call is hagup by the user
piopiy.on( 'hangup', function ( object ) {
    console.log('inside hangup',object);
    setIsAttended(false); 
    setShowOutgoing(false); 
    setShowInComming(false);   
});

piopiy.on( 'logout', function ( object ) {
        
  if(object.code == 200 ) {
    console.log("logout")
      //  The user logged out successfully. 
  }
});
 return (
 <div>
  <callContext.Provider value={{mobileNumber,setMobileNumber}}>
  <OutgoingCallPopup flag={showOutgoing} attended={isAttended} setflag={setShowOutgoing} mobileNumber={mobileNumber}/>
  <CallPopup flag={showInComming} setflag={setShowInComming} attended={isAttended} mobileNumber={mobileNumber}/>
  <AppRouter />
  </callContext.Provider>
  </div>
 )
}

export default hot(App);
