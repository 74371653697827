import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  Form,
  DatePicker,
  Checkbox,
  Select,
  Row,
  Col,
  Typography,
} from "antd";
import "./price.less";
import moment from "moment";
import { platformApi } from "../../../../api";
import { formatValue } from '../../../../utils'
import { any } from "prop-types";
const { Item } = Form;
const { Text, Title } = Typography;

const VehiclePriceForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(props.editable);
  const [model, setModel] = useState([]);
  const [modelColor, setModelColor] = useState([]);
  const [ins1, setIns1] = useState(true);
  const [ins2, setIns2] = useState(true);
  const [ins3, setIns3] = useState(true);
  const [ins4, setIns4] = useState(true);
  const [errorObj, setErrorObj] = useState({});
  const { form, open, close, data, modify } = props;
  const { getFieldDecorator } = form;
  useEffect(() => {
    // platformApi.get("/api/vehicleMaster/").then((res) => {
    //   if (res.data.response.data.length !== 0) setModel(res.data.response.data);
    // });
    platformApi
      .post("/api/options/get", {
        module: "vehicleMasters",
        column: "modelName",
        fields: ["modelCode"],
      })
      .then((res) => {
        console.log(res.data.response)
        setModel(res.data.response);
      });
    setEditable(props.editable);
    if (data) {
      callAnApi(data.vehicleModel.id);
      data.insurance1plus5 && setIns1(false);
      data.insurance5plus5 && setIns2(false);
      data.insurance1plus5ZD && setIns3(false);
      data.insurance5plus5ZD && setIns4(false);
      // console.log("value check");
      // console.log(data?.colors?.length, data.colors);
      const colorData = data.colors.map((item) => ({id : item.colorId, color: item.colorName}));
      // console.log(colorData);
      
      setModelColor(colorData);
      form.setFieldsValue({
        model: data.vehicleModel && data.vehicleModel.id,
        modelColor: colorData.length > 0 ? colorData.map(item=> item.id) : [],
        rtax: data?.roadTax,
        from: moment(data.priceValidFrom),
        to: moment(data.priceValidTill),
        hcharges: data.handlingCharges,
        rfee: data.registrationFee,
        rsa: data.rsa,
        amc: data.amc,
        tcs: data.tcs,
        other_charges: data.otherCharges,
        other_charges_textarea: data.accessoriesRemarks,
        discount: data.discount,
        exshow: data.showroomPrice,
        warrantyPrice: data.warrantyPrice,
        insuranceValue: data.insuranceValue,
        insuranceType: data.insurance,
        onePlusFive: data.insurance1plus5,
        insurance5plus5: data.insurance5plus5,
        onePlusFiveZero: data.insurance1plus5ZD,
        insurance5plus5ZD: data.insurance5plus5ZD,
      });
      // console.log("modol color binding");
      // console.log(form.getFieldsValue())
      if (data.vehicleModel.id) {
        let total =
          data.showroomPrice +
          data?.roadTax +
          data.handlingCharges +
          data.warrantyPrice +
          data.registrationFee +
          data.rsa +
          data.amc +
          data.tcs +
          data.insurance1plus5 +
          data.insurance1plus5ZD +
          data.otherCharges -
          data.discount;
        form.setFieldsValue({
          t1: total,
        });
        console.log(total, "total")
        // console.log({
        //   showroomPrice: data.showroomPrice,
        //   rto: data.rto,
        //   handlingCharges: data.handlingCharges,
        //   warrantyPrice: data.warrantyPrice,
        //   registrationFee: data.registrationFee,
        //   rsa: data.rsa,
        //   amc: data.amc,
        //   tcs: data.tcs,
        //   onePlusFive: data.insurance1plus5,
        //   onePlusFiveZero: data.insurance1plus5ZD,
        //   otherCharges: data.otherCharges,
        //   discount: data.discount,
         
        // });
        // console.log(data.colors)
      }
      // if (data.insurance1plus5) {
      //   let total =
      //     data.insurance1plus5 +
      //     data.roadTax +
      //     data.handlingCharges +
      //     data.warrantyPrice +
      //     data.showroomPrice +
      //     data.registrationFee;
      //   form.setFieldsValue({
      //     t1: total,
      //   });
      // }
      // if (data.insurance5plus5) {
      //   let total =
      //     data.insurance5plus5 +
      //     data.roadTax +
      //     data.handlingCharges +
      //     data.warrantyPrice +
      //     data.showroomPrice +
      //     data.registrationFee;
      //   form.setFieldsValue({
      //     t2: total,
      //   });
      // }
      // if (data.insurance1plus5ZD) {
      //   let total =
      //     data.insurance1plus5ZD +
      //     data.roadTax +
      //     data.handlingCharges +
      //     data.warrantyPrice +
      //     data.showroomPrice +
      //     data.registrationFee;
      //   form.setFieldsValue({
      //     t3: total,
      //   });
      // }
      // if (data.insurance5plus5ZD) {
      //   let total =
      //     data.insurance5plus5ZD +
      //     data.roadTax +
      //     data.handlingCharges +
      //     data.warrantyPrice +
      //     data.showroomPrice +
      //     data.registrationFee;
      //   form.setFieldsValue({
      //     t4: total,
      //   });
      // }
    }
  }, [data, open, props.editable]);

  const handleSubmit = () => {
    // console.log(modelColor);
    // return
    form.validateFields((validationError) => {
      if (validationError === null) {
        setLoading(true);
        const showroomPrice = parseFloat(form.getFieldValue("exshow"));
        const roadTax = parseFloat(form.getFieldValue("rtax"));
        const priceValidFrom = form.getFieldValue("from");
        const priceValidTill = form.getFieldValue("to");
        const insurance = form.getFieldValue("insuranceType");
        const registrationFee = parseFloat(form.getFieldValue("rfee"));
        const handlingCharges = parseFloat(form.getFieldValue("hcharges"));
        const rsa = parseFloat(form.getFieldValue("rsa"));
        const amc = parseFloat(form.getFieldValue("amc"));
        const warrantyPrice = parseFloat(form.getFieldValue("warrantyPrice"));
        const insuranceValue = parseFloat(form.getFieldValue("insuranceValue"));
        const vehicleModel = form.getFieldValue("model");
        const tempColorsId = form.getFieldValue("modelColor")
        // console.log("sending model color");
        // console.log(modelColor);
        const vehicleColors = modelColor
        .filter(item => tempColorsId.includes(item.id))
        .map(item => ({
          colorId: item.id,
          colorName: item.color,
        }));
        const tcs = parseFloat(form.getFieldValue("tcs"));
        const otherCharges = parseFloat(form.getFieldValue("other_charges"));
        const accessoriesRemarks = form.getFieldValue("other_charges_textarea");
        const discount = parseFloat(form.getFieldValue("discount"));
        const insurance1plus5 = parseFloat(
          form.getFieldValue("onePlusFive")
        );
        const insurance5plus5 = parseFloat(
          form.getFieldValue("insurance5plus5")
        );
        const insurance1plus5ZD = parseFloat(
          form.getFieldValue("onePlusFiveZero")
        );
        const insurance5plus5ZD = parseFloat(
          form.getFieldValue("insurance5plus5ZD")
        );

        const vehiclePrice = {
          vehicleModel,
          vehicleColors,
          showroomPrice,
          roadTax,
          priceValidFrom,
          priceValidTill,
          insurance,
          warrantyPrice,
          insuranceValue,
          registrationFee,
          handlingCharges,
          rsa,
          amc,
          tcs,
          otherCharges,
          accessoriesRemarks,
          discount,
          insurance1plus5,
          insurance5plus5,
          insurance1plus5ZD,
          insurance5plus5ZD,
        };
        data ? (vehiclePrice.id = data.id) : {};
        props.emitData(vehiclePrice);
        clearFields();
        setLoading(false);
        props.close();
      }
    });
  };

  const clearFields = () => {
    form.resetFields();
    // setIns1(true);
    // setIns2(true);
    // setIns3(true);
    // setIns4(true);
  };

  const checkDateValidity = (moment, type) => {
    if (type === "to") {
      return moment._d < form.getFieldValue("from");
    } else if (type === "from") {
      if (form.getFieldValue("to")) return form.getFieldValue("to") < moment._d;
      else return false;
    }
  };

  const getVehicleDetails = (id) => {
    platformApi.get(`/api/vehiclePrice/model/${id}`).then((res) => {
    });
  };

  const callAnApi = (id) => {
    form.setFieldsValue({
      modelColor: [],
    });
    platformApi.get(`/api/vehiclePrice/getColors/${id}`).then((res) => {
      setModelColor(res.data.response?.data?.colors); 
      console.log(res.data.response?.data?.colors);
      console.log("modelColor triggered");
    });

  }

  
  const calculateOnRoad = (event, type) => {
    let showroomPrice = 0,
      roadTax = 0,
      registrationFee = 0,
      handlingCharges = 0,
      rsa = 0,
      tcs = 0,
      other_charges = 0,
      discount = 0,
      amc = 0,
      warrantyPrice = 0,
      insurance1plus5 = 0,
      insurance5plus5 = 0,
      insurance1plus5ZD = 0,
      insurance5plus5ZD = 0,
      onePlusFive = 0,
      onePlusFiveZero = 0;



    if (type === "exshow") {
      showroomPrice = event.target.value;
    } else {
      showroomPrice = form.getFieldValue("exshow");
    }

    console.log("hi1", event.target.value)
    console.log("hi2", form.getFieldValue("rtax"))
    if (type === "rtax") {
      roadTax = event.target.value;
    }
    else if (form.getFieldValue("rtax")) {

      roadTax = form.getFieldValue("rtax");
    }
    console.log(roadTax, "roadTax");
    if (type === "hcharges") {
      handlingCharges = event.target.value;
    } else {
      handlingCharges = form.getFieldValue("hcharges");
    }

    if (type === "rsa") {
      rsa = event.target.value;
    } else {
      rsa = form.getFieldValue("rsa");
    }

    if (type === "tcs") {
      tcs = event.target.value;
    } else {
      tcs = form.getFieldValue("tcs");
    }

    if (type === "amc") {
      amc = event.target.value;
    } else {
      amc = form.getFieldValue("amc");
    }

    if (type === "rfee") {
      registrationFee = event.target.value;
    } else {
      registrationFee = form.getFieldValue("rfee");
    }

    if (type === "other_charges") {
      other_charges = event.target.value;
    } else {
      other_charges = form.getFieldValue("other_charges");
    }

    if (type === "warrantyPrice") {
      warrantyPrice = event.target.value;
    } else {
      warrantyPrice = form.getFieldValue("warrantyPrice");
    }

    if (type === "insurance1plus5") {
      insurance1plus5 = event.target.value;
    } else {
      insurance1plus5 = form.getFieldValue("insurance1plus5");
    }
    if (type === "insurance5plus5") {
      insurance5plus5 = event.target.value;
    } else {
      insurance5plus5 = form.getFieldValue("insurance5plus5");
    }
    if (type === "insurance1plus5ZD") {
      insurance1plus5ZD = event.target.value;
    } else {
      insurance1plus5ZD = form.getFieldValue("insurance1plus5ZD");
    }
    if (type === "insurance5plus5ZD") {
      insurance5plus5ZD = event.target.value;
    } else {
      insurance5plus5ZD = form.getFieldValue("insurance5plus5ZD");
    }

    if (type === "discount") {
      discount = event.target.value;
    } else {
      discount = form.getFieldValue("discount") || 0;
    }

    if (type === "onePlusFive") {
      onePlusFive = event.target.value;
    }
    else {
      onePlusFive = form.getFieldValue("onePlusFive") || 0;
    }

    if (type === "onePlusFiveZero") {
      onePlusFiveZero = event.target.value;
    }
    else {
      onePlusFiveZero = form.getFieldValue("onePlusFiveZero") || 0;
    }

    let total = 0;
    if (showroomPrice) {
      showroomPrice = parseInt(showroomPrice);
      total = total + showroomPrice;
    }
    if (roadTax) {
      roadTax = parseInt(roadTax);
      total = total + roadTax;
    }
    if (registrationFee) {
      registrationFee = parseInt(registrationFee);
      total = total + registrationFee;
    }
    if (rsa) {
      rsa = parseInt(rsa);
      total = total + rsa;
    }
    if (tcs) {
      tcs = parseInt(tcs);
      total = total + tcs;
      console.log(total, typeof total)
    }

    if (other_charges) {
      other_charges = parseInt(other_charges);
      total = total + other_charges;
    }

    if (amc) {
      amc = parseInt(amc);
      total = total + amc;
    }
    if (warrantyPrice) {
      warrantyPrice = parseInt(warrantyPrice);
      total = total + warrantyPrice;
      console.log("warrantyPrice", warrantyPrice);
    }
    if (handlingCharges) {
      handlingCharges = parseInt(handlingCharges);
      total = total + handlingCharges;
    }
    if (onePlusFive) {
      onePlusFive = parseInt(onePlusFive);
      let total2 = total + onePlusFive - discount;
      form.setFieldsValue({
        t2: total2,
      });
    }
    else {
      form.setFieldsValue({
        t2: null,
      }); 
    }

    if (onePlusFiveZero) {
      onePlusFiveZero = parseInt(onePlusFiveZero);
      let total3 = total + onePlusFiveZero - discount;
      form.setFieldsValue({
        t3: total3,
      });
    } else {
      form.setFieldsValue({
        t3: null,
      }); }

    // if (total) {
    //   let total1 = total - discount;
    //   form.setFieldsValue({
    //     t1: total1,
    //   });
    // }
    
  

    // if (insurance5plus5) {
    //   insurance5plus5 = parseInt(insurance5plus5);
    //   let total2 = total + insurance5plus5;
    //   form.setFieldsValue({
    //     t2: total2,
    //   });
    // } else {
    //   form.setFieldsValue({
    //     t2: null,
    //   });
    // }
    // if (insurance5plus5ZD) {
    //   insurance5plus5ZD = parseInt(insurance5plus5ZD);
    //   let total4 = total + insurance5plus5ZD;
    //   form.setFieldsValue({
    //     t4: total4,
    //   });
    // } else {
    //   form.setFieldsValue({
    //     t4: null,
    //   });
    // }

    // console.log({
    //   showroomPrice,
    //   roadTax,
    //   registrationFee,
    //   handlingCharges,
    //   rsa,
    //   tcs,
    //   other_charges,
    //   discount,
    //   amc,
    //   warrantyPrice,
    //   insurance1plus5,
    //   insurance5plus5,
    //   insurance1plus5ZD,
    //   insurance5plus5ZD,
    //   onePlusFive,
    //   onePlusFiveZero
    // });
    console.log(total, form.getFieldValue("t1"))

  };
  const dropdownSearch = (search) => {
    platformApi
      .post("/api/options/get", {
        module: search.module,
        column: search.column,
        searchString: search.value,
        fields: search.fields,
        searchColumns: search.searchColumns,
      })
      .then((res) => {
        search.setValue(res.data.response);
      });
  };
  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  return (
    <Modal
      wrapClassName="VehiclePriceModal"
      title={<Title level={4}>Vehicle Price</Title>}
      okText={editable ? "Save" : "Modify"}
      visible={open}
      onCancel={() => {
        clearFields();
        close();
      }}
      okButtonProps={{ loading, disabled: !editable && !modify }}
      onOk={() => (editable ? handleSubmit() : setEditable(true))}
      width={800}
    >
          <h2 style={{ marginBottom: 0, fontSize: "16px" }} >Pricing</h2>
      <Row type="flex" justify="space-between">
     
          
            <Col span={11}>
            <Item label={<Text>Vehicle Model</Text>}
              colon={false} required>
              {getFieldDecorator("model", {
                rules: [{ required: true, message: "Select Vehicle Model" }],
              })(
                <Select
                  showAction={["click", "focus"]}
                  onChange={(id) => {
                    getVehicleDetails(id);
                    
                    callAnApi(id);
                  }}
                  disabled={!editable}
                  placeholder="Vehicle Model"
                  filterOption={null}
                  showSearch
                  onSearch={(e) => {
                    const search = {
                      module: "vehicleMasters",
                      column: "modelName",
                      fields: ["modelCode"],
                      value: e,
                      searchColumns: ["modelCode"],
                      setValue: setModel,
                    };
                    dropdownSearch(search);
                  }}

                >
                  {model.map((code, index) => (
                    <Select.Option key={index} value={code.id}>
                      {code.modelCode + " - " + code.modelName}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Item>
            </Col>
            <Col span={11}>
            <Item label={<Text>Vehicle Model Color</Text>}
              colon={false} required>
              {getFieldDecorator("modelColor", {
                rules: [{ required: true, message: "Select Vehicle color" }],
              })(
                <Select
                  showAction={["click", "focus"]}
                  disabled={!editable}
                  placeholder="Vehicle Vehicle color"
                  filterOption={null}
                  // showSearch
                  // onSearch={(e) => {
                  //   const search = {
                  //     module: "vehicleMasters",
                  //     column: "modelName",
                  //     fields: ["modelCode"],
                  //     value: e,
                  //     searchColumns: ["modelCode"],
                  //     setValue: setModel,
                  //   };
                  //   dropdownSearch(search);
                  // }}
                  mode="multiple"

                >
                {modelColor?.map((colorItem) => (
                  <Select.Option key={colorItem?.id}  value={colorItem?.id}>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                      <img
                        src={colorItem?.url}
                        alt={colorItem?.color?.charAt(0) || ""}
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                      <span>{colorItem?.color}</span>
                    </div>
                  </Select.Option>
                ))}
                </Select>
              )}
            </Item>
            </Col>
      
       
 

        {/* checkbox comment */}
        {/* <Col span={11}> */}
          {/* <Row type="flex" align="middle" justify="space-between">
            <Col span={14}>
              <Checkbox
                disabled={!editable}
                checked={!ins1}
                onChange={() => {
                  setIns1(!ins1);
                  // !ins1 && form.resetFields(["insurance1plus5"]);
                }}
              >
                <Text>1+5</Text>
              </Checkbox>
            </Col>
            </Row> */}
        {/* </Col>  */}
         {/* 
            <Col span={10}>
              <Item colon={false}
                validateStatus={errorObj && errorObj.insurance1plus5 ? "error" : ""}
                help={errorObj && errorObj.insurance1plus5}
              >
                {getFieldDecorator("insurance1plus5", {
                  rules: [
                    { required: false, message: "Select Insurance Type" },
                  ],
                })(
                  <Input
                    // type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onWheel={(event) => event.currentTarget.blur()}
                    addonBefore="&#8377;"
                    disabled={!editable || ins1}
                    id="insurance1plus5"
                    onChange={(e) => calculateOnRoad(e, "insurance1plus5")}
                    onKeyUp={e =>
                      form.setFieldsValue({
                        insurance1plus5: formatValue(e, "noWithDot")
                      })}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          insurance1plus5: "Enter Valid insurance1plus5 Price"
                        })
                      } else {
                        delete errorObj.insurance1plus5
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={14}>
              <Checkbox
                disabled={!editable}
                checked={!ins2}
                onChange={() => {
                  setIns2(!ins2);
                  !ins2 && form.resetFields(["insurance5plus5"]);
                }}
              >
                <Text>5+5</Text>
              </Checkbox>
            </Col>
            <Col span={10}>
              <Item colon={false}
                validateStatus={errorObj && errorObj.insurance5plus5 ? "error" : ""}
                help={errorObj && errorObj.insurance5plus5}
              >
                {getFieldDecorator("insurance5plus5", {
                  rules: [
                    { required: false, message: "Select Insurance Type" },
                  ],
                })(
                  <Input
                    addonBefore="&#8377;"
                    // type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onWheel={(event) => event.currentTarget.blur()}
                    disabled={!editable || ins2}
                    id="insurance5plus5"
                    onChange={(e) => calculateOnRoad(e, "insurance5plus5")}
                    onKeyUp={e =>
                      form.setFieldsValue({
                        insurance5plus5: formatValue(e, "noWithDot")
                      })}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          insurance5plus5: "Enter Valid insurance5plus5 Price"
                        })
                      } else {
                        delete errorObj.insurance5plus5
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={14}>
              <Checkbox
                disabled={!editable}
                checked={!ins3}
                onChange={() => {
                  setIns3(!ins3);
                  !ins3 && form.resetFields(["insurance1plus5ZD"]);
                }}
              >
                <Text>1+5 Zero Dep</Text>
              </Checkbox>
            </Col>
            <Col span={10}>
              <Item colon={false}
                validateStatus={errorObj && errorObj.insurance1plus5ZD ? "error" : ""}
                help={errorObj && errorObj.insurance1plus5ZD}
              >
                {getFieldDecorator("insurance1plus5ZD", {
                  rules: [
                    { required: false, message: "Select Insurance Type" },
                  ],
                })(
                  <Input
                    addonBefore="&#8377;"
                    // type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onWheel={(event) => event.currentTarget.blur()}
                    disabled={!editable || ins3}
                    id="insurance1plus5ZD"
                    onChange={(e) => calculateOnRoad(e, "insurance1plus5ZD")}
                    onKeyUp={e =>
                      form.setFieldsValue({
                        insurance1plus5ZD: formatValue(e, "noWithDot")
                      })}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          insurance1plus5ZD: "Enter Valid insurance5plus5 Price"
                        })
                      } else {
                        delete errorObj.insurance1plus5ZD
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={14}>
              <Checkbox
                checked={!ins4}
                disabled={!editable}
                onChange={() => {
                  setIns4(!ins4);
                  !ins4 && form.resetFields(["insurance5plus5ZD"]);
                }}
              >
                <Text>5+5 Zero Dep</Text>
              </Checkbox>
            </Col>
            <Col span={10}>
              <Item colon={false}
                validateStatus={errorObj && errorObj.insurance5plus5ZD ? "error" : ""}
                help={errorObj && errorObj.insurance5plus5ZD}
              >
                {getFieldDecorator("insurance5plus5ZD", {
                  rules: [
                    { required: false, message: "Select Insurance Type" },
                  ],
                })(
                  <Input
                    addonBefore="&#8377;"
                    type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onWheel={(event) => event.currentTarget.blur()}
                    disabled={!editable || ins4}
                    id="insurance5plus5ZD"
                    onChange={(e) => { calculateOnRoad(e, "insurance5plus5ZD") }}
                    onKeyUp={e =>
                      form.setFieldsValue({
                        insurance5plus5ZD: formatValue(e, "noWithDot")
                      })}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          insurance5plus5ZD: "Enter Valid insurance5plus5 Price"
                        })
                      } else {
                        delete errorObj.insurance5plus5ZD
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>
        </Col> */}
      </Row>
      <br />

      <Row type="flex" justify="space-between">
            <Col span={11}>
            <h2 style={{ marginBottom: 0, fontSize: "16px" }}>Vehicle Base Pricing</h2>
            <Item label={<Text>Ex-Showroom Price</Text>}
              validateStatus={errorObj && errorObj.exshow ? "error" : ""}
              help={errorObj && errorObj.exshow}
              colon={false} required>
              {getFieldDecorator("exshow", {
                rules: [{ required: true, message: "Enter Ex-Showroom Price" }],
              })(
                <Input
                  // type="number"
                  pattern="[0-9]+(\.[0-9][0-9]?)?"
                  onWheel={(event) => event.currentTarget.blur()}
                  addonBefore="&#8377;"
                  disabled={!editable}
                  placeholder="Ex-Showroom Price"
                  onChange={(e) => calculateOnRoad(e, "exshow")}
                  onKeyUp={e =>
                    form.setFieldsValue({
                      exshow: formatValue(e, "noWithDot")
                    })}
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setErrorObj({
                        ...errorObj,
                        exshow: "Enter Valid Ex-Showroom Price"
                      })
                    } else {
                      delete errorObj.exshow
                    }
                  }}
                />
              )}
            </Item>
            </Col>
            <Col span={11}>
              <h4 style={{ marginBottom: 0, fontSize: "12px" }}>Price Validity (This price will be valid untill further update)</h4>

              <Item label={<Text>Valid From</Text>} colon={false} required>
                {getFieldDecorator("from", {
                  rules: [
                    {
                      type: "object",
                      required: true,
                      message: "Select Valid Date",
                    },
                  ],
                })(
                  <DatePicker
                    format="DD/MM/YYYY"
                    allowClear={true}
                    style={{ width: "100%" }}
                    disabled={!editable}
                    disabledDate={(moment) => checkDateValidity(moment, "from")}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <br />
      <Row>
        <Col>
        <h2 style={{ marginBottom: 0, fontSize: "16px" }}>RTO, Insurance & Other Charges</h2>

          <Row type="flex" justify="space-between">
            {/* Road Tax */}
            {/* <Col span={11}>
              <Item label={<Text>Road Tax</Text>}
                validateStatus={errorObj && errorObj.rtax ? "error" : ""}
                help={errorObj && errorObj.rtax}
                colon={false}>
                {getFieldDecorator("rtax", {
                  rules: [{ required: false, message: "Enter Road Tax" }],
                })(
                  <Input
                    // type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onKeyUp={e =>
                      form.setFieldsValue({
                        rtax: formatValue(e, "noWithDot")
                      })}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          rtax: "Enter Valid insurance5plus5 Price"
                        })
                      } else {
                        delete errorObj.rtax
                      }
                    }}
                    onWheel={(event) => event.currentTarget.blur()}
                    addonBefore="&#8377;"
                    disabled={!editable}
                    placeholder="Road Tax"
                    onChange={(e) => calculateOnRoad(e, "rtax")}
                  />
                )}
              </Item>
            </Col> */}
            <Col span={11}>
              <Item label={<Text>Road Tax</Text>}
                validateStatus={errorObj && errorObj.rtax ? "error" : ""}
                help={errorObj && errorObj.rtax}
                colon={false}
                required={true}
              >
                {getFieldDecorator("rtax", {
                  rules: [{ required: true, message: "Enter RTO" }],
                })(
                  <Input
                    // type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onKeyUp={e =>
                      form.setFieldsValue({
                        rtax: formatValue(e, "noWithDot")
                      })}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          rtax: "Enter Valid insurance5plus5 Price"
                        })
                      } else {
                        delete errorObj.rtax
                      }
                    }}
                    onWheel={(event) => event.currentTarget.blur()}
                    addonBefore="&#8377;"
                    disabled={!editable}
                    placeholder="Road Tax"
                    onChange={(e) => calculateOnRoad(e, "rtax")}
                  />
                )}
              </Item>
              
            </Col>
            <Col span={11}>
              <Item label={<Text>Other Charges</Text>}
                validateStatus={errorObj && errorObj.other_charges ? "error" : ""}
                help={errorObj && errorObj.other_charges}
                colon={false}>
                {getFieldDecorator("other_charges", {
                  rules: [{ required: false, message: "Enter Other Charges" }],
                })(
                  <Input
                    // type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onKeyUp={e =>
                      form.setFieldsValue({
                        other_charges: formatValue(e, "noWithDot")
                      })}
                    onWheel={(event) => event.currentTarget.blur()}
                    addonBefore="&#8377;"
                    disabled={!editable}
                    placeholder="Enter Other Charges"
                    onChange={(e) => calculateOnRoad(e, "other_charges")}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          other_charges: "Enter Valid Other Charges Price"
                        })
                      } else {
                        delete errorObj.other_charges
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
       
          </Row>

          <Row type="flex" justify="space-between">
            <Col span={11}>
            <Row>
                <Item label={<Text>Registration Charges</Text>}
                    validateStatus={errorObj && errorObj.rfee ? "error" : ""}
                    help={errorObj && errorObj.rfee}
                    colon={false}
                    required={true}
                  >
                    {getFieldDecorator("rfee", {
                      rules: [{ required: true, message: "Enter Registration Fee" }],
                    })(
                      <Input
                        // type="number"
                        pattern="[0-9]+(\.[0-9][0-9]?)?"
                        onKeyUp={e =>
                          form.setFieldsValue({
                            rfee: formatValue(e, "noWithDot")
                          })}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setErrorObj({
                              ...errorObj,
                              rfee: "Enter Valid Registration Charges"
                            })
                          } else {
                            delete errorObj.rfee
                          }
                        }}
                        onWheel={(event) => event.currentTarget.blur()}
                        addonBefore="&#8377;"
                        disabled={!editable}
                        placeholder="Registration Fee"
                        onChange={(e) => calculateOnRoad(e, "rfee")}
                      />
                    )}
                  </Item>
                  <Item label={<Text>Extended Warranty</Text>}
                    validateStatus={errorObj && errorObj.warrantyPrice ? "error" : ""}
                    help={errorObj && errorObj.warrantyPrice}
                    colon={false}>
                    {getFieldDecorator("warrantyPrice", {
                      rules: [{ required: false, message: "Enter Warranty Price" }],
                    })(
                      <Input
                        addonBefore="&#8377;"
                        // type="number"
                        pattern="[0-9]+(\.[0-9][0-9]?)?"
                        onKeyUp={e =>
                          form.setFieldsValue({
                            warrantyPrice: formatValue(e, "noWithDot")
                          })}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setErrorObj({
                              ...errorObj,
                              warrantyPrice: "Enter Valid Warranty Price"
                            })
                          } else {
                            delete errorObj.warrantyPrice
                          }
                        }}
                        onWheel={(event) => event.currentTarget.blur()}
                        style={{ width: "100%" }}
                        disabled={!editable}
                        placeholder="Warranty Price"
                        onChange={(e) => calculateOnRoad(e, "warrantyPrice")}
                      />
                    )}
                  </Item>
                  <Item label={<Text>RSA</Text>}
                    validateStatus={errorObj && errorObj.rsa ? "error" : ""}
                    help={errorObj && errorObj.rsa}
                    colon={false}>
                    {getFieldDecorator("rsa", {
                      rules: [{ required: false, message: "Enter RSA" }],
                    })(
                      <Input
                        // type="number"
                        pattern="[0-9]+(\.[0-9][0-9]?)?"
                        onKeyUp={e =>
                          form.setFieldsValue({
                            rsa: formatValue(e, "noWithDot")
                          })}
                        onWheel={(event) => event.currentTarget.blur()}
                        addonBefore="&#8377;"
                        disabled={!editable}
                        placeholder="Enter RSA"
                        onChange={(e) => calculateOnRoad(e, "rsa")}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setErrorObj({
                              ...errorObj,
                              rsa: "Enter Valid RSA Price"
                            })
                          } else {
                            delete errorObj.rsa
                          }
                        }}
                      />
                    )}
                  </Item>
            </Row>
       
            </Col>
            <Col span={11}>
              {getFieldDecorator("other_charges_textarea", {
                rules: [{ required: false }],
              })(<Input.TextArea placeholder="Inclusions/ Accessories - Eg: Side Stand, Saree Gaurd, Mud Gaurd etc" style={{ height: 239, }}
              >
              </Input.TextArea>)}
            </Col>
          </Row>


          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Item label={<Text>AMC</Text>}
                validateStatus={errorObj && errorObj.amc ? "error" : ""}
                help={errorObj && errorObj.amc}
                colon={false}>
                {getFieldDecorator("amc", {
                  rules: [{ required: false, message: "Enter AMC" }],
                })(
                  <Input
                    // type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onKeyUp={e =>
                      form.setFieldsValue({
                        amc: formatValue(e, "noWithDot")
                      })}
                    onWheel={(event) => event.currentTarget.blur()}
                    addonBefore="&#8377;"
                    disabled={!editable}
                    placeholder="Enter AMC"
                    onChange={(e) => calculateOnRoad(e, "amc")}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          amc: "Enter Valid AMC Price"
                        })
                      } else {
                        delete errorObj.amc
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
            <Col span={11}>
            <Row type="flex" justify="space-between" >
              <Col span={11}>
                <Item label={<Text>Total - [1+5]</Text>} colon={false}>
                {getFieldDecorator("t2", {
                  rules: [{ required: false }],
                })(
                  <Input
                    // type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onWheel={(event) => event.currentTarget.blur()}
                    disabled={true}
                    placeholder="On-Road"
                  />
                )}
                </Item>
              </Col>
              <Col span={11}>
                <Item label={<Text>Total - [1+5] Nil Dep</Text>} colon={false}>
                  {getFieldDecorator("t3", {
                    rules: [{ required: false }],
                  })(
                    <Input
                      // type="number"
                      pattern="[0-9]+(\.[0-9][0-9]?)?"
                      onWheel={(event) => event.currentTarget.blur()}
                      disabled={true}
                      placeholder="On-Road"
                    />
                  )}
                </Item>
              </Col>
            </Row>
            </Col>
          </Row>
          {/* handlingCharges */}
          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Item label={<Text>Handling Charges</Text>}
                validateStatus={errorObj && errorObj.hcharges ? "error" : ""}
                help={errorObj && errorObj.hcharges}
                colon={false}>
                {getFieldDecorator("hcharges", {
                  rules: [{ required: false, message: "Enter Handling Charges" }],
                })(
                  <Input
                    // type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onKeyUp={e =>
                      form.setFieldsValue({
                        hcharges: formatValue(e, "noWithDot")
                      })}
                    onWheel={(event) => event.currentTarget.blur()}
                    addonBefore="&#8377;"
                    disabled={!editable}
                    placeholder="Handling Charges"
                    onChange={(e) => calculateOnRoad(e, "hcharges")}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          hcharges: "Enter Valid Handeling Price"
                        })
                      } else {
                        delete errorObj.hcharges
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
            <Col span={11}>
              <Item label={<Text>Discount</Text>}
                validateStatus={errorObj && errorObj.discount ? "error" : ""}
                help={errorObj && errorObj.discount}
                colon={false}>
                {getFieldDecorator("discount", {
                  rules: [{ required: false, message: "Enter Discount" }],
                })(
                  <Input
                    // type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onKeyUp={e =>
                      form.setFieldsValue({
                        discount: formatValue(e, "noWithDot")
                      })}
                    onWheel={(event) => event.currentTarget.blur()}
                    addonBefore="&#8377;"
                    disabled={!editable}
                    placeholder="Enter Discount"
                    onChange={(e) => calculateOnRoad(e, "discount")}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          discount: "Enter Valid Discount Price"
                        })
                      } else {
                        delete errorObj.discount
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Item label={<Text>TCS (Tax Collected at Source)</Text>}
                validateStatus={errorObj && errorObj.tcs ? "error" : ""}
                help={errorObj && errorObj.tcs}
                colon={false}>
                {getFieldDecorator("tcs", {
                  rules: [{ required: false, message: "Enter TCS" }],
                })(
                  <Input
                    // type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onKeyUp={e =>
                      form.setFieldsValue({
                        tcs: formatValue(e, "noWithDot")
                      })}
                    onWheel={(event) => event.currentTarget.blur()}
                    addonBefore="&#8377;"
                    disabled={!editable}
                    placeholder="Enter TCS"
                    onChange={(e) => calculateOnRoad(e, "tcs")}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          tcs: "Enter Valid TCS Price"
                        })
                      } else {
                        delete errorObj.tcs
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <br />
          <h2 style={{ marginBottom: 0, fontSize: "16px" }} >Insurance</h2>
          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Item label={<Text>1 Year Comprehensive + 5 Year Third Party</Text>}
                validateStatus={errorObj && errorObj.onePlusFive ? "error" : ""}
                help={errorObj && errorObj.onePlusFive}
                colon={false}>
                {getFieldDecorator("onePlusFive", {
                  rules: [{ required: false, message: "Enter Value" }],
                })(
                  <Input
                    // type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onKeyUp={e =>
                      form.setFieldsValue({
                        onePlusFive: formatValue(e, "noWithDot")
                      })}
                    onWheel={(event) => event.currentTarget.blur()}
                    addonBefore="&#8377;"
                    disabled={!editable}
                    placeholder="Enter in rupees"
                    onChange={(e) => calculateOnRoad(e, "onePlusFive")}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          onePlusFive: "Enter Valid  Price"
                        })
                      } else {
                        delete errorObj.onePlusFive
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Item label={<Text>1 Year Comprehensive + 5 Year Third Party ( Zero Depreciation )</Text>}
                validateStatus={errorObj && errorObj.onePlusFiveZero ? "error" : ""}
                help={errorObj && errorObj.onePlusFiveZero}
                colon={false}>
                {getFieldDecorator("onePlusFiveZero", {
                  rules: [{ required: false, message: "Enter Value" }],
                })(
                  <Input
                    // type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onKeyUp={e =>
                      form.setFieldsValue({
                        onePlusFiveZero: formatValue(e, "noWithDot")
                      })}
                    onWheel={(event) => event.currentTarget.blur()}
                    addonBefore="&#8377;"
                    disabled={!editable}
                    placeholder="Enter in rupees"
                    onChange={(e) => calculateOnRoad(e, "onePlusFiveZero")}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          onePlusFiveZero: "Enter Valid  Price"
                        })
                      } else {
                        delete errorObj.onePlusFiveZero
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
            <Col span={11}>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      {/* <Row type="flex" justify="space-between" align="middle">
        <Col span={5}>
          <Item label={<Text>Total - [1+5]</Text>} colon={false}>
            {getFieldDecorator("t1", {
              rules: [{ required: false }],
            })(
              <Input
                // type="number"
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onWheel={(event) => event.currentTarget.blur()}
                disabled={true}
                placeholder="On-Road"
              />
            )}
          </Item>
        </Col> 
    

        <Col span={5}>
          <Item label={<Text>Total - [5+5] ZD</Text>} colon={false}>
            {getFieldDecorator("t4", {
              rules: [{ required: false, message: "Enter Road Tax" }],
            })(
              <Input
                // type="number"
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onWheel={(event) => event.currentTarget.blur()}
                disabled={true}
                placeholder="On-Road"
              />
            )}
          </Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <Item label={<Text>Valid From</Text>} colon={false} required>
            {getFieldDecorator("from", {
              rules: [
                {
                  type: "object",
                  required: true,
                  message: "Select Valid Date",
                },
              ],
            })(
              <DatePicker
                format="DD/MM/YYYY"
                allowClear={true}
                style={{ width: "100%" }}
                disabled={!editable}
                disabledDate={(moment) => checkDateValidity(moment, "from")}
              />
            )}
          </Item>
        </Col>
        <Col span={11}>
          <Item label={<Text>Valid To</Text>} colon={false} required>
            {getFieldDecorator("to", {
              rules: [
                {
                  type: "object",
                  required: true,
                  message: "Select Valid Date",
                },
              ],
            })(
              <DatePicker
                format="DD/MM/YYYY"
                style={{ width: "100%" }}
                allowClear={true}
                disabled={!editable}
                disabledDate={(moment) => checkDateValidity(moment, "to")}
              />
            )}
          </Item>
        </Col>
      </Row>  */}
    </Modal>
  );
};

const WrappedAccessoriesModal = Form.create({ name: "normal_login" })(
  VehiclePriceForm
);

export default WrappedAccessoriesModal;
