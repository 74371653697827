import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  Radio,
  Typography,
  Button,
  message,
  Tag,
  Icon,
  Empty,
  TimePicker,
  Table,
  Steps,
} from "antd";
import { baseUrl, platformApi } from "../../../../api";
import VehicleModal from "./VehicleModal";
import { formatValue } from "../../../../utils";
import { PlusOutlined } from "@ant-design/icons";
import TenureTable from "./TenureTable"

import "./index.less";
import PDFGenerator from "./PDFGenerator";
import FollowUpModal from "./FollowUpModal";
import moment from "moment";
import { withRouter } from "react-router-dom";

const { Item } = Form;
const { Text, Title } = Typography;
const { TextArea } = Input;

const QuotationForm = ({
  history,
  form,
  open,
  close,
  cusData,
  data,
  emitData,
  editable,
  addData,
  leadData,
  setView,
  view,
}) => {
  const [loading, setLoading] = useState(false);
  const [quotationId, setQuotationId] = useState(null);
  const [PDFFlag, setPDFFlag] = useState(false);
  const [chooseModalFlag, setChooseModalFlag] = useState(false);
  const [validNum, setValidNum] = useState(false);
  const [customers, setCustomer] = useState({});
  const [branch, setBranch] = useState(null);
  const [employeeBranch, setEmployeeBranch] = useState([]);
  const [employeeBranchManufacturer, setEmployeeBranchManufacturer] = useState(
    []
  );
  const [leadEntry, setLeadEntry] = useState(null);
  const [fetchedUser, setFetchedUser] = useState([]);
  // const [view, setView] = useState(false)
  const [users, setUser] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [selected, setSelected] = useState(null);
  const [followUpVisible, setFollowUpVisible] = useState(false);
  const [followUpData, setFollowUpData] = useState([]);
  const [PDFData, setPDFData] = useState([]);
  const [provisional, setProvisional] = useState(null);
  const [error, setError] = useState({});
  const [sms, setSms] = useState(null);
  const [smsTime, setSmsTime] = useState(null);
  const [whatsapp, setWhatsApp] = useState(null);
  const [whatsappTime, setWhatsAppTime] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [associatedVehicle, setAssociatedVehicle] = useState([]);
  const [financer, setFinancer] = useState([]);
  const [financer_tenure, setFinancerTenure] = useState([]);
  const [quotationStatus, setQuotationStatus] = useState(0);
  const [flag, setFlag] = useState(false);
  const [sentStatus, setsentStatus] = useState(null);
  const [sentWhatsApp, setSentWhatsApp] = useState(null);
  const [whatsappData, setWhatsappData] = useState(null);
  const [wFlag, setWFlag] = useState(false);
  // const [resetForm, setResetForm] = useState(false);
  const [initialData, setinitialData] = useState([]);
  const [financeType, setFinanceType] = useState("cash");
  const initialState = [{ id: 1, tenure: null, emi: "" }];
  const [emiRows, setEmiRows] = useState(initialState);

const resetFinanceForm = () => {
  setFieldsValue({
    downPayment: null,
    financerName: null
  })
    setEmiRows(initialState);
    handleFinanceData({ data: [] }); 
};


  // const [newName, setNewName] = useState("");
  const {
    getFieldDecorator,
    resetFields,
    getFieldValue,
    setFieldsValue,
  } = form;
  const leadSources = [
    { key: "WALK IN", title: "Walk In" },
    { key: "CALL ENQUIRY", title: "Call Enquiry" },
    { key: "REFERRAL", title: "Referral" },
    { key: "SOCIAL MEDIA", title: "Social Media" },
    { key: "SMS", title: "SMS" },
    { key: "NEWSPAPER", title: "Newspaper" },
    { key: "TELEVISION AD", title: "Television Ad" },
    { key: "LEAFLET", title: "Leaflet" },
  ];
  const customerTypes = [
    { key: "Sales Customer", title: "Sales Customer" },
    { key: "Service Customer", title: "Service Customer" },
    { key: "Non Customer", title: "Non Customer" },
  ];

  const enquiryTypes = ["Hot", "Warm", "Cold"];
  const insTypes = {
    insurance1plus5: "1+5",
    insurance5plus5: "5+5",
    insurance1plus5ZD: "1+5 Zero Dep",
    insurance5plus5ZD: "5+5 Zero Dep",
  };

  useEffect(() => {
    if (cusData) {
      const {
        executive,
        leadSource,
        enquiryType,
        testDriveTaken,
        remarks,
        customerName,
        gender,
        locality,
        branch,
        customerType,
      } = cusData;
      setCustomer(cusData);
      setValidNum(true);
      setFieldsValue({
        customerNumber: cusData.contacts ? cusData.contacts[0].phone : null,
        customerName: cusData.name ? cusData.name : null,
        gender: cusData.gender ? cusData.gender : null,
        locality:
          cusData.address && cusData.address.locality
            ? cusData.address.locality
            : null,
        salesExecutive: executive ? executive.id : undefined,
        customerType: customerType ? customerType : undefined,
        branch: branch ? branch.id : undefined,
      });
    }
  }, [open, cusData]);

  useEffect(() => {
    if(data)
    setinitialData(data.financerTenure);
  else setinitialData([]);

    if (data) {
      console.log("ReceiveData ", data);
      // setPDFData(prev => ({...prev, id: data.id}))
      setQuotationId(data.quotationId);
      // setinitialData(data.financerTenure)
      const {
        proCustomer,
        customer,
        executive,
        leadSource,
        enquiryType,
        testDriveTaken,
        remarks,
        quotationPhone,
        customerName,
        gender,
        locality,
        sms,
        branch,
        expectedDateOfPurchase,
        scheduleDate,
        scheduleTime,
      } = data;

/* ---------------------- For whatsapp message sending ---------------------- */
      // console.log("Whole data");
      // console.log(data)
      if(data?.financer) setFinanceType("finance")
      const whatsappMessageData = {
        customerNo: customer?.contacts ? customer.contacts[0]?.phone:"",
        salesNo: executive?.phone,
        salesName: executive?.profile?.employeeName
      }
      // console.log(whatsappMessageData)
      setWhatsappData(whatsappMessageData)

      setProvisional(proCustomer);
      if (customer) {
        setCustomer(customer);
        setAssociatedVehicle(customer.purchasedVehicle);
      }
      setsentStatus(data.sentStatus && data.sentStatus);
      setSentWhatsApp(data.sentWhatsApp && data.sentWhatsApp);
      let message =
        sms && sms.length > 0
          ? sms.filter((item) => {
              return item.messageId != null;
            })
          : null;
      let whats_app =
        sms && sms.length > 0
          ? sms.filter((item) => {
              return item.whatsAppId != null;
            })
          : null;
      // console.log(message, whats_app);
      console.log(sms);
      if (message?.length) {
        setSms(message[message.length - 1].smsStatus);
        setSmsTime(message[message.length - 1].smsDeliveredTime);
      } else if (whats_app?.length) {
        if (whats_app[whats_app.length - 1].whatsAppDeliveredTime != null) {
          setWhatsApp(whats_app[whats_app.length - 1].whatsAppSmsStatus);
          setWhatsAppTime(
            whats_app[whats_app.length - 1].whatsAppDeliveredTime
          );
        } else {
          console.log(whats_app[whats_app.length - 1]);
          let data = {
            id: whats_app[whats_app.length - 1].id,
            whatsAppId: whats_app[whats_app.length - 1].whatsAppId,
          };
          console.log("Data", data);
          platformApi.post(`/api/sms/whatsAppMsg/`, data).then((result) => {
            const { data } = result;
            // console.log("Data", data);
            if (data.code === 200) {
              const { response } = data;
              if (response.code === 200) {
                console.log("Response", response.data);
                setWhatsApp(response.data.data.status);
                setWhatsAppTime(response.data.data.updated_time);
              }
            }
          });
        }
      } else {
        setSms(sms && sms.length > 0 ? sms[sms.length - 1].smsStatus : null);
        setSmsTime(
          sms && sms.length > 0 ? sms[sms.length - 1].smsDeliveredTime : null
        );
        setWhatsApp(
          sms && sms.length > 0 ? sms[sms.length - 1].whatsAppSmsStatus : null
        );
        setWhatsAppTime(
          sms && sms.length > 0
            ? sms[sms.length - 1].whatsAppDeliveredTime
            : null
        );
      }

      if (data.quotationStatus === "BOOKED") {
        setQuotationStatus(1);
      }
      if (data.quotationStatus === "REJECTED") {
        setQuotationStatus(4);
      }
      setFieldsValue({
        customerNumber:
          quotationPhone ||
          (proCustomer && proCustomer.phone
            ? proCustomer.phone
            : customer
            ? customer.contacts[0].phone
            : proCustomer.refferedBy.contacts[0].phone),
        customerName:
          customerName || (customer ? customer.name : proCustomer.name),
        gender: gender || (customer ? customer.gender : proCustomer.gender),
        locality:
          locality ||
          (proCustomer
            ? proCustomer.locality
            : customer.address && customer.address.locality),
        salesExecutive: executive ? executive.id : undefined,
        financerName:data?.financer? data.financer?.id:undefined,
        leadSource,
        customerType: customer.customerType ? customer.customerType : undefined,
        enquiryType,
        testDriveTaken,
        downPayment: data?.downPayment,
        remarks,
        branch: branch ? branch.id : undefined,
        expectedDateOfPurchase: expectedDateOfPurchase
          ? moment(expectedDateOfPurchase)
          : undefined,
        scheduleDate: scheduleDate ? moment.utc((scheduleDate)) : undefined,
        scheduleTime: scheduleTime ? moment.utc((scheduleTime)) : undefined,
      });
      setSelectedVehicle(data.vehicle);
    } else {
      setFieldsValue({
        branch: employeeBranch && employeeBranch[0] && employeeBranch[0].id,
      });
    }
  }, [data]);

  useEffect(() => {
    if (leadData) {
      setValidNum(true);
      setCustomer(leadData);
      setLeadEntry(leadData);
      setFieldsValue({
        customerNumber: leadData.contacts[0].phone,
        customerName: leadData.name,
        gender: leadData.gender,
        locality: leadData.address.locality,
        customerType: leadData.customerType,
      });
    }

    if (open) {
      let view;
      if (data) {
        view = true;
      } else {
        view = false;
      }
      let branchId = "";
      platformApi.get("/api/financer").then((result)=>{
        let {data} = result;
        if(data.code === 200){
          let {response} = data;
          // console.log(response.data)
          setFinancer(response.data)
        }
      })
      //old function
      platformApi.get("/api/user/currentUser").then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            if (!view) {
              setFieldsValue({
                 salesExecutive: response.data.id,
              });
              employeeChange(response.data.id);
            }
            setBranch(response.data.profile.branch[0]);
            // setEmployeeBranch(response.data.profile.branch);
          }
        }
      });

      // getting all the branches
      platformApi.get("/api/company/branches").then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            if (response.data.length > 0) {
              fetchEmployeeBasedOnBanch(response.data[0].id);
            }
            setEmployeeBranch(response.data);
          }
        }
      });
    }
  }, [open]);

  const fetchEmployeeBasedOnBanch = (id) => {
    console.log(id);
    platformApi
      .post("/api/user/get", {
        size: 1000,
        page: 1,
      })
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            let employees = [];
            response.data.users.forEach((element) => {
              let sale = false;
              element.profile.department.departmentType.forEach((data) => {
                if (data === "Sales") {
                  sale = true;
                  return;
                }
              });
              //sale check
              if (sale) {
                //branch check
                let branches = false;
                element.profile.branch.forEach((data) => {
                  if (data.id === id) {
                    branches = true;
                    return;
                  }
                });
                if (branches) {
                  if (element.status === true) {
                    employees.push(element);
                  }
                }
              }
            });
            setUser(employees);
          }
        }
      })
      .catch((error) => {
        message.error("Unable to fetch Users", 1);
        console.error("Error on Quotations Form : ", error);
      });
  };

  const getDisabledHours = () => {
    var hours = [0,1,2,3,4,5,6,7,20,21,22,23];
    if (scheduledDate < new Date()) {
      for (var i = 0; i < moment().hour(); i++) {
        hours.push(i);
      }
    }
    return hours;
  };

  const getDisabledMinutes = (selectedHour) => {
    var minutes = [];
    if (scheduledDate < new Date()) {
      if (selectedHour === moment().hour()) {
        for (var i = 0; i < moment().minute(); i++) {
          minutes.push(i);
        }
      }
    }
    return minutes;
  };

  useEffect(() => {
    if (!data) {
      setFieldsValue({
        branch: employeeBranch && employeeBranch[0] && employeeBranch[0].id,
      });
    }
  }, [employeeBranch]);

  const handleSubmit = () => {
    form.validateFields((validationError) => {
      if (
        validationError === null &&
        !error.PHONE &&
        !error.LOC &&
        !error.NAME
      ) {
        if (!selectedVehicle[0]) {
          message.error("Select a vehicle", 2);
          return;
        }
        if (!validNum) {
          message.error("Enter valid fields", 1);
          return;
        }
        let branch = getFieldValue("branch");
        platformApi
          .post("/api/idGenerate/quotation", { branch })
          .then((result) => {
            let { data } = result;
            if (data.code === 200) {
              let { response } = data;
              if (response.code === 200) {
                setQuotationId(response.data.QuotationId);
                provisional
                  ? (provisional.customerId = response.data.customerId)
                  : {};
                customers
                  ? {}
                  : (provisional.phone = getFieldValue("customerNumber"));
                const quotation = {
                  ...provisional,
                  quotationId: response.data.QuotationId,
                  phone: getFieldValue("customerNumber"),
                  id: response.data.id,
                  quotationPhone: getFieldValue("customerNumber"),
                  vehicle: selectedVehicle,
                  leadSource: getFieldValue("leadSource"),
                  customerType: getFieldValue("customerType"),
                  enquiryType: getFieldValue("enquiryType"),
                  testDriveTaken: getFieldValue("testDriveTaken"),
                  remarks: getFieldValue("remarks"),
                  executive: getFieldValue("salesExecutive"),
                  financer: getFieldValue("financerName"),
                  financer_tenure:financer_tenure?.data || [],
                  downPayment:Number(getFieldValue("downPayment")),
                  scheduleDate: moment(getFieldValue("scheduleDate")).format(
                    "DD-MM-YYYY"
                  ),
                  scheduleTime: moment(getFieldValue("scheduleTime")).format(
                    "HH:mm:ss"
                  ),
                  branch: getFieldValue("branch"),
                  expectedDateOfPurchase: moment(
                    getFieldValue("expectedDateOfPurchase")
                  ).format("DD-MM-YYYY"),
                };
                customers
                  ? (quotation.customer = customers.id)
                  : ((quotation.customer = null),
                    (quotation.customerId = response.data.customerId));
                // data ? (quotation.id = data.id) : {};
                console.log("Handle Submit", quotation);
                emitData(quotation, (isSuccess, data) => {
                  setLoading(false);
                  if (isSuccess) {
                    generatePDFData(data.id, data);
                    setPDFFlag(true);
                    close();
                  }
                });
              }
            }
          })
          .catch((error) => {
            message.error("Unable to fetch Id", 1);
            console.error("Error on Quotations Form : ", error);
          });
        setLoading(true);
      }
    });
  };

  const clearFields = () => {
    resetFields();
    resetFinanceForm();
    setQuotationId(null);
    setValidNum(false);
    setSelectedVehicle([]);
    setProvisional(null);
    setUser([]);
    setFinancer([]);
    setFinancerTenure([]);
    setFinanceType("cash");
    setCustomer([]);
    setSelected(null);
    setError({});
    setEmployeeBranch([]);
    setEmployeeBranchManufacturer([]);
    setFetchedUser([]);
    setSms("");
    setSmsTime(null);
    setBranch(null);
    setScheduledDate(null);
    setQuotationStatus(0);
    setAssociatedVehicle([]);
    setWhatsappData(null);
  };

  const searchCustomer = (number) => {
    // const number = await form.getFieldValue("customerNumber");
    platformApi
      .get("api/customer/phone/" + number)
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            if (response.data[0]) {
              message.success("User fetched", 2);
              if (response.data.length === 1) {
                setCustomer(response.data[0]);
                form.setFieldsValue({
                  customerName: response.data[0].name,
                  gender: response.data[0].gender,
                  customerType: response.data[0].customerType,
                  locality:
                    response.data[0].address &&
                    response.data[0].address.locality,
                });
                setAssociatedVehicle(response.data[0].purchasedVehicle);
              } else if (response.data.length > 1) {
                setFetchedUser(response.data);
                setCustomer(response.data[0]);
                form.setFieldsValue({
                  customerName: response.data[0].id,
                  gender: response.data[0].gender,
                  customerType: response.data[0].customerType,
                  locality:
                    response.data[0].address &&
                    response.data[0].address.locality,
                });
              }
            } else {
              message.error("Customer not found");
              form.setFieldsValue({
                customerType: "Non Customer",
              });
              newAlteredCustomer();
            }
          }
        }
      })
      .catch((error) => {
        console.error("Error on Quotations Form : ", error);
      });
  };

  const addVehicle = (vehicle) => {
    // console.log(vehicle);
    const vehicleData = {
      ...vehicle,
      vehicleDetail:vehicle.vehicleDetail.model,
      price:vehicle.vehicleDetail.price
    }
    console.log(vehicleData);
    setSelectedVehicle([...selectedVehicle, vehicleData]);
  };

  const removeSelectedVehicle = (index) => {
    selectedVehicle.splice(index, 1);
    setSelectedVehicle([...selectedVehicle]);
  };

  const generatePDFData = (QuoId, mData) => {
    mData = mData ? mData : data;

    // console.log("mData-ID---" + QuoId);

    // console.log("mData----" + JSON.stringify(mData));

    if (mData) {
      setBranch(mData.branch);
      const SMSData = {
        qtno: quotationId
          ? quotationId
          : mData.quotationId
          ? mData.quotationId
          : null,
        vname: mData.vehicle.map(
          (currentData) => currentData.vehicleDetail.modelName
        ),
        slex: mData.executive
          ? `${mData.executive.profile.employeeName}(+91-${mData.executive.phone})`
          : null,
        link: `api/quotation/generatePdf/${mData.id}?withBrochure=true`,
        linkWithoutBrochure: `api/quotation/generatePdf/${mData.id}`,
        dlr: `${branch ? branch.name || mData.branch.name : null}`,
      };
      if (customers) {
        SMSData.cname = customers.name;
        SMSData.customerId = customers.id;
        SMSData.phone = mData.quotationPhone || customers.contacts[0].phone;
      } else {
        SMSData.cname = provisional.name;
        SMSData.phone =
          mData.quotationPhone ||
          (provisional.phone
            ? provisional.phone
            : provisional.refferedBy.contacts[0].phone);
      }
      SMSData.id = mData.id;
      // console.log(SMSData);
      setPDFData(SMSData);
      return;
    }
    const Data = {};
    Data.id = QuoId ? QuoId : data.id;
    // console.log("Data-------" + Data);
    setPDFData(Data);
  };

  const changeSelectedUser = (id) => {
    let users = fetchedUser;
    let user = users.filter((data) => data.id === id);
    setCustomer(user[0]);
    form.setFieldsValue({
      customerName: user[0].id,
      gender: user[0].gender,
      locality: user[0].address && user[0].address.locality,
      customerType: user[0].customerType,
    });
  };

  const newAlteredCustomer = () => {
    setCustomer({ ...customers, id: null });
    setFetchedUser([]);
    setLeadEntry(null);
    form.setFieldsValue({
      customerName: undefined,
      gender: undefined,
      locality: undefined,
      customerType: "Non Customer",
    });
    setAssociatedVehicle([]);
  };

  const employeeChange = (id) => {
    platformApi.get(`/api/user/getUser/${id}`).then((res) => {
      const { data } = res;
      if (data.code === 200) {
        let branchArray = [];
        let selectedBranch = JSON.parse(localStorage.getItem("branch"));
        data.data.profile.branch.map((eachBranch) => {
          selectedBranch.map((eachSelected) => {
            if (eachSelected.id === eachBranch.id) {
              branchArray.push(eachBranch);
            }
          });
        });
        setBranch(branchArray);
        setEmployeeBranchManufacturer(
          branchArray[0] && branchArray[0].manufacturer
            ? branchArray[0].manufacturer
            : []
        );
      }
    });
  };

  const settingBranchBasedManufacturer = (id) => {
    employeeBranch.map((eachBranch) => {
      if (eachBranch.id === id) {
        setEmployeeBranchManufacturer(eachBranch.manufacturer);
      }
    });
    platformApi
      .post("/api/idGenerate/quotation", { branch: id })
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setQuotationId(response.data.QuotationId);
          }
        }
      })
      .catch((error) => {
        message.error("Unable to fetch Id", 1);
        console.error("Error on Quotations Form : ", error);
      });
  };

  const handleFinanceData = (data) => {
    // console.log("Payload:", data);
    setFinancerTenure(data)
  };

  
  const filterMethod = (input, option) =>
    option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const filterType = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  const toDisableLocality = () => {
    if (Array.isArray(customers)) {
      if (customers.length) return true;
      else return false;
    } else if (customers)
      if (customers.address && customers.address.locality) return true;
      else return false;
    else return false;
  };
  const columns = [
    {
      title: "Reg No.",
      dataIndex: "registerNo",
      key: "registerNo",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Vehicle",
      dataIndex: "vehicle.modelName",
      key: "vehicle.modelName",
      render: (record) => <span>{record}</span>,
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <a>Delete</a>
    //     </Space>
    //   ),
    // },
  ];
  const { Step } = Steps;
  return (
    <div>
      <Modal
        title={<Title level={4}>Quotations</Title>}
        visible={open}
        onCancel={() => {
          close();
          clearFields();
        }}
        wrapClassName="Quotations-Form"
        footer={[
          <Button
            key={true}
            onClick={() => {
              clearFields();
              close();
            }}
          >
            {editable ? "Cancel" : "Close"}
          </Button>,
          editable && (
            <Button type="primary" loading={loading} onClick={handleSubmit}>
              Save
            </Button>
          ),
        ]}
      >
        <Form>
          <Row type="flex" justify="space-around">
            <Col span={11}>
              {data ? (
                <Item style={{ marginTop: "20px" }}>
                  <Text strong>Quotation Id:</Text> {quotationId}
                </Item>
              ) : (
                <div></div>
              )}
            </Col>
            <Col span={11}>
              {data && (
                <Item label="Created On" colon={false}>
                  <DatePicker
                    style={{ width: "100%" }}
                    value={moment(data.createdAt)}
                    format="DD/MM/YYYY"
                    disabled={true}
                  />
                </Item>
              )}
            </Col>
          </Row>
          {/* <Col span={11}> */}
          <Row type="flex" justify="space-around">
            <Col span={11}>
              <Item label="Branch" colon={false}>
                {getFieldDecorator("branch", {
                  rules: [
                    {
                      required: true,
                      message: "Pick Branch",
                    },
                  ],
                })(
                  <Select
                    showAction={["click", "focus"]}
                    placeholder="Select Branch"
                    disabled={
                      !editable || (selectedVehicle && selectedVehicle[0])
                    }
                    onChange={(id) => {
                      fetchEmployeeBasedOnBanch(id);
                      settingBranchBasedManufacturer(id);
                    }}
                  >
                    {employeeBranch.map((eachBranch) => {
                      return (
                        <Select.Option key={eachBranch.id}>
                          {eachBranch.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </Item>
            </Col>
            <Col span={11}>
              <Item label="Sales Executive" colon={false}>
                {getFieldDecorator("salesExecutive", {
                  rules: [
                    { required: true, message: "Select Sales Executive" },
                  ],
                })(
                  <Select
                    showSearch
                    showAction={["click", "focus"]}
                    // filterOption={filterType}
                    disabled={
                      !editable || (selectedVehicle && selectedVehicle[0])
                    }
                    placeholder="Sales Executive"
                    onChange={(id) => {
                      employeeChange(id);
                    }}
                  >
                    {users.map((user) => (
                      <Select.Option key={user.id}>
                        {user.profile.employeeName}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
          </Row>
          {/* </Col> */}

          <Row type="flex" justify="space-around">
            <Col span={11}>
              <Item
                label={<Text>Customer Phone</Text>}
                colon={false}
                validateStatus={error.PHONE && error.PHONE.type}
                help={error.PHONE && error.PHONE.message}
              >
                <Row>
                  <Col span={18}>
                    {getFieldDecorator("customerNumber", {
                      rules: [
                        { required: true, message: "Enter Mobile Number" },
                      ],
                    })(
                      <Input
                        disabled={!editable || leadEntry}
                        addonBefore="+91"
                        min="0"
                        maxLength={10}
                        required
                        onKeyUp={(e) =>
                          form.setFieldsValue({
                            customerNumber: formatValue(e, "onlyNo"),
                          })
                        }
                        onInput={(event) => {
                          if (event.target.value.length === 10) {
                            searchCustomer(event.target.value);
                          }
                          if (event.target.value.length < 10) {
                            setFetchedUser([]);
                            setFieldsValue({
                              customerName: undefined,
                              gender: undefined,
                              locality: undefined,
                            });
                          }
                          if (event.target.checkValidity()) {
                            setValidNum(true);
                            delete error.PHONE;
                            setError(error);
                          } else {
                            setValidNum(false);
                            setError({
                              ...error,
                              PHONE: {
                                type: "error",
                                message: "Enter Valid Mobile Number",
                              },
                            });
                          }
                        }}
                        onChange={() => customers && setCustomer(null)}
                        pattern="^\d{10}$"
                        placeholder="Mobile Number"
                      />
                    )}
                  </Col>
                </Row>
              </Item>
            </Col>
            <Col span={11}>
              <Item
                label={<Text>Customer Name</Text>}
                colon={false}
                validateStatus={error.NAME && error.NAME.type}
                help={error.NAME && error.NAME.message}
              >
                {!fetchedUser[0] ? (
                  getFieldDecorator("customerName", {
                    rules: [{ required: true, message: "Enter Customer Name" }],
                  })(
                    <Input
                      disabled={!editable || leadEntry}
                      onKeyUp={(e) => {
                        form.setFieldsValue({
                          customerName: formatValue(e, "allCaps"),
                        });
                        if (form.getFieldValue("customerName").length === 0) {
                          delete error.NAME;
                        }
                      }}
                      pattern="^[A-Z][a-zA-Z\s]*[a-zA-Z]+$"
                      onInput={(event) => {
                        setProvisional({
                          ...provisional,
                          name: formatValue(event, "allCaps"),
                          customer: null,
                        });
                        setCustomer({});

                        if (event.target.value.trim()) {
                          delete error.NAME;
                          setError(error);
                        } else {
                          setError({
                            ...error,
                            NAME: {
                              type: "error",
                              message: "Enter Valid Customer Name",
                            },
                          });
                        }
                      }}
                      placeholder="Customer Name"
                    />
                  )
                ) : (
                  <Row gutter={16}>
                    <Col span={18}>
                      {getFieldDecorator("customerName", {
                        rules: [
                          { required: true, message: "Enter Customer Name" },
                        ],
                      })(
                        <Select
                          showAction={["click", "focus"]}
                          onChange={(id) => changeSelectedUser(id)}
                          showSearch
                          filterOption={filterType}
                        >
                          {fetchedUser.map((user) => (
                            <Select.Option key={user.id}>
                              {user.name}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Col>
                    <Col span={2}>
                      <Button onClick={newAlteredCustomer} type="primary">
                        New
                      </Button>
                    </Col>
                  </Row>
                )}
              </Item>
            </Col>
          </Row>

          <Row type="flex" justify="space-around">
            <Col span={11}>
              <Item label={<Text>Gender</Text>} colon={false}>
                {getFieldDecorator("gender", {
                  rules: [{ required: true, message: "Select Gender" }],
                })(
                  <Radio.Group
                    disabled={
                      !editable ||
                      leadEntry ||
                      (fetchedUser[0] && fetchedUser[0].gender)
                    }
                    onChange={(event) => {
                      setProvisional({
                        ...provisional,
                        gender: event.target.value,
                      });
                    }}
                    buttonStyle="solid"
                  >
                    <Radio.Button value="Male">Male</Radio.Button>
                    <Radio.Button value="Female">Female</Radio.Button>
                  </Radio.Group>
                )}
              </Item>
            </Col>
            <Col span={11}>
              <Row>
                <Item label={<Text>Customer Type</Text>} colon={false}>
                  {getFieldDecorator("customerType", {
                    rules: [
                      { required: false, message: "Select Customer Type" },
                    ],
                  })(
                    <Select
                      showAction={["click", "focus"]}
                      disabled={true}
                      placeholder="Customer Type"
                    >
                      {customerTypes.map((source) => (
                        <Select.Option value={source.key} key={source.key}>
                          {source.title}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Row>
            </Col>
          </Row>

          <Row type="flex" justify="space-around">
            <Col span={11}>
              <Item
                label={<Text>Locality</Text>}
                colon={false}
                validateStatus={error.LOC && error.LOC.type}
                help={error.LOC && error.LOC.message}
              >
                {getFieldDecorator("locality", {
                  rules: [{ required: true, message: "Enter Locality" }],
                })(
                  <Input
                    placeholder="Locality"
                    pattern="^([a-zA-Z0-9-/,]+[ -/,])*\w+.?$"
                    onKeyUp={(e) => {
                      form.setFieldsValue({
                        locality: formatValue(e, "allCaps"),
                      });
                      if (form.getFieldValue("locality").length === 0) {
                        delete error.LOC;
                      }
                    }}
                    disabled={!editable || leadEntry || toDisableLocality()}
                    onInput={(event) => {
                      setProvisional({
                        ...provisional,
                        locality: formatValue(event, "allCaps"),
                      });
                      if (event.target.value.trim()) {
                        delete error.LOC;
                        setError(error);
                      } else {
                        setError({
                          ...error,
                          LOC: {
                            type: "error",
                            message: "Enter Valid Locality",
                          },
                        });
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
            <Col span={11}>
              <Row gutter={16}>
                <Col span={12}>
                  <Item label="Schedule Follow-Up Date" colon={false}>
                    {getFieldDecorator("scheduleDate", {
                      rules: [
                        {
                          required: true,
                          message: "Pick Follow Up Date",
                        },
                      ],
                    })(
                      <DatePicker
                        style={{ width: "100%" }}
                        disabled={!editable}
                        format="DD/MM/YYYY"
                        // value={}
                        onChange={(date) => {
                          if (
                            form.getFieldValue("expectedDateOfPurchase") &&
                            date._d >
                              form.getFieldValue("expectedDateOfPurchase")._d
                          ) {
                            form.setFieldsValue({
                              expectedDateOfPurchase: "",
                            });
                          }
                          setScheduledDate(date),
                            form.setFieldsValue({ scheduleTime: null });
                        }}
                        disabledDate={(moment) =>
                          Date.now() - 1000 * 60 * 60 * 24 > moment._d
                        }
                        showToday={false}
                      />
                    )}
                  </Item>
                </Col>

                <Col span={12}>
                  <Item label="Schedule Follow-Up Time" colon={false}>
                    {getFieldDecorator("scheduleTime", {
                      rules: [
                        {
                          required: true,
                          message: "Pick Follow Up Time",
                        },
                      ],
                    })(
                      <TimePicker
                        style={{ width: "100%" }}
                        // use12Hours
                        showTime={{ format: "HH:mm" }}
                        disabled={!editable || !scheduledDate}
                        disabledHours={getDisabledHours}
                        disabledMinutes={getDisabledMinutes}
                        format="HH:mm"
                        minuteStep={15}
                      />
                    )}
                  </Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row type="flex" justify="space-around">
            <Col span={11}>
              <Row>
                <Item label={<Text>Lead Source</Text>} colon={false}>
                  {getFieldDecorator("leadSource", {
                    rules: [{ required: true, message: "Enter Lead Source" }],
                  })(
                    <Select
                      disabled={!editable}
                      showAction={["click", "focus"]}
                      placeholder="Lead Source"
                    >
                      {leadSources.map((source) => (
                        <Select.Option value={source.key} key={source.key}>
                          {source.title}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Row>
            </Col>
            <Col span={11}>
              <Item label="Expected Date of Purchase" colon={false}>
                {getFieldDecorator("expectedDateOfPurchase", {
                  rules: [
                    {
                      required: true,
                      message: "Enter Expected Date of Purchase",
                    },
                  ],
                })(
                  <DatePicker
                    style={{ width: "100%" }}
                    disabled={!editable || !scheduledDate}
                    format="DD/MM/YYYY"
                    disabledDate={(moment) => {
                      return scheduledDate._d > moment._d;
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-around">
            <Col span={11}>
              <Item label={<Text>Test Drive Taken</Text>} colon={false}>
                {getFieldDecorator("testDriveTaken", {
                  rules: [
                    {
                      required: true,
                      message: "Test drive taken is required",
                    },
                  ],
                })(
                  <Radio.Group disabled={!editable} buttonStyle="solid">
                    <Radio.Button value={true}>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                )}
              </Item>
            </Col>
            <Col span={10}>
            {/* Financer Name & Type */}
            <Row gutter={16} style={{ marginTop: "25px", marginLeft: "2px" }}>
            <Col span={12}>
              <Item label={<Text>Payment Type</Text>} colon={false}>
                
                  <Radio.Group value={financeType} 
                   onChange={(e) => {
                    resetFinanceForm();
                    setFinanceType(e.target.value);
                  }}
           disabled={!editable} buttonStyle="solid">
                    <Radio.Button value={"cash"}>Cash</Radio.Button>
                    <Radio.Button value={"finance"}>Finance</Radio.Button>
                  </Radio.Group>
              </Item>
            </Col>
            
            </Row>

            
            <Row Row gutter={16}  style={{ marginLeft: "2px" }}>
            {financeType === "finance" &&     <Col span={11} >
            <Item label={<Text>Financer Name</Text>} colon={false}>
            {getFieldDecorator("financerName")(
            <Select
            showSearch
            showAction={["click", "focus"]}
            disabled={!editable||financeType=="cash"}
            placeholder="Select Financer"
            onChange={(id) => {
            employeeChange(id);
            }}
            >
            {financer &&
            financer?.map((user) => (
              <Select.Option key={user.id} value={user.id}>
                {user.name}
              </Select.Option>
            ))}
            </Select>
            )}
            </Item>
      </Col>}
      {financeType === "finance" && <Col span={11} >
        <Item label={<Text>Down Payment</Text>} colon={false}>
{getFieldDecorator("downPayment")(
          <Input
          type="number"
          // onChange={(e) => {
          // const value = e.target.value ? Number(e.target.value) : ""; 
          // }}
          placeholder="Enter down payment"
          max={60}
          style={{ width: "100%" }}
          disabled={!editable | financeType=="cash"} />)}
          </Item>
</Col>}
            </Row>

            {/* Tenure Table (Visible Only if Finance is Selected) */}
            {financeType === "finance" && (
            <Row gutter={16} style={{ marginTop: "20px", marginLeft: "11px" }}>
            <TenureTable
            editable={editable}
            onDataChange={handleFinanceData}
            initialData={initialData}
            emiRows={emiRows}
            setEmiRows={setEmiRows}
            />
            </Row>
            )}

            {/* Select Vehicle Button */}
            <Row gutter={16} style={{ marginTop: "25px", marginLeft: "2px" }}>
            {editable && (
            <Item>
            <Button onClick={() => setChooseModalFlag(true)}>Select Vehicle</Button>
            </Item>
            )}
            </Row>
            </Col>
          </Row>
          <Row type="flex" justify="space-around">
            <Col span={11}>
              <Item label={<Text>Enquiry Type</Text>}>
                {getFieldDecorator("enquiryType", {
                  rules: [{ required: true, message: "Enter Enquiry Type" }],
                })(
                  <Select
                    disabled={!editable}
                    showAction={["click", "focus"]}
                    placeholder="Enquiry Type"
                  >
                    {enquiryTypes.map((type) => (
                      <Select.Option value={type} key={type}>
                        {type}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
            <Col span={11}>
              <Item
                label={
                  <Text>
                    <span style={{ color: "red" }}>*</span> Vehicle Name
                  </Text>
                }
                colon={false}
              >
                {selectedVehicle && selectedVehicle[0] ? (
                  selectedVehicle.map((vehicle, index) => (
                    <Tag key={true} closable={false}>
                      <Text
                        strong
                        onClick={() => {
                          setSelected(index);
                          setChooseModalFlag(true);
                        }}
                      >
                        {vehicle.vehicleDetail?.model?.modelCode ||vehicle.vehicleDetail.modelCode } -{" "}
                        {vehicle.vehicleDetail?.model?.modelName || vehicle.vehicleDetail.modelName}
                      </Text>
                      {editable && (
                        <Icon
                          color
                          type="close"
                          onClick={() => removeSelectedVehicle(index)}
                        />
                      )}
                    </Tag>
                  ))
                ) : (
                  <Empty
                    imageStyle={{ height: 20, fontSize: 20 }}
                    description={
                      <Text disabled strong>
                        No Vehicle Chosen
                      </Text>
                    }
                    image={
                      <Icon
                        theme="twoTone"
                        twoToneColor="#BDBDBD"
                        type="container"
                      />
                    }
                  />
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-around">
            <Col span={11}>
              {/* <Row> */}
              <Item label={<Text>Remarks</Text>} colon={false}>
                {getFieldDecorator("remarks")(
                  <TextArea
                    onKeyUp={(e) =>
                      form.setFieldsValue({
                        remarks: formatValue(e, "firstCaps"),
                      })
                    }
                    disabled={!editable}
                    autoSize={{ minRows: 5, maxRows: 5 }}
                  />
                )}
              </Item>
              {/* </Row> */}
            </Col>
            <Col span={11}>
              <Item label={<Text>Associated vehicles</Text>} colon={false}>
                <Table columns={columns} dataSource={associatedVehicle} />
              </Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-around">
            <Col span={11}>
              <h4 style={{ fontWeight: "bold", marginLeft: "20px" }}>
                Status:{" "}
                {quotationStatus == 4 ? <span>Discarded Quotation</span> : null}
              </h4>
              {/* <Item label={<Text> </Text>} colon={false}> */}
              {quotationStatus != 4 ? (
                <Steps progressDot current={quotationStatus}>
                  <Step title="Quoted" description="Customer got Quotation." />
                  <Step
                    title="Booked"
                    description="Customer Booked the Vehicle."
                  />
                  <Step title="Sold" description="We sold the vehicle." />
                </Steps>
              ) : null}

              <Row>
                <Col span={18}></Col>
              </Row>
              {/* </Item> */}
            </Col>
            <Col span={11} style={{ marginTop: "25px" }}>
              <Item>
                {data && (
                  <Row type="flex" justify="start">
                    <Col span={8}>
                      {!view && data ? (
                        <Button
                          onClick={() => {
                            // console.log("followup to quotation", data.customer.contacts[0].phone, data.scheduleDateAndTime)
                            history.push(
                              `/autoadmin/follow-ups/${data.customer.contacts[0].phone}/fupdate/${data.scheduleDateAndTime}`
                            );
                          }}
                        >
                          Follow-Up
                        </Button>
                      ) : null}
                    </Col>
                    <Col span={8}>
                      <Button
                        onClick={() => {
                          generatePDFData();
                          setPDFFlag(true);
                        }}
                        type="default"
                      >
                        PDF
                      </Button>
                    </Col>
                  </Row>
                )}
              </Item>
            </Col>
          </Row>
        </Form>
        <VehicleModal
          data={selectedVehicle && selectedVehicle[selected]}
          emitData={addVehicle}
          open={chooseModalFlag}
          editable={editable && selected === null}
          selected={selectedVehicle}
          manufacturer={employeeBranchManufacturer}
          close={() => {
            setSelected(null);
            setChooseModalFlag(false);
          }}
        />
      </Modal>
      <FollowUpModal
        dataSource={followUpData}
        open={followUpVisible}
        close={() => {
          setFollowUpVisible(false);
        }}
      />
      <PDFGenerator
        flag={flag}
        setFlag={setFlag}
        setWFlag={setWFlag}
        wFlag={wFlag}
        pdfData={PDFData}
        open={PDFFlag}
        close={() => {
          if (!open) clearFields();
          setPDFFlag(false);
          setFlag(false);
          setWFlag(false);
        }}
        sentStatus={sentStatus}
        sentWhatsApp={sentWhatsApp}
        sms={sms}
        smsTime={smsTime}
        setSmsTime={setSmsTime}
        whatsapp={whatsapp}
        whatsappTime={whatsappTime}
        setWhatsAppTime={setWhatsAppTime}
        editable={editable}
        whatsappData={whatsappData}
      />
    </div>
  );
};
const WrappedQuotationModal = Form.create({ name: "normal_login" })(
  QuotationForm
);

export default withRouter(WrappedQuotationModal);
