import React, { useState, useEffect } from 'react';
import { Table, Select, Row, Col, Form, Typography, message, Button } from "antd";
import { v4 as uuidv4 } from 'uuid';
import { platformApi } from '../../../../api';

const { Title } = Typography;
const { Option } = Select;

const AddVehicleInventory = ({ form }) => {
    const [branches, setBranch] = useState([]);
    const [vehicles, setVehicle] = useState([]);
    // const [colors, setColor] = useState([]);
    const [chassisNo, setchassisNo] = useState([]);
    const [challanData, setChallanData] = useState([]);
    const { getFieldDecorator, getFieldValue } = form;

    const generateRow = () => ({
        id: uuidv4(),
        model: null,
        // color: null,
        chassisNo: null,
    });

    useEffect(() => {
        platformApi.get("/api/user/branch").then((result) => {
            const { data } = result;
            if (data.code === 200) setBranch(data.data);
        });
        setChallanData([generateRow()]);
    }, []);

    const columns = [
        {
            title: "Model",
            key: "model",
            render: (text, record, index) => (
                <Select
                    value={record.model}
                    onChange={(value) => handleModelChange(value, record.id)}
                    placeholder="Model"
                    showSearch
                >
                    {vehicles.map(vehicle => (
                        <Option key={vehicle.vehicle} value={vehicle.vehicle}>
                            {vehicle.modelCode} - {vehicle.modelName}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Chassis No",
            key: "chassisNo",
            render: (text, record) => (
                <Select
                    value={record.chassisNo}
                    onChange={(value, option) => handleChassisChange(value, record.id,option.key)}
                    placeholder="Chassis No"
                    disabled={!record.model}
                >
                    {chassisNo.filter(c => !challanData.some(d => d.chassisNo === c.chassisNumber)).map(data => (
                            <Option key={data.vehicleInventory} value={data.chassisNumber}>
                                {data.chassisNumber}
                            </Option>
                        ))}
                </Select>
            ),
        },
        {
            title: "Color",
            key: "color",
            render: (text, record) => (
                <Select
                    value={record.color}
                    placeholder="Color"
                    disabled={true} // Disable editing since it's auto-populated
                >
                    {record.color && (
                        <Option key={record.color} value={record.color}>
                            {record.color}
                        </Option>
                    )}
                </Select>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <Button 
                    type="danger" 
                    icon="delete" 
                    onClick={() => handleDelete(record.id)}
                >
                    Delete
                </Button>
            ),
        }
    ];

    const handleModelChange = (value, rowId) => {
        setChallanData(prev => prev.map(row => 
            row.id === rowId ? { 
                ...generateRow(), 
                id: rowId,
                model: value,
                chassisNo: null ,
                chassisKey:null
            } : row
        ));

        const vehicle = value;
        platformApi.post(`/api/inventoryTransfers/chassisNumbers`, {
            branch: getFieldValue("fromBranch"),
            vehicle,
        }).then((result) => {
            if (result.status === 200) setchassisNo(result.data);
            else message.error("Error in Fethching Chassis Number");
        });
    };

    const handleChassisChange = (value, rowId, chassisKey) => {
        setChallanData(prev => {
            const updated = prev.map(row => {
                if (row.id === rowId) {
                    const selectedChassis = chassisNo.find(c => c.chassisNumber === value);
                    return {
                        ...row,
                        chassisNo: value,
                        chassisKey,
                        color: selectedChassis?.color || null // Set the color from the API response
                    };
                }
                return row;
            });
    
            if (updated.every(r => r.chassisNo)) {
                return [...updated, generateRow()];
            }
            return updated;
        });
    };

    const handleDelete = (rowId) => {
        setChallanData(prev => {
            const filtered = prev.filter(row => row.id !== rowId);
            return filtered.length === 0 ? [generateRow()] : filtered;
        });
    };

    const handleFromBranchChange = (value) => {
        form.setFieldsValue({ toBranch: null });
        platformApi.post(`api/inventoryTransfers/vehicles`, { branch: value })
            .then((result) => {
                const { data } = result;
                if (data.code === 200) setVehicle(data.data);
            });
        setChallanData([generateRow()]);
    };

    const handleSubmit = () => {
        const toBranch = getFieldValue("toBranch");
        const fromBranch = getFieldValue("fromBranch");
        const validData = challanData.filter(row => 
            row.model && row.chassisNo
        );

        if (!toBranch || !fromBranch) {
            message.error("Please select both branches!");
            return;
        }

        if (validData.length === 0) {
            message.error("Please add at least one valid vehicle entry!");
            return;
        }

        const payload = {
            vehicles: validData.map(row => row.chassisKey),
            to_branch: toBranch,
            from_branch: fromBranch
        };

        platformApi.put(`/api/inventoryTransfers/vehicles`, payload)
            .then((result) => {
                if (result.status === 200) {
                    message.success("Transfer successful!");
                    setChallanData([generateRow()]);
                    form.resetFields();
                }
            })
            .catch(error => {
                message.error(`Transfer failed: ${error.message}`);
            });
    };

    return (
        <div>
            <Title className="heading" style={{ width: "60%" }} level={4}>
                Vehicle Transfer
            </Title>
            <Form>
                <Row gutter={30}>
                    <Col span={8}>
                        <Form.Item label="From Branch">
                            {getFieldDecorator("fromBranch", {
                                rules: [{ required: true }]
                            })(
                                <Select
                                    placeholder="Select branch"
                                    onChange={handleFromBranchChange}
                                >
                                    {branches.map(branch => (
                                        <Option key={branch.id} value={branch.id}>
                                            {branch.name}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="To Branch">
                            {getFieldDecorator("toBranch", {
                                rules: [{ required: true }]
                            })(
                                <Select
                                    placeholder="Select branch"
                                    disabled={!getFieldValue("fromBranch")}
                                >
                                    {branches
                                        .filter(b => b.id !== getFieldValue("fromBranch"))
                                        .map(branch => (
                                            <Option key={branch.id} value={branch.id}>
                                                {branch.name}
                                            </Option>
                                        ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Table
                    size="small"
                    pagination={false}
                    scroll={{ y: 300 }}
                    columns={columns}
                    dataSource={challanData}
                    rowKey="id"
                    bordered
                    // footer={() => (
                    //     <Button type="dashed" onClick={() => setChallanData([...challanData, generateRow()])}>
                    //         Add Row
                    //     </Button>
                    // )}
                />

                <Button 
                    type="primary" 
                    style={{ marginTop: 16 }}
                    onClick={handleSubmit}
                    disabled={ !getFieldValue("fromBranch") ||
                        !getFieldValue("toBranch")}
                >
                    Submit Transfer
                </Button>
            </Form>
        </div>
    );
};

const WrappedAddVehicleInventory = Form.create()(AddVehicleInventory);
export default WrappedAddVehicleInventory;