import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Button,
  Input,
  Slider,
  message,
  DatePicker,
  Typography,
  Checkbox,
} from "antd";
import { platformApi } from "../../../../../api";
import "./index.less";
import moment from "moment";
const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

function Filters({
  form,
  filteropen,
  setId,
  id,
  setDataSource,
  dataSource,
  setFilterData,
  data,
  editable,
  setLoading
}) {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
    resetFields,
  } = form;
  const [model, setModels] = useState([]);
  const Filters = [
    { id: "1", name: "Model Name & Code" },
    { id: "2", name: "Purchase Date Range" },
    { id: "3", name: "Customer Type" },
    { id: "4", name: "Gender" },
    // { id: "5", name: "Lead Stage" },
    { id: "6", name: "Customer Grouping" },
    { id: "7", name: "Age Range" },
    { id: "8", name: "Vehicle Category" },
  ];
  const customerTypes = [
    { key: "Sales Customer", title: "Sales Customer" },
    { key: "Service Customer", title: "Service Customer" },
    { key: "Non Customer", title: "Non Customer" },
  ];
  const customerGrouping = [
    { key: "Individual", title: "Individual" },
    { key: "Non-Individual", title: "Non-Individual" },
  ];
  const Gender = [
    { key: "Female", title: "Female" },
    { key: "Male", title: "Male" },
  ];

  const vehicleCategory = [
    { key: "MOTORCYCLE", title: "Motorcycle" },
    { key: "SCOOTER", title: "Scooter" },
  ];

  const [allFilterCheck, setAllFilterCheck] = useState(false);
  const [modelCheck, setModelCheck] = useState(false);
  const [cTypeCheck, setCtypeCheck] = useState(false);
  const [genderCheck, setGenderCheck] = useState(false);
  const [cGroupCheck, setCgroupCheck] = useState(false);
  const [vcCheck, setVcCheck] = useState(false);

  const selectFilter = (id) => {
    setId(id);
  };
  const searchValue = () => {
    setId([]);
    resetFields();
    setDataSource([]);
    setAllFilterCheck(false);
    setModelCheck(false);
    setCgroupCheck(false);
    setCtypeCheck(false);
    setGenderCheck(false);
    setVcCheck(false);
  };
  useEffect(() => {
    // setFieldsValue({ selectfilter: id });
    platformApi.get(`/api/vehicleMaster`).then((res) => {
      const { data } = res;
      if (data.code === 200) {
        const { response } = data;
        if (response.code === 200) {
          setModels(response.data);
        }
      }
    });
  }, [filteropen]);
  useEffect(() => {
    if (data) {
      const selectFilter = [];
      if (data.filters) {
        if (data.filters.model.length > 0) {
          selectFilter.push("1");
          setFieldsValue({ name: data.filters.model });
        }
        if (data.filters.from) {
          selectFilter.push("2");
        }
        if (data.filters.customerType.length > 0) {
          selectFilter.push("3");
          setFieldsValue({ type: data.filters.customerType });
        }
        if (data.filters.gender.length > 0) {
          selectFilter.push("4");
          setFieldsValue({ gender: data.filters.gender });
        }
        // if (data.filters.leadStage) {
        //   selectFilter.push("5");
        // }
        if (data.filters.customerGrouping.length > 0) {
          selectFilter.push("6");
          setFieldsValue({ grouping: data.filters.customerGrouping });
        }
        if (data.filters.fromAge) {
          selectFilter.push("7");
        }
        setModelCheck(data.filters.allModel);
        setCgroupCheck(data.filters.allCustomerGrouping);
        setCtypeCheck(data.filters.allCustomerType);
        setGenderCheck(data.filters.allGender);
      }
      setId(selectFilter);
      setFieldsValue({ selectfilter: selectFilter });
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      if (data.filters) {
        if (id.includes("1")) {
          setFieldsValue({ name: data.filters.model });
        }
        // if (data.filters.from) {
        if (id.includes("2") && data.filters.from) {
          let date = [moment(data.filters.from), moment(data.filters.to)];
          setFieldsValue({ date: date });
        }
        // if (data.filters.customerType) {
        if (id.includes("3")) {
          setFieldsValue({ type: data.filters.customerType });
        }
        // if (data.filters.gender) {
        if (id.includes("4")) {
          setFieldsValue({ gender: data.filters.gender });
        }
        // if (data.filters.leadStage) {
        if (id.includes("5")) {
          setFieldsValue({ lead: data.filters.leadStage });
        }
        // if (data.filters.customerGrouping) {
        if (id.includes("6")) {
          setFieldsValue({ grouping: data.filters.customerGrouping });
        }
        // if (data.filters.fromAge) {
        if (id.includes("7")) {
          let age = [data.filters.fromAge, data.filters.toAge];
          setFieldsValue({ age: age });
        }
      }
    }
  }, [id]);
  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  function formatter(value) {
    return value;
  }
  const handleFiltersFeild = () =>{
    const model = getFieldValue("name");
    const date = getFieldValue("date");
    const type = getFieldValue("type");
    const grouping = getFieldValue("grouping");
    const lead = getFieldValue("lead");
    const gender = getFieldValue("gender");
    const age = getFieldValue("age");
    const vehicleType = getFieldValue("vehicleCategory");
    const data = {
      model: model ? model : [],
      from: date ? moment(date[0]).format("YYYY-MM-DD") : null,
      to: date ? moment(date[1]).format("YYYY-MM-DD") : null,
      customerType: type ? type : [],
      customerGrouping: grouping ? grouping : [],
      // leadStage: lead ? lead : null,
      gender: gender ? gender : [],
      fromAge: age ? age[0] : null,
      toAge: age ? age[1] : null,
      vehicleType: vehicleType ? vehicleType : [],
    };
    const Gdata = {
      model: model ? model : [],
      allModel: modelCheck,
      from: date ? moment(date[0]).format("YYYY-MM-DD") : null,
      to: date ? moment(date[1]).format("YYYY-MM-DD") : null,
      customerType: type ? type : [],
      allCustomerType: cTypeCheck,
      customerGrouping: grouping ? grouping : [],
      allCustomerGrouping: cGroupCheck,
      // leadStage: lead ? lead : null,
      gender: gender ? gender : null,
      allGender: genderCheck,
      fromAge: age ? age[0] : null,
      toAge: age ? age[1] : null,
      vehicleType: vehicleType ? vehicleType : [],
    };
    setFilterData(Gdata);
    return data
  }
  const handleFilters = () => {
   const payloaddata = handleFiltersFeild()
   setLoading((prev)=> !prev)
    platformApi.post(`/api/promotions/filter`, payloaddata).then((res) => {
      // setSpinning(false);
      const { data } = res;
      if (data.code === 200) {
        const { response } = data;
        if (response.code === 200) {
          setDataSource(response.data);
        } else {
          message.error("Unable to fetch ");
        }
      } else {
        message.error("Unable to fetch ");
      }
    }).finally(()=>{
      setLoading((prev)=>!prev);
    });
  };
  const marks = {
    0: 0,
    50: 50,
    100: 100,
  };
  const deleteFilter = (element) => {
    let changedfilter = [];
    id.map((filter) => {
      if (filter !== element) {
        changedfilter.push(filter);
      }
    });
    setId(changedfilter);
    setFieldsValue({ selectfilter: changedfilter });
    setAllFilterCheck(false);
  };
  const branchSelect = (id) => {
  };

  const allFilters = (e) => {
    const arr = ["1", "2", "3", "4", "6", "7", "8"];
    if (e.target.checked) {
      setAllFilterCheck(e.target.checked);
      setId(arr);
      form.setFieldsValue({ selectfilter: arr });
    } else {
      setAllFilterCheck(e.target.checked);
      setModelCheck(e.target.checked);
      setCgroupCheck(e.target.checked);
      setCtypeCheck(e.target.checked);
      setGenderCheck(e.target.checked);
      setVcCheck(e.target.checked);
      searchValue();
    }
  };

  const modelCheckAll = (e) => {
    if (e.target.checked) {
      let arr = [];
      setModelCheck(e.target.checked);
      model.map((obj) => {
        arr.push(obj.id);
      });
      setFieldsValue({ name: arr });
    } else {
      setModelCheck(e.target.checked);
      form.setFieldsValue({ name: [] });
    }
  };

  const cTypeCheckAll = (e) => {
    if (e.target.checked) {
      setCtypeCheck(e.target.checked);
      setFieldsValue({
        type: ["Sales Customer", "Service Customer", "Non Customer"],
      });
    } else {
      setFieldsValue({ type: [] });
      setCtypeCheck(e.target.checked);
    }
  };

  const genderCheckAll = (e) => {
    if (e.target.checked) {
      setGenderCheck(e.target.checked);
      setFieldsValue({ gender: ["Male", "Female"] });
    } else {
      setFieldsValue({ gender: [] });
      setGenderCheck(e.target.checked);
    }
  };

  const cGroupCheckAll = (e) => {
    if (e.target.checked) {
      setCgroupCheck(e.target.checked);
      setFieldsValue({ grouping: ["Non-Individual", "Individual"] });
    } else {
      setFieldsValue({ grouping: [] });
      setCgroupCheck(e.target.checked);
    }
  };

  const vcCheckAll = (e) => {
    if (e.target.checked) {
      setVcCheck(e.target.checked);
      setFieldsValue({ vehicleCategory: ["MOTORCYCLE", "SCOOTER"] });
    } else {
      setFieldsValue({ vehicleCategory: [] });
      setVcCheck(e.target.checked);
    }
  };
  return (
    <div>
      {/* {filteropen && ( */}
      <div className="Filters">
        <Form>
          <Row type="flex" justify="space-between">
            <Col span={24}>
              <Row type="flex" justify="space-between">
                <Col span={24}>
                  <Text strong>Filters</Text>&nbsp;&nbsp;
                  <Checkbox checked={allFilterCheck} onChange={allFilters}>
                    Select All
                  </Checkbox>
                  <Item colon={false}>
                    {getFieldDecorator("selectfilter")(
                      <Select
                      showAction={["click", "focus"]}
                        placeholder="Select the Filter"
                        onChange={selectFilter}
                        mode="multiple"
                        showSearch
                        filterOption={filterMethod}
                        disabled={!editable}
                      >
                        {Filters.map((type) => (
                          <Option key={type.id}>{type.name}</Option>
                        ))}
                      </Select>
                    )}
                  </Item>
                </Col>
              </Row>
              {/* <Row
                type="flex"
                justify="space-between"
                // style={{ marginRight: "0.79rem" }}
              >
                <Col style={{ marginLeft: "auto" }}>
                  <Button
                    type="ghost"
                    // style={{ marginRight: "1rem" }}
                    onClick={searchValue}
                  >
                    Clear
                  </Button>
                </Col>
                &nbsp;
                <Col>
                  <Button
                    type="primary"
                    onClick={handleFilters}
                    disabled={!editable}
                  >
                    Filter
                  </Button>
                </Col>
              </Row> */}
            </Col>
          </Row>
          <br />
          <Row type="flex" justify="space-between">
            <Col span={24}>
              <Row type="flex" justify="start">
                {id.map((element) =>
                  element === "1" ? (
                    <Col span={8}>
                      <Item colon={false}>
                        <Row type="flex">
                          <Text strong>Model Name {"&"} Code</Text>
                          &nbsp;&nbsp;&nbsp;
                          <Checkbox
                            checked={modelCheck}
                            onChange={modelCheckAll}
                            disabled={!editable}
                          >
                            Select All
                          </Checkbox>
                        </Row>
                        <Row type="flex">
                          <Col span={20}>
                            {getFieldDecorator("name", {
                              rules: [
                                {
                                  required: true,
                                  message: "Please Select a Vehicle",
                                },
                              ],
                            })(
                              // <Input placeholder="Model Name & Code" />
                              <Select
                                placeholder="Model Name & Code"
                                filterOption={filterMethod}
                                showSearch
                                showAction={["click", "focus"]}
                                disabled={!editable}
                                mode="multiple"
                                onDeselect={(e) => {
                                  setModelCheck(false);
                                }}
                              >
                                {model.map((model) => (
                                  <Select.Option key={model.id}>
                                    {model.modelName + "-" + model.modelCode}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                          </Col>
                          &nbsp;
                          <Col span={2}>
                            <Button
                              type="ghost"
                              shape="circle"
                              size="small"
                              onClick={() => deleteFilter(element)}
                            >
                              &#10005;
                            </Button>
                          </Col>
                        </Row>
                      </Item>
                    </Col>
                  ) : element === "2" ? (
                    <Col span={8}>
                      <Item colon={false}>
                        <Row type="flex">
                          <Text strong>Purchase Date Range</Text>&nbsp;
                        </Row>
                        <Row type="flex">
                          <Col span={20}>
                            {getFieldDecorator("date", {
                              rules: [
                                {
                                  required: true,
                                  message: "Please Choose a range of Dates",
                                },
                              ],
                            })(
                              <RangePicker
                                onChange={branchSelect}
                                disabled={!editable}
                              />
                            )}
                          </Col>
                          &nbsp;
                          <Col span={2}>
                            <Button
                              type="ghost"
                              shape="circle"
                              size="small"
                              onClick={() => deleteFilter(element)}
                            >
                              &#10005;
                            </Button>
                          </Col>
                        </Row>
                      </Item>
                    </Col>
                  ) : element === "3" ? (
                    <Col span={8}>
                      <Item colon={false}>
                        <Row type="flex">
                          <Text strong>Customer Type</Text>&nbsp;&nbsp;&nbsp;
                          <Checkbox
                            checked={cTypeCheck}
                            onChange={cTypeCheckAll}
                            disabled={!editable}
                          >
                            Select All
                          </Checkbox>
                        </Row>
                        <Row type="flex">
                          <Col span={20}>
                            {getFieldDecorator("type", {
                              rules: [
                                {
                                  required: true,
                                  message: "Please Select Customer Type",
                                },
                              ],
                            })(
                              <Select
                              showAction={["click", "focus"]}
                                placeholder="Customer Type"
                                filterOption={filterMethod}
                                showSearch
                                disabled={!editable}
                                mode="multiple"
                                onDeselect={(e) => {
                                  setCtypeCheck(false);
                                }}
                              >
                                {customerTypes.map((model) => (
                                  <Select.Option key={model.key}>
                                    {model.title}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                          </Col>
                          &nbsp;
                          <Col span={2}>
                            <Button
                              type="ghost"
                              shape="circle"
                              size="small"
                              onClick={() => deleteFilter(element)}
                            >
                              &#10005;
                            </Button>
                          </Col>
                        </Row>
                      </Item>
                    </Col>
                  ) : element === "4" ? (
                    <Col span={8}>
                      <Item colon={false}>
                        <Row type="flex">
                          <Text strong>Gender</Text>&nbsp;&nbsp;&nbsp;
                          <Checkbox
                            checked={genderCheck}
                            onChange={genderCheckAll}
                            disabled={!editable}
                          >
                            Select All
                          </Checkbox>
                        </Row>
                        <Row type="flex">
                          <Col span={20}>
                            {getFieldDecorator("gender", {
                              rules: [
                                {
                                  required: true,
                                  message: "Please Select Gender",
                                },
                              ],
                            })(
                              <Select
                              showAction={["click", "focus"]}
                                placeholder="Gender"
                                filterOption={filterMethod}
                                showSearch
                                disabled={!editable}
                                mode="multiple"
                                onDeselect={(e) => {
                                  setGenderCheck(false);
                                }}
                              >
                                {Gender.map((model) => (
                                  <Select.Option key={model.key}>
                                    {model.title}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                          </Col>
                          &nbsp;
                          <Col span={2}>
                            <Button
                              type="ghost"
                              shape="circle"
                              size="small"
                              onClick={() => deleteFilter(element)}
                            >
                              &#10005;
                            </Button>
                          </Col>
                        </Row>
                      </Item>
                    </Col>
                  ) : element === "5" ? (
                    <Col span={8}>
                      <Item colon={false}>
                        <Row type="flex">
                          <Col span={20}>
                            {getFieldDecorator("lead")(
                              <Input
                                placeholder="Lead Stage"
                                disabled={!editable}
                              />
                            )}
                          </Col>
                          &nbsp;
                          <Col span={2}>
                            <Button
                              type="ghost"
                              shape="circle"
                              size="small"
                              onClick={() => deleteFilter(element)}
                            >
                              &#10005;
                            </Button>
                          </Col>
                        </Row>
                      </Item>
                    </Col>
                  ) : element === "6" ? (
                    <Col span={8}>
                      <Item colon={false}>
                        <Row type="flex">
                          <Text strong>Customer Grouping</Text>
                          &nbsp;&nbsp;&nbsp;
                          <Checkbox
                            checked={cGroupCheck}
                            onChange={cGroupCheckAll}
                            disabled={!editable}
                          >
                            Select All
                          </Checkbox>
                        </Row>
                        <Row type="flex">
                          <Col span={20}>
                            {getFieldDecorator("grouping", {
                              rules: [
                                {
                                  required: true,
                                  message: "Please Select Customer Grouping",
                                },
                              ],
                            })(
                              <Select
                              showAction={["click", "focus"]}
                                placeholder="Customer Grouping"
                                filterOption={filterMethod}
                                showSearch
                                disabled={!editable}
                                mode="multiple"
                                onDeselect={(e) => {
                                  setCgroupCheck(false);
                                }}
                              >
                                {customerGrouping.map((model) => (
                                  <Select.Option key={model.key}>
                                    {model.title}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                          </Col>
                          &nbsp;
                          <Col span={2}>
                            <Button
                              type="ghost"
                              shape="circle"
                              size="small"
                              onClick={() => deleteFilter(element)}
                            >
                              &#10005;
                            </Button>
                          </Col>
                        </Row>
                      </Item>
                    </Col>
                  ) : element === "7" ? (
                    <Col span={8}>
                      <Item colon={false}>
                        <Row type="flex">
                          <Text strong>Age Range</Text>&nbsp;
                        </Row>
                        <Row type="flex">
                          <Col
                            span={20}
                            style={{ padding: "0px 20px" }}
                            className="RangeSlider"
                          >
                            {getFieldDecorator("age", {
                              rules: [
                                {
                                  required: true,
                                  message: "Please Select Age Range",
                                },
                              ],
                            })(
                              <Slider
                                range
                                marks={marks}
                                tipFormatter={formatter}
                                disabled={!editable}
                              />
                            )}
                          </Col>
                          <Col span={2}>
                            <Button
                              type="ghost"
                              shape="circle"
                              size="small"
                              style={{ marginLeft: "0.5rem" }}
                              onClick={() => deleteFilter(element)}
                            >
                              &#10005;
                            </Button>
                          </Col>
                        </Row>
                      </Item>
                    </Col>
                  ) : (
                                  <Col span={8}>
                                    <Item colon={false}>
                                      <Row type="flex">
                                        <Text strong>Vehicle Category</Text>
                          &nbsp;&nbsp;&nbsp;
                          <Checkbox
                                          checked={vcCheck}
                                          onChange={vcCheckAll}
                                          disabled={!editable}
                                        >
                                          Select All
                          </Checkbox>
                                      </Row>
                                      <Row type="flex">
                                        <Col span={20}>
                                          {getFieldDecorator("vehicleCategory", {
                                            rules: [
                                              {
                                                required: true,
                                                message: "Please Select Vehicle Category",
                                              },
                                            ],
                                          })(
                                            <Select
                                            showAction={["click", "focus"]}
                                              placeholder="Vehicle Category"
                                              filterOption={filterMethod}
                                              showSearch
                                              disabled={!editable}
                                              mode="multiple"
                                              onDeselect={(e) => {
                                                setVcCheck(false);
                                              }}
                                            >
                                              {vehicleCategory.map((model) => (
                                                <Select.Option
                                                  key={model.key}
                                                  value={model.key}
                                                >
                                                  {model.title}
                                                </Select.Option>
                                              ))}
                                            </Select>
                                          )}
                                        </Col>
                          &nbsp;
                          <Col span={2}>
                                          <Button
                                            type="ghost"
                                            shape="circle"
                                            size="small"
                                            onClick={() => deleteFilter(element)}
                                          >
                                            &#10005;
                            </Button>
                                        </Col>
                                      </Row>
                                    </Item>
                                  </Col>
                                )
                )}
              </Row>
            </Col>
          </Row>
          <br />
          <Row
            type="flex"
            justify="space-between"
          // style={{ marginRight: "0.79rem" }}
          >
            <Col style={{ marginLeft: "auto" }}>
              <Button
                type="ghost"
                // style={{ marginRight: "1rem" }}
                onClick={searchValue}
              >
                Clear
              </Button>
            </Col>
            &nbsp;
            <Col>
              <Button
                type="primary"
                onClick={handleFilters}
                disabled={!editable}
              >
                Filter
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {/* )} */}
    </div>
  );
}

const WrappedFilters = Form.create()(Filters);
export default WrappedFilters;
