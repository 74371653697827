import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Table,
  Divider,
  Popconfirm,
  Col,
  Row,
  Steps,
  Button,
  message,
  Typography
} from "antd";
import moment from "moment";
import "../index.less";
import { DownloadOutlined } from "@ant-design/icons";
import { baseUrl, platformApi } from "../../../../api";
import vehicleLogo from "../../../../assets/vehiclelogo.png";
const { Step } = Steps;
const { Text } = Typography
const JobOrderTable = (props) => {
  const history = useHistory();

  const {
    pagination,
    dataSource,
    openModal,
    spinner,
    modify,
    setAssign,
    setAssignData,
    setLogData,
    setLog,
    setInsp,
    setStatusData,
    setOtpData,
    setOtp,
    setReferenceId,
    gst,
    deleteAccess,
    printAccess
  } = props;
  console.log(deleteAccess,printAccess);

  let status;
  const [pdf, setPdf] = useState(null);
  const checkStatus = (jobStatus) => {
    switch (jobStatus) {
      case "Vehicle Received":
        status = "0";
        break;
      case "Mechanic Allocated":
        status = "0";
        break;
      case "Estimation":
        status = "0";
        break;
      case "Estimation Approved":
        status = "0";
        break;
      case "Material Issued":
        status = "1";
        break;
      case "Work In Progress":
        status = "1";
        break;
      case "Final Inspection":
        status = "1";
        break;
      case "Proforma Invoice":
        status = "2";
        break;
      case "Invoice":
        status = "2";
        break;
      case "Payment Received":
        status = "2";
        break;
      case "Gate Pass":
        status = "2";
        break;
    }
    return status;
  };

  const sendSms = async (data, type) => {
    let value = {
      phone: data.customerPhone,
      id: data.id,
      customerId: data.customer.id,
      cname: data.customer.name,
      jcid: data.jobNo,
      vname: data.vehicle.vehicle.modelName,
      vno: data.vehicle.registerNo,
      srty: data.serviceType,
      type: type,
    };

    if (type === "SMS") {
      await platformApi
        .post("api/jobOrder/sms", value)
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              message.success("Message Send Successfully");
            } else {
              message.error("Failed to Sms Send");
            }
          } else {
            message.error("Failed to Sms Send");
          }
        })
        .catch((error) => {
          console.error("Failed to Sms Send: ", error);
          message.error("Failed to Sms Send");
        });
    } else if (type === "WhatsApp") {
      await platformApi
        .post("api/jobOrder/sms", value)
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              message.success("WhatsApp Message Send Successfully");
            } else {
              message.error("Failed to WhatsApp Message Send");
            }
          } else if (data.code === 400) {
            message.error(data.message);
          } else if (data.code === 401) {
            message.error(data.message);
          } else {
            message.error("Failed to WhatsApp Message Send");
          }
        })
        .catch((error) => {
          console.error("Failed to WhatsApp Message Send: ", error);
          message.error("Failed to WhatsApp Message Send");
        });
    }
  };

  const sendEstOtp = async (data) => {
    platformApi
      .post("/api/JobOrder/sendOtp", {
        phone: data.customerPhone,
        type: "WhatsApp",
      })
      .then((res) => {
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            const { data } = response;
            const { referenceId } = data;
            setReferenceId(referenceId);
            message.success("OTP is sent to your registered number", 5);
            setOtp(true);
          } else {
            message.error("Unable to send OTP", 2);
          }
        } else if (data.code === 400) {
          message.error(data.message);
        } else if (data.code === 401) {
          message.error(data.message);
        } else {
          message.error("Unable to send OTP", 2);
        }
      })
      .catch((e) => {
        message.error("Unable to send OTP", 2);
      });
  };

  const pdfGenerate = async (data) => {
    await platformApi.post("api/jobOrder/pdfGenerate", data).then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          console.log("Response", response.pdfWithoutBrochure);
          setPdf(response.pdfWithoutBrochure.data.Location);
          message.success("PDF Generated Successfully");
        } else {
          message.error("Failed to PDF Generated");
        }
      } else {
        message.error("Failed to PDF Generated");
      }
    });
  };
  const column = [
    {
      // title: "Action",
      render: (data, key) => (
        <div className="box" style={{ backgroundColor: "white" }} key={key}>
          <Row key={key}>
            <Col
              span={6}
              style={{
                borderRight: "0.1px solid rgba(0, 0, 0, 0.1)",
                padding: "1rem",
              }}
            >
              <table>
                <thead>
                  <tr>
                    <td style={{ textAlign: "right", fontWeight: "bolder" }}>
                      Job No
                    </td>
                    <td>:</td>
                    <td style={{ textAlign: "left", paddingLeft: ".5rem" }}>
                      {data.jobNo && data.jobNo}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right", fontWeight: "bolder" }}>
                      Reg. No
                    </td>
                    <td>:</td>
                    <td style={{ textAlign: "left", paddingLeft: ".5rem" }}>
                      {data.vehicle && data.vehicle.registerNo}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right", fontWeight: "bolder" }}>
                      Customer
                    </td>
                    <td>:</td>
                    <td style={{ textAlign: "left", paddingLeft: ".5rem" }}>
                      {data.customer && data.customer.name}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right", fontWeight: "bolder" }}>
                      Model
                    </td>
                    <td>:</td>
                    <td style={{ textAlign: "left", paddingLeft: ".5rem" }}>
                      {data.vehicle && data.vehicle.vehicle.modelName}
                    </td>
                  </tr>
                </thead>
              </table>
            </Col>
            <Col
              span={5}
              style={{
                borderRight: "0.1px solid rgba(0, 0, 0, 0.1)",
                padding: "1rem",
              }}
            >
              <div>
                <img
                  style={{ width: "150px", height: "120px" }}
                  src={
                    data.vehicle?.color && data.vehicle.color
                      ? data.vehicle.color.url
                      : vehicleLogo
                  }
                />
              </div>
            </Col>
            <Col span={13}>
              <div style={{ margin: "1rem" }}>
                <Row>
                  <Col span={10}>
                    <table>
                      <thead>
                        <tr>
                          <td
                            style={{ textAlign: "right", fontWeight: "bolder" }}
                          >
                            Service Type
                          </td>
                          <td>:</td>
                          <td
                            style={{ textAlign: "left", paddingLeft: ".5rem" }}
                          >
                            {data.serviceType && data.serviceType}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ textAlign: "right", fontWeight: "bolder" }}
                          >
                            Mechanic
                          </td>
                          <td>:</td>
                          <td
                            style={{ textAlign: "left", paddingLeft: ".5rem" }}
                          >
                            {data.mechanic
                              ? data.mechanic.profile.employeeName
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ textAlign: "right", fontWeight: "bolder" }}
                          >
                            Supervisor
                          </td>
                          <td>:</td>
                          <td
                            style={{ textAlign: "left", paddingLeft: ".5rem" }}
                          >
                            {data.supervisor ? data.supervisor.name : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ textAlign: "right", fontWeight: "bolder" }}
                          >
                            Service Number
                          </td>
                          <td>:</td>
                          <td
                            style={{ textAlign: "left", paddingLeft: ".5rem" }}
                          >
                            {data.serviceNo? data.serviceNo : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ textAlign: "right", fontWeight: "bolder" }}
                          >
                            Kms
                          </td>
                          <td>:</td>
                          <td
                            style={{ textAlign: "left", paddingLeft: ".5rem" }}
                          >
                            {data.kms ? data.kms : "-"}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </Col>
                  <Col span={14}>
                    <Row>
                      <Col span={12}>
                        <table>
                          <thead>
                            <tr>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bolder",
                                }}
                              >
                                Date
                              </td>
                              <td>:</td>
                              <td
                                style={{
                                  textAlign: "left",
                                  paddingLeft: ".5rem",
                                }}
                              >
                                {data.createdAt &&
                                  moment(new Date(data.createdAt)).format(
                                    "DD-MM-YYYY"
                                  )}
                              </td>
                            </tr>
                          </thead>
                        </table>
                      </Col>
                      <Col span={12}>
                        <table>
                          <thead>
                            <tr>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bolder",
                                }}
                              >
                                Time
                              </td>
                              <td>:</td>
                              <td
                                style={{
                                  textAlign: "left",
                                  paddingLeft: ".5rem",
                                }}
                              >
                                {data.createdAt &&
                                  new Date(data.createdAt).toLocaleTimeString()}
                              </td>
                            </tr>
                          </thead>
                        </table>
                      </Col>
                    </Row>
                    <div
                      style={{
                        border: "0.1px solid rgba(0, 0, 0, 0.3)",
                        borderRadius: ".2rem",
                        margin: "1rem",
                      }}
                    >
                      <Row>
                        <h1 style={{ textAlign: "center", marginTop: ".5rem" }}>
                          Status :{data.jobStatus && data.jobStatus}
                        </h1>
                      </Row>
                    </div>
                    <Row style={{ display: "flex", justifyContent: "center" }}>
                      {/* <Col style={{ marginRight: "10px" }}>
                        <Button
                          onClick={(event) => {
                            event.stopPropagation();
                            sendSms(data, "SMS");
                          }}
                        >
                          Send Sms
                        </Button>
                      </Col> */}
                      <Col style={{ marginRight: "10px" }}>
                        <Button
                          onClick={(event) => {
                            event.stopPropagation();
                            sendSms(data, "WhatsApp");
                          }}
                        >
                          Send WhatsApp
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col span={15}>
              <Steps
                progressDot
                current={parseInt(checkStatus(data.jobStatus))}
              >
                <Step
                  title="Pending"
                  description={
                    checkStatus(data.jobStatus) == "0"
                      ? data.jobStatus && data.jobStatus
                      : ""
                  }
                />
                <Step
                  title="In Progress"
                  description={
                    checkStatus(data.jobStatus) === "1"
                      ? data.jobStatus && data.jobStatus
                      : ""
                  }
                />
                <Step
                  title="Completed"
                  description={
                    checkStatus(data.jobStatus) === "2"
                      ? data.jobStatus && data.jobStatus
                      : ""
                  }
                />
                {/* <Step title="All" description={tab === 3  ? data.jobStatus && data.jobStatus : ""} /> */}
              </Steps>
            </Col>

            {checkStatus(data.jobStatus) === "2" ? (
              <Col>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    setStatusData(data.id);
                    setInsp(true);
                  }}
                >
                  Inspect
                </Button>
              </Col>
            ) : data.jobStatus == "Vehicle Received" ? (
              <Col style={{ marginRight: "10px" }}>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push({
                      pathname: "/autoadmin/estimate/:id",
                      state: {
                        jobNo: data.id,
                        gst: gst,
                      },
                    });
                  }}
                >
                  Estimate
                </Button>
              </Col>
            ) : data.jobStatus == "Estimation" ? (
              <Col style={{ marginRight: "10px" }}>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    sendEstOtp(data);
                    // setOtp(true)
                    setOtpData(data.id);
                  }}
                >
                  Approve Estimate OTP
                </Button>
              </Col>
            ) : (
              (data.jobStatus === "Estimation Approved" ||
                data.jobStatus === "Mechanic Allocated" ||
                checkStatus(data.jobStatus) === "1") && (
                <Col style={{ marginRight: "10px" }}>
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      setAssignData(data.id);
                      setAssign(true);
                    }}
                  >
                    {data.mechanic ? "ReAssign Mech." : "Assign Mechanic"}
                  </Button>
                </Col>
              )
            )}
            {data.jobStatus == "Mechanic Allocated" && (
              <Col>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push({
                      pathname: "/autoadmin/material_issue/:id",
                      state: {
                        jobNo: data.id,
                      },
                    });
                  }}
                >
                  Parts Issue
                </Button>
              </Col>
            )}
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: ".5rem",
            }}
          > <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/job_status/${data.id}`}
          className="linkylink"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          Tracking URL
        </a>
        <Divider type="vertical" />
            <span
              onClick={(event) => {
                event.stopPropagation();
                setLogData(data.id);
                setLog(true);
              }}
              className="linkylink"
            >
              Logs
            </span>
            <Divider type="vertical" />
            {modify ? (
              <span
                onClick={(event) => {
                  event.stopPropagation();
                  openModal(data.id && data.id, true, true);
                }}
                className="linkylink"
              >
                Modify
              </span>
            ) : (
              <span></span>
            )}
            <Divider type="vertical" />
            { printAccess ?
            <a
              className="linkylink"
              target="_blank"
              rel="noopener noreferrer"
              href={`/api/jobOrder/generatePDF/${data.id}`}
            >
              Download PDF
            </a>
            :
            <span></span>
          }
            <Divider type="vertical" />
            {deleteAccess ?
            <Popconfirm
              title="Do you want to delete this Job Order?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                props.delete(data.id && data.id);
              }}
            >
              <span
                onClick={(event) => event.stopPropagation()}
                className="linkylink"
              >
                Delete
              </span>
            </Popconfirm>
            :
            <span></span>
          }
               {
                !modify && !deleteAccess && !printAccess
                  ?
                  <Text strong disabled>No Actions Given</Text>
                  :
                  <span></span>
              }
            
          </div>
        </div>
      ),
    },
  ];
  return (
    <div id="jobOrder">
      <Table
        rowKey={(record) => record.id}
        pagination={pagination}
        columns={column}
        tableLayout="auto"
        style={{ cursor: "pointer" }}
        dataSource={dataSource}
        onRow={(props) => ({
          onClick: () => openModal(props.id, false),
        })}
        loading={spinner}
      />
    </div>
  );
};

export default JobOrderTable;
