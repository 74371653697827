import React, { useState, useEffect } from 'react';
import {
    Modal,
    Table,
    Popconfirm,
    Input,
    Select,
    DatePicker,
    Divider,
    Icon,
    Row,
    Col,
    Form,
    Typography,
    message,
} from "antd";
import moment from "moment";
import { formatValue } from '../../../../../utils'
import { set } from 'lodash';
import { platformApi } from '../../../../../api';
const { Title, Text } = Typography;
const { Option } = Select;
const { Item } = Form;

const AddVehicleInventory = ({
    open,
    close,
    editable,
    form,
    modify,
    toDelete,
    emitData
}) => {
    const tabledata = {
        vehicle: {},
        color: {},
        chassisNo: "",
        engineNo: "",
        keyNo: "",
        id: "",
        batteryNo: "",
        warrantyBookNo: "",
        manMonthYear: "",
    };
    const [branches, setBranch] = useState([]);
    const [currBranch, setCurrBranch] = useState('');
    const [error, setError] = useState({});
    const [data, setData] = useState([]);
    const [colors, setColor] = useState([]);
    const [manufacturer, setManufacturer] = useState([]);
    const [vehicles, setVehicle] = useState([]);
    const [vehiclecolor, setVehiclecolor] = useState([]);
    const [manId, setManId] = useState(null)
    const [challanData, setChallanData] = useState([]);

    const {
        getFieldDecorator,
        setFieldsValue,
        getFieldValue,
        validateFields,
        resetFields,
    } = form;

    const addVehicle = (data) => {
        if (data) {
            setChallanData([...challanData, data]);
        }
        else {
            setChallanData([...challanData, tabledata])
        }
    };
    useEffect(() => {
        if (open) {
            console.log("Open")
            // setEditable(editData);
            addVehicle(tabledata);

            platformApi.get("/api/user/branch").then((result) => {
                let { data } = result;
                if (data.code === 200) {
                    setBranch(data.data);
                    setManufacturers(data.data[0].id)
                }
            });

            platformApi.get("/api/user/currentUser").then((result) => {
                let { data } = result;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        setCurrBranch(response.data.profile.branch[0].id)
                    }
                }
            });
        }
    }, [open]);

    useEffect(() => {
        setData(challanData);
        if (
            challanData &&
            challanData[0] &&
            challanData[0].vehicle &&
            challanData[0].vehicle.manufacturer &&
            challanData[0].vehicle.manufacturer.id
        ) {
            let Color = colors;
            setFieldsValue({ manufacturer: challanData[0].vehicle.manufacturer.name });
            handleManufacturerChange(challanData[0].vehicle.manufacturer.id);
            console.log("Challan Data", challanData)
            challanData.forEach((datum, index) => {
                Color[index] = datum.vehicle.image;
                setColor([...Color]);
            });
        }
    }, [challanData]);

    const setManufacturers = (id) => {
        platformApi.post('/api/purchaseChallan/manufacturer', { branch: id })
            .then(res => {
                let { data } = res;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        console.log(response.data.manufacturer)
                        setManufacturer(response.data.manufacturer)
                    }
                }
            })
    }
    const clearFields = () => {
        resetFields();
        setBranch([]);
        setManufacturer([]);
        setChallanData([]);
    };



    const handleChange = (event, index, type) => {
        // let data = dataSource;
        let temp = challanData;
        //to restrict special characters
        if (event.target) {
            let { target: { value } } = event;
            value = formatValue(event, "code")
            temp[index][type] = value;
        }
        else {
            temp[index][type] = event;
        }
        // editData([...data]);
        setData([...temp])
    };


    const handleManufacturerChange = (id) => {
        // let vehicle = vehicles;
        setManId(id)
        let models = [];
        platformApi.get(`/api/vehicleMaster/man/${id}`).then((result) => {
            let { data } = result;
            if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                    setVehicle(response.data);
                    response.data.map((model) => {
                        if (model.vehicleStatus === "AVAILABLE") {
                            models.push(model);
                        }
                    });
                    setVehicle(models);
                }
            }
        });
    };

    const handleMonthChange = (id, index) => {
        const date = new Date(id).toLocaleDateString("en-us", {
            month: "short",
            year: "numeric",
        });
        handleChange(date, index, "manMonthYear");
    };

    const handleModelChange = (id, index) => {
        let Color = colors;
        vehicles.some((vehicle) => {
            if (vehicle.id === id) {
                handleChange(vehicle, index, "vehicle");
                Color[index] = vehicle.image;
                setColor([...Color]);
            }
        });
    };
    const handleColorChange = (id, index) => {
        vehiclecolor.some((color) => {
            if (color.id === id) {
                handleChange(color, index, "color");
            }
        });
    };
    const disableMonth = (current) => {
        const date = moment(Date.now());
        if (date <= current) {
            return true;
        } else return false;
    };

    const checkChassisNumber = (chassisNo, index) => {
        let arr = data;
        platformApi.post(`/api/purchaseChallan/frameNumber`, { chassisNo, manufacturer: manId }).then((result) => {
            let { data } = result;
            if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                    const date = new Date(response.data).toLocaleDateString("en-us", {
                        month: "short",
                        year: "numeric",
                    });
                    handleChange(date, index, "manMonthYear");
                    if (index === challanData.length - 1) {
                        addVehicle()
                    }
                    message.success("Chassis number valid")
                }
                else if (response.code === 400) {
                    setError({
                        ...error,
                        CHS: {
                            type: "error",
                            message: "Enter Valid Chassis Number",
                        },
                    });
                    message.error('Enter valid Chassis Number')
                }
            }
        })
    }

    const column = [
        {
            title: "Model",
            dataIndex: "vehicle",
            key: "vehicle",
            width: 200,
            render: (data, record, index) => (
                <Select
                    className="ChallanTable-Manufacturers"
                    dropdownStyle={{
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}
                    onChange={(event) => {
                        handleModelChange(event, index);
                    }}
                    showSearch
                    defaultValue={
                        data && data.modelName ? data.modelCode + " - " + data.modelName : undefined
                    }
                    disabled={!toDelete || !(vehicles.length >= 1)}
                    placeholder="Model"
                    filterOption={(input, option) =>
                        option.props.children.some(
                            (child) => child.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        )
                    }
                >
                    {vehicles.map((vehicle) => (
                        <Select.Option key={vehicle.id}>
                            {vehicle.modelCode} - {vehicle.modelName}
                        </Select.Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Color",
            dataIndex: "color",
            key: "color",
            width: 100,
            render: (data, record, index) => (
                <Select
                    className="ChallanTable-Manufacturers"
                    dropdownStyle={{
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}
                    onFocus={() => {
                        setVehiclecolor(colors[index]);
                    }}
                    onChange={(event) => {
                        handleColorChange(event, index);
                    }}
                    disabled={!toDelete || !colors[index]}
                    placeholder="Color"
                    defaultValue={record.color ? record.color.color : undefined}
                >
                    {vehiclecolor.map((color) => (
                        <Select.Option key={color.id} value={color.id}>
                            {color.color}
                        </Select.Option>
                    ))}
                </Select>
            ),
        },
        {
            title: " Chassis No",
            dataIndex: "chassisNo",
            key: "chassisNo",
            width: 160,
            render: (data, record, index) => (
                <Input
                    style={{ textTransform: "uppercase" }}
                    key="chassisNo"
                    pattern="^[A-Z0-9]{17}$"
                    onInput={(event) => {
                        handleChange(event, index, "chassisNo");
                        if (event.target.value.length === 17) {
                            checkChassisNumber(event.target.value, index)
                        }
                        else {
                            if (!event.target.checkValidity()) {
                                setError({
                                    ...error,
                                    CHS: {
                                        type: "error",
                                        message: "Enter Valid Chassis Number",
                                    },
                                });
                            } else {
                                delete error.CHS;
                                setError(error);
                            }
                        }
                    }}
                    onWheel={(event) => event.currentTarget.blur()}
                    maxLength={17}
                    // disabled={!toDelete}
                    disabled={!toDelete || !(vehicles.length >= 1)}
                    placeholder="Chassis No"
                    value={data}
                />
            ),
        },
        {
            title: "Engine No",
            dataIndex: "engineNo",
            key: "engineNo",
            width: 160,
            render: (data, record, index) => (
                <Input
                    style={{ textTransform: "uppercase" }}
                    key="engineNo"
                    onChange={(event) => {
                        handleChange(event, index, "engineNo");
                    }}
                    maxLength={17}
                    onWheel={(event) => event.currentTarget.blur()}
                    disabled={!toDelete}
                    placeholder="Engine No"
                    value={data}
                />
            ),
        },
        {
            title: "Key no",
            dataIndex: "keyNo",
            key: "keyNo",
            width: 160,
            render: (data, record, index) => {
                return <Input
                    style={{ textTransform: "uppercase" }}
                    key="keyNo"
                    onChange={(event) => {
                        handleChange(event, index, "keyNo");
                    }}
                    onWheel={(event) => event.currentTarget.blur()}
                    disabled={!toDelete}
                    placeholder="Key No"
                    value={data}
                    maxLength={17}
                />
            },
        },
        {
            title: "Battery No",
            dataIndex: "batteryNo",
            key: "batteryNo",
            width: 160,
            render: (data, record, index) => (
                <Input
                    style={{ textTransform: "uppercase" }}
                    key="batteryNo"
                    onChange={(event) => {
                        handleChange(event, index, "batteryNo");
                    }}
                    onWheel={(event) => event.currentTarget.blur()}
                    disabled={!toDelete}
                    value={data}
                    placeholder="Battery No"
                    maxLength={17}
                />
            ),
        },
        {
            title: "WRC",
            dataIndex: "warrantyBookNo",
            key: "warrantyBookNo",
            width: 160,
            render: (data, record, index) => (
                <Input
                    style={{ textTransform: "uppercase" }}
                    key="warrantyBookNo"
                    onChange={(event) => {
                        handleChange(event, index, "warrantyBookNo");
                    }}
                    onWheel={(event) => event.currentTarget.blur()}
                    disabled={!toDelete}
                    placeholder="Warranty Book No"
                    value={data}
                    maxLength={17}
                />
            ),
        },
        {
            title: "MFG Month",
            dataIndex: "manMonthYear",
            key: "manMonthYear",
            width: 100,
            render: (data, record, index) => (
                <DatePicker.MonthPicker
                    onFocus={() => {
                        // if (index === dataSource.length - 1) {
                        //   addVehicle();
                        // }
                    }}
                    onChange={(event) => {
                        handleMonthChange(event, index);
                    }}
                    disabledDate={disableMonth}
                    style={{ width: "100%" }}
                    format="MMM-YYYY"
                    disabled={true}
                    placeholder="Month"
                    value={data ? moment(data, "MMM-YYYY") : null}
                />
            ),
        },
        {
            key: "actions",
            width: 30,
            render: (data1, data2, index) =>
                toDelete && (
                    <div>
                        <Popconfirm
                            title="Are you sure delete this Vehicle?"
                            okText="Yes"
                            cancelText="No"
                            onCancel={(event) => { }}
                            onConfirm={(event) => {
                                if (
                                    challanData.length === 1 &&
                                    challanData.length - 1 === index
                                ) {
                                    message.error("Vehicle cannot be deleted as there is only one vehicle", 2);
                                } else {
                                    deleteData(data1.id, index);
                                }
                            }}
                        >
                            <Icon type="delete" />
                        </Popconfirm>
                    </div>
                ),
        },
    ];
    const deleteData = (id, index) => {
        setChallanData(challanData.filter((chData, ind) => ind !== index));
        // if (id) {
        //     platformApi
        //         .delete("api/purchaseChallan/vehicle/" + id)
        //         .then((result) => {
        //             setChallanLoading(false);
        //             let { data } = result;
        //             if (data.code === 200) {
        //                 let { response } = data;
        //                 if (response.code === 200) {
        //                     setChallanData(challanData.filter((chData) => chData.id !== id));
        //                     message.success("Vehicle Deleted Successfully", 2);
        //                 } else {
        //                     message.error("Unable to delete Vehicle");
        //                 }
        //             } else {
        //                 message.error("Unable to delete Vehicle");
        //             }
        //         })
        //         .catch((error) => {
        //             console.error("Error on Vehicle Purchase Challan : ", error);
        //             message.error("Unable to delete Vehicle");
        //             setChallanLoading(false);
        //         });
        // } 
        // else {
        //     setChallanData(challanData.filter((chData, ind) => ind !== index));
        // }

    };
    const handleSubmit = () => {
        const chalan = challanData.filter((mockChallan) => {
            return mockChallan.vehicle.id;
        });
        validateFields((validationError) => {
            if (validationError === null) {
                if (chalan[0]) {
                    chalan.forEach((mockData, index) => {
                        chalan[index].vehicle = mockData.vehicle.id;
                        chalan[index].color = mockData.color.id;
                    });
                    const Challan = {
                        vehicleDetail: [...chalan],
                        // date: new Date(getFieldValue("date")).toLocaleDateString(),
                        // challanDate: new Date(
                        //     getFieldValue("challanDate")
                        // ).toLocaleDateString(),
                        // challanNo: id,
                        branch: currBranch,
                        inventoryBranch: getFieldValue("branch")
                    };
                    data ? (Challan.id = data.id) : (Challan.id = null);
                    emitData(Challan);
                    clearFields();
                    close();
                } else {
                    message.error("Select atleast one vehicle", 2);
                }
            }
        });
    };
    return (
        <div>
            <Modal
                wrapClassName="purchase-challan"
                title={<Title level={4}>Add Vehicle Inventory</Title>}
                visible={open}
                okText={editable ? "Save" : "Modify"}
                onCancel={() => {
                    clearFields();
                    close();
                }}
                okButtonProps={{ disabled: (!editable && !modify) }}
                onOk={() => (editable ? handleSubmit() : modifyData())}
            >

                <Form>
                    <Row type='flex' style={{"gap":"10px"}}>
                        <Col span={4}>
                         <span>Manufacturer</span>
                            <Item>
                                {getFieldDecorator("manufacturer")(
                                    <Select
                                        showSearch={true}
                                        disabled={!toDelete}
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={handleManufacturerChange}
                                        initialValue={
                                            data
                                                ? data[0]
                                                    ? data[0].vehicle
                                                        ? data[0].vehicle.manufacturer
                                                            ? data[0].vehicle.manufacturer.name
                                                            : undefined
                                                        : undefined
                                                    : undefined
                                                : undefined
                                        }
                                        placeholder="Manufacturer"
                                    >
                                        {manufacturer.map((manufacturer) => (
                                            <Select.Option key={manufacturer.id} value={manufacturer.id}>
                                                {manufacturer.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Item>
                        </Col>
                        <Col span={4}>
                        <span>Branch</span>
                            <Item>
                                {getFieldDecorator("branch")(
                                    <Select
                                        showSearch={true}
                                        disabled={!toDelete}
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        placeholder="branch"
                                    >
                                        {branches.map((branch) => (
                                            <Select.Option key={branch.id} value={branch.id}>
                                                {branch.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Item>
                        </Col>
                    </Row>
                    <span>Vehicle Details</span>
                    <Table
                        size="small"
                        pagination={false}
                        scroll={{ y: 160 }}
                        className="Challantable"
                        columns={column.filter((obj) => obj.title !== "Action" || toDelete)}
                        style={{ cursor: "pointer" }}
                        dataSource={data}
                    />
                </Form>
            </Modal>
        </div>
    )
}

const WrappedAddVehicleInventory = Form.create()(
    AddVehicleInventory
);

export default WrappedAddVehicleInventory;