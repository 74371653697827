import React, { useState, useEffect } from 'react'
import { Card, List, Row, Col, message, Tag } from 'antd'
import moment from 'moment';
import {
  EyeFilled,
  EditFilled,
} from '@ant-design/icons';
import '../style.less'

const MessageActivity = (props) => {
  const { item, openEditor } = props
  return (
    <div className="activity-card">
      <table>
        {/* First Row */}
        <tr>
          <td>
            <strong>{item.interactionType === "WhatsApp Message" ? "WhatsApp Activity" : "SMS Activity"} </strong>
          </td>
          <td rowSpan={4} className="vat bb-none">
            {
              item.remarks && item.remarks.length ?
                <>
                  <strong>Message:</strong><br />
                  {item.remarks}
                </>
                : null
            }
            {
              item.createdBy &&
              <div>
                <strong>Employee:</strong> {item.createdBy.profile.employeeName}
              </div>
            }
          </td>
          <td align="center">
            <strong>{item.createdAt ? moment(item.createdAt).format('DD-MM-YYYY HH:mm') : ' -'}</strong>
          </td>
        </tr>
        {/* Second Row */}
        <tr>
          <td rowSpan={2}>
            {
              item.sms ?
                <><strong>Phone No: </strong> {item.sms.phone}<br /></> : null
            }
            {
              item.sms ?
                <>
                  <strong>SMS: </strong> {
                    item.sms.smsStatus
                      ?
                      item.sms.smsStatus
                      :
                      item.sentStatus
                        ? "Sent "
                        : ""
                  }<br />
                </>
                :
                <>
                  <strong>SMS: </strong> {
                    item.sms.whatsAppSmsStatus
                      ?
                      item.sms.whatsAppSmsStatus
                      :
                      item.sentWhatsApp
                        ? "Sent "
                        : ""
                  }<br />
                </>
            }
            {/* <><strong>Lead Source: </strong>WALK IN</> */}

            {/* <td rowSpan={2}> 
                <><strong>Lead Source: </strong>WALK IN</>
          </td> */}
          </td>
          {/* <td rowSpan={2}>

          </td> */}
          <td align="center" rowSpan={2}>
            {
              item.enquiryType ?
                <strong className={`center pa ${item.enquiryType}`}>{item.enquiryType}</strong> : null
            }
          </td>
        </tr>
        {/* Third Row */}
        <tr>
        </tr>
        <tr>
          <td>
            {
              item.scheduleDateAndTime ?
                <>
                  <strong>FollowUp Date: </strong>{moment.utc(item.scheduleDateAndTime).format("DD-MM-YYYY")}<br />
                  <strong>FollowUp Time: </strong>{moment.utc(item.scheduleDateAndTime).format("HH:mm")}
                </>
                : null
            }
          </td>
          <td>
            <Row>
              <Col className="center pointer icon" onClick={() => openEditor(item.id)} span={12}>
                <EyeFilled />
              </Col>
              <Col className="bl center pointer icon" onClick={() => openEditor(item.id, true)} span={12}>
                <EditFilled />
              </Col>
            </Row>
          </td>
        </tr>
      </table>
    </div>
  )
}

export default MessageActivity;
