import React, { useContext, useEffect, useState } from 'react';
import {
  Menu, Icon, Spin, Avatar,
} from 'antd';
import { withRouter } from 'react-router-dom';
import { ContextAPI } from "../../../ContextAPI";

import HeaderDropdown from '../HeaderDropdown';
import './index.less'
import { piopiy } from '../../../utils/piopiy';

const AvatarDropdown = (props) => {
  const { history } = props;
  const { loginCredintials } = useContext(ContextAPI);
  const [companyReadAccess, setCompanyReadAccess] = useState(false);
  const [companySettingsAccess, setCompanySettingsAccess] = useState(false);

  useEffect(() => {
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "company") {
          if (each.access.read) {
            setCompanyReadAccess(true);
          }
        }
        if (each.subModule === "company_settings") {
          if (each.access.read) {
            setCompanySettingsAccess(true);
          }
        }
      });
  }, [loginCredintials]);

  const onMenuClick = (event) => {
    const { key } = event;

    if (key === 'logout') {
      localStorage.removeItem('token');
      localStorage.removeItem('branch')
      piopiy.logout()
      history.push('/');
    }
    else if (key === 'companySettings') {
      history.push('/autoadmin/company')
    }
    // else if (key === 'branch') {
    //   history.push("/autoadmin/company")
    // }
  };

  const {
    currentUser = {
      avatar: '',
      name: '',
      role: ''
    },
    menu,
  } = props;
  const menuHeaderDropdown = (
    <Menu className="menu" selectedKeys={[]} onClick={onMenuClick}>
      {/* {menu && (
        <Menu.Item key="center">
          <Icon type="user" />
          Account Center
        </Menu.Item>
      )} */}
      {/* {
        menu && companyReadAccess && (
          <Menu.Item key="branch">
            <Icon type="setting" theme="filled" />
            Company Branch
      </Menu.Item>
        )
      } */}
      {/* {menu && (
        <Menu.Item key="settings">
          <Icon type="setting" theme="filled" />
          Account Settings
        </Menu.Item>
      )} */}
      {menu && (
        <Menu.Item key="companySettings">
          <Icon type="setting" theme="filled" />
          Company Settings
        </Menu.Item>
      )}
      {menu && <Menu.Divider />}
      <Menu.Item key="logout">
        <Icon type="logout" />
        Logout
      </Menu.Item>

    </Menu>
  );
  return currentUser && currentUser.name ? (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span className="action account">
        <Avatar size="small" className="avatar" src={currentUser.avatar} alt="avatar" />
        <span style={{
          display: 'inline-block',
          verticalAlign: 'middle'
        }}>
          <span style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            <span className="role">{currentUser.name}</span>
            <span className="role" style={{fontSize:"12px"}}>{currentUser.role}</span>
          </span>
        </span>
      </span>
    </HeaderDropdown>
  ) : (
    <Spin
      size="small"
      style={{
        marginLeft: 8,
        marginRight: 8,
      }}
    />
  );
};

export default withRouter(AvatarDropdown);
