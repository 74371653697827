import React, { useState, useEffect } from 'react';
import {
  Upload,
  Button,
  Icon,
  List,
  Spin,
  message,
  Typography,
  Card
} from "antd";
import { platformApi } from '../../../api';
const { Title } = Typography;
const { Dragger } = Upload;
import './VehiclePDFUpload.less';

const VehiclePDFUpload = () => {
  const [files, setFiles] = useState([]);
  const [csvUrl, setCsvUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [pollingInterval, setPollingInterval] = useState(null);
  const [selectingFiles, setSelectingFiles] = useState(false); 

  const handleChange = (info) => {
    const { fileList } = info;
  
    if (fileList.length > files.length) {
      setSelectingFiles(true);
    }
  
    setTimeout(() => {
      const validFiles = fileList.filter(file => {
        const isPDF = file.type === 'application/pdf';
        const isLt5M = file.size / 1024 / 1024 < 5;
        
        if (!isPDF) {
          message.error(`${file.name} is not a PDF file.`);
          return false;
        }
        if (!isLt5M) {
          message.error(`${file.name} exceeds 5MB size limit.`);
          return false;
        }
        return true;
      });
  
      setFiles(validFiles);
      setSelectingFiles(false);
    }, 500);
  };
  

  const handleUpload = async () => {
    if(!files) return
    setUploading(true); 
    const formData = new FormData();
  
    files.forEach(file => {
      formData.append('pdfFiles', file.originFileObj || file);
    });
  
    try {
      const response = await platformApi.post('/api/numberPlate/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob', 
      });
  
      // Convert the response data (Blob) into a URL
      const xlsxBlob = new Blob([response.data], {type:'data:application/vnd.ms-excel;base64'});
      const xlsxUrl = URL.createObjectURL(xlsxBlob);
      setFiles([]);      
      message.success('Processing complete! xlsx file is ready.');
      downloadFile(xlsxUrl)
    } catch (error) {
      console.log(error)
      message.error('Upload failed. Please try again.');

    } finally {
      setUploading(false);
    }
  };
  
  // const startPolling = (taskId) => {
  //   const interval = setInterval(async () => {
  //     try {
  //       const response = await axios.get(`/api/check-status/${taskId}`);
  //       if (response.data.csvReady) {
  //         clearInterval(interval);
  //         setProcessing(false);
  //         setCsvUrl(response.data.csvUrl);
  //         message.success('Processing complete!');
  //       }
  //     } catch (error) {
  //       clearInterval(interval);
  //       setProcessing(false);
  //       message.error('Error checking processing status');
  //     }
  //   }, 5000);
  //   setPollingInterval(interval);
  // };

  useEffect(() => {
    return () => {
      if (pollingInterval) clearInterval(pollingInterval);
    };
  }, [pollingInterval]);

  const draggerProps = {
    onChange: handleChange,
    beforeUpload: () => false,
    multiple: true,
    accept: '.pdf',
    fileList: files,
    onRemove: (file) => {
      setFiles(prev => prev.filter(f => f.uid !== file.uid));
      // Do NOT set selectingFiles to true here
    },
  };
  

  const downloadFile = (xlsx) => {
    if (!xlsx) return;
    const now = new Date();
    const timestamp = now.toISOString().slice(0, 19).replace(/[:T]/g, '-').replace('-', '_');
    const uniqueFilename = `vehicle-details-${timestamp}.xlsx`;
    const link = document.createElement('a');
    link.href = xlsx;
    link.setAttribute('download',uniqueFilename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  return (
    <Card className="vehicle-upload-card" bordered={false}>
      <Title level={4} className="upload-title">
        Vehicle Details PDF Upload
      </Title>
      
      <Dragger {...draggerProps} className="upload-dragger">
        {selectingFiles ? (
          <Spin tip="Loading selected files..." size="large" />
        ) : (
          <>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">Click or drag PDF files to this area</p>
            <p className="ant-upload-hint">
              Upload multiple vehicle detail PDFs (Max 5MB per file)
            </p>
          </>
        )}
      </Dragger>

      <List
        className="file-list"
        dataSource={files}
        renderItem={file => (
          <List.Item>
            <List.Item.Meta
              avatar={<Icon type="file-pdf" style={{ color: '#ff4d4f' }} />}
              title={file.name}
              description={`${(file.size / 1024 / 1024).toFixed(2)} MB`}
            />
          </List.Item>
        )}
      />

      <div className="action-section">
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={files.length === 0 || uploading || processing}
          className="upload-button"
        >
          {uploading ? <Spin indicator={<Icon type="loading" spin />} /> 
            : processing ? 'Processing...' : 'Process PDFs'}
        </Button>

        {processing && (
          <Spin tip="Generating CSV..." style={{ marginLeft: 16 }} />
        )}

        {/* {csvUrl && (
          <Button 
            type="link" 
            onClick={downloadCSV}
            className="download-button"
          >
            <Icon type="file-excel" /> Download CSV Report
          </Button>
        )} */}
      </div>
    </Card>
  );
};

export default VehiclePDFUpload;