import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Input,
  Button,
  Dropdown,
  Menu,
  Icon,
  message,
  Tabs,
} from "antd";
import "./index.less";

// Components
import QuotationTable from "./QuotationsTable";
import { platformApi } from "../../../api";
import { ContextAPI } from "../../../ContextAPI";
import WrappedQuotationModal from "./QuotationsForm";
import WrappedAdvancedFilter from "./AdvancedFilters";

const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;
const { TabPane } = Tabs;

function Quotation() {
  const [limit, setLimit] = useState(10);
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [dataSource, setData] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [search, setSearch] = useState({});
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [printAccess, setPrintAccess] = useState(false);
  const [filteropen, setFilteropen] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [filter, setFilter] = useState([]);
  const [tab, setTab] = useState("ACTIVE");
  const [view, setView] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);

  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    let menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item
        onClick={() => {
          setLimit(menu), setPage(1);
        }}
        key={menu}
      >
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;

  const deleteData = (id) => {
    setSpinning(true);
    let temp = page != 1 && dataSource.length === 1;
    // if (page != 1 && dataSource.length === 1) {
    //   setPage(page - 1);
    // }
    platformApi
      .delete(`/api/quotation/${id}`)
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            message.success("Quotation deleted successfully");
            if (temp) {
              setPage(1);
              setPageData(null, null, 1);
            } else {
              setPageData();
            }
          } else {
            message.error("Unable to delete this Quotation");
          }
        } else {
          message.error("Unable to delete this Quotation");
        }
      })
      .catch((error) => {
        console.error("Error on Quotation : ", error);
        message.error("Cannot delete this Quotation");
        setSpinning(false);
      });
  };

  useEffect(() => {
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "quotations") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
          if (each.access.print) {
            setPrintAccess(true);
          }
        }
      });
    // setPageData();
  }, []);

  useEffect(() => {
    setPageData(search);
  }, [limit, search, page]);
  
  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      setPageData(search);
    }
  }, [filterData]);

  const setPageData = (search, status, val) => {
    if (!status) {
      status = tab;
    }
    setSpinning(true);

    // if (!searches) {
    const branchData = localStorage.getItem('branch');
    const branchIds = branchData ? JSON.parse(branchData).map(branch => branch.id) : [];
    let obj = {
      page: val ? val : page,
      size: limit,
      branch: branchIds,
      filter: filterData,
      status,
    };
    console.log(filterData)
    if (filteropen) {
      if (filterData.status) {
        obj.filter.status = filterData.status;
        obj.filter.fromDate = filterData.fromDate;
        obj.filter.toDate = filterData.toDate;
      } else {
        obj.filter.fromDate = filterData.fromDate;
        obj.filter.toDate = filterData.toDate;
      }
    } else {
      obj.searchString = search && search.name;
    }
    platformApi
      .post("/api/quotation/get", obj)
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setData(response.data.Quotation);
            setCount(response.data.count);
          } else {
            message.error("Unable to fetch Quotation", 2);
          }
        } else {
          message.error("Unable to fetch Quotation", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on Quotation : ", error);
        message.error("Unable to fetch Quotation", 2);
      });
  };

  const addData = async (quotation, callBack) => {
    const formData = new FormData();
    formData.append("finalData", JSON.stringify(quotation));
    setSpinning(true);
    platformApi
      .post("/api/quotation", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setData([response.data, ...dataSource]);
            setCount(count + 1);
            message.success("Quotation added successfully", 2);
            callBack(true, response.data);
          } else {
            message.error("Unable to add new quotation", 2);
            callBack(false);
          }
        } else {
          message.error("Unable to add quotation", 2);
          callBack(false);
        }
      })
      .catch((error) => {
        callBack(false);
        console.error("Error on quotation : ", error);
        message.error("Unable to add new quotation");
        setSpinning(false);
      });
  };

  const collapseFilter = () => {
    if(filteropen)  setFilterData({})
    filteropen ? setFilteropen(false) : setFilteropen(true);
  };

  const handleTabChange = (activeKey) => {
    switch (activeKey) {
      case "1":
        setPageData(null, "ACTIVE");
        setTab("ACTIVE");
        setPage(1);
        break;
      case "2":
        setPageData(null, "BOOKED");
        setTab("BOOKED");
        setPage(1);
        // setPage(2)
        break;
      case "3":
        setPageData(null, "REJECTED");
        setTab("REJECTED");
        setPage(1);
        // setPage(3)
        break;
      case "4":
        setPageData(null, "ALL");
        setTab("ALL");
        setPage(1);
        // setPage(4)
        break;
      default:
        setPageData(null, null);
        setTab("ALL");
        setPage(1);
      // setPage(4)
    }
  };

  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          Quotations [{count}]
          <span style={{ margin: ".5rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit} &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          style={{ width: "30%" }}
          placeholder="Search Quotations/Customers"
          onSearch={(event) => {
            let searches = { name: event };
            setPage(1);
            setSearch(searches);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null);
              setPage(1);
            }
          }}
        />
        <div style={{ width: "12%", textAlign: "center" }}>
          <Button
            type="link"
            onClick={() => {
              collapseFilter();
            }}
          >
            {filteropen ? "Back" : "Advanced  Filters"}
          </Button>
        </div>
        <Button
          onClick={() => {
            setAddFlag(true);
            setEditFlag(true);
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add Quotations
        </Button>
      </div>

      <WrappedAdvancedFilter
        filteropen={filteropen}
        searchName={search}
        setId={setFilter}
        setPageData={setPageData}
        setSearch={setSearch}
        setFilterData={setFilterData}
        setPage={setPage}
      />
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab={<span>Active Quotations</span>} key="1">
          {/* {setPageData(null, "ALL")} */}
          <QuotationTable
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              total: count,
              current: page,
              showQuickJumper: true,
            }}
            openModal={(id, editable) => {
              setSelected(id);
              setEditFlag(editable);
              setAddFlag(true);
            }}
            delete={(id) => deleteData(id)}
            dataSource={dataSource}
            spinner={{ spinning }}
            deleteAccess={deleteAccess}
            modify={modifyAccess}
          />
        </TabPane>
        <TabPane tab={<span>Booked Quotations</span>} key="2">
          {/* {setPageData(null, "ACTIVE")} */}
          <QuotationTable
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              total: count,
              current: page,
              showQuickJumper: true,
            }}
            openModal={(id, editable) => {
              setSelected(id);
              setEditFlag(editable);
              setAddFlag(true);
            }}
            delete={(id) => deleteData(id)}
            dataSource={dataSource}
            spinner={{ spinning }}
            deleteAccess={deleteAccess}
            modify={modifyAccess}
          />
        </TabPane>
        <TabPane tab={<span>Rejected Quotations</span>} key="3">
          {/* {setPageData(null, "ACTIVE")} */}
          <QuotationTable
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              total: count,
              current: page,
              showQuickJumper: true,
            }}
            openModal={(id, editable) => {
              setSelected(id);
              setEditFlag(editable);
              setAddFlag(true);
            }}
            delete={(id) => deleteData(id)}
            dataSource={dataSource}
            spinner={{ spinning }}
            deleteAccess={deleteAccess}
            modify={modifyAccess}
          />
        </TabPane>
        <TabPane tab={<span>All Quotations</span>} key="4">
          {/* {setPageData(null, "ACTIVE")} */}
          <QuotationTable
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              total: count,
              current: page,
              showQuickJumper: true,
            }}
            openModal={(id, editable) => {
              setSelected(id);
              setEditFlag(editable);
              setAddFlag(true);
            }}
            delete={(id) => deleteData(id)}
            dataSource={dataSource}
            spinner={{ spinning }}
            deleteAccess={deleteAccess}
            modify={modifyAccess}
          />
        </TabPane>
      </Tabs>
      {/* <QuotationTable
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: limit,
          total: count,
          current: page,
          showQuickJumper: true,
        }}
        openModal={(id, editable) => {
          setSelected(id);
          setEditFlag(editable);
          setAddFlag(true);
        }}
        delete={(id) => deleteData(id)}
        dataSource={dataSource}
        spinner={{ spinning }}
        deleteAccess={deleteAccess}
        modify={modifyAccess}
      /> */}
      <WrappedQuotationModal
        leadData={null}
        editable={editFlag}
        data={dataSource[dataSource.findIndex((data) => data.id === selected)]}
        open={addFlag}
        view={view}
        setView={setView}
        emitData={addData}
        addData={(quotation, callBack) => {
          dataSource.some((obj, index) => {
            if (obj.id === quotation.id) {
              dataSource[index].pdfWithBrochure = quotation.pdfWithBrochure;
              dataSource[index].pdfWithOutBrochure =
                quotation.pdfWithOutBrochure;
              callBack(dataSource[index]);
              return true;
            }
          });
          setData([...dataSource]);
        }}
        close={() => {
          setSelected(null);
          setAddFlag(false);
        }}
        printAccess={printAccess}
        // loginCredintials={loginCredintials}
      />
    </div>
  );
}

export default Quotation;
