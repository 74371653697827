import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Table,
  Tabs,
  Upload,
  Button,
  message,
  Typography,
  Input,
  Dropdown,
  Menu,
  Icon,
  Row,
  Col,
  Form,
  DatePicker,
  Radio
} from 'antd';
import moment from "moment";
import { InboxOutlined } from '@ant-design/icons';
import { Card } from 'react-bootstrap';
import { platformApi } from '../../../api';
import './insuranceRenewalReminder.less';

const { Title } = Typography;
const { TabPane } = Tabs;
const { Dragger } = Upload;
const { Search } = Input;
const { Item } = Menu;
const { MonthPicker } = DatePicker;

const columns = [
  {
    title: 'S.No',
    dataIndex: 'serialNo',
    key: 'serialNo',
  },
  {
    title: 'Customer Name',
    dataIndex: 'customerName',
    key: 'customerName',
  },
  {
    title: 'Customer Phone Number',
    dataIndex: 'customerPhoneNumber',
    key: 'customerPhoneNumber',
  },
  {
    title: 'Registration Number',
    dataIndex: 'vehicleNumber',
    key: 'vehicleNumber',
  },
  {
    title: 'Renewable Date',
    dataIndex: 'renewingDate',
    key: 'renewingDate',
  },
  {
    title: 'Renewal Type',
    dataIndex: 'typeOfRenewal',
    key: 'typeOfRenewal',
  },
];

const InsuranceReminder = () => {
  const history = useHistory();
  const location = useLocation();
  // Read date param from URL if available, otherwise default to today's date.
  const queryParams = new URLSearchParams(location.search);
  const initialDateParam = queryParams.get('date');
  const initialDate = initialDateParam ? moment(initialDateParam, "YYYY-MM-DD") : moment(new Date());

  const [selectDate, setSelectDate] = useState(initialDate);
  const [activeTab, setActiveTab] = useState(initialDate.format("YYYY-MM"));
  const [customTab, setCustomTab] = useState(null);

  const [firstDate, setFirstDate] = useState(selectDate.clone().startOf('month').toISOString());
  const [lastDate, setLastDate] = useState(selectDate.clone().endOf('month').toISOString());

  const [fileList, setFileList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [insuranceData, setInsuranceData] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  const [spinning, setSpinning] = useState(false);

  // Update date range and radio button selection when MonthPicker value changes.
  useEffect(() => {
    const month = selectDate.month();
    const year = selectDate.year();
    const newFirstDay = new Date(year, month, 1).toISOString();
    const newLastDay = new Date(year, month + 1, 0).toISOString();
    setFirstDate(newFirstDay);
    setLastDate(newLastDay);

    const tabKey = selectDate.format("YYYY-MM");
    setActiveTab(tabKey);

    // Determine if selectDate is in the default 12-month range
    const currentMonth = moment().startOf('month');
    const defaultEndMonth = currentMonth.clone().add(11, 'months');
    const isInDefaultRange = selectDate.isBetween(currentMonth, defaultEndMonth, 'month', '[]');

    // Only create/update a custom tab if the selected date is outside the default range.
    // Once a custom tab is created, it will persist.
    if (!isInDefaultRange && (!customTab || customTab.key !== tabKey)) {
      setCustomTab({
        key: tabKey,
        label: selectDate.format("MMM-YYYY")
      });
    }
    
    // Persist the MonthPicker selection in the URL.
    const params = new URLSearchParams(location.search);
    params.set('date', selectDate.format('YYYY-MM-DD'));
    history.push({ search: params.toString() });
  }, [selectDate, history, location.search, customTab]);

  // Fetch API data when parameters change.
  useEffect(() => {
    const fetchInsuranceData = async () => {
      setSpinning(true);
      try {
        const response = await platformApi.post('/api/insurance/getInsuranceRenewal', {
          page: page,
          size: limit,
          searchString: search ?? "",
          fromDate: firstDate,
          toDate: lastDate
        });
        const res = response.data.response;
        if (res.code === 200) {
          const insuranceArray = res.data?.insurance;
          setCount(res.data?.count);
          const transformedData = insuranceArray.map((item, index) => {
            const customerInfo = item.vehicle.customer && item.vehicle.customer[0];
            const customerName = customerInfo ? customerInfo.customer.name : '-';
            const customerPhoneNumber =
              customerInfo && customerInfo.customer.contacts && customerInfo.customer.contacts.length > 0
                ? customerInfo.customer.contacts[0].phone
                : '-';
            const vehicleNumber = item.vehicle.registerNo || '-';
            const renewingDate = item.expiryDate ? new Date(item.expiryDate).toLocaleDateString() : '-';
            const typeOfRenewal = item.expiryType || '-';
            return {
              key: item.id,
              serialNo: (page - 1) * limit + index + 1,
              customerName,
              customerPhoneNumber,
              vehicleNumber,
              renewingDate,
              typeOfRenewal,
              rawData: item,
            };
          });
          setInsuranceData(transformedData);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSpinning(false);
      }
    };
    if (activeKey === "1") {
      fetchInsuranceData();
    }
  }, [page, limit, search, firstDate, lastDate, activeKey]);

  const handleUpload = ({ file, fileList }) => {
    const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel';
    if (!isExcel) {
      message.error('You can only upload Excel files!');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error('File must be smaller than 5MB!');
    }
    setFileList([file]);
  };

  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    let menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item
        onClick={() => {
          setLimit(menu);
          setPage(1);
        }}
        key={menu}
      >
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;

  const handleSubmit = async () => {
    if (fileList.length > 0) {
      const formData = new FormData();
      formData.append('xlsxFile', fileList[0]);
      try {
        const response = await platformApi.post('api/insurance/upsertInsurance', formData);
        if (response.status === 200) {
          message.success('File submitted successfully!');
          setFileList([]);
          setActiveKey("1"); // Switch back to the table view
        } else {
          message.error('File submission failed.');
        }
      } catch (error) {
        message.error('File submission failed.');
      }
    } else {
      message.error('Please upload a file before submitting.');
    }
  };

  const draggerProps = {
    name: 'file',
    multiple: false,
    fileList,
    onChange: handleUpload,
    beforeUpload: () => false,
  };

  const generateDefaultTabs = () => {
    const tabs = [];
    const currentMonth = moment().startOf('month');
    for (let i = 0; i < 12; i++) {
      const tabMoment = currentMonth.clone().add(i, 'months');
      tabs.push({
        key: tabMoment.format("YYYY-MM"),
        label: tabMoment.format("MMM-YYYY")
      });
    }
    return tabs;
  };

  const getTabList = () => {
    const defaultTabs = generateDefaultTabs();
    const tabs = [...defaultTabs];
    if (customTab) {
      tabs.unshift(customTab);
    }
    return tabs.slice(0, 13);
  };

  const onTabChange = (value) => {
    setActiveTab(value);
    const newDate = moment(value, "YYYY-MM").startOf('month');
    setSelectDate(newDate);
    setPage(1);
  };

  return (
    <>
      <div className="page-head" style={{ marginBottom: '1rem' }}>
        <Title style={{ width: '60%', marginTop: '0.9rem' }} level={4}>
          Insurance Renewal [{count}]
          <span style={{ marginLeft: '1rem' }}>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button>
                {limit} &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>

        <div style={{ width: "30%" }}>
          <Col span={10}>
            <MonthPicker
              value={selectDate}
              onChange={(date) => {
                if (date) {
                  setSelectDate(date);
                  setPage(1);
                }
              }}
              placeholder="Select month"
              style={{ width: '100%' }}
            />
          </Col>
        </div>
        <Search
          placeholder="Search Vehicle"
          onSearch={(value) => {
            setPage(1);
            setSearch(value);
          }}
          onChange={(event) => {
            if (event.target.value === '') {
              setSearch(null);
              setPage(1);
            }
          }}
          style={{ width: '30%' }}
        />
      </div>

      <Tabs activeKey={activeKey} onChange={setActiveKey}>
        <TabPane tab="View Data" key="1">
          <Radio.Group
            value={activeTab}
            onChange={(e) => onTabChange(e.target.value)}
            optionType="button"
            buttonStyle="solid"
            style={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              marginBottom: '1rem',
              backgroundColor: '#fafafa',
              borderRadius: '4px',
              border: '1px solid #f0f0f0'
            }}
          >
            {getTabList().map((tab, index, arr) => (
              <Radio.Button
                key={tab.key}
                value={tab.key}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '8px 16px',
                  marginRight: 0,
                  borderRadius: 0,
                  borderRight: index !== arr.length - 1 ? 'none' : undefined,
                }}
              >
                {tab.label}
              </Radio.Button>
            ))}
          </Radio.Group>

          <Table
            dataSource={insuranceData}
            columns={columns}
            loading={spinning}
            pagination={{
              onChange: (page) => setPage(page),
              pageSize: limit,
              defaultCurrent: 1,
              total: count,
              current: page,
              showQuickJumper: true,
            }}
            onRow={(record) => ({
              onClick: () => {
                history.push({
                  pathname: `/autoadmin/insurance_renewal_reminder/${record.customerPhoneNumber}`
                });
              },
            })}
          />
        </TabPane>
        <TabPane tab="Upload Files" key="2">
          <Card className="vehicle-upload-card" bordered={false}>
            <Title level={4} className="upload-title">
              Vehicle Insurance Details Upload
            </Title>
            <Dragger {...draggerProps} className="upload-dragger">
              <>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag Excel file to this area
                </p>
                <p className="ant-upload-hint">
                  Upload a vehicle detail Excel file (Max 5MB)
                </p>
              </>
            </Dragger>
            <Button type="primary" onClick={handleSubmit} style={{ marginTop: 16 }}>
              Submit
            </Button>
          </Card>
        </TabPane>
      </Tabs>
    </>
  );
};

const WrappedInsuranceRenewalReminder = Form.create("insurance_renewal_reminder")(InsuranceReminder);
export default WrappedInsuranceRenewalReminder;
