import React, { useState, useEffect } from "react";
import { CloseOutlined, PhoneOutlined } from "@ant-design/icons";
import {
  answerincommingCall,
  declineIncommingCall,
  piopiy,
} from "../../utils/piopiy";

function CallPopup({ flag, setflag, attended, mobileNumber = "9876543210" }) {
  const [callDuration, setCallDuration] = useState(0);
  const [animationState, setAnimationState] = useState("entering");

  useEffect(() => {
    let intervalId;
    if (attended) {
      intervalId = setInterval(() => {
        setCallDuration((prev) => prev + 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [attended]);

  useEffect(() => {
    if (flag) {
      setCallDuration(0);
      setAnimationState("entering");
    }
  }, [flag]);

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleAccept = () => {
    answerincommingCall(piopiy);
  };

  const handleReject = () => {
    setAnimationState("exiting");
    setTimeout(() => {
      setflag(false);
      declineIncommingCall(piopiy);
      setCallDuration(0);
    }, 300); // Wait for exit animation to complete
  };

  if (!flag) return null;

  const containerStyle = {
    backgroundColor: "white",
    padding: "1rem",
    position: "fixed",
    zIndex: 500,
    border: "solid 2px #e0e0e0",
    borderRadius: "12px",
    right: "2vw",
    top: "10vh",
    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
    width: "350px",
    maxWidth: "90vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transition: "all 0.3s ease",
    height: "95px",
    animation: `${
      animationState === "entering"
        ? "fadeInRight 0.3s forwards"
        : "fadeOutRight 0.3s forwards"
    }`,
  };

  const headerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "1rem",
  };

  const avatarStyle = {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "2px solid #f0f0f0",
  };

  const callerInfoStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    marginLeft: "1rem",
    marginRight: "1rem",
  };

  const callerNameStyle = {
    fontWeight: "bold",
    fontSize: "18px",
    color: "#333",
    marginBottom: "5px",
    textAlign: "center",
  };

  const callerStatusStyle = {
    color: "#666",
    fontSize: "14px",
    animation: "pulse 1.5s infinite",
    textAlign: "center",
  };

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "center",
    gap: "1rem",
    marginTop: "0.5rem",
  };

  const getButtonStyle = (backgroundColor) => ({
    backgroundColor,
    border: "none",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "all 0.2s ease",
    color: "white",
    fontSize: "24px",
    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
  });

  const handleButtonHover = (e, scale) => {
    e.target.style.transform = `scale(${scale})`;
  };

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <img
          src={
            "https://img.freepik.com/premium-vector/social-media-logo_1305298-29989.jpg?ga=GA1.1.1780620384.1735468347&semt=ais_hybrid"
          }
          alt={"userImage"}
          style={avatarStyle}
        />
        <div style={callerInfoStyle}>
          <div style={callerNameStyle}>{mobileNumber}</div>
          <div style={callerStatusStyle}>
            {attended
              ? `Call Duration: ${formatTime(callDuration)}`
              : "Incoming Call"}
          </div>
        </div>
        <div style={buttonContainerStyle}>
          {!attended && (
            <button
              onClick={handleAccept}
              style={getButtonStyle("#00EC32")}
              onMouseOver={(e) => handleButtonHover(e, 1.1)}
              onMouseOut={(e) => handleButtonHover(e, 1)}
            >
              <PhoneOutlined />
            </button>
          )}
          <button
            onClick={handleReject}
            style={getButtonStyle("#E25D53")}
            onMouseOver={(e) => handleButtonHover(e, 1.1)}
            onMouseOut={(e) => handleButtonHover(e, 1)}
          >
            <CloseOutlined />
          </button>
        </div>
      </div>

      <style>{`
        @keyframes pulse {
          0%, 100% { opacity: 1; }
          50% { opacity: 0.6; }
        }
        
        @keyframes fadeInRight {
          from {
            opacity: 0;
            transform: translateX(100px);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        }
        
        @keyframes fadeOutRight {
          from {
            opacity: 1;
            transform: translateX(0);
          }
          to {
            opacity: 0;
            transform: translateX(100px);
          }
        }
      `}</style>
    </div>
  );
}

export default CallPopup;
